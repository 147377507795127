import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { getSiteUrl } from '../../common/Global';

export default function (ComposedComponent) {
    // If user not authenticated render out to root
    class Authentication extends PureComponent {
        static contextTypes = {
            router: PropTypes.object
        };
        render() {
            // isSessionLogin : when user session created and not signedin, for e.g. disclaimer 
            const { isAuthenticated } = this.props.LoginReducer;

            if (isAuthenticated !== true) {
                return <Redirect to={{ pathname: getSiteUrl('/'), state: { from: this.props.location.pathname } }} />;
            }
            else {
                return (
                    <Fragment>
                        <ComposedComponent {...this.props} />
                    </Fragment>
                );

            }
        }
    }

    return connect(state => state)(Authentication);
}