const initialstate = {
    mailStatusData: null,
    filters: {
        mailCategory: "All",
        mailStatus: "All",
        pageNumber: "1",
        mailPerPage: "10",
        startDate: null,
        endDate: null,
        isLoading:''
    }
}


const MailStatusReducer = (state = initialstate, action) => {

    switch (action.type) {
        case 'SET_MAILSTATUS_DATA':
            return {
                ...state, mailStatusData: action.payload,isLoading:false
            };
        case 'SET_MAILSTATUS_FILTERS':
            return {
                ...state, filters: action.filters
            };
        case 'GET_MAILSTATUS_DATA':
            return Object.assign({}, state, { isLoading: true });
        default:
            return state;
    }
}

export default MailStatusReducer;