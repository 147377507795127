
const initialState = {

    bhajanGalleryDirPath: [],
    SectionName:'',

}

const AudioAlbumReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_BHAJAN_GALLERY_DIR_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_BHAJAN_GALLERY_DIR_NAME':
            return Object.assign({}, state, { bhajanGalleryDirPath: action.BhajanGalleryDirPath.audioDirectoryPath, isLoading: false, SectionName: action.BhajanGalleryDirPath.section });

        default:
            return state;
    }

}

export default AudioAlbumReducer;
