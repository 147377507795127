import React from 'react';
import { Media } from 'reactstrap';
import { setApproveStatus, resetFilters } from '../../../actions/MemberDirectoryAction';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import WebsiteIcon from '../../../images/web-icon.png';
import ApprovedMemberDirectory from './ApprovedMemberDirectory'
import PendingMemberDirectory from './PendingMemberDirectory'

class MemberDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    openRegistrtationPage = () => {
        const win = window.open(process.env.PUBLIC_URL + "/Register", "_blank");
        win.focus();
    }

    handleStatus = (status) => {
        this.props.setApproveStatus(status);
        this.props.resetFilters();
    }

    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center pb-2 mb-4 page-title-wrapper">
                    <div className="w-100 my-md-2 d-flex justify-content-between flex-column flex-md-row">
                        <h1 className="text-primary">Members Directory</h1>
                        <div className="d-flex flex-row align-items-center align-self-end mb-2 mb-md-0">
                            <span className="pr-2">New Member Form</span>
                            <span className="member-directory-icon rounded border border-dark p-1">
                                <Media className="img-fluid" src={WebsiteIcon} alt="register" onClick={() => this.openRegistrtationPage()} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column w-100 mt-3">
                    <div className="status-button">
                        <button onClick={() => this.handleStatus(false)} className={!this.props.approve ? "selected-status" : ""}>Pending</button>
                        <button onClick={() => this.handleStatus(true)} className={this.props.approve ? "selected-status" : ""}>Approved</button>
                    </div>
                    {
                        this.props.approve ? <ApprovedMemberDirectory/> : <PendingMemberDirectory/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        approve: state.MemberDirectoryReducer.approve,
    };
}

const mapDispatchToProps = dispatch => ({
    setApproveStatus: (status) => {
        dispatch(setApproveStatus(status));
    },
    resetFilters: () => {
        dispatch(resetFilters());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDirectory);

