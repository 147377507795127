import React from 'react';
import { connect } from 'react-redux';
import { Media, } from 'reactstrap';
import SaveIcon from '../../images/save-icon.png';
import EditIcon from '../../images/edit-icon.png';
import CloseIcon from '../../images/close-icon.png';
import { setShowLayover, getEditedMember } from '../../actions/MatrimonialAction';
import { AjaxLoader } from '../AjaxLoader'
import EditMatrimonial from './EditMatrimonial'
import nextId from "react-id-generator";
import ReactTooltip from 'react-tooltip';

class MatrimonialDetailElement extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            editForm: false
        };
    }

    ChangeEditMode = (isEditMode) => {
        if (isEditMode) {
            this.props.setShowLayover(false);
            this.props.getEditedMember(parseInt(this.props.member.rowId));
        }
        this.setState({ editForm: isEditMode });
    }

    setShowLayover = (showLayover) => {
        this.props.setShowLayover(showLayover);
    }

    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    render() {
        return (
            <div className="row single-member-detail position-relative no-gutters">
                <div onClick={() => this.ChangeEditMode(!this.isEditMode)} className={`member-detail-edit ${!this.state.editForm ? "d-block" : "d-none"}`}>
                    <span className="member-detail-icon" data-tip="Edit"><Media className="img-fluid" src={EditIcon} alt="edit" /></span>
                    
                </div>
                <div className={`member-detail-edit ${this.state.editForm ? "d-block" : "d-none"}`}>
                    <span className={`member-detail-icon ${this.props.showLayover ? "d-none" : " "}`} data-tip="Save"><Media className="img-fluid" src={SaveIcon} alt="save" onClick={() => this.EditMatrimonial.doUpdate()} /></span>
                    <span className="member-detail-icon" data-tip="Close"><Media className="img-fluid" src={CloseIcon} onClick={() => this.ChangeEditMode(!this.state.editForm)} alt="close" /></span>
                </div>
                <ReactTooltip effect="solid" place="bottom" type="error" border="true" />
                <div className={`${this.state.editForm ? "d-none" : "d-flex"} py-5 px-md-2 px-xl-5 flex-wrap w-100 `}>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personName) ? "--" : this.props.member.personName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Gender</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.gender) ? "--" : this.props.member.gender}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Date Of Birth</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.agedob) ? "--" : this.props.member.agedob}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Father/Mother Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.fatherMotherName) ? "--" : this.props.member.fatherMotherName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Caste</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.caste) ? "--" : this.props.member.caste}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Address Residence</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressRes) ? "--" : this.props.member.addressRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Phone Residence</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneRes) ? "--" : this.props.member.phoneRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Country</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.countryName) ? "--" : this.props.member.countryName}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">State</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stateName) ? "--" : this.props.member.stateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mobile</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.mobile) ? "--" : this.props.member.mobile}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Stdcode</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stdcode) ? "--" : this.props.member.stdcode}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Email</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.email) ? "--" : this.props.member.email}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Qualification</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.qualification) ? "--" : this.props.member.qualification}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Highest Degree</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.highestDegree) ? "--" : this.props.member.highestDegree}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Qualification</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherQualication) ? "--" : this.props.member.otherQualication}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Related Details</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherRelatedDetails) ? "--" : this.props.member.otherRelatedDetails}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">City</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.cityName) ? "--" : this.props.member.cityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Job Status</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.jobStatus) ? "--" : this.props.member.jobStatus}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Job Location</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.jobLocation) ? "--" : this.props.member.jobLocation}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Annual Income</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.anualIncome) ? "--" : this.props.member.anualIncome}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Job Details</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherJobDetails) ? "--" : this.props.member.otherJobDetails}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Marital Status</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.maritalStatus) ? "--" : this.state.maritalStatus}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mother Tongue</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.motherTongue) ? "--" : this.props.member.motherTongue}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Height</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.height) ? "--" : this.props.member.height}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Complexion</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.complexion) ? "--" : this.props.member.complexion}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Body Type</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.bodyType) ? "--" : this.props.member.bodyType}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Smoke</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.smoke) ? "--" : this.props.member.smoke}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Drink</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.drink) ? "--" : this.props.member.drink}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Personal values</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personalValues) ? "--" : this.props.member.personalValues}</div>
                        </div>
                    </div>
                </div>
                <div className={`${this.state.editForm ? "d-flex" : "d-none"} py-5 px-md-2 px-xl-5 flex-wrap w-100`}>
                    <AjaxLoader isOpen={this.props.singleMemberLoader} />
                    <div className={`edit-form-layover justify-content-center align-items-center ${this.props.showLayover ? 'd-flex' : 'd-none'} `} onClick={() => this.setShowLayover(false)}><span>{this.props.updateMessage}</span></div>
                    <EditMatrimonial id={nextId()} member={this.props.editedMember !== null && this.props.member.rowId === this.props.editedMember.rowId ? this.props.editedMember : this.props.member} onRef={ref => (this.EditMatrimonial = ref)} />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        singleMemberLoader: state.MatrimonialReducer.singleMemberLoader,
        showLayover: state.MatrimonialReducer.showLayover,
        updateMessage: state.MatrimonialReducer.updateMessage,
        editedMember: state.MatrimonialReducer.editedMember
    };
};

const mapDispatchToProps = dispatch => ({
    setShowLayover: (showlayover) => {
        dispatch(setShowLayover(showlayover));
    },
    getEditedMember: (rowId) => {
        dispatch(getEditedMember(rowId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialDetailElement);

