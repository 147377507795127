import React from "react";
import { Media } from 'reactstrap';

class PushtimargElement extends React.Component {
    render() {
        var imgSource = this.props.img === "" ? "" : <Media src={require("../../images/pushtimarg/" + this.props.img)} alt={this.props.header} className="img-fluid img-left" />;
        return (
            <div className="row pt-3 pb-4">
                <div className="col-12">{imgSource}{this.props.content.split('<br/>').map(line => <p>{line}</p>)}</div>
            </div>);
    }
}
export default PushtimargElement;
