import React from "react";
import GalleryHeader from './GalleryHeader';
import AudioGallery from "./AudioGallery";
import VideoGallery from "./VideoGalleryComponent";
import PhotoGallery from "./PhotoGallery";
import DocumentGallery from "./DocumentGallery";

class Gallery extends React.Component {
    showGallery = () => {
        var GalleryType = Object.freeze({ "AudioGallery": "Audios", "VideoGallery": "Videos", "PhotoGallery": "Photos", "DocumentGallery": "Documents" })
        const queryString = require('query-string');


        if (GalleryType.AudioGallery == queryString.parse(this.props.location.search).Type) {
            return (
                <div>
                    <GalleryHeader isActive='1' />
                    <AudioGallery />
                </div>
            );
        }

        else if (GalleryType.VideoGallery == queryString.parse(this.props.location.search).Type) {
            return (
                <div>
                    <GalleryHeader isActive='2' />
                    <VideoGallery />
                </div>
            );
        }

        else if (GalleryType.PhotoGallery == queryString.parse(this.props.location.search).Type) {
            return (
                <div>
                    <GalleryHeader isActive='3' />
                    <PhotoGallery />
                </div>
            );
        }
        else if (GalleryType.DocumentGallery == queryString.parse(this.props.location.search).Type) {
            return (
                <div>
                    <GalleryHeader isActive='4' />
                    <DocumentGallery />
                </div>
            );
        }
        else {
            return (
                <div>
                    <GalleryHeader isActive='1' />
                    <AudioGallery />
                </div>
            );
        }


    }

    render() {

        return (

            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                {this.showGallery()}
            </div>
        );
    }
}

export default Gallery;