import React from 'react';
import { connect } from 'react-redux';
import DocumentDirectory from '../components/Documents/DocumentDirectory';
import { documentGalleryDirPath } from '../actions/DocumentDirectoryAction';
class DocumentGallery extends React.Component {
    componentDidMount() {
        this.props.documentGalleryDirPath();
    }

    showDocumentComponent = () => {
        if (this.props.documentDirPath != null && this.props.documentDirPath != '') {
            return (
                <DocumentDirectory DocumentDirectoryPath={this.props.documentDirPath} SectionName={this.props.sectionName} />
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.showDocumentComponent()}
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        documentDirPath: state.DocumentDirectoryReducer.documentGalleryDirPath,
        isLoading: state.DocumentDirectoryReducer.isLoading,
        sectionName: state.DocumentDirectoryReducer.sectionName,
    };
};

const mapDispatchToProps = dispatch => ({
    documentGalleryDirPath: () => {
        dispatch(documentGalleryDirPath());
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(DocumentGallery);

