import React from 'react';
import { connect } from 'react-redux';
import { folderImage } from '../../actions/ImageAction';
import "react-image-gallery/styles/css/image-gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LightBox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { If } from 'rc-if-else';

class ImageCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            isOpen: true,
            photoIndex: 0,
            prevPath: '',
            filteredList: [],
            imageRootPath:[],
        };
    }

    componentWillMount() {
        if (this.props.location.search !== null) {
            this.setState({ path: decodeURIComponent(this.props.location.search.substring(1)) });
        }
    }

    componentDidMount() {
        
        this.state.imageRootPath = this.state.path.split("/");
        var n = this.state.path.lastIndexOf('/');
        var result = this.state.path.substring(n + 1);
        if (this.props.location.search.search('ChaturShlokiGranth') === -1) {
            this.setState({ photoIndex: this.props.imageGalleryFilesName.indexOf(result) });
            this.setState({ filteredList: this.props.imageGalleryFilesName });
        } else {
            this.setState({ photoIndex: this.props.memberSectionImageFilesName.indexOf(result) });
            this.setState({ filteredList: this.props.memberSectionImageFilesName });
        }          
     
        let rootPath = this.state.imageRootPath[0] + "/" + this.state.imageRootPath[1]; 
        if (this.props.location.search !== null) {
            this.props.folderImage(rootPath);            
        }
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.imageGalleryFilesName !== prevProps.imageGalleryFilesName || this.props.memberSectionImageFilesName !== prevProps.memberSectionImageFilesName) {
            if (this.props.location.search.search('ChaturShlokiGranth') === -1) {
                this.setState({ filteredList: this.props.imageGalleryFilesName });
            }
            else {
                this.setState({ filteredList: this.props.memberSectionImageFilesName });
            }
        }
        if (!this.state.isOpen && this.props.location.search.search('ChaturShlokiGranth') === -1) {
            this.props.history.push(process.env.PUBLIC_URL + "/ImageFiles?" + this.state.imageRootPath[0] + "/" + this.state.imageRootPath[1]);
        }
        else if (!this.state.isOpen && this.props.location.search.search('ChaturShlokiGranth') !== -1) {
            this.props.history.push(process.env.PUBLIC_URL + '/ChaturShlokiGranth');
        }
    }
    render() {      
        const { photoIndex, isOpen, filteredList } = this.state;
      
        let images;
      
        if (filteredList !== null) {
            images = filteredList.map(image => (process.env.PUBLIC_URL + "/Media/" + this.state.imageRootPath[0] + "/" + this.state.imageRootPath[1] +"/" + this.state.imageRootPath[2] +"/" + image));
        }
        return (
            <If condition={isOpen} >
                {images.length > 0 ?
                    <React.Fragment>
                        <LightBox
                            mainSrc={`${images[photoIndex]}`}
                            nextSrc={`${images[(photoIndex + 1) % images.length]}`}
                            prevSrc={`${images[(photoIndex + images.length - 1) % images.length]}`}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                })
                            }
                        />  </React.Fragment> :
                    <div />}
            </If>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        imageGalleryFilesName: state.ImageReducer.ImageGalleryFilesName,
        memberSectionImageFilesName: state.ImageReducer.MemberSectionImageFilesName,
        folderName: state.ImageReducer.selectedFolderPath,
        isLoading: state.ImageReducer.isLoading,
    };
};

const mapDispatchToProps = dispatch => ({
    folderImage: (selectedFolderPath) => {
        dispatch(folderImage(selectedFolderPath));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel);



