import React from "react";
import PushtimargElement from './PushtimargElement';
import Pagination from "react-js-pagination";
import PushtimargData from './PushtimargData.json';
import PushtimargDataDetail from './PustimargDetailData.json';
import PushtimargDetail from './PustimargDetail';
import { SetCurrentPageNumber } from '../../actions/PushtimargDetailAction';
import { connect } from 'react-redux';
import Chittabhed from './Chittabhed';
import Vanchanamrut from './Vanchanamrut'
import DefaultSectionHeader from '../../components/DefaultSectionHeader';
class PushtimargCollection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
            activePage: 1
        }
    }
   
    componentWillMount() {
        this.showSection(this.state.activePage);
        this.props.SetCurrentPageNumber(1, false);
    }

    showSection = (pageNumber) => {
        switch (pageNumber) {
            case 1:
                this.GetPageContent(0, 8);
                break;
            case 2:
                this.GetPageContent(8, 16);
                break;
            case 3:
                this.GetPageContent(16, 22);
                break;
            default:
                break;
        }
    }

    getSection = (startIndex, endIndex) => {
        var content = [];
        for (var i = startIndex; i < endIndex; i++) {
            content.push(
                <div className="col-md-12 col-xl-6 pt-2 pb-2 pushtimarg-block" key={i} >
                    <PushtimargElement header={PushtimargData[i].header} content={PushtimargData[i].content} img={PushtimargData[i].img} id={PushtimargData[i].Id} />
                </div>
            );
        }
        return (<div className="row p-0 m-0">{content}</div>);
    }

    GetPageContent = (startIndex, endIndex) => {
        this.setState({ data: this.getSection(startIndex, endIndex) });
    }

    handlePageClick = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.showSection(pageNumber);
    }

    getPushtimargDetail = () => {
        if (this.props.currentPageNumber !== undefined && this.props.showDetail) {
            var detailContent = [];
            var currentPageNumber = this.props.currentPageNumber;
            let pushtimargDetail = PushtimargDataDetail.filter((object) => {
                return object.Index === currentPageNumber;
            });
            detailContent.push(<div className="row no-gutters pb-3 mt-2 mb-4 position-relative page-title-wrapper"><div className="col-sm-12"><div className="pb-2"><span className="root-header pr-2" onClick={this.showSectionHistory}>History of Pustimarg</span> > <span className="">{pushtimargDetail[0].header}</span></div> <h1 className="text-primary">{pushtimargDetail[0].header}</h1></div ></div >)
            for (var i = 0; i < pushtimargDetail[0].content.length; i++) {
                detailContent.push(<PushtimargDetail img={pushtimargDetail[0].img[i]} content={pushtimargDetail[0].content[i]} header={pushtimargDetail[0].header}></PushtimargDetail>);
            }
            if (this.props.currentPageNumber === 7) {
                detailContent.push(<div className="text-center"><b>Chitta Bhed ( Classification of heart )</b> <br /><b>As conceived by Shri Gokuleshji</b></div>);
                detailContent.push(<Chittabhed />);
            }
            if (this.props.currentPageNumber === 8) {
                detailContent.push(<Vanchanamrut />);
            }
        }
        return detailContent;
    }

    showSectionHistory = () => {
        this.props.SetCurrentPageNumber(1, false);
    }

    handlePreviousPageClick = () => {
        if (this.props.currentPageNumber > 1) {
            this.props.SetCurrentPageNumber(this.props.currentPageNumber - 1, true);
        }
    }

    handleNextPageClick = () => {
        if (this.props.currentPageNumber < 22) {
            this.props.SetCurrentPageNumber(this.props.currentPageNumber + 1, true);
        }
    }


    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className={`${!this.props.showDetail ? 'd-block' : 'd-none'} `}>
                    <DefaultSectionHeader title="History of Pushtimarg"/>
                    <div className="h-100 w-100 d-block d-md-none pushtimarg-collection-content">
                        {this.getSection(0, PushtimargData.length)}
                    </div>
                    <div className="d-none d-md-block">
                        <div className="pushtimarg-collection-content">
                            {this.state.data}
                        </div>
                        <div className="pagination-section d-flex justify-content-end">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={1}
                                totalItemsCount={3}
                                onChange={this.handlePageClick}
                                prevPageText="Previous"
                                nextPageText="Next"
                                itemClassPrev="previous"
                                itemClassNext="next"
                                itemClassFirst="firstPageText"
                                itemClassLast="lastPageText"
                                activeClass="activepage"
                            />
                        </div>
                    </div>
                </div>
                <div className={`${this.props.showDetail ? 'd-block' : 'd-none'} `} >
                    <div className="pushtimarg-detail-content">{this.getPushtimargDetail()}</div>
                    <div className="d-flex justify-content-end pt-3"><button onClick={this.handlePreviousPageClick} className={`detail-button ${this.props.currentPageNumber === 1 ? "d-none" : "d-block"}`}>Previous</button><button onClick={this.handleNextPageClick} className={`detail-button ${this.props.currentPageNumber === 22 ? "d-none" : "d-block"}`}>Next</button></div>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    SetCurrentPageNumber: (currentPageNumber, showDetail) => {
        dispatch(SetCurrentPageNumber(currentPageNumber, showDetail));
    }
});

const mapStateToProps = (state) => {
    return {
        currentPageNumber: state.PushtimargDetailReducer.currentPageNumber,
        showDetail: state.PushtimargDetailReducer.showDetail
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushtimargCollection)

