import axios from 'axios';

export const SetMembersNationalityType = (isIndian) => {
    return function (dispatch) {
        dispatch(SetNationalityType(isIndian));
    }
}

export const SetNationalityType = (isIndian) => {
    return {
        type: "SET_MATRIMONIAL_NATIONALITY_TYPE", isIndian
    };
}

export const setSortProperty = (isIncreasingOrder) => {
    return function (dispatch) {
        dispatch(setSortPropertyOfMemberDetails(isIncreasingOrder));
    }
}

export const setSortPropertyOfMemberDetails = (isIncreasingOrder) => {
    return {
        type: "SET_MATRIMONIAL_SORT_PROPERTY", isIncreasingOrder
    };
}

export const setMemberPerPage = (memberPerPage) => {
    return function (dispatch) {
        dispatch(setMemberPerPageReducer(memberPerPage));
    }
}

export const setMemberPerPageReducer = (memberPerPage) => {
    return {
        type: "SET_MATRIMONIAL_PER_PAGE_COUNT", memberPerPage
    };
}

export const searchMemberDetails = (matrimonialSearchFilters) => {
    return function (dispatch) {
        searchMemberDetailsWithFilters('/api/matrimonial/searchMemberDetails', matrimonialSearchFilters, dispatch)
    };
}

const searchMemberDetailsWithFilters = (url, matrimonialSearchFilters, dispatch) => {
    dispatch(setSearchInProgress());
    axios.post(process.env.PUBLIC_URL + url, matrimonialSearchFilters)
        .then(function (response) {
            const message = response.data;
            dispatch(setmemberDetails(message));
        })
}

const setmemberDetails = (message) => {
    return {
        type: 'SET_MATRIMONIAL_DETAILS',
        payload: message
    };
}


export const searchApprovedMatrimonialDetailsForAdmin = (matrimonialSearchFilters) => {
    return function (dispatch) {
        searchMemberDetailsWithFiltersForAdmin('/api/matrimonial/SearchApprovedMatrimonialDetailsForAdmin', matrimonialSearchFilters, dispatch, setApprovedMemberDetailsForAdmin)
    };
}

export const searchPendingMatrimonialDetailsForAdmin = (matrimonialSearchFilters) => {
    return function (dispatch) {
        searchMemberDetailsWithFiltersForAdmin('/api/matrimonial/SearchPendingMatrimonialDetailsForAdmin', matrimonialSearchFilters, dispatch, setPendingMemberDetailsForAdmin)
    };
}


const setPendingMemberDetailsForAdmin = (reponse) => {
    return {
        type: 'SET_PENDING_MATRIMONIAL_DETAILS_ADMIN',
        payload: reponse
    };
}

const searchMemberDetailsWithFiltersForAdmin = (url, memberSearchFilters, dispatch, setDetails) => {
    dispatch(setSearchInProgress());
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            const data = response.data;
            dispatch(setDetails(data));
        })
}

const setApprovedMemberDetailsForAdmin = (reponse) => {
    return {
        type: 'SET_APPROVED_MATRIMONIAL_DETAILS_ADMIN',
        payload: reponse
    };
}



export const deleteApprovedMatrimonialFromDirectory = (matrimonialSearchFilters) => {
    return function (dispatch) {
        deleteMember('/api/matrimonial/DeleteApprovedMatrimonial', matrimonialSearchFilters, dispatch, deleteApprovedMemberForAdmin)
    };
}

export const deletePendingMatrimonialFromDirectory = (matrimonialSearchFilters) => {
    return function (dispatch) {
        deleteMember('/api/matrimonial/DeletePendingMatrimonial', matrimonialSearchFilters, dispatch, deletePendingMemberForAdmin)
    };
}

const deleteMember = (url, matrimonialSearchFilters, dispatch, deleteDetails) => {
    dispatch(setSingleMemberLoader(true));
    axios.post(process.env.PUBLIC_URL + url, matrimonialSearchFilters )
        .then(function (response) {
            if (response.data) {
                dispatch(deleteDetails(matrimonialSearchFilters));
            }
            else {
                dispatch(setSingleMemberLoader(false));
                dispatch(setShowLayover(true));
                dispatch(setUpdateMessage("Some error occured"));
            }
        })
}

export const approveMemberFromDirectory = (matrimonialSearchFilters) => {
    return function (dispatch) {
        approveMember('/api/matrimonial/approveMember', matrimonialSearchFilters, dispatch)
    };
}

const approveMember = (url, matrimonialSearchFilters, dispatch) => {
    dispatch(setSingleMemberLoader(true));
    axios.post(process.env.PUBLIC_URL + url, matrimonialSearchFilters)
        .then(function (response) {
            
            if (response.data) {
                dispatch(approveMemberForAdmin(matrimonialSearchFilters.ID));
            }
            else {
                dispatch(setSingleMemberLoader(false));
                dispatch(setShowLayover(true));
                dispatch(setUpdateMessage("Some error occured"));
            }
        })
}


const deletePendingMemberForAdmin = (matrimonialSearchFilters) => {
    return {
        type: 'DELETE_MEMBER_PENDING_MATRIMONIAL',  id: matrimonialSearchFilters.id
    };
}
const deleteApprovedMemberForAdmin = (matrimonialSearchFilters) => {
    return {
        type: 'DELETE_MEMBER_APPROVED_MATRIMONIAL',  rowId: matrimonialSearchFilters.rowId 
    };
}
export const approveMemberForAdmin = (id) => {
    return {
        type: 'APPROVE_MEMBER_MATRIMONIAL', id
    };
}

export const getEditedMember = (rowId) => {
    return function (dispatch) {
        setEditedmemberInReducer('/api/matrimonial/getEditedMember', rowId, dispatch)
    };
}

const setEditedmemberInReducer = (url, rowId, dispatch) => {
    dispatch(setSingleMemberLoader(true));
    axios.get(process.env.PUBLIC_URL + url, {
        params: {
            "rowId": rowId
        }
    })
        .then(function (response) {
            dispatch(setEditedMember(response.data));
        })
}

const setEditedMember = (message) => {
    return {
        type: 'SET_EDITED_MEMBER',
        payload: message
    };
};

export const setpageNumber = (pageNumber) => {
    return function (dispatch) {
        dispatch(setpageNumbrReducer(pageNumber));
    }
}

export const setpageNumbrReducer = (pageNumber) => {
    return {
        type: "SET_MATRIMONIAL_PAGE_NUMBER", pageNumber
    };
}

export const updateMemberDetails = (updateDetails) => {
    return function (dispatch) {
        dispatch(setSingleMemberLoader(true));
        updateMemberDetailsReducer('/api/matrimonial/UpdateDetail', updateDetails, dispatch)
    };
}

const updateMemberDetailsReducer = (url, updateDetails, dispatch) => {
    axios.post(process.env.PUBLIC_URL + url, updateDetails)
        .then(function (response) {
            dispatch(setSingleMemberLoader(false));
            dispatch(setUpdateMessage(response.data.message));
            dispatch(setShowLayover(true));
        })
}

export const registerMemberDetails = (registerDetails) => {
    return function (dispatch) {
        dispatch(setRegisterInProgress(true));
        registerMemberDetailsReducer('/api/matrimonial/Register', registerDetails, dispatch)
    };
}

const registerMemberDetailsReducer = (url, registerDetails, dispatch) => {
    axios.post(process.env.PUBLIC_URL + url, registerDetails)
        .then(function (response) {
            if (response.data.isSuccessfull) {
                dispatch(setRegisterMessage(response.data.message, true));
            }
            else {
                dispatch(setRegisterMessage(response.data.message, false));
            }
        });
}

export const setSearchInProgress = () => {
    return {
        type: "SET_MATRIMONIAL_SEARCH_PROGRESS"
    };
}

export const setSingleMemberLoader = (isLoading) => {
    return {
        type: "SET_MATRIMONIAL_SINGLE_MEMBER_LOADER", isLoading
    };
}

export const setShowLayover = (showLayover) => {
    return {
        type: "SET_MATRIMONIAL_SHOW_LAYOVER", showLayover
    };
}

export const setRegisterInProgress = (isLoading) => {
    return {
        type: "SET_REGISTER_IN_PROGRESS", isLoading
    };
}

export const setRegisterMessage = (message, isSuccessfull) => {
    return {
        type: "SET_REGISTER_MESSAGE", message, isSuccessfull
    };
}


export const setUpdateMessage = (message) => {
    return {
        type: "SET_MATRIMONIAL_UPDATE_MESSAGE", message
    };
}


export const setAdminFilters = (country, city, approve, isNew, startDate, endDate) => {
    return {
        type: "SET_ADMIN_FILTERS_MATRIMONIAL", country, city, approve, isNew, startDate, endDate
    };
}

export const getCityList = (nationality) => {
    return function (dispatch) {
        axios.post(process.env.PUBLIC_URL + '/api/matrimonial/getCityList', nationality)
            .then(function (response) {
                const message = response.data;
                dispatch(setCityList(message));
            });
    };
}

export const getCityListForAdmin = (countryName) => {
    
    return function (dispatch) {
        axios.get(process.env.PUBLIC_URL + '/api/matrimonial/getCityListForAdmin', { params: { countryName } })
            .then(function (response) {
                const message = response.data;
                dispatch(setCityList(message));
            });
    };
}

const setCityList = (message) => {
    return {
        type: 'SET_CITY_LIST_MATRIMONIAL',
        payload: message
    };
}