import React from 'react';
import { connect } from 'react-redux';
import { Media } from 'reactstrap';
import SaveIcon from '../../images/save-icon.png';
import EditIcon from '../../images/edit-icon.png';
import CloseIcon from '../../images/close-icon.png';
import { updateMemberDetails, setShowLayover, getEditedMember } from '../../actions/MemberDirectoryAction';
import { AjaxLoader } from '../AjaxLoader'
import EditMember from './EditMember';
import nextId from "react-id-generator";
import ReactTooltip from 'react-tooltip';
var dateFormat = require('dateformat');

class MemberDetailElement extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            editForm: false
        };
    }

    ChangeEditMode = (isEditMode) => {
        if (isEditMode) {
            this.props.setShowLayover(false);
            this.props.getEditedMember(parseInt(this.props.member.rowId));
        }
        this.setState({ editForm: isEditMode });
    }

    setShowLayover = (showLayover) => {
        this.props.setShowLayover(showLayover);
    }

    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    render() {
        return (
            <div className="row single-member-detail position-relative no-gutters">
                <div onClick={() => this.ChangeEditMode(!this.isEditMode)} className={`member-detail-edit ${!this.state.editForm ? "d-block" : "d-none"}`}>
                    <span className="member-detail-icon" data-tip="Edit"><Media className="img-fluid" src={EditIcon} alt="edit" /></span>
                </div>
                <div className={`member-detail-edit ${this.state.editForm ? "d-block" : "d-none"}`}>
                    <span className={`member-detail-icon ${this.props.showLayover ? "d-none" : ""}`} data-tip="Save">
                        <Media className="img-fluid" src={SaveIcon} alt="save" onClick={() => this.EditMember.doUpdate()} data-tip="Save"/>
                    </span>
                    <span className="member-detail-icon" data-tip="Close"><Media className="img-fluid" src={CloseIcon} data-tip="Close" onClick={() => this.ChangeEditMode(!this.state.editForm)} alt="close" /></span>
                </div>
                <ReactTooltip effect="solid" place="bottom" type="error" border="true" />
                <div className={`${this.state.editForm ? "d-none" : "d-flex"} py-5 px-md-2 px-xl-5 flex-wrap w-100`}>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className=" text-muted">Name</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personName) ? "--" : this.props.member.personName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Father/Mother Name</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.fatherMotherName) ? "--" : this.props.member.fatherMotherName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Blood Group</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.bloodGroup) ? "--" : this.props.member.bloodGroup}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Profession</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.profession) ? "--" : this.props.member.profession}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">About Profession</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.description) ? "--" : this.props.member.description}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Experience</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.experience) ? "--" : this.props.member.experience}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current Country</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.countryName) ? "--" : this.props.member.countryName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native Country</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perCountryName) ? "--" : this.props.member.perCountryName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Name of Spouse</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.spouseName) ? "--" : this.props.member.spouseName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Caste</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.caste) ? "--" : this.props.member.caste}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Date Of Birth</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.ageDob) ? "--" : this.props.member.ageDob}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mobile</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.mobile) ? "--" : <a href={"callto:" + this.props.member.mobile}>{this.props.member.mobile}</a>}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Phone</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneRes) ? "--" : <a href={"callto:" + this.props.member.phoneRes}>{this.props.member.phoneRes}</a>}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current State</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stateName) ? "--" : this.props.member.stateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native State</div>
                            <div className="">{this.isInvalidPropsValue(this.props.member.perStateName) ? "--" : this.props.member.perStateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">STD Code</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stdcode) ? "--" : this.props.member.stdcode}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Email</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.email) ? "--" : this.props.member.email}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Phone(Shop/Office)</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneShop) ? "--" : <a href={"callto:" + this.props.member.phoneShop}>{this.props.member.phoneShop}</a>}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Type Of Sewa</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.service) ? "--" : this.props.member.service}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Website</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.website) ? "--" : this.props.member.website}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current City</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.cityName) ? "--" : this.props.member.cityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native City</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perCityName) ? "--" : this.props.member.perCityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4"> 
                            <div className="text-muted">Address Shop/Office</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressShop) ? "--" : this.props.member.addressShop}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native Address</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perAddressRes) ? "--" : this.props.member.perAddressRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current Address</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressRes) ? "--" : this.props.member.addressRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Qualification</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.qualification) ? "--" : this.props.member.qualification}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Last Modified Date</div>
                            <div className=" text-right text-md-left">{this.isInvalidPropsValue(this.props.member.approveDate) ? "--" : dateFormat(this.props.member.approveDate, "isoDate")}</div>
                        </div>
                    </div>
                </div>
                <div className={`${this.state.editForm ? "d-flex py-5 px-md-2 px-xl-5 flex-wrap w-100" : "d-none"}`}>
                    <AjaxLoader isOpen={this.props.singleMemberLoader} />
                    <div className={`edit-form-layover justify-content-center align-center ${this.props.showLayover ? 'd-flex' : 'd-none'} `} onClick={() => this.setShowLayover(false)}><span className="align-middle">{this.props.updateMessage}</span></div>
                    <EditMember key={nextId()} member={this.props.editedMember !== null && this.props.member.rowId === this.props.editedMember.rowId ? this.props.editedMember : this.props.member} onRef={ref => (this.EditMember = ref)} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        singleMemberLoader: state.MemberDirectoryReducer.singleMemberLoader,
        showLayover: state.MemberDirectoryReducer.showLayover,
        updateMessage: state.MemberDirectoryReducer.updateMessage,
        address: state.AddressDetailReducer.address,
        editedMember: state.MemberDirectoryReducer.editedMember
    };
}

const mapDispatchToProps = dispatch => ({
    updateMemberDetails: (memeberDetail) => {
        dispatch(updateMemberDetails(memeberDetail));
    },
    setShowLayover: (showlayover) => {
        dispatch(setShowLayover(showlayover));
    },
    getEditedMember: (rowId) => {
        dispatch(getEditedMember(rowId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetailElement);

