import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Media, FormFeedback } from 'reactstrap';
import { updateMemberDetails } from '../../actions/MemberDirectoryAction';
import profileImg from '../../images/proimg.png';
import DateTime from '../../components/DatePicker';
import { format } from 'date-fns'
var validator = require('validator');

class MemberDetailElement extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            personName: props.member.personName,
            fatherMotherName: props.member.fatherMotherName,
            bloodGroup: props.member.bloodGroup,
            spouseName: props.member.spouseName,
            caste: props.member.caste,
            ageDob: props.member.ageDob,
            mobile: props.member.mobile,
            phoneRes: props.member.phoneRes,
            stdcode: props.member.stdcode,
            email: props.member.email,
            phoneShop: props.member.phoneShop,
            service: props.member.service,
            website: props.member.website,
            addressShop: props.member.addressShop,
            countryName: props.member.countryName,
            addressRes: props.member.addressRes,
            qualification: props.member.qualification,
            lastApproveDate: props.member.lastApproveDate,
            rowId: parseInt(props.member.rowId),
            file: props.member.profilePicture,
            cityName: props.member.cityName,
            stateName: props.member.stateName,
            perAddressRes: props.member.perAddressRes,
            perCountryName: props.member.perCountryName,
            perStateName: props.member.perStateName,
            perCityName: props.member.perCityName,
            profession: props.member.profession,
            description: props.member.description,
            experience: props.member.experience,
            showOtherProfession: false,
            showOtherQualification: false,
            invalidPersonName: props.member.personName === '',
            invalidAddressRes: props.member.addressRes === '',
            invalidEmail: props.member.email === null || !validator.isEmail(props.member.email),
            invalidCountryName: props.member.countryName === "Select",
            invalidStateName: props.member.stateName === "Select" || props.member.stateName === "",
            invalidCityName: props.member.cityName === "Select" || props.member.cityName === "",
            invalidCaste: false,
            invalidSpouseName: false,
            invalidStdCode: false,
            invalidPhoneRes: false,
            invalidPhoneShop: false,
            invalidMobile: false,
            showOtherCity: false,
            showOtherPerCity: false,
            invalidPerStateName: false,
            invalidPerCityName: false
        };
    }

    componentDidMount() {
        this.props.onRef(this);
        if (this.state.countryName === "INDIA") {
            //for current city
            var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.stateName); });
            var cityList = this.props.address.city
                .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) });
            var isCityFound = cityList.filter((e) => { return (e.cityName === this.state.cityName); }).length > 0;
            if (!isCityFound) {
                this.setState({ showOtherCity: true });
            }
            else {
                this.setState({ showOtherCity: false });
            }

            //for native city
            state = this.props.address.state.filter((e) => { return (e.stateName === this.state.perStateName); });
            cityList = this.props.address.city
                .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId); });
            isCityFound = cityList.filter((e) => { return (e.cityName === this.state.cityName) }).length > 0;
            if (!isCityFound) {
                this.setState({ showOtherPerCity: true });
            }
            else {
                this.setState({ showOtherPerCity: false });
            }
        }
    }

   

    componentDidUpdate(prevProps, prevState) {
        if (this.state.countryName === "INDIA" && prevState.countryName !== "INDIA") {
            this.setState({ showOtherCity: false });
        }
        else if (this.state.countryName !== "INDIA" && prevState.countryName === "INDIA") {
            this.setState({ showOtherCity: true });
        }
        if (this.state.perCountryName === "INDIA" && prevState.perCountryName !== "INDIA") {
            this.setState({ showOtherPerCity: false });
        }
        else if (this.state.perCountryName !== "INDIA" && prevState.perCountryName === "INDIA"){
            this.setState({ showOtherPerCity: true });
        }
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    handleChange = (type, event) => {
      
        switch (type) {
            case "personName":
                this.setState({ invalidPersonName: validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value });
                break;
            case "fatherMotherName":
                this.setState({ invalidFatherMotherName: !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value });
                break;
            case "spouseName":
                this.setState({ invalidSpouseName: !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value });
                break;
            case "addressRes":
                this.setState({ invalidAddressRes: event.target.value === "", [type]: event.target.value });
                break;
            case "email":
                this.setState({ invalidEmail: !validator.isEmail(event.target.value), [type]: event.target.value });
                break;
            case "countryName":
                this.setState({ invalidCountryName: event.target.value === "Select", [type]: event.target.value, stateName: "", cityName:"" });
                break;
            case "perCountryName":
                this.setState({ [type]: event.target.value, perStateName: "", perCityName: "" });
                break;
            case "stateNameDropdown":
                this.setState({ invalidStateName: event.target.value === "Select" || event.target.value === "" || !validator.matches(event.target.value, /^([^0-9]*)$/), stateName: event.target.value, cityName: "" });
                break;
            case "perStateName":
                this.setState({ invalidPerStateName: !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value, perCityName: "" });
                break;
            case "cityNameDropdown":
                this.setState({ invalidCityName: event.target.value === "Select" || event.target.value === "" || !validator.matches(event.target.value, /^([^0-9]*)$/), cityName: event.target.value });
                break;
            case "perCityName":
                this.setState({ invalidPerCityName: !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value });
                break;
            case "ageDob":
                this.setState({ [type]: format(event._d, 'dd/MM/yyyy') });
                break;
            case "caste":
                this.setState({ invalidCaste: !validator.matches(event.target.value, /^([^0-9]*)$/), [type]: event.target.value });
                break;
            case "stdcode":
                this.setState({ invalidStdCode: !validator.isNumeric(event.target.value), [type]: event.target.value });
                break;
            case "mobile":
                this.setState({ invalidMobile: !validator.isMobilePhone(event.target.value), [type]: event.target.value });
                break;
            case "phoneRes":
                this.setState({ invalidPhoneRes: !validator.isMobilePhone(event.target.value), [type]: event.target.value });
                break;
            case "phoneShop":
                this.setState({ invalidPhoneShop: !validator.isMobilePhone(event.target.value), [type]: event.target.value });
                break;
            default:
                this.setState({ [type]: event.target.value });
                break;
        }
    }

    createCountryItems = () => {
        const listItems = this.props.address.country
            .map((country) =>
                <option key={country.countryId} value={country.countryName}>{country.countryName}</option>
            );
        return listItems;
    }

    createStateItems = () => {
        var country = this.props.address.country.filter((e) => { return (e.countryName === this.state.countryName) });
        return this.props.address.state
            .filter((e) => { return (country.length === 1 && e.countryId === country[0].countryId) })
            .map((state) =>
                <option key={state.stateId} value={state.stateName}>{state.stateName}</option>
            );
    }

    createNativeStateItems = () => {
        var country = this.props.address.country.filter((e) => { return (e.countryName === this.state.perCountryName); });
        return this.props.address.state
            .filter((e) => { return (country.length == 1 && e.countryId === country[0].countryId); })
            .map((state) =>
                <option key={state.stateId} value={state.stateName}>{state.stateName}</option>
            );
    }

    createBloodGroupItems() {
        const listItems = this.props.address.bloodGroups.map((bloodGroup) =>
            <option key={bloodGroup.SystemId} value={bloodGroup.code}>{bloodGroup.code}</option>
        );
        return listItems;
    }

    createQualificationItems() {
        const listItems = this.props.address.qualifications.map((qualification) =>
            <option key={qualification.SystemID} value={qualification.code}>{qualification.code}</option>
        );
        return listItems;
    }

    createProfessionItems() {
        const listItems = this.props.address.professions.map((profession) =>
            <option key={profession.SystemID} value={profession.code}>{profession.code}</option>
        );
        return listItems;
    }

  
    createCityItems = () => {
        if (this.state.countryName === "INDIA") {
            var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.stateName); });
            return this.props.address.city
                .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) })
                .map((city) =>
                    <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
                );
        }
        else {
            return "";
        }
    }

    createNativeCityItems = () => {
        if (this.state.perCountryName === "INDIA") {
            var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.perStateName); });
            return this.props.address.city
                .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId); })
                .map((city) =>
                    <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
                );
        }
        else {
            return "";
        }
    }

    doUpdate = () => {
        if (!this.state.invalidPersonName && !this.state.invalidAddressRes && !this.state.invalidEmail && !this.state.invalidCountryName && !this.state.invalidStateName && !this.state.invalidCityName && !this.state.invalidPerStateName && !this.state.invalidPerCityName) {
            let registerDetails = [{
                RowId: parseInt(this.state.rowId),
                PersonName: this.state.personName,
                FatherMotherName: this.state.fatherMotherName,
                BloodGroup: this.state.bloodGroup,
                SpouseName: this.state.spouseName,
                Caste: this.state.caste,
                AgeDob: this.state.ageDob,
                Mobile: this.state.mobile,
                PhoneRes: this.state.phoneRes,
                Stdcode: this.state.stdcode,
                Email: this.state.email,
                PhoneShop: this.state.phoneShop,
                Service: this.state.service,
                Website: this.state.website,
                AddressShop: this.state.addressShop,
                AddressRes: this.state.addressRes,
                Qualification: this.state.qualification,
                ProfilePicture: this.state.file,
                CityName: this.state.cityName,
                StateName: this.state.stateName,
                CountryName: this.state.countryName,
                PerAddressRes: this.state.perAddressRes,
                PerCountryName: this.state.perCountryName,
                PerStateName: this.state.perStateName,
                PerCityName: this.state.perCityName,
                Profession: this.state.profession,
                Description: this.state.description,
                Experience: this.state.experience
            },
            {
                RowId: parseInt(this.state.rowId),
                PersonName: this.props.member.personName,
                FatherMotherName: this.props.member.fatherMotherName,
                BloodGroup: this.props.member.bloodGroup,
                SpouseName: this.props.member.spouseName,
                Caste: this.props.member.caste,
                AgeDob: this.props.member.ageDob,
                Mobile: this.props.member.mobile,
                PhoneRes: this.props.member.phoneRes,
                Stdcode: this.props.member.stdcode,
                Email: this.props.member.email,
                PhoneShop: this.props.member.phoneShop,
                Service: this.props.member.service,
                Website: this.props.member.website,
                AddressShop: this.props.member.addressShop,
                AddressRes: this.props.member.addressRes,
                Qualification: this.props.member.qualification,
                ProfilePicture: this.props.member.profilePicture,
                CityName: this.props.member.cityName,
                StateName: this.props.member.stateName,
                CountryName: this.props.member.countryName,
                PerAddressRes: this.props.member.perAddressRes,
                PerCountryName: this.props.member.perCountryName,
                PerStateName: this.props.member.perStateName,
                PerCityName: this.props.member.perCityName,
                Profession: this.props.member.profession,
                Description: this.props.member.description,
                Experience: this.props.member.experience
            }];
            this.props.updateMemberDetails(registerDetails);
           
    }
    
    }

    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileName = file.name;
        var Extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (file.size > 8 * 1000 * 1000) {
            this.inputElement.value = ""
            alert("File size limit exceeds 8 MB");
            return;
        }
        if (Extension == "gif" || Extension == "png" || Extension == "bmp"
            || Extension == "jpeg" || Extension == "jpg") {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result,
                });
            };
        }
        else {
            return alert("Photo only allows file types of GIF, PNG, JPG, JPEG and BMP.");
        }
    }

    handleClick = () => {
        this.inputElement.click();
    }

    showOtherQualification = () => {
        var toggleQualification = !this.state.showOtherQualification
        this.setState({ showOtherQualification: toggleQualification });
    }

    showOtherProfession = () => {
        var toggleProfession = !this.state.showOtherProfession
        this.setState({ showOtherProfession: toggleProfession });
    }


    showOtherCity = () => {
        var toggleCity = !this.state.showOtherCity
        this.setState({ showOtherCity: toggleCity });
    }

    showOtherPerCity = () => {
        var toggleCity = !this.state.showOtherPerCity
        this.setState({ showOtherPerCity: toggleCity });
    }

    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    render() {
        let $imagePreview = null;
        if (!this.isInvalidPropsValue(this.state.file)) {
            $imagePreview = (<Media src={this.state.file} alt="profile-Pic" className="profile-photo clip-circle" />);
        } else {
            $imagePreview = (<div className="profile-upload"><img src={profileImg} /></div>);
        }
        return (
            <React.Fragment>
                <form onSubmit={(e) => this._handleSubmit(e)}>
                    <input className="fileInput d-none" type="file" onChange={(e) => this._handleImageChange(e)} ref={input => this.inputElement = input} />
                </form>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-md-column flex-xl-row align-items-center align-items-md-start align-items-xl-center mb-md-3 pb-4">
                        <div className="mask mb-0 mb-md-2 mb-xl-0">
                            {$imagePreview}
                        </div>
                        <button onClick={() => this.handleClick()} className="btn ml-3 ml-md-0 ml-xl-3 bg-primary text-white">Upload photo</button>

                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Name<span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="personName" id="personName" placeholder="Name" onChange={(event) => this.handleChange("personName", event)} defaultValue={this.state.personName} invalid={this.state.invalidPersonName} />
                            <FormFeedback>Please enter person name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Father/Mother Name</div>
                        <FormGroup>
                            <Input type="text" name="fatherMotherName" id="fatherMotherName" placeholder="Father/Mother Name" onChange={(event) => this.handleChange("fatherMotherName", event)} defaultValue={this.props.member.fatherMotherName} invalid={this.state.invalidFatherMotherName} />
                            <FormFeedback>Please enter a valid Name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Blood Group</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="bloodGroup" id="bloodGroup" placeholder="BloodGroup" onChange={(event) => this.handleChange("bloodGroup", event)} defaultValue={this.props.member.bloodGroup}>
                                <option value="Select">--Blood Group--</option>
                                {this.createBloodGroupItems()}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Profession</div>
                        <FormGroup className={this.state.showOtherProfession ? "d-block" : "d-none"}>
                            <Input type="text" name="profession" id="profession" placeholder="Profession" onChange={(event) => this.handleChange("profession", event)} defaultValue={this.props.member.profession} />
                        </FormGroup>
                        <FormGroup  className={!this.state.showOtherProfession ? "d-block select-wrapper" : "d-none select-wrapper"}>
                            <Input type="select" name="profession" id="profession" placeholder="Profession" onChange={(event) => this.handleChange("profession", event)} defaultValue={this.props.member.profession}>
                                <option value="Select">--Profession--</option>
                                {this.createProfessionItems()}
                            </Input>
                        </FormGroup>
                        <a onClick={() => this.showOtherProfession()}><small><u>{!this.state.showOtherProfession ? "Other Profession" : "Select from list"}</u></small></a>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">About Profession</div>
                        <Input type="textarea" name="description" id="description" placeholder="Description" row="1" onChange={(event) => this.handleChange("description", event)} defaultValue={this.props.member.description} />
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Experience</div>
                        <Input type="text" name="experience" id="experience" placeholder="Experience" onChange={(event) => this.handleChange("experience", event)} defaultValue={this.props.member.experience} />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Current Country<span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="countryName" id="countryName" placeholder="Country" onChange={(event) => this.handleChange("countryName", event)} defaultValue={this.props.member.countryName} invalid={this.state.invalidCountryName}>
                                <option value="Select">--Select--</option>
                                {this.createCountryItems()}
                            </Input>
                            <FormFeedback>Please select country</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Native Country</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="perCountryName" id="perCountryName" placeholder="Permanent Country" defaultValue={this.props.member.perCountryName} onChange={(event) => this.handleChange("perCountryName", event)}>
                                <option value="Select">--Select--</option>
                                {this.createCountryItems()}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Name of Spouse</div>
                        <FormGroup>
                            <Input type="text" name="spouseName" id="spouseName" placeholder="Spouse Name" onChange={(event) => this.handleChange("spouseName", event)} defaultValue={this.props.member.spouseName} invalid={this.state.invalidSpouseName} />
                            <FormFeedback>Please enter a valid name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Caste</div>
                        <FormGroup>
                            <Input type="text" name="caste" id="caste" placeholder="Caste" onChange={(event) => this.handleChange("caste", event)} defaultValue={this.props.member.caste} invalid={this.state.invalidCaste} />
                            <FormFeedback>Please enter a valid Caste Name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Date Of Birth</div>
                        <FormGroup>
                            <DateTime id="agedob"
                                viewMode="days"
                                defaultValue={this.props.member.ageDob}
                                value={this.state.ageDob}
                                dateFormat="DD/MM/YYYY"
                                onChange={(event) => this.handleChange("ageDob", event)}
                                placeholder="DOB (DD/MM/YYYY)"
                                className="flex-fill"
                                name="agedob"
                                
                            />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Mobile</div>
                        <FormGroup>
                            <Input type="text" name="mobile" id="mobile" placeholder="Mobile" onChange={(event) => this.handleChange("mobile", event)} defaultValue={this.props.member.mobile} invalid={this.state.invalidMobile} />
                            <FormFeedback>Please enter valid Mobile Number</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Phone</div>
                        <FormGroup>
                            <Input type="text" name="phoneRes" id="phoneRes" placeholder="Phone Residence" onChange={(event) => this.handleChange("phoneRes", event)} defaultValue={this.props.member.phoneRes} invalid={this.state.invalidPhoneRes} />
                            <FormFeedback>Please enter valid Phone Number</FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Current State<span className="text-danger">*</span></div>
                        <FormGroup className={`${this.state.countryName !== "INDIA" ? "d-none select-wrapper" : "d-block select-wrapper"}`}>
                            <Input type="select" name="stateNameDropdown" id="stateNameDropdown" placeholder="State" onChange={(event) => this.handleChange("stateNameDropdown", event)} value={this.state.stateName} invalid={this.state.invalidStateName}>
                                <option value="Select">--Select--</option>
                                {this.createStateItems()}
                            </Input>
                            <FormFeedback >Please select state</FormFeedback>
                        </FormGroup>
                        <FormGroup className={`${this.state.countryName === "INDIA" ? "d-none" : "d-block"}`}>
                            <Input type="text" name="stateNameDropdown" id="stateNameDropdown" placeholder="State" onChange={(event) => this.handleChange("stateNameDropdown", event)} value={this.state.stateName} invalid={this.state.invalidStateName} />
                            <FormFeedback >Please enter state</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Native State</div>
                        <FormGroup className={`${this.state.perCountryName !== "INDIA" ? "d-none select-wrapper" : "d-block select-wrapper"}`}>
                            <Input type="select" name="perStateName" id="perStateName" placeholder="State" defaultValue={this.props.member.perStateName} value={this.state.perStateName} onChange={(event) => this.handleChange("perStateName", event)}>
                                <option value="Select">--Select--</option>
                                {this.createNativeStateItems()}
                            </Input>
                        </FormGroup>
                        <FormGroup className={`${this.state.perCountryName === "INDIA" ? "d-none" : "d-block"}`} >
                            <Input type="text" name="perStateName" id="perStateName" placeholder="State" defaultValue={this.props.member.perStateName} value={this.state.perStateName} onChange={(event) => this.handleChange("perStateName", event)} invalid={this.state.invalidPerStateName} />
                            <FormFeedback >Please enter valid state</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">STD Code</div>
                        <FormGroup>
                            <Input type="text" name="stdcode" id="stdcode" placeholder="STD Code" onChange={(event) => this.handleChange("stdcode", event)} defaultValue={this.props.member.stdcode} invalid={this.state.invalidStdCode} />
                            <FormFeedback>Please enter valid STD Code</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Email<span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="email" id="email" placeholder="Email" onChange={(event) => this.handleChange("email", event)} defaultValue={this.props.member.email} invalid={this.state.invalidEmail} />
                            <FormFeedback >Please valid email</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Phone(Shop/Office)</div>
                        <FormGroup>
                            <Input type="text" name="phoneShop" id="phoneShop" placeholder="Phone Shop" onChange={(event) => this.handleChange("phoneShop", event)} defaultValue={this.props.member.phoneShop} invalid={this.state.invalidPhoneShop} />
                            <FormFeedback>Please enter valid Phone Number</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Type Of Sewa</div>
                        <FormGroup>
                            <Input type="text" name="service" id="service" placeholder="Service" onChange={(event) => this.handleChange("service", event)} defaultValue={this.props.member.service} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Website</div>
                        <FormGroup>
                            <Input type="text" name="website" id="website" placeholder="Website" onChange={(event) => this.handleChange("website", event)} defaultValue={this.props.member.website} />
                        </FormGroup>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Current City<span className="text-danger">*</span></div>
                        <FormGroup className={`${this.state.countryName === "INDIA" && !this.state.showOtherCity ? "d-block select-wrapper" : "d-none select-wrapper"}`}>
                            <Input type="select" name="cityNameDropdown" id="cityNameDropdown" placeholder="City" onChange={(event) => this.handleChange("cityNameDropdown", event)} value={this.state.cityName} invalid={this.state.invalidCityName}>
                                <option value="Select">--Select--</option>
                                {this.createCityItems()}
                            </Input>
                            <FormFeedback>Please select city</FormFeedback>
                        </FormGroup>
                        <FormGroup className={`${this.state.countryName === "INDIA" && !this.state.showOtherCity ? "d-none" : "d-block"}`}>
                            <Input type="text" name="cityNameDropdown" id="cityNameDropdown" placeholder="City" onChange={(event) => this.handleChange("cityNameDropdown", event)} value={this.state.cityName} invalid={this.state.invalidCityName} />
                            <FormFeedback>Please enter valid city</FormFeedback>
                        </FormGroup>
                        <a onClick={() => this.showOtherCity()} className={this.state.countryName === "INDIA" ? "d-block" : "d-none"} > <small><u>{this.state.showOtherCity ? "Select from list" : "Other city"}</u></small></a>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Native City</div>
                        <FormGroup className={`${this.state.countryName === "INDIA" && !this.state.showOtherPerCity ? "d-block select-wrapper" : "d-none select-wrapper"}`}>
                            <Input type="select" name="perCityName" id="perCityName" placeholder="Native City" defaultValue={this.props.member.perCityName} value={this.state.perCityName} onChange={(event) => this.handleChange("perCityName", event)} >
                                <option value="Select">--Select--</option>
                                {this.createNativeCityItems()}
                            </Input>
                        </FormGroup>
                        <FormGroup className={`${this.state.countryName === "INDIA" && !this.state.showOtherPerCity ? "d-none" : "d-block"}`} >
                            <Input type="text" name="perCityName" id="perCityName" placeholder="Native City" defaultValue={this.props.member.perCityName} value={this.state.perCityName} onChange={(event) => this.handleChange("perCityName", event)} invalid={this.state.invalidPerCityName} />
                            <FormFeedback>Please enter valid city</FormFeedback></FormGroup>
                        <a onClick={() => this.showOtherPerCity()} className={this.state.perCountryName === "INDIA" ? "d-block" : "d-none"}><small><u>{this.state.showOtherPerCity ? "Select from list" : "Other city"}</u></small></a>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Address Shop/Office</div>
                        <FormGroup>
                            <Input type="text" name="addressShop" id="addressShop" placeholder="Address Shop" onChange={(event) => this.handleChange("addressShop", event)} defaultValue={this.props.member.addressShop} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Native Address</div>
                        <FormGroup>
                            <Input type="text" name="perAddressRes" id="perAddressRes" placeholder="Native Address" onChange={(event) => this.handleChange("perAddressRes", event)} defaultValue={this.props.member.perAddressRes} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Current Address<span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="addressRes" id="addressRes" placeholder="Current Address" onChange={(event) => this.handleChange("addressRes", event)} defaultValue={this.props.member.addressRes} invalid={this.state.invalidAddressRes} />
                            <FormFeedback>Please enter current Address</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Qualification</div>
                        <FormGroup className={!this.state.showOtherQualification ? "d-none" : "d-block"}>
                            <Input type="text" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)} defaultValue={this.props.member.qualification} />
                        </FormGroup>
                        <FormGroup className={this.state.showOtherQualification ? "d-none select-wrapper" : "d-block select-wrapper"}>
                            <Input type="select" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)} defaultValue={this.props.member.qualification}>
                                <option value="Select">--Select--</option>
                                {this.createQualificationItems()}
                            </Input>
                        </FormGroup>
                        <a onClick={() => this.showOtherQualification()}><small><u>{this.state.showOtherQualification ? "Select from list" : "Other qualification"}</u></small></a>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Last Modified Date</div>
                        <div className="filed-data">{this.isInvalidPropsValue(this.props.member.lastApproveDate) ? "--" : this.props.member.lastApproveDate}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        address: state.AddressDetailReducer.address
    };
};

const mapDispatchToProps = dispatch => ({
    updateMemberDetails: (memeberDetail) => {
        dispatch(updateMemberDetails(memeberDetail));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetailElement);

