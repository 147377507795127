//import axios from 'axios';

//export const imageDetail = () => {
    
//    return function (dispatch) {
//        imageDetails('/api/photogallery/FetchImageFolders', dispatch);
//    };
//}
//const imageDetails = (url, dispatch) => {
//    dispatch(get());
//    axios.get(process.env.PUBLIC_URL + url)
//        .then(function (response) {
//            const imageDetail = response.data;
//            dispatch(setImageDetail(imageDetail));
//        })
//    function get() { return { type: 'GET_IMAGE_NAME' }; }
//};

//export const setImageDetail = (imageDetail) => {

//    return {
//        type: 'SET_IMAGE_NAME',
//        payload: imageDetail
//    };
//};

import axios from 'axios';

export const photoGalleryDirPath = () => {

    return function (dispatch) {
        PhotoGalleryDirPath('/api/photogallery/FetchImageDirPath', dispatch);
    };
}
const PhotoGalleryDirPath = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const ImageDirectoryPath = response.data;
            dispatch(setImageDetail(ImageDirectoryPath));
        })
    function get() { return { type: 'GET_IMAGE_GALLERY_DIR_PATH' }; }
};

function setImageDetail(ImageDirectoryPath) { return { type: 'SET_IMAGE_GALLERY_DIR_PATH', ImageDirectoryPath }; }