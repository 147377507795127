import React, { Component } from "react";
import { Row, Col, Nav, NavItem } from 'reactstrap';
import 'rmc-tabs/assets/index.css';
import { Link } from 'react-router-dom';
import DefaultSectionHeader from '../DefaultSectionHeader';
import { connect } from 'react-redux';
import { searchName, ResetSearch } from '../../actions/SearchAction';
import { withRouter } from 'react-router';
import { withBreakpoints } from 'react-breakpoints';

class VanchanHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isActiveForImages: false,
            isActiveForAudios: false,
            isActiveForVideos: false,
            searchtextBoxVisibility: false,
            search: '',
            type: '',

        };
    }
    componentDidMount() {
        this.props.ResetSearch();
    }
    ShowSearchTextBox = () => {

        this.setState(prevState => ({
            searchtextBoxVisibility: !prevState.searchtextBoxVisibility
        }));
    }

    onSearchChange = (event) => {
        this.search = event.target.value;
        this.props.searchName(this.search);
    }



    render() {

        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        return (
            <React.Fragment>

                <div className="row">
                    <div className="col-sm-12">
                        <DefaultSectionHeader title="Vanchan" />
                    </div>
                </div>
                {isMobile ?
                    <React.Fragment>
                        <Row>
                            <Col xs={12}>

                                <Nav tabs className="pill-Style border-primary">
                                    <NavItem className={this.props.isActive === "1" ? "active link-style mr-1" : "link-style mr-1 "}>
                                        <Link className=" text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Vanchan", search: '?Type=Audios' }}>Bhajan</Link>
                                    </NavItem>
                                    <NavItem className={this.props.isActive === "2" ? "active link-style mr-1" : "link-style mr-1"}>
                                        <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Vanchan", search: '?Type=Documents' }}>Documents</Link>
                                    </NavItem>
                                    <NavItem className="d-flex align-items-center flex-grow-1 justify-content-end">
                                        <a className="btn btn-outline-primary btn-sm" href={process.env.PUBLIC_URL +'/Media/Vanchan/Pushti Sidhdhant.pdf'} target='_blank' type="application/pdf">Pushti Sidhdhant</a>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        
                    </React.Fragment>
                    :
                    <Row>
                        <Col sm="12">

                            <Nav tabs className="pill-Style  border-primary">
                                <NavItem className={this.props.isActive === "1" ? "active link-style mr-1" : "link-style mr-1 "}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Vanchan", search: '?Type=Audios' }}>Bhajan</Link>
                                </NavItem>
                                <NavItem className={this.props.isActive === "2" ? "active link-style mr-1" : "link-style mr-1"}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Vanchan", search: '?Type=Documents' }}>Documents</Link>
                                </NavItem>
                                <NavItem className="d-flex align-items-center flex-grow-1 justify-content-end">
                                    <a className="btn btn-outline-primary btn-sm" href={process.env.PUBLIC_URL +'/Media/Vanchan/Pushti Sidhdhant.pdf'} target='_blank' type="application/pdf"> Pushti Sidhdhant</a>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                }


            </React.Fragment>


        );
    }
}

const mapStateToProps = (State) => {

    return {

    };

}

const mapDispatchToProps = dispatch => ({
    searchName: (search) => {
        dispatch(searchName(search));
    },

    ResetSearch: () => {
        dispatch(ResetSearch());
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withBreakpoints(VanchanHeader)));




