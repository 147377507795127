import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import React from 'react';
import { connect } from 'react-redux';
import SideNav, { NavItem, NavText } from '@trendmicro/react-sidenav';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { sidebarNavClose } from '../actions/SideBarNavAction';
import { SetCurrentPageNumber } from '../actions/PushtimargDetailAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';

class SidebarNav extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showAdmin: true,
            showUser: false
        }
    }

    openAdmin = () => {
        if (this.state.showAdmin) {
            this.setState({ showAdmin: false, showUser: false });
        }
        else {
            this.setState({ showAdmin: true, showUser: false });
        }
    }
    openUser = () => {
        if (this.state.showUser) {
            this.setState({ showAdmin: false, showUser: false });
        }
        else {
            this.setState({ showAdmin: false, showUser: true });
        }
    }

    activeSideBar = (index) => {
        if ((index.routePath).includes(this.props.location.pathname + this.props.location.search)
            || (this.props.location.search === "?Rasalay" && index.routePath === "/Rasalay")
            || ((this.props.location.pathname === "/ShriNath" || this.props.location.pathname === "/ChaturShlokiGranth" || this.props.location.search.includes("?MembersSection")) && index.routePath === "/Members_Section")
            || ((this.props.location.pathname + this.props.location.search === "/Vanchan?Type=Documents" || this.props.location.search.includes("?Vanchan")) && index.routePath.includes("/Vanchan"))
            || (this.props.location.search.includes("?VideoGallery") && index.routePath === "/Gallery?Type=Videos")
            || (this.props.location.search.includes("?ImageGallery") && index.routePath === "/Gallery?Type=Photos")
            || (this.props.location.search.includes("?BhajanGallery") && index.routePath === "/Gallery?Type=Audios")
            || (this.props.location.pathname === "/ViewSentMails" && index.routePath === "/MailToMembers"))
            return "sidenav-subnavitem-selected";
        else
            return "nav-item";
            }
    renderNavigationLinksAdmin() {
        var sideBarNav = this.getNavigationItems();
        var sideBarNavAdmin = this.getNavigationItemsAdmin();
        if (sideBarNav.length > 0) {
            return (
                <React.Fragment>
                    <SideNav expanded={true} className="bg-primary d-block" >
                        <SideNav.Nav defaultSelected="Admin">
                            <NavItem className="nav-item" eventKey="Admin" expanded={this.state.showAdmin} onClick={() => this.openAdmin()} >
                                <NavText >
                                    <div className="d-flex px-1 flex-direction-row">
                                        <div>Admin</div>
                                        <div className="w-100 d-flex justify-content-end pr-4 align-items-center"><FontAwesomeIcon className="fa-w-14" icon={this.state.showAdmin ? faChevronDown : faChevronLeft} /></div>
                                    </div>
                                </NavText>
                                {
                                    sideBarNavAdmin.map((index) =>
                                        <NavItem key={index.name} className={this.activeSideBar(index)} onClick={this.props.sidebarNavClose}>
                                            <NavText>
                                                <Link to={process.env.PUBLIC_URL + index.routePath} activeClassName="font-weight-bold active-sidenav-item" className="d-block">{index.name}</Link>
                                            </NavText>
                                        </NavItem>
                                    )}
                            </NavItem>
                            <NavItem className="nav-item" eventKey="User" expanded={this.state.showUser} onClick={() => this.openUser()}>
                                <NavText>
                                    <div className="d-flex px-1 flex-direction-row">
                                        <div>User</div>
                                        <div className="w-100  d-flex justify-content-end pr-4 align-items-center"><FontAwesomeIcon className="fa-w-14 justify-content-end" icon={this.state.showUser ? faChevronDown : faChevronLeft} /></div>
                                    </div>
                                </NavText>
                                {
                                    sideBarNav.map((index) =>
                                        <NavItem key={index.name} className={this.activeSideBar(index)} onClick={this.props.sidebarNavClose}>
                                            <NavText>
                                                <Link to={process.env.PUBLIC_URL + index.routePath} activeClassName="font-weight-bold active-sidenav-item" className="d-block">{index.name}</Link>
                                            </NavText>
                                        </NavItem>
                                    )}
                            </NavItem>
                            <NavItem className="nav-item" key="Logout" onClick={this.props.sidebarNavClose}>
                                <NavText>
                                    <Link to={process.env.PUBLIC_URL + "/Logout"} className="d-block">Logout</Link>
                                </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </React.Fragment >
            );
        }
    }

    renderNavigationLinks() {
        var sideBarNav = this.getNavigationItems();
        if (sideBarNav.length > 0) {
            return (
                <React.Fragment>
                    <SideNav expanded={true} className="bg-primary d-block">
                        <SideNav.Nav >
                            {
                                sideBarNav.map((index) =>
                                    <NavItem key={index.name} className={this.activeSideBar(index)} onClick={this.props.sidebarNavClose}>
                                        <NavText>
                                            <NavLink to={process.env.PUBLIC_URL + index.routePath} activeClassName="font-weight-bold" className="d-block">{index.name}</NavLink>
                                        </NavText>
                                    </NavItem>
                                )}
                            <NavItem key="Logout" className="nav-item" onClick={this.props.sidebarNavClose}>
                                <NavText>
                                    <NavLink to={process.env.PUBLIC_URL + "/Logout"} activeClassName="font-weight-bold" className="d-block">Logout</NavLink>
                                </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </React.Fragment>
            );
        }
    }

    getNavigationItems = () => {
        var navigationItems = [];
        navigationItems.push({ name: 'Welcome', routePath: '/Welcome' });
        navigationItems.push({ name: 'Bhajan Gallery', routePath: '/Gallery?Type=Audios' });
        navigationItems.push({ name: 'Photo Gallery', routePath: '/Gallery?Type=Photos' });
        navigationItems.push({ name: 'Video Gallery', routePath: '/Gallery?Type=Videos' });
        navigationItems.push({ name: 'Members Directory', routePath: '/MemberDirectory' });
        navigationItems.push({ name: 'History Of Pushtimarg', routePath: '/HistoryOfPushtimarg' });
        navigationItems.push({ name: 'List Of Utsav', routePath: '/UtsavList' });
        navigationItems.push({ name: 'Vanchan', routePath: '/Vanchan?Type=Audios' });
        navigationItems.push({ name: 'Rasalay', routePath: '/Rasalay' });
        navigationItems.push({ name: 'Life Of Maryadi Vashnavas', routePath: '/MaryadiVashnavas' });
        navigationItems.push({ name: 'Members Section', routePath: '/Members_Section' })
        navigationItems.push({ name: 'Accommodation Available', routePath: '/Accommodation' });
        navigationItems.push({ name: 'Matrimonial', routePath: '/Matrimonial' });
        navigationItems.push({ name: 'Contact Us', routePath: '/ContactUs' });
        return navigationItems;
    }

    getNavigationItemsAdmin = () => {
        var navigationItems = [];
        navigationItems.push({ name: 'Members Directory', routePath: '/MemberDirectoryAdmin' });
        navigationItems.push({ name: 'Matrimonial', routePath: '/MatrimonialAdmin' });
        navigationItems.push({ name: 'Mail to Members', routePath: '/MailToMembers' });
        navigationItems.push({ name: 'Mail Status', routePath: '/MailStatus' });
        return navigationItems;
    }

    render() {
        var sideMenuHtmlContent = null;
        if (this.props.isAuthenticated) {
            sideMenuHtmlContent = this.props.loginType === 'U' ? this.renderNavigationLinks() : this.renderNavigationLinksAdmin();
            let toggleClass = !this.props.isSidebarOpen ? "sidenav-collapsed" : "sidenav-expanded"
            return (
                <React.Fragment>
                    <div className={`bg-primary side-nav-mobile  d-block d-lg-none ${toggleClass}`}>
                        <div className='sidebar-Header'>Raman Sujati</div>
                        <div className="sidebar-menuItems">
                            {sideMenuHtmlContent}
                        </div>
                    </div>
                    <div className="bg-primary side-nav d-none d-lg-block">
                        <div className='sidebar-Header'>Raman Sujati</div>
                        <div className="sidebar-menuItems">
                            {sideMenuHtmlContent}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return null;
        }
    }
}

const mapDispatchToProps = dispatch => ({
    sidebarNavClose: (e) => {
        e.preventDefault();
        dispatch(sidebarNavClose());
        dispatch(SetCurrentPageNumber(1, false));
    }
});

const mapStateToProps = (state) => {
    return {
        isSidebarOpen: state.SidebarNavReducer.isSidebarOpen,
        isAuthenticated: state.LoginReducer.isAuthenticated,
        loginType: state.LoginReducer.loginType,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarNav));

