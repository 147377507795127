var dateFormat = require('dateformat');
const initialState = {
    memberDetails: [],
    memberDetailsAdmin: [],
    isLoading: false,
    singleMemberLoader: false,
    isIndian: true,
    sortIncreasingOrder: true,
    memberPerPage: 10,
    isSearchMode: false,
    totalPageCount: 0,
    pageNumber: 0,
    showLayover: false,
    updateMessage: '',
    editedMember: '',
    isUS: false,
    isUK: false,
    isOther: false,
    country: "",
    city: "",
    approve: false,
    isNew: null,
    startDate: null,
    endDate: null,
    cityList: [],
    sortByApprovalDate:true,
    active:1
}

const MemberDirectoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MEMBER_NATIONALITY_TYPE':
            return Object.assign({}, state, { isIndian: action.isIndian, isUS: action.isUS, isUK: action.isUK, isOther: action.isOther });
        case 'SET_SORT_PROPERTY':
            return Object.assign({}, state, { sortIncreasingOrder: action.isIncreasingOrder });
        case 'SET_PER_PAGE_COUNT':
            return Object.assign({}, state, { memberPerPage: action.memberPerPage });
        case 'SET_APPROVE_STATUS':
            return Object.assign({}, state, { approve: action.status });
        case 'SET_PAGE_NUMBER':
            return Object.assign({}, state, { pageNumber: action.pageNumber });
        case 'SET_MEMBER_DETAILS':
            return Object.assign({}, state, { memberDetails: action.payload.personInfoList, memberDetailsAdmin:[], totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_MEMBER_DETAILS_ADMIN':
            return Object.assign({}, state, { memberDetailsAdmin: action.payload.personInfoListForAdmin, memberDetails:[], totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_APPROVED_MEMBER_DETAILS_ADMIN':
            return Object.assign({}, state, { memberDetailsAdmin: action.payload.personInfoList, memberDetails: [], totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_SEARCH_PROGRESS':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_SINGLE_MEMBER_LOADER':
            return Object.assign({}, state, { singleMemberLoader: action.isLoading });
        case 'SET_SHOW_LAYOVER':
            return Object.assign({}, state, { showLayover: action.showLayover });
        case 'SET_UPDATE_MESSAGE':
            return Object.assign({}, state, { updateMessage: action.message }); 
        case 'SET_EDITED_MEMBER':
            return Object.assign({}, state, { editedMember: action.payload, singleMemberLoader: false });
        case 'RESET_FILTERS':
            return Object.assign({}, state, { country: "", city: "", active:1 });
        case 'DELETE_MEMBER':
            var memberDetails = state.memberDetailsAdmin.filter(item => { return item.id !== action.id });
            return Object.assign({}, state, { memberDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'APPROVE_MEMBER':
            var memberDetails = state.memberDetailsAdmin.filter(item => { return item.id !== action.id });
            return Object.assign({}, state, { memberDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'ACTIVE_INACTIVE_MEMBER':
            var memberDetails = [];
            for (var i = 0; i < state.memberDetailsAdmin.length; i++) {
                var member = state.memberDetailsAdmin[i];
                if (member.rowId === action.rowID) {
                    member.isActive = action.status;
                }
                memberDetails.push(member);
            }
            return Object.assign({}, state, { memberDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'SET_ADMIN_FILTERS':
            return Object.assign({}, state, {
                country: action.country, city: action.city,
                isNew: action.isNew, startDate: action.startDate, endDate: action.endDate, sortIncreasingOrder: action.sortIncreasingOrder,
            });
        case 'SET_ADMIN_FILTERS_APPROVED_MEMBER':
            return Object.assign({}, state, {
                country: action.country, city: action.city,
                sortByApprovalDate: action.sortByApprovalDate,
                active: action.status
            });
            break;
        case 'SET_CITY_LIST':
            return Object.assign({},state, { cityList: action.payload});
        default:
            return state;
    }

}

export default MemberDirectoryReducer;