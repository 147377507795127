import React from 'react';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { sidebarNavOpen, sidebarNavClose } from '../actions/SideBarNavAction';

const HamburgerMenuControl = props => (    
    <div id="SidebarButtons" className="hamburger-menu">
        <NavLink className={`${!props.isSidebarOpen ? 'd-block' : 'd-none'} `} onClick={props.sidebarNavOpen} title="Open Sidebar"><FontAwesomeIcon className="d-flex text-white fa-w-16" icon={faBars} /></NavLink>
        <NavLink className={`${props.isSidebarOpen ? 'd-block' : 'd-none'}`} onClick={props.sidebarNavClose} title="Close Sidebar"><FontAwesomeIcon className="d-flex text-white fa-w-16" icon={faTimes} /></NavLink>
    </div>
);

const mapDispatchToProps = dispatch => ({
    sidebarNavOpen: (e) => {
        e.preventDefault();
        dispatch(sidebarNavOpen());
    },
    sidebarNavClose: (e) => {
        e.preventDefault();
        dispatch(sidebarNavClose());
    }
});

const mapStateToProps = (state) => {
   
    return {
        isSidebarOpen: state.SidebarNavReducer.isSidebarOpen
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenuControl);