import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { memberSectionPath } from '../../actions/MembersSectionAction';
import { resetDocumentFiles } from '../../actions/DocumentListAction';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';
import DefaultSectionHeader from '../../components/DefaultSectionHeader';
import CommonFunction from '../../common/CommonFunction';
import { AjaxLoader } from "../AjaxLoader";


class MembersSection extends Component {
    componentDidMount() {
        this.props.resetDocumentFiles();
        this.props.memberSectionPath();
    }


    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <DefaultSectionHeader title="Members Section" />
                <Row className="no-gutters">
                <p className="pb-4">Any Vaishnava can send articles/information related to religion for sharing among other vaishnavas. It will help in increase in knowledge of religion.
                            It will be uploaded here with the name and address of the sender, if found useful. Anybody can email to anil@ramansujatisamaj.com.</p>
                    <AjaxLoader isOpen={this.props.isLoading} />
                    <Col lg="6" className="mb-3">
                       
                        <Row>
                            <Col xs="auto">
                                <Link to={process.env.PUBLIC_URL + "/ShriNath"} className="text-style">
                                    <span className="icon-story" />
                                    <span className="cat-title">
                                        <span className="position-relative pr-5 d-inline-block">SHRI NATHJI'S APPEARANCE STORY <span className="preview-icon-MemberSection"><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span></span>
                                        <span className="author">(Provide by: Rikin Kotecha, Leicester, UK)</span>
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="auto">
                                <Link to={process.env.PUBLIC_URL + '/ChaturShlokiGranth'} className="text-style">
                                    <span className="icon-folder" />
                                    <span className="cat-title">
                                        Chatur Shloki Granth
                                        <span className="author"> (Provide by: Yash Piplani, Delhi)</span>
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="auto">
                                <Link to={{ pathname: process.env.PUBLIC_URL + "/Documents", search: `${this.props.SectionName + '/84 Vaishnavas'}` }} className="text-style">
                                    <span className="icon-folder" />
                                    <span className="cat-title">
                                        84 Vaishnavas
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6">
                        <Row>
                            <Col xs={"auto"} >
                              
                                <a target="_blank" href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + 'Media/MembersSection/SHRI GOKULESH RATNAMAL GRANTH.pdf'} className="text-style" type="application/pdf" title="Preview-file">
                                        <span className="icon-document"/>
                                        <span className="cat-title">
                                        <span className="position-relative pr-5 d-inline-block">Shri Gokulesh Ratnamal Granth - Scanned Pustakji
                                            <span className="preview-icon-MemberSection"><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span>
                                            <span onClick={() => { CommonFunction.downloadPdfFile("MembersSection", "SHRI GOKULESH RATNAMAL GRANTH") }} className="download-icon-MemberSection"><img src={downloadFileIcon} alt="download" className="downLoadIcon" /></span>
                                        </span>
                                        <span className="author">(Provide by: Hina Sadrani)</span>
                                    </span>

                                </a>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={"auto"}>
                                <div>
                                    <a target="_blank" href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/MembersSection/NITYAPATH.pdf'} className="text-style"  type="application/pdf" title="Preview-file">
                                     <span className="icon-document"/>
                                        <span className="cat-title">
                                            <span className="position-relative pr-5 d-inline-block">
                                                Nitya Path
                                                <span className="preview-icon-MemberSection"><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span>
                                                <span onClick={() => { CommonFunction.downloadPdfFile("MembersSection", "NITYAPATH") }} className="download-icon-MemberSection"><img src={downloadFileIcon} alt="download" className="downLoadIcon" /></span>

                                            </span>
                                            <span className="author">(Provide by: Mr. Pinesh Gokani of Pune)</span>
                                        </span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={"auto"}>
                                <div>
                                    <a href={this.props.filePathURL + '/Media/MembersSection/ShriMukhyaSawminijiNa108Nam.doc'} className="text-style"  type="application/pdf" title="Preview-file">
                                        <span className="icon-document"/>
                                        <span className="cat-title">
                                            <span className="position-relative pr-5 d-inline-block">
                                                Shri Mukhya Sawminiji Na 108 Nam
                                                <a className="preview-icon-MemberSection" href={"https://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/MembersSection/ShriMukhyaSawminijiNa108Nam.doc'} target='_blank' type="application/pdf">
                                                    <span ><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span>
                                                </a>
                                                <span className="download-icon-MemberSection"><img src={downloadFileIcon} alt="download" className="downLoadIcon" /></span>
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={"auto"}>
                                <div>
                                    <a href={this.props.filePathURL + '/Media/MembersSection/shrigokulnathji.doc'} className="text-style" type="application/pdf" title="Preview-file">
                                        <span className="icon-document"/>
                                        <span className="cat-title">
                                            <span className="position-relative pr-5 d-inline-block">
                                                SHRI GOKULNATHJI
                                                <a className="preview-icon-MemberSection" href={"https://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/MembersSection/shrigokulnathji.doc'} target='_blank'>
                                                    <span ><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span>
                                                </a>
                                                <span className="download-icon-MemberSection"><img src={downloadFileIcon} alt="download" className="downLoadIcon " /></span>
                                            </span>
                                            <span className="author">(Provide by: Rikin Kotecha, Leicester, UK)</span>
                                        </span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={"auto"}>
                                <div>
                                    <a href={this.props.filePathURL + '/Media/MembersSection/ShriMohanbhaijiNa108Nam.docx'} className="text-style" target='_blank' type="application/pdf" title="Preview-file">
                                        <span className="icon-document" />
                                        <span className="cat-title">
                                            <span className="position-relative pr-5 d-inline-block">
                                                Shri Mohanbhaiji Na 108 Nam
                                                <a className="preview-icon-MemberSection" href={"https://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/MembersSection/ShriMohanbhaijiNa108Nam.docx'} target='_blank'>
                                                    <span ><img src={previewFileIcon} alt="preview" className="downLoadIcon" /></span>
                                                </a>
                                                <span className="download-icon-MemberSection"><img src={downloadFileIcon} alt="download" className="downLoadIcon" /></span>
                                            </span>
                                            <span className="author">(Provide by: Anila Ben Devani, Leicester, UK)</span>
                                        </span>

                                    </a>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        memberSectionDirPath: state.MembersSectionReducer.memberSectionDirPath,
        isLoading: state.MembersSectionReducer.isLoading,
        SectionName: state.MembersSectionReducer.SectionName,
        filePathURL:state.LoginReducer.filePathURL
    };
};

const mapDispatchToProps = dispatch => ({
    memberSectionPath: () => {
        dispatch(memberSectionPath());
    },
    resetDocumentFiles: () => {
        dispatch(resetDocumentFiles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MembersSection);

