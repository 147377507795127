import axios from 'axios';

export const videoDirectoryName = (videoDirectoryPath) => {
    
    return function (dispatch) {
        audioDirectoryNames('/api/videogallery/FetchVideoDirectories', videoDirectoryPath, dispatch);
    };
}
const audioDirectoryNames = (url, videoDirectoryPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { videoDirectoryPath } })
        .then(function (response) {
            const directoryName = response.data;
            dispatch(setDirectories(directoryName));

        })
    function get() { return { type: 'GET_VIDEO_DIRECTORY_NAME' }; }
};


function setDirectories(directoryName) { return { type: 'SET_VIDEO_DIRECTORY_NAME', directoryName }; }


export const resetVideoFiles = () => { return { type: 'RESET_FOLDER_VIDEOS' }; }