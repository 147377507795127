import React, { Component } from 'react';
import { Col, Row, Card, CardBody, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import Ramanlalji from '../images/shri-ramanlal-ji-maharaj.jpg';
import DefaultSectionHeader from '../components/DefaultSectionHeader';

export default class Welcome extends Component {
    render() {
        return (
            <div className="welcome-layout-bg content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <DefaultSectionHeader title="Welcome to Shri Raman Sujati Samaj" />
                <div className="welcome-container">
                    <div className="decription-container d-md-flex">
                        <Media src={Ramanlalji} alt="ramanlalji" className="img-fluid pr-md-5 mb-2 mb-md-0 mr-2 mr-md-0 float-left float-md-none" />
                        <div>
                            <p>In the fifteenth century of Vikram Era a great man was born. He became famous as <strong>Jagadguru Shrimad Vallabhacharyaji</strong>. The ancestor of His Holiness was Yagnanarayan Bhatt. Who belonged to Velnadu of Andhra province. He was a Brahmin by caste and was very learned. He began performing "Som Yagna".</p>
                            <p>Once the Lord of Lords, the Bhagawan Himself came out from the altar and gave him a boon. He emphatically declared that when the 100th Yagna would be finished, he would appear again at his place.</p>
                            <p>Thus from Yagnanarayan Bhatt to Gangadhar Bhatt, Ganapati Bhatt, Vallabh Bhatt and his son Laxman Bhatt, 100th "Som Yagna" was completed. Then in V.S. 1529 Shri Vallabhacharyaji was born fulfilling the promise given by the Bhagawan, His Holiness Established the Shuddhadwait Pushtimarg.</p>
                        </div>
                    </div>
                    <div>
                        <Row className="pt-4">
                            <Col xl="4" sm="6" className="pt-3 d-flex">
                                <Card className="flex-grow-1 border border-primary overflow-hidden">
                                    <CardBody className="p-3 pl-5">
                                        <div className="">
                                            <div className="bg-primary rounded-circle welcome-card-icon welcome-announcement"></div>
                                            <div className="welcome-card-title pt-2">
                                                <h5 className="pl-4 font-weight-bold">Announcement</h5>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul className="pt-3">
                                                <li> <Link className="p-0" to={{ pathname: process.env.PUBLIC_URL + "/ImageFiles", search: `${"ImageGallery/Mahautsav 2013"}` }}>Pl. click here for pictures of mahautsav 2013</Link></li>
                                                <li> <a target="_blank" href={require('../files/Shri-Gokulnathji-Ke-Vachnam.jpg')} className="p-0">Shri GokulnathJi ke vachnamrit</a></li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="4" sm="6" className="pt-3 d-flex">
                                <Card className="flex-grow-1 border border-primary overflow-hidden">
                                    <CardBody className="p-3 pl-5">
                                        <div className="">
                                            <div className="bg-primary rounded-circle welcome-card-icon welcome-address" />
                                            <div className="welcome-card-title pt-2">
                                                <h5 className="pl-4 font-weight-bold">Address</h5>
                                            </div>
                                        </div>
                                        <div className="float-left">
                                            <ul className="pt-3">
                                                <li> <a target="_blank" href={require('../files/AddressesOfBaithaksOfShriGusaiJiShriVitthalnathJiShriGokulnathji.pdf')} className="p-0">Addresses of Baithak's of Shri Gusai ji(Shri Vitthalnath ji) & Shri Gokulnath ji</a></li>
                                                <li> <a target="_blank" href={require('../files/Addresses_84BaithaksOfShriMahaprabhuJi.pdf')} className="p-0">Addresses of 84 baithaks of Shri Mahaprabhu ji</a></li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
