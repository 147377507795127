import React from 'react';
import { connect } from 'react-redux';
import { bhajanGalleryDirPath } from '../actions/AudioGalleryAction';
import AudioFolderComponent from './Common/AudioFolderComponent';
class AudioGallery extends React.Component {
    componentDidMount() {
        this.props.bhajanGalleryDirPath();
    }

    showAudioComponent = () => {
        if (this.props.BhajanGalleryDirPath != null && this.props.BhajanGalleryDirPath != '') {
            return (
                <AudioFolderComponent AudioFolderPath={this.props.BhajanGalleryDirPath} SectionName={this.props.SectionName} />
            );
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.showAudioComponent()}
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        BhajanGalleryDirPath: state.AudioGalleryReducer.bhajanGalleryDirPath,
        isLoading: state.AudioGalleryReducer.isLoading,
        SectionName: state.AudioGalleryReducer.SectionName,
    };
}

const mapDispatchToProps = dispatch => ({
    bhajanGalleryDirPath: () => {
        dispatch(bhajanGalleryDirPath());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioGallery);

