const initialState = {
    currentPageNumber: 1,
    showDetail: false
};

const PushtimargDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CURRENT_PAGE_NUMBER":
            return Object.assign({}, state, { currentPageNumber: action.currentpageNumber, showDetail: action.showDetail });
        default:
            return state;
    }
};
export default PushtimargDetailReducer;