import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { documentList } from '../../actions/DocumentListAction';
import { AjaxLoader } from '../AjaxLoader';
import CommonFunction from '../../common/CommonFunction';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';

class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativePath: decodeURIComponent(this.props.location.search.substring(1))
        };
    }

    componentDidMount() {
        if (this.state.relativePath !== null && this.props.relativePath !== '') {
            this.props.documentList(this.state.relativePath);
        }
    }

    openFile = (filePath) => {
        require(filePath);
    }

    showPdfFiles = () => {
        if (this.props.PdfFiles !== null) {
            return this.props.PdfFiles.map((file) => (
                <Col xl="6" lg="6" md="6" sm="6" xs="6" key={file}>
                    <div className="mt-5">
                        <a className="text-link" target='_blank' href={process.env.PUBLIC_URL + this.state.relativePath + "/" + file + '.pdf'} type="application/pdf">{file}</a>
                    </div>
                </Col>
            ));
        }
    }

    showRasalayFiles = () => {
        if (this.props.PdfFiles.length > 0) {
            let rasalayFilesTree;
            rasalayFilesTree = this.props.PdfFiles.map((folder) => (
                <div className="features-section">
                    <ul className="ank-list">
                        <li><strong>{folder.directoryName}</strong></li>
                        {
                            folder.files.map((file) => (
                                <li>
                                    <p>{file}</p>
                                    <p>
                                        <a href={("http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/' + this.state.relativePath + "/" + folder.directoryName + "/" + file + '.pdf')} target='_blank' type="application/pdf" title="Preview-file" key={file}>
                                            <span className="download-icon"><img src={previewFileIcon} alt="preview" /></span>
                                        </a>
                                        <a onClick={() => { CommonFunction.downloadFile(this.state.relativePath, folder.directoryName, file) }} title="Download-file" className="file-icon">
                                            <span className="download-icon"><img src={downloadFileIcon} alt="download" /></span>
                                        </a>
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            ));
            return rasalayFilesTree;
        }
    }

    render() {
        return (
            <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <AjaxLoader isOpen={this.props.isLoading} />
                <div className="row no-gutters pb-3 mt-2 mb-4 position-relative page-title-wrapper">
                    <div className="col-sm-12">
                        <h1 className="text-primary">{this.state.relativePath}</h1>
                    </div>
                </div>
                <div className="feature-wrapper">
                    {this.state.relativePath.toLocaleLowerCase() === "rasalay" ? this.showRasalayFiles() : this.showPdfFiles()}
                </div>
            </div>
        );

    };
}

const mapStateToProps = (state) => {
    return {
        PdfFiles: state.DocumentListReducer.PdfFilesList,
        isLoading: state.DocumentListReducer.isLoading,
        filePathURL: state.LoginReducer.filePathURL
    }
};

const mapDispatchToProps = dispatch => ({
    documentList: (selectedFolderPath) => {
        dispatch(documentList(selectedFolderPath));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);