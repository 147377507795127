import React, { Component } from 'react';
import { UtsavFileYearWise } from '../../actions/UtsavListYearWiseAction';
import { Col, Row } from 'reactstrap';
import { AjaxLoader } from '../AjaxLoader';
import { connect } from 'react-redux';
import CommonFunction from '../../common/CommonFunction';
import DefaultSectionHeader from '../DefaultSectionHeader';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';
import '../../../node_modules/tabulator-tables/src/scss/bootstrap/tabulator_bootstrap4.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons';
class UtsavListYearWise extends Component {
   
    componentDidMount() {
        this.props.UtsavFileYearWise();
    }

    showUtsavFilesYearWise = () => {
        if (this.props.fileDetail.length > 0) {
            let utsavsFilesYearWise;
            utsavsFilesYearWise = this.props.fileDetail.map((files, index) => (
                <li className="utsav-list-items" key={index}>
                    <div>{files[0].directoryName}</div>
                    <div>{files[0].name}</div>
                    <React.Fragment key={index}>
                        <div className="utsav-items-icons">
                            <a href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/AllUtsavsOfYear/' + files[0].directoryName + "/" + files[0].name + '.pdf'} target='_blank' type="application/pdf" title="Preview-file">
                                <span className="preview-icon"><img src={previewFileIcon} alt="preview" /></span>
                            </a>
                            <a onClick={() => { CommonFunction.downloadFile("AllUtsavsOfYear", files[0].directoryName, files[0].name) }} title="Download-icon" className="file-icon">
                                <span className="download-icon"><img src={downloadFileIcon} alt="download" /></span>
                            </a>
                        </div>
                    </React.Fragment>
                </li>
            ));
            return utsavsFilesYearWise;
        }
    }

    render() {
        return (
            <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5 utsav-layout-bg">
                <AjaxLoader isOpen={this.props.isLoading} />
                {
                    !this.props.isLoading ? <div> <div className="breadcrumb-link"><Link className="pr-2" to={process.env.PUBLIC_URL + "/UtsavList"}>List of Utsav</Link>
                        <FontAwesomeIcon icon={faChevronRight} />
                        <span className="pl-2">All Utsavs Of Year</span>
                    </div>
                        <DefaultSectionHeader title="All Utsavs Of Year" />
                        <React.Fragment>
                            <Row>
                                <Col md="10">
                                    <div className="utsav-list utsav-yearwise">
                                        <ul>
                                            <li className="utsav-title">
                                                <div><strong>Utsav Date</strong></div>
                                                <div><strong>Utsav Name</strong></div>
                                            </li>
                                            {this.showUtsavFilesYearWise()}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment> </div> : null
                }
               
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        fileDetail: state.UtsavListYearWiseReducer.UtsavFiles,
        isLoading: state.UtsavListReducer.isLoading,
        filePathURL: state.LoginReducer.filePathURL
    };
}

const mapDispatchToProps = dispatch => ({
    UtsavFileYearWise: () => {
        dispatch(UtsavFileYearWise());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UtsavListYearWise);
