import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import ConfigureStore, { history } from './store/ConfigureStore';
import { ConnectedRouter } from 'connected-react-router';
import ReactBreakpoints from 'react-breakpoints';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios';
import _get from 'lodash/get';
import { logout } from './actions/LoginAction';

const rootElement = document.getElementById('root');
const store = ConfigureStore({});

const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    mobileView: 992,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
};


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ReactBreakpoints breakpoints={breakpoints}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </ReactBreakpoints>
        </BrowserRouter>
    </Provider>,
    rootElement);

serviceWorker.unregister();

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (_get(error, 'response.status') === 401) {
        store.dispatch(logout());
    } 

    return Promise.reject(error);
});
