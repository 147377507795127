import React from 'react';
import { Col, Row, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { videoDirectoryName } from '../../actions/VideoDirectoryAction';
import { AjaxLoader } from '../AjaxLoader';
import { resetVideoFiles } from '../../actions/VideoDirectoryAction';
import Pagination from "react-js-pagination";
import { withBreakpoints } from 'react-breakpoints';
import CommonFunction from '../../common/CommonFunction';
import NoItemFound from '../NoItemFoundPage';

class VideoDirectory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
            filteredList: [],
            activePage: 1,
            folderPerPage: 12,
        };
    }
    componentWillMount() {
        this.props.resetVideoFiles();
    }

    componentDidMount() {
        this.props.resetVideoFiles();
        if (this.props.VideoDirectoryPath !== null) {
            this.props.videoDirectoryName(this.props.VideoDirectoryPath);
        };
    }
    componentDidUpdate(prevProps) {
       if (this.props.VideoDirectoryName !== prevProps.VideoDirectoryName || this.props.search !== prevProps.search) {
            if (this.props.search === "") {
                this.setState( { filteredList: this.props.VideoDirectoryName });
            } else {
                this.setState({ filteredList: this.props.VideoDirectoryName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
            }
        }
    }

    handlePageClick = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        const { filteredList, activePage, folderPerPage } = this.state;
        let ListofFolders;
        if (isMobile)
            ListofFolders = filteredList;
        else
            ListofFolders = CommonFunction.getFolderList(filteredList, activePage, folderPerPage);
        let pageNumber = CommonFunction.getPageCount(filteredList, folderPerPage);
        if (ListofFolders !== undefined && ListofFolders !== null) {
            if (ListofFolders.length > 0) {
                return (
                    <React.Fragment>
                        <AjaxLoader isOpen={this.props.isLoading} />
                        <Row>
                            {ListofFolders.map(name => {
                                return (
                                    <Col xl="2" md="4" sm="3" xs="6" className="mt-4 mt-md-5 directorylist" key={name}>
                                        <Link to={{ pathname: process.env.PUBLIC_URL + "/VideoSongs", search: `${this.props.SectionName + '/' + name}` }}>
                                            <div className="video-directory d-flex justify-content-center">
                                                <Media src={process.env.PUBLIC_URL + '/Media/' + this.props.SectionName + '/Thumbnails/' + name + '.jpg'} className="imageStyle" alt="RamanSujatiSamaj" />
                                            </div>
                                            <div className="directory-title text-black border border-top-0 border-primary rounded-bottom bg-white d-flex align-items-center justify-content-center px-2">{name}</div>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                        {isMobile || pageNumber === 1 ? "" :
                            <div className="pushtimarg-pagination pt-10">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={1}
                                    totalItemsCount={pageNumber}
                                    onChange={this.handlePageClick}
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClassPrev="pagination-previous"
                                    itemClassNext="pagination-next"
                                    itemClassFirst="pagination-firstPageText"
                                    itemClassLast="pagination-lastPageText"
                                    activeClass="pagination-active"
                                />
                            </div>
                        }
                    </React.Fragment>
                );
            }
            else {
                if (!this.props.isLoading) {
                    return (<NoItemFound />);
                }
                else
                {
                    return (<AjaxLoader isOpen={this.props.isLoading} />);
                }
            }
        }
    }
}

const mapStateToProps = (State) => {
    return {
        VideoDirectoryName: State.VideoReducer.videoDirectoryName,
        isLoading: State.VideoReducer.isLoading,
        search: State.SearchReducer.search,
    };
}

const mapDispatchToProps = dispatch => ({
    videoDirectoryName: (VideoFolderPath) => {
        dispatch(videoDirectoryName(VideoFolderPath));
    },
    resetVideoFiles: () => {
        dispatch(resetVideoFiles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(VideoDirectory));
