const initialState = {
    AudioDirectoryPath: '',
    VideoDirectoryPath: '',
    DocDirectoryPath: '',
    SectionName:'',
   

}

const VanchanReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'SET_VANCHAN_DIRECTORY_NAME':
            return Object.assign({}, state, { AudioDirectoryPath: action.vanchanDirectories.audioDirectoryPath, VideoDirectoryPath: action.vanchanDirectories.videoDirectoryPath, DocDirectoryPath: action.vanchanDirectories.docDirectoryPath, SectionName: action.vanchanDirectories.section});
        default:
            return state;
            
    }
}

export default VanchanReducer;