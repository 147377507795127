import axios from 'axios';

export const audioDirectoryName = (folderPath) => {
    return function (dispatch) {
        audioDirectoryNames('/api/audiogallery/FetchAudioDirectories', folderPath, dispatch);
    };
}

const audioDirectoryNames = (url, folderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { folderPath } })
        .then(function (response) {
            const directoryName = response.data;
            if (folderPath.search('Vanchan') === -1) {
                dispatch(setAudioDirectories(directoryName));
            }
            else {
                dispatch(setVanchanDirectories(directoryName));
            }
        })
    function get() { return { type: 'GET_AUDIO_DIRECTORY_NAME' }; }
};


function setAudioDirectories(directoryName) { return { type: 'SET_AUDIO_DIRECTORY_NAME', directoryName }; }

function setVanchanDirectories(directoryName) { return { type: 'SET_VANCHAN_AUDIO_DIRECTORY_NAME', directoryName }; }


export const checkDirectories = (selectedFolderPath) => {
    return function (dispatch) {
        CheckDirectories('/api/audiogallery/CheckDirectories', selectedFolderPath, dispatch);
    };
}

const CheckDirectories = (url, selectedFolderPath, dispatch) => {

    axios.get(process.env.PUBLIC_URL + url, { params: { selectedFolderPath } })
        .then(function (response) {
            const isPresent = response.data;
            dispatch(check(isPresent));
        });
};
function check(isPresent) { return { type: 'DIRECTORY_PRESENT', isPresent }; }

export const audioSubDirectoryName = (folderPath) => {
    return function (dispatch) {
        AudioSubDirectoryName('/api/audiogallery/FetchAudioDirectories', folderPath, dispatch);
    };
}

const AudioSubDirectoryName= (url, folderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { folderPath } })
        .then(function (response) {
            const directoryName = response.data;

            if (folderPath.search('Vanchan') === -1) {
                dispatch(setSubAudioDirectories(directoryName));
            }
            else {
                dispatch(setSubVanchanDirectories(directoryName));
            }
        });
    function get() { return { type: 'GET_AUDIO_DIRECTORY_NAME' }; }
};

function setSubAudioDirectories(directoryName) { return { type: 'SET_AUDIO_SUB_DIRECTORY_NAME', directoryName }; }

function setSubVanchanDirectories(directoryName) { return { type: 'SET_VANCHAN_SUB_AUDIO_DIRECTORY_NAME', directoryName }; }




