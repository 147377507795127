import React, { Component } from 'react';
import { Form, FormGroup, Input, Col, Row, Card, CardBody, CardHeader, Alert, FormFeedback, InputGroup, Media} from 'reactstrap';
import { connect } from 'react-redux';
import { doLogin, isCookieExist } from '../actions/LoginAction';
import { AjaxLoader } from './AjaxLoader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import logimg from '../images/raman-sujati-samaj-logo.png';
import shrinathji from '../images/Login-screen-main-img.png';
import { withRouter } from 'react-router';


class Login extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            LoginName: null,
            LoginPass: null,
            isAuthenticated: '',
            isUserNameInValid: false,
            isPasswordInValid: false,
            isRemeberPassword: false,
        };
    }
    componentDidMount() {   

        this.props.isCookieExist();

    }
   registerClick() {
        this.props.history.push(process.env.PUBLIC_URL + '/register');
    }
    isFormValid = () => {
        return !this.state.isUserNameInValid && !this.state.isPasswordInValid
    }
    formSubmit = (e) => {
         if (e.key === 'Enter' && this.isFormValid()) {
             this.doLogin(e);
         
        }
    }
    doLogin = (evt) => {
        evt.preventDefault();
        let requestData = {
            LoginName: this.state.LoginName,
            LoginPass: this.state.LoginPass,
            RememberPassword: this.state.isRemeberPassword
        };
       
        this.props.doLogin(requestData);
    }

    handleUserChange = (event) => {
        this.updateState({ isUserNameInValid: event.target.value ? false : true, LoginName: event.target.value });
    }
    updateState = (state) => {
        this.setState({ ...state });
    }
    handlePasswordChange = (event) => {
        this.updateState({ isPasswordInValid: event.target.value ? false : true, LoginPass: event.target.value });
    }

    handleRememberPassword = (event) => {
        this.setState({ isRemeberPassword: !this.state.isRemeberPassword });
    }
    render() {
        
        return (
            <div className="login-section">
                <div className="container full-height h-100">
                    <Row className="justify-content-between full-height align-items-center h-100">
                        <div className="col-xl-7 col-lg-6 login-img">
                            <Media src={shrinathji} className="img-fluid" alt="RamanSujatiSamaj" />
                        </div>
                        <div className="col-xl-4 col-lg-5 login-form-block">
                            <Media src={logimg} className="img-fluid mb-4" alt="Raman Sujati Samaj" />
                            <Card className="login-card bg-transparent border-0">
                                <CardHeader className="card-bg border-0 text-center font-weight-bold py-4 px-3 ">Login</CardHeader>
                                <CardBody className="card-bg p-0">
                                    <AjaxLoader isOpen={this.props.isLoading} />
                                    <Form>    
                                      
                                        <FormGroup>
                                            <Col className="login-form">
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon className="fa-w-14 text-primary" icon={faUser} />
                                                        </span>
                                                    </div>
                                                    <Input  maxLength={120} type="text" name="userName "  id="userName" onChange={this.handleUserChange} placeholder="Username"  invalid={this.state.isUserNameInValid}  />
                                                    <FormFeedback>Please enter Username</FormFeedback>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>

                                            <Col className="login-form">
                                                <InputGroup>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FontAwesomeIcon className="fa-w-14 text-primary" icon={faLock} />
                                                        </span>
                                                    </div>
                                                    <Input maxLength={120} type="password" onChange={this.handlePasswordChange} name="password" id="password" placeholder="Password" invalid={this.state.isPasswordInValid} />
                                                    <FormFeedback>Please enter Password.</FormFeedback>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <div className="login-password-section ">
                                            <small style={{ marginLeft: "20px" }} >
                                                <Input type="checkbox" name="rememberPassword" id="rememberPassword" onChange={this.handleRememberPassword} />Remember Password
                                            </small>
                                            <div className="text-right">
                                                <Link className="buttonLink" to={process.env.PUBLIC_URL + "/ForgotPassword"}><small>Forgot Password?</small></Link>
                                            </div>
                                        </div>

                                        <Alert color="danger" isOpen={this.props.errorMessage !== null} className="small">
                                            <p className="m-0">{this.props.errorMessage}</p>
                                        </Alert>
                                        <FormGroup >
                                            <Col sm={12} md={12} lg={12} xl={12} className="pt-3">
                                                <button onClick={this.doLogin} className="btn btn-primary btn-submit btn-block" onKeyPress={this.formSubmit} disabled={!this.isFormValid()} >Login
                                         </button>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col style={{ textAlign: "center" }}>
                                                <span className="text-secondary">
                                                    <Link className="buttonLink" to={process.env.PUBLIC_URL + "/Register"}><small>Register an Account</small></Link>
                                                    <span className="text-dark"> &nbsp;|&nbsp; </span>
                                                    <a className="buttonLink" href={require('../files/RamanSujatiNewMemberRegistrationForm.pdf')} target='_blank' type="application/pdf"><small>Register Offline</small></a>
                                                </span>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        isAuthenticated: state.LoginReducer.isAuthenticated,
        errorMessage: state.LoginReducer.errorMessage,
        isLoading: state.LoginReducer.loading,
        loginType: state.LoginReducer.loginType
    };
};

const mapDispatchToProps = dispatch => ({
    doLogin: (requestData) => {
        dispatch(doLogin(requestData));
    },

    isCookieExist: () => {

        dispatch(isCookieExist());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
