import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { audioDirectoryName, audioSubDirectoryName } from '../../actions/AudioDirectoryAction';
import { connect } from 'react-redux';
import { AjaxLoader } from '../AjaxLoader';
import Pagination from "react-js-pagination";
import { withBreakpoints } from 'react-breakpoints';
import { checkDirectories } from '../../actions/AudioDirectoryAction';
import { withRouter } from 'react-router';
import NoItemFound from '../NoItemFoundPage';
import CommonFunction from '../../common/CommonFunction';

class AudioFolderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            filteredList: [],
            activePage: 1,
            folderPerPage: 12,
            selectedDirectoryName: props.AudioFolderPath
        };
    }

    componentDidMount() {
        this.props.audioDirectoryName(this.state.selectedDirectoryName);
    }

    componentDidUpdate(prevProps) {

        if (this.props.BhajanDirectoryName !== prevProps.BhajanDirectoryName || this.props.VanchanDirectoryName !== prevProps.VanchanDirectoryName || this.props.search !== prevProps.search) {
            if (this.props.AudioFolderPath.search('Vanchan') === -1) {
                if (this.props.search === "") {
                    this.setState({ filteredList: this.props.BhajanDirectoryName });

                } else {
                    this.setState({ filteredList: this.props.BhajanDirectoryName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
                }
            }
            else if (this.props.AudioFolderPath.search('Vanchan') !== 1) {
                this.setState({ filteredList: this.props.VanchanDirectoryName });
            }
        }
    }

    handleDirectoryClick = (name) => {
        this.props.checkDirectories(this.props.SectionName + '/' + name);
        if (this.props.isPresent === false) {
            this.props.history.push({ pathname: process.env.PUBLIC_URL + "/AudioSongsList", search: `${this.props.SectionName + '/' + name}` });
        }
    }


    handlePageClick = (pageNumber) => {
        this.setState({ activePage: pageNumber });
    }

    render() {
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        const { filteredList, activePage, folderPerPage } = this.state;
        let ListofFolders;
        if (isMobile)
            ListofFolders = filteredList;
        else
            ListofFolders = CommonFunction.getFolderList(filteredList, activePage, folderPerPage);
        let pageNumber = CommonFunction.getPageCount(filteredList, folderPerPage);
        if (ListofFolders !== undefined && ListofFolders !== null) {
            if (ListofFolders.length > 0) {
                return (
                    <React.Fragment>
                        <AjaxLoader isOpen={this.props.isLoading} />
                        <Row>
                            {
                                ListofFolders.map(name => {
                                    return (
                                        <React.Fragment>
                                            <Col xl="2" md="4" sm="3" xs="6" className="mt-4 mt-md-5 directorylist" key={name}>
                                                <Link to={{ pathname: process.env.PUBLIC_URL + "/AudioSongsList", search: `${this.props.SectionName + '/' + name}` }}  /*onClick={() => this.handleDirectoryClick(name)} to ="#"*/>
                                                    <div className="directory-type audio-type" />
                                                    <div className="directory-title text-black border border-top-0 border-primary rounded-bottom bg-white d-flex align-items-center justify-content-center px-2">{name}</div>
                                                </Link>
                                            </Col>
                                        </React.Fragment>
                                    );
                                }
                                )
                            }
                        </Row>
                        {isMobile || pageNumber === 1 ? "" :
                            <div className="pushtimarg-pagination pt-10">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={1}
                                    totalItemsCount={pageNumber}
                                    onChange={this.handlePageClick}
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClassPrev="pagination-previous"
                                    itemClassNext="pagination-next"
                                    itemClassFirst="pagination-firstPageText"
                                    itemClassLast="pagination-lastPageText"
                                    activeClass="pagination-active"
                                />
                            </div>
                        }
                    </React.Fragment>
                );
            }
            else {
                if (!this.props.isLoading)
                {
                    return (<NoItemFound />);
                }
                else { return (<AjaxLoader isOpen={this.props.isLoading} />);}
             }
        }
    }
}

const mapStateToProps = (State) => {
    return {
        BhajanDirectoryName: State.AudioReducer.BhajanDirectoryName,
        VanchanDirectoryName: State.AudioReducer.VanchanDirectoryName,
        isLoading: State.AudioReducer.isLoading,
        search: State.SearchReducer.search,
        isPresent: State.AudioReducer.isPresent,
        vanchanSubDirectoryName: State.AudioReducer.VanchanSubDirectoryName
    };
};

const mapDispatchToProps = dispatch => ({
    audioDirectoryName: (folderPath) => {
        dispatch(audioDirectoryName(folderPath));
    },
    checkDirectories: (selectedFolderPath) => {
        dispatch(checkDirectories(selectedFolderPath));

    },
    audioSubDirectoryName: (folderPath) => {
        dispatch(audioSubDirectoryName(folderPath));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(AudioFolderComponent)));

