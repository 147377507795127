import React from 'react';
import { Media } from 'reactstrap';
import { addressDetail } from '../../../actions/AddressDetailsAction'
import { connect } from 'react-redux';
import MatrimonialPendingDirectory from './MatrimonialPendingDirectory';
import MatrimonialApprovedDirectory from './MatrimonialApprovedDirectory';
import "react-datepicker/dist/react-datepicker.css";
import WebsiteIcon from '../../../images/web-icon.png';

class MatrimonialDirectory extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isApproved: false,
            isPending: true
        };
    }

    componentDidMount() {
        this.props.addressDetail();

    }

    openRegistrtationPage = () => {
        const win = window.open(process.env.PUBLIC_URL + "/RegisterMatrimonial", "_blank");
        win.focus();
    }

    setSelectedApprovalStatus = (Approved, Pending) => {
        this.setState({ isApproved: Approved, isPending: Pending });
    }
    render() {

        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                {
                    this.props.address.country.length > 0 ? <div>
                        <div className="d-flex flex-column w-100 mt-3">

                            <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center pb-2 page-title-wrapper">
                                <div className="w-100 my-md-2 d-flex justify-content-between flex-column flex-md-row">
                                    <h1 className="text-primary">Matrimonials</h1>
                                    <div className="d-flex flex-row align-items-center align-self-end mb-2 mb-md-0">
                                        <span className="pr-2">New matrimonial registraion</span>
                                        <span className="member-directory-icon rounded border border-dark p-1">
                                            <Media className="img-fluid" src={WebsiteIcon} alt="register" onClick={() => this.openRegistrtationPage()} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-100 mt-3">
                                <div className="status-button">
                                    <button onClick={() => this.setSelectedApprovalStatus(false, true)} className={this.state.isPending ? "selected-status" : ""}>Requests</button>
                                    <button onClick={() => this.setSelectedApprovalStatus(true, false)} className={this.state.isApproved ? "selected-status" : ""}>Members</button>
                                </div>
                                {this.state.isPending ?
                                    <MatrimonialPendingDirectory /> :
                                    <MatrimonialApprovedDirectory />
                                }
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        address: state.AddressDetailReducer.address,
    };
}

const mapDispatchToProps = dispatch => ({
    addressDetail: () => {
        dispatch(addressDetail());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialDirectory);

