import React, { Component } from 'react';
import { Form, Input, FormGroup, Button, Row, Col } from 'reactstrap';
import Tabulator from "tabulator-tables";
import { withBreakpoints } from 'react-breakpoints';
import { fetchData, setFilters } from '../../../actions/MailStatusAction';
import { connect } from 'react-redux';
import DefaultSectionHeader from '../../DefaultSectionHeader';
import DatePicker from "react-datepicker";
import { AjaxLoader } from '../../AjaxLoader';

class MailStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate:null,
            endDate:null
        }
    }
    el = React.createRef();


    componentDidMount() {
        this.props.fetchData(this.props.filters)
    }

    componentDidUpdate(prevProps) {
        if (this.props.mailStatusData !== prevProps.mailStatusData) {
            this.buildTable();
        }
      
    }

    handleDateChange = (type, date) => {
        var dateFormat = require('dateformat');
        var filters = this.props.filters;
        switch (type) {
            case "startDate":
                if (date !== null) {
                    filters.startDate = dateFormat(date, "yyyy-mm-dd h:MM:ss TT");
                    this.setState({ startDate: date });
                }
                else {
                    filters.startDate = null;
                    this.setState({ startDate: null });
                }
                break;
            case "endDate":
                if (date !== null) {
                    filters.endDate = dateFormat(date, "yyyy-mm-dd h:MM:ss TT");
                    this.setState({ endDate: date });
                }
                else {
                    filters.endDate = null;
                    this.setState({ endDate: null });
                }
                break;
            default:
                break;
        }
        this.props.setFilters(filters);
    }

  


    handleChange = (type, event) => {
        var filters = this.props.filters;
        switch (type) {
            case "selectCategory":
                filters.mailCategory = event.target.value; 
                break;
            case "selectStatus":
                filters.mailStatus = event.target.value;
                break;
            default:
                break;
            
        }
        this.props.setFilters(filters);
    }

    searchMails = () => {
        var filters = this.props.filters;
        var searchFilters = {
            MailCategory: filters.mailCategory,
            MailStatus: filters.mailStatus,
            PageNumber: filters.pageNumber,
            MailPerPage: filters.mailPerPage,
            StartDate: filters.startDate,
            EndDate: filters.endDate
        }
        this.props.fetchData(searchFilters);
    }
    getFormattedDate=(date) =>{
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }
    dateFormatter = (cell) => {
        let that = this;
        let cellValue = cell.getValue();
        return that.getFormattedDate(new Date(cellValue));
    }

    buildTable = () => {
        
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        if (this.props.mailStatusData != null) {
            this.tabulator = new Tabulator(this.el,{
                data: this.props.mailStatusData,     
                layout: "fitColumns",
                resizableRows: false,
                autoResize: true,
                pagination: "local",
                paginationSize: 20, 
                title: "Required",
                responsiveLayout: isMobile ? "collapse" : "",
                columns: [
                    { responsive: 1, formatter: "rownum", title: "SNo", headerSort: true},
                    { responsive: 2, field: "mailDate", title: "Date", headerSort: true, formatter: this.dateFormatter},
                    { responsive: 2, field: "personName", title: "Person Name", headerSort: true, minWidth: "170"  },
                    { responsive: 2, field: "emailFrom", title: "Email From", headerSort: true  },
                    { responsive: 2, field: "emailTo", title: "Email To", headerSort: true},
                    { responsive: 2, field: "category", title: "Category", headerSort: true },
                    { responsive: 2, field: "status", title: "Status", headerSort: true },
                    { responsive: 2, field: "error", title: "Error", headerSort: true, minWidth:"350"   }

                ]
            });
        }
    }

    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <DefaultSectionHeader title="Mail Status" />
                
                <Form >
                    <AjaxLoader isOpen={this.props.isLoading}/>
                         <Row className="flex-md-wrap">
                        <Col md={3} xl={2}>
                                <FormGroup className="select-wrapper text-input">
                                <Input className="bg-transparent border-dark" type="select" id="selectCategory" name="selectCategory" onChange={(event) => this.handleChange("selectCategory", event)}>
                                    <option value="All" disabled>Category</option>
                                    <option value="All">All</option>
                                    <option value="New Registration Request">New Registration Request</option>
                                    <option value="Member Modification Request">Member Modification Request</option>
                                    <option value="Approved New Registration Request">Approved New Registration Request</option>
                                    <option value="Approved Member Modification Request">Approved Member Modification Request</option>
                                    <option value="Forgot Password Request">Forgot Password Request</option>
                                    </Input>
                                    </FormGroup >
                            </Col>

                            <Col md={3} xl={2}>
                                <FormGroup className="select-wrapper text-input">
                                <Input className="bg-transparent border-dark" type="select" id="selectStatus" name="selectStatus" onChange={(event) => this.handleChange("selectStatus", event)}>
                                    <option value="All" disabled>Status</option>
                                    <option value="All">All</option>
                                    <option value="Success">Success</option>
                                    <option value="Fail">Fail</option>
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup >
                                <DatePicker
                                    id="startDate"
                                    name="startDate"
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(date) => this.handleDateChange("startDate", date)}
                                    selected={this.state.startDate}
                                    placeholderText="   Start Date  "
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    pointer-events= "none"
                         
                           />
                                </FormGroup>
                            </Col>

                        <Col md={3}>
                            <FormGroup >
                                <DatePicker 
                                    id="endDate"
                                    name="endDate"
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(date) => this.handleDateChange("endDate", date)}
                                    selected={this.state.endDate}
                                    placeholderText="   End Date  "
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                />
                            </FormGroup>
                            </Col>

                        <Col md={3} xl={2}>
                            <FormGroup >
                                <Button onClick={() => this.searchMails()} className="search-button bg-primary text-white">Search</Button>
                            </FormGroup>
                            </Col>      </Row>
 
                </Form>
                {
                    !this.props.isLoading ? <div><div id="tabulator" ref={el => (this.el = el)} className="mailstatus" />
                    </div> : null
                }
            </div>
            );

    }
}
const mapStateToProps = (state) => {
    return {
        mailStatusData: state.MailStatusReducer.mailStatusData,
        filters: state.MailStatusReducer.filters,
        isLoading: state.MailStatusReducer.isLoading
    };

}

const mapDispatchToProps = dispatch => ({
      fetchData: (searchFilters) => {
        dispatch(fetchData(searchFilters));
    },

    setFilters: (filters) => {
        dispatch(setFilters(filters));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(MailStatus));