import axios from 'axios';

export const documentList = (selectedFolderPath) => {
    return function (dispatch) {
        DocumentDetails('/api/utsav/FetchDocumentList', selectedFolderPath, dispatch);
    };
}

const DocumentDetails = (url, selectedFolderPath, dispatch) => {
    dispatch(get());
    
    axios.get(process.env.PUBLIC_URL + url, { params: { selectedFolderPath } })
        .then(function (response) {
            const DocumentFilesList = response.data;
            dispatch(setDocumentFiles(DocumentFilesList));
        })
    function get() { return { type: 'GET_PDF_FILES_LIST' }; }
};


function setDocumentFiles(DocumentFilesList) {  return { type: 'SET_PDF_FILES_LIST', DocumentFilesList }; }

export const resetDocumentFiles = () =>{ return { type: 'RESET_PDF_FILES_LIST' }; }

