const initialState = {

    videoDirectoryName: [],
    VideoFilesList: [],
    isLoading: '',
}

const VideoReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_VIDEO_DIRECTORY_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_VIDEO_DIRECTORY_NAME':
            return Object.assign({}, state, { videoDirectoryName: action.directoryName, isLoading: false });
        case 'SET_VIDEO_FILES_LIST':
            return Object.assign({}, state, { VideoFilesList: action.VideoFilesList, isLoading: false });
        case 'RESET_FOLDER_VIDEOS':
            return Object.assign({}, state, { videoDirectoryName: [], VideoFilesList: [], isLoading: false });
        case 'GET_VIDEO_FILES_LIST':
            return Object.assign({}, state, { isLoading: true });
        default:
            return state;

    }

}

export default VideoReducer;
