import React, { Component } from 'react';

class DefaultSectionHeader extends Component {
    render() {
        return (
            <div className="row no-gutters pb-3 mt-2 mb-4 position-relative page-title-wrapper">
                <div className="col-sm-12">
                    <h1 className="text-primary">{this.props.title}</h1>
                </div>
            </div>
        );}
}
export default DefaultSectionHeader;