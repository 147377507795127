import axios from 'axios';

export const audioFiles = (selectedFolderPath) => {
    return function (dispatch) {
        AudioFiles('/api/audiogallery/FetchAudioFiles', selectedFolderPath, dispatch);
    };
}

const AudioFiles = (url, selectedFolderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { selectedFolderPath } })
        .then(function (response) {
            const audioDirectoryFiles = response.data;
            if (selectedFolderPath.search('Vanchan') === -1) {
                dispatch(setAudioFiles(audioDirectoryFiles));
            }
            else {
                dispatch(setVanchanAudioFiles(audioDirectoryFiles));
            }
        });

    function get() { return { type: 'GET_AUDIO_FILES_LIST' }; }
};

function setAudioFiles(AudioFilesList) { return { type: 'SET_AUDIO_FILES_LIST', AudioFilesList }; }

function setVanchanAudioFiles(AudioFilesList) { return { type: 'SET_VANCHAN_AUDIO_FILES_LIST', AudioFilesList }; }
