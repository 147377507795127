import axios from 'axios';

export const fetchData = (mailStatusFilter) => {
    return function (dispatch) {
        searchMailStatusData('/api/mailstatus/searchmaildata', mailStatusFilter, dispatch);
    };
};

const searchMailStatusData = (url, mailStatusFilter, dispatch) => {
    dispatch(get())
    axios.post(process.env.PUBLIC_URL + url, mailStatusFilter)
        .then(function (response) {
            const mailstatusdata = response.data;
            dispatch(setMailStatusData(mailstatusdata));
        })
};

function get() { return { type: 'GET_MAILSTATUS_DATA' }; }
export const setMailStatusData = (mailstatusdata) => {
   return {
        type: 'SET_MAILSTATUS_DATA',
        payload: mailstatusdata
    };
};
export const setFilters = (filters) => {

    return {
        type: 'SET_MAILSTATUS_FILTERS',
        filters: filters
    };
};
