const initialState = {
    address: {
        country: [],
        city: [],
        state: [],
        bloodGroups: [],
        qualifications: [],
        professions: [],
        isLoading:''
    }
}
const AddressDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ADDRESS_DETAILS':
            return {
                ...state, address: { ...state.address, country: action.payload.countryMasters, city: action.payload.cityMasters, state: action.payload.stateMasters, bloodGroups: action.payload.bloodGroups, qualifications: action.payload.qualifications, professions: action.payload.professions, isLoading: false }
            };
        case 'GET_ADDRESS_DETAILS':
            return Object.assign({}, state, { isLoading: true });
           
            
        default:
            return state;
    }
}
export default AddressDetailReducer;