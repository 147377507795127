import axios from 'axios';

export const VanchanDetail = () => {
    return function (dispatch) {
        VanchanDetails('/api/vanchan/FetchVanchanDirectories', dispatch)
    };
}
const VanchanDetails = (url, dispatch) => {
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const vanchanDirectories = response.data;
            dispatch(SetVanchanDirectories(vanchanDirectories));

        })
};

function SetVanchanDirectories(vanchanDirectories) {  return { type: 'SET_VANCHAN_DIRECTORY_NAME', vanchanDirectories }; }

//export const SetVanchanDirectories = (vanchanDirectories) => {
    
//    return {
//        type: 'SET_VANCHAN_DIRECTORY_NAME',
//        payload: vanchanDirectories
//    };
//};

