import axios from 'axios';

export const imagesDirectoryName = (folderPath) => {
    return function (dispatch) {
        ImagesDirectoryName('/api/photogallery/FetchImageFolders', folderPath, dispatch);
    };
}

const ImagesDirectoryName = (url, folderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { folderPath } })
        .then(function (response) {
            const directoryName = response.data;
            if (folderPath.search('ChaturShlokiGranth') === -1) {
                dispatch(setDirectories(directoryName));
            }
            else {
                dispatch(setMemberSectionImageDirectories(directoryName));
            }
        });
    function get() { return { type: 'GET_IMAGE_DIRECTORY_NAME' }; }
};

function setDirectories(directoryName) { return { type: 'SET_IMAGE_DIRECTORY_NAME', directoryName }; }
function setMemberSectionImageDirectories(directoryName) { return { type: 'SET_MEMBER_SECTION_IMAGE_DIRECTORY_NAME', directoryName }; }

export const folderImage = (selectedFolderPath) => {
    return function (dispatch) {
        folderImages('/api/photogallery/FetchAllImages', selectedFolderPath, dispatch);
    }
}

const folderImages = (url, selectedFolderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { selectedFolderPath } })
        .then(function (response) {
            const images = response.data;
            if (selectedFolderPath.search('ChaturShlokiGranth') === -1) {
                dispatch(setImageFolderImages(images))
            }
            else {
                dispatch(MemberFolderImages(images));
            }
        });
    function get() { return { type: 'GET_IMAGE_FILES' }; }
}

function setImageFolderImages(images, selectedFolderPath) { return { type: 'SET_IMAGE_FILES', images, selectedFolderPath }; }

function MemberFolderImages(images, selectedFolderPath) { return { type: 'SET_MEMBER_SECTION_IMAGE_FILES', images, selectedFolderPath }; }

export const resetImageFiles = () => {  return { type: 'RESET_FOLDER_IMAGES' }; }
