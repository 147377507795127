import axios from 'axios';

export const UtsavFiles = () => {
    
    return function (dispatch) {
        UtsavDetails('/api/utsav/FetchUtsavFiles', dispatch)
    };
}

const UtsavDetails = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const files = response.data;
            dispatch(setUtsavDetails(files));

        })
    function get() { return { type: 'GET_UTSAV_FILE_NAME' }; }
}

export const setUtsavDetails = (files) => {
    
    return {
        type: 'SET_UTSAV_FILE_NAME',
        payload: files
    };
};