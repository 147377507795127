const initialState = {
    message:'',
    loading: false,
    isSuccessful:''
}
const ForgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FORGOTPASSWORD_REQUEST':
            return Object.assign({}, state, { message: null, loading: true });
        case 'SET_FORGOTPASSWORD_SUCCESS':
            return Object.assign({}, state, { message: action.successMessage.message, isSuccessful: action.successMessage.isSuccessful,loading: false });
        case 'SET_FORGOTPASSWORD_FAILURE':
            return Object.assign({}, state, { message: action.failureMessage.message, isSuccessful: action.failureMessage.isSuccessful, loading: false });
        default:
            return state;
    }
}

export default ForgotPasswordReducer;
