import React from 'react';
import { connect } from 'react-redux';
import { VanchanDetail } from '../../actions/VanchanAction';
import AudioFolderComponent from '../Common/AudioFolderComponent';
import DocumentDirectory from '../Documents/DocumentDirectory';
import { resetDocumentFiles } from '../../actions/DocumentListAction';
import { resetImageFiles } from '../../actions/ImageAction';
import VanchanHeader from './VanchanHeader';

class Vanchan extends React.Component {
    componentWillMount() {
        this.props.resetImageFiles();
    }

    componentDidMount() {
        this.props.resetDocumentFiles();
        this.props.VanchanDetail();
    }

    DisplayContainer = () => {
        var VanchanType = Object.freeze({ "Audios": "Audios", "Documents": "Documents" })
        const queryString = require('query-string');
        const urlVariable = this.props.location.search;
       
        if (queryString.parse(this.props.location.search).Type != null) {
            if (VanchanType.Documents === queryString.parse(this.props.location.search).Type) {
                if (this.props.DocDirectoryPath !== null && this.props.DocDirectoryPath !== '') {
                    return (
                        <div>
                            <VanchanHeader isActive='2' />
                            <DocumentDirectory DocumentDirectoryPath={this.props.DocDirectoryPath} SectionName={this.props.SectionName + "/Documents"} />
                        </div>
                    );
                }
            }
            else {
                if (this.props.AudioDirectoryPath !== null && this.props.AudioDirectoryPath !== '') {
                    return (
                        <div>
                            <VanchanHeader isActive='1' />
                            <AudioFolderComponent AudioFolderPath={this.props.AudioDirectoryPath} SectionName={this.props.SectionName + "/Audios"} />
                        </div>
                    );
                }
            }
        }
    }

    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                {this.DisplayContainer()}
            </div>
        );
    }
}

const mapStateToProps = (State) => {
    return {
        AudioDirectoryPath: State.VanchanReducer.AudioDirectoryPath,
        VideoDirectoryPath: State.VanchanReducer.VideoDirectoryPath,
        DocDirectoryPath: State.VanchanReducer.DocDirectoryPath,
        SectionName: State.VanchanReducer.SectionName,
    };
};

const mapDispatchToProps = dispatch => ({
    VanchanDetail: () => {
        dispatch(VanchanDetail());
    },
    resetDocumentFiles: () => {
        dispatch(resetDocumentFiles());
    },
    resetImageFiles: () => {
        dispatch(resetImageFiles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Vanchan);
