
import axios from 'axios';

export const addressDetail = () => {
    return function (dispatch) {
        addressDetails('/api/member/addressDetails', dispatch);
    };
}
const addressDetails = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const address = response.data;
            dispatch(setAddressDetails(address));
        })
    function get() { return { type: 'GET_ADDRESS_DETAILS' }; }
};

export const setAddressDetails = (address) => {

    return {
        type: 'SET_ADDRESS_DETAILS',
        payload: address
    };
};