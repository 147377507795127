import { sidebarActionType } from './ActionTypes';

export const sidebarNavOpen = () => {
    return function (dispatch) {        
        dispatch(openSidebarNav());
    }
};

export const sidebarNavClose = () => {
    return function (dispatch) {       
        dispatch(closeSidebarNav());
    }
};

export const openSidebarNav = () => {
    return {
        type: sidebarActionType.OPEN_SIDEBARNAV , isSidebarOpen: true
    };
};

export const closeSidebarNav = () => {
    return {
        type: sidebarActionType.CLOSE_SIDEBARNAV , isSidebarOpen: false
    };
};

