import axios from 'axios';

export const videoFiles = (selectedFolderPath) => {

    return function (dispatch) {
        AudioFiles('/api/videogallery/FetchVideoFiles', selectedFolderPath, dispatch);
    };
}

const AudioFiles = (url, selectedFolderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { selectedFolderPath } })
        .then(function (response) {
            const VideoFilesList = response.data;
            dispatch(setVideoFiles(VideoFilesList));

        })
    function get() { return { type: 'GET_VIDEO_FILES_LIST' }; }
    
};


function setVideoFiles(VideoFilesList) {  return { type: 'SET_VIDEO_FILES_LIST', VideoFilesList }; }
