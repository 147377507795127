import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Row, Card, CardBody, CardHeader, InputGroup, FormFeedback, Media, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { doForgotPassword } from '../actions/ForgotPasswordAction';
import { connect } from 'react-redux';
import { AjaxLoader } from './AjaxLoader';
import logimg from '../images/raman-sujati-samaj-logo.png';
import shrinathji from '../images/Login-screen-main-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


class ForgotPassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            isEmailValid: ''
        };
    }
    onEmailChange = (event) => {
        this.updateState({ isEmailValid: event.target.value ? false : true, email: event.target.value });
    }
    updateState = (state) => {
        this.setState({ ...state });
    }
    doForgotPassword = () => {
        
       
        let requestData = {
            EmailTo: this.state.email,
        }
        this.props.doForgotPassword(requestData);
    }
    render() {
        return (
            <div className="login-section">
                <div className="container full-height h-100">
                    <Row className="justify-content-between full-height align-items-center h-100">
                        <div className="col-xl-7 col-lg-6 login-img">
                            <Media src={shrinathji} className="img-fluid" alt="RamanSujatiSamaj" />
                        </div>
                        <div className="col-xl-4 col-lg-5 login-form-block">
                            <Media src={logimg} className="img-fluid mb-4" alt="Raman Sujati Samaj" />
                            <Card className="login-card bg-transparent border-0">
                                <CardHeader className="card-bg border-0 text-center font-weight-bold py-4 px-3"><div className="fp-header p-2">RESET PASSWORD</div></CardHeader>
                                <CardBody className="card-bg p-0">
                                    <div className="text-center px-2">
                                        <b className="pb-2">Forgot your password?</b>
                                        <p>Enter your email address and we will send you instructions on how to reset your password.</p>
                                    </div>
                                    <Form>
                                        <AjaxLoader isOpen={this.props.isLoading} />
                                        <FormGroup className="pl-3 pr-3 login-form">
                                            <InputGroup>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <FontAwesomeIcon className="fa-w-14 text-primary" icon={faEnvelope} />
                                                    </span>
                                                </div>
                                                <Input type="email" name="email" id=" exampleEmail" placeholder="Email Address" onChange={this.onEmailChange} invalid={this.state.isEmailValid} />
                                                <FormFeedback>Please enter email</FormFeedback>
                                            </InputGroup>
                                        </FormGroup>
                                        <Alert color="danger" isOpen={this.props.message !== ""} className="small">
                                            <p className="m-0">{this.props.message}</p>
                                        </Alert>
                                        <FormGroup className="px-3">
                                            <Button className="btn btn-primary btn-block btn-submit text-white" onClick={this.doForgotPassword}>Send</Button>
                                        </FormGroup>
                                        <FormGroup row className="ml-1 justify-content-center text-center">
                                            <span className="text-secondary">
                                                <Link className="buttonLink" to={process.env.PUBLIC_URL + "/Register"}><small>Register an Account</small></Link> <br />
                                                <Link className="buttonLink" to={process.env.PUBLIC_URL + "/Login"}><small>Login Again</small></Link>
                                            </span>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>
                </div>
            </div >
        );
    };
};
const mapStateToProps = (state) => {
    
    return {
        isLoading: state.ForgotPasswordReducer.loading,
        message: state.ForgotPasswordReducer.message
    };
};
const mapDispatchToProps = dispatch => ({
    doForgotPassword: (requestData) => {
        dispatch(doForgotPassword(requestData));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);