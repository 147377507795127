import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import DefaultSectionHeader from '../../src/components/DefaultSectionHeader';

class Accommodation extends Component {
    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <DefaultSectionHeader title="Accommodation Available" />
                <Row className="h-100 no-gutters">
                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Raman Sujati Samaj Jaipur Trust</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">1268/4A, Gangapole Ka Rasta, Jaipur, Raj. (INDIA)</span>
                                </li>
                                <li>
                                    <span className="label">Pin:</span>
                                    <span className="descrip">302003</span>
                                </li>
                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:2576768">2576768</a>, <a href="callto:2575344">2575344</a> (Manohardas Kotawala), <a href="callto:2204593">2204593</a>, <a href="callto:2365850">2365850</a> (Tikam Kumar Agrawal)</span>
                                </li>
                                <li>
                                    <span className="label">Mobile:</span>
                                    <span className="descrip"><a href="callto:9829065850">9829065850</a></span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Ramanlalji Maharaj Mandir Trust</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Nand Chowk, Gokul (Uttar Pradesh)</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:09821136162">09821136162</a> (Kamal Badiyani), <a href="callto:09837345686">09837345686</a>(Sanjay Kambalwalla), <a href="callto:09810799503">09810799503</a> (Dinesh Sharma)</span>
                                </li>
                                <li>
                                    <span className="label">Email:</span>
                                    <span className="descrip">kamalbadiyani@gmail.com</span>
                                </li>
                            </ul>
                        </div>
                    </Col>


                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Ramanesh Puri (Nainse Dharamshala)</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Shyam Ghat, Mathura, (Uttar Pradesh)</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:2413238">2413238</a>, <a href="callto:2404238">2404238</a>(Vaishnavdas Kambelwale)</span>
                                </li>
                            </ul>
                        </div>
                    </Col>


                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Ramanesh Puri</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Shri Raman Ananya Sujati Samaj Trust, Baroda, Near Nageshwar Society, Armeet Nagar, Near Circle, VIP Road, Kareli Bag, Baroda.</span>
                                </li>
                                <li>
                                    <span className="label">Pin:</span>
                                    <span className="descrip">390022</span>
                                </li>
                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:2489495">2489495</a> (Gokul H. Desai), <a href="callto:2398571">2398571</a> (Harshbhai), <a href="callto:2483228">2483228</a> (Kiritbhai)</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Rajdeep</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">3rd Floor, First Kasturba Cross Road, Borivili (West) Mumbai (INDIA)</span>
                                </li>
                                <li>
                                    <span className="label">Pin:</span>
                                    <span className="descrip">400066</span>
                                </li>
                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:28014665">28014665</a> (Raj Deep - Baby)</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Raman Ananya Sujati Samaj</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Near Dr. Bhuwana Banglow, Section Road, Opp. Niradhar Ashram, Jamnagar (INDIA)</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:2674504">2674504</a></span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Raman Ananya Sujati Samaj</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Sarover Complex, Near Kankaria, Opp. Ichnaben Ki Badi, Mani Nagar, Ahemdabad (Gujrat) (INDIA)</span>
                                </li>
                                <li>
                                    <span className="label">Pin:</span>
                                    <span className="descrip">380008</span>
                                </li>
                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:2371077">2371077</a>, <a href="callto:22113048">22113048</a>, <a href="callto:25464773">25464773</a> (Indra Vadan Modi), <a href="callto:26621578">26621578</a> (Kantibhai),  <a href="callto:25460810">25460810</a> (Bachiben)</span>
                                </li>
                            </ul>
                        </div>
                    </Col>



                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Vallabh Sujati Samaj</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">6, Nutan Nagar Society, Shakti Nagar, Gram Panchayat, Jam Khambalia</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:22978">22978</a> (Jathulal), <a href="callto:234316">234316</a> (Mohanlal)</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>c/o Harish Nagpal</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">4 navajo lane , freehold, NJ 07728, USA</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:9086928024">9086928024</a></span>
                                </li>
                                <li>
                                    <span className="label">Email:</span>
                                    <span className="descrip">Harish.nagpal@gmail.com</span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col className="address-block col-12" xl={6} >
                        <div className="address-section" >
                            <h4>Shri Raman Sujati Samaj Seva Samiti</h4>
                            <ul className="address-list">
                                <li>
                                    <span className="label">Address:</span>
                                    <span className="descrip">Shyam Ghat, Mathura, U.P. (INDIA)</span>
                                </li>

                                <li>
                                    <span className="label">Phone:</span>
                                    <span className="descrip"><a href="callto:915652404238">91-565-2404238</a></span>
                                </li>
                            </ul>
                        </div>
                    </Col>

                </Row>
            </div>
        );
    }
}
export default Accommodation;