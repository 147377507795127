
export const searchName = (search) => {
    
    return (dispatch) => {
        const searchDetail = search;
        dispatch(setSearchDetail(searchDetail));
    }
   

}
function setSearchDetail(searchDetail) { return { type: 'SET_SEARCH', searchDetail }; }

export const ResetSearch = () => { return { type: 'RESET_SEARCH' }; }