import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import reducers from '../reducers';

export const history = createBrowserHistory();

function ConfigureStore(history, initialState = {}) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];
    //    routerMiddleware(history)
    //];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        reducers,
        initialState,
        compose(applyMiddleware(...middleware))
    );
}

export default ConfigureStore; 