import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { AjaxLoader } from '../AjaxLoader';
import { UtsavFiles } from '../../actions/UtsavListAction';
import _reverse from 'lodash/reverse';
import CommonFunction from '../../common/CommonFunction';
import DefaultSectionHeader from '../DefaultSectionHeader';
import utsavImage from '../../images/utsav-img.jpg';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';
import { Link } from 'react-router-dom';

//const media = require.context('../../../public/Media', true);

class UtsavList extends Component {
    constructor(props) {
        super(props)
        this.State = {
            pdfFilePath: '#',
        }
    }

    componentDidMount() {
        this.props.UtsavFiles();
    }

    showUtsavFiles = () => {

        if (this.props.utsavsList.length > 0) {
            let utsavsFiles;

            utsavsFiles = this.props.utsavsList.map((folder, index) => (
                <li className="utsav-list-items" key={index}>
                    <div>{folder.directoryName}</div>
                    {
                        _reverse(folder.files).map((file, fileIndex) => (
                            <React.Fragment key={fileIndex}>
                                <div className="utsav-items-icons">
                                    <a href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/UtsavList/' + folder.directoryName + "/" + file + '.pdf'} target='_blank' type="application/pdf" title="Preview-file">
                                        <span className="preview-icon"><img src={previewFileIcon} alt="preview" /></span>
                                    </a>
                                    <a onClick={() => { CommonFunction.downloadFile("UtsavList", folder.directoryName, file) }} title="Download-icon" className="file-icon">
                                        <span className="download-icon"><img src={downloadFileIcon} alt="download" /></span>
                                    </a>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </li>
            ));
            return utsavsFiles;
        }
    }

    render() {
        return (
            <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5 utsav-layout-bg">
                <div className="row">
                    <div className="col-sm-12">
                        <DefaultSectionHeader title="List of Utsavs" />
                    </div>
                    <AjaxLoader isOpen={this.props.isLoading} />
                </div>
                {
                   !this.props.isLoading ? <div>
                        <React.Fragment>
                            <Row>
                                <Col md="4">
                                    <img src={utsavImage} className="ustav-image" alt="Utsav" />
                                </Col>
                                <Col md="8" xl="4" className="offset-xl-1">
                                    <div className="utsav-list mb-5">
                                        <ul>
                                            <li className="utsav-title">
                                                <div><strong>Utsav</strong></div>
                                                <div><strong>Hindi</strong></div>
                                                <div><strong>English</strong></div>
                                            </li>
                                            {this.showUtsavFiles()}
                                        </ul>
                                    </div>
                                    <div className="all-utsav">
                                        <span><Link to={process.env.PUBLIC_URL + "/UtsavListYearWise"}>All Utsavs Of Year</Link></span>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment></div> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        utsavsList: state.UtsavListReducer.FileDetail,
        isLoading: state.UtsavListReducer.isLoading,
        filePathURL: state.LoginReducer.filePathURL
    };
}

const mapDispatchToProps = dispatch => ({
    UtsavFiles: () => {
        dispatch(UtsavFiles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UtsavList);