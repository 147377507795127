import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Col, Row, Card, CardBody, CardHeader, Alert, FormFeedback, Media } from 'reactstrap';
import { connect } from 'react-redux';
import { AjaxLoader } from '../AjaxLoader';
import { If } from 'rc-if-else';
import { Link } from 'react-router-dom';
import { addressDetail } from '../../actions/AddressDetailsAction';
import { registerMemberDetails } from '../../actions/MatrimonialAction';
import logimg from '../../images/raman-sujati-samaj-logo.png';
import DateTime from '../DatePicker';
import {format} from 'date-fns'
var validator = require('validator');

class Register extends Component {
    constructor() {
        super();
        this.state = {
            personName: "",
            gender: "",
            agedob: "",
            fatherMotherName: "",
            caste: "",
            addressRes: "",
            phoneRes: "",
            mobile: "",
            stdcode: "",
            email: "",
            qualification: "",
            highestDegree: "",
            otherQualication: "",
            otherRelatedDetails: "",
            jobStatus: "",
            jobLocation: "",
            anualIncome: "",
            otherJobDetails: "",
            maritalStatus:"",
            motherTongue: "",
            height: "",
            complexion: "",
            bodyType: "",
            specialCases: "",
            smoke: "",
            drink: "",
            personalValues: "",
            countryName: "",
            stateName: "",
            cityName: "",
            invalidPersonName: '',
            invalidGender: '',
            invalidDateOfBirth: '',
            invalidAddressRes: '',
            invalidEmail: '',
            invalidCountryName: '',
            invalidStateName: '',
            invalidCityName: '',
            invalidQualification: '',
            invalidJobStatus: '',
            invalidMaritalStatus: '',
            invalidFatherMotherName: '',
            invalidPhoneRes: '',
            invalidMobile: '',
            invalidCaste: '',
            invalidStdCode: '',
            showTextBox: false,
            invalidAnualIncome:false
        };

        this.registerForm = React.createRef();
    }

    handleChange = (type, event) => {
        event.preventDefault();
        switch (type) {
            case "personName":
                this.setState({ invalidPersonName: validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/), [type]: event.target.value });
                break;
            case "gender":
                this.setState({ invalidGender: event.target.value === "Select", [type]: event.target.value });
                break;
            case "addressRes":
                this.setState({ invalidAddressRes: validator.isEmpty(event.target.value), [type]: event.target.value });
                break;
            case "email":
                this.setState({ invalidEmail: !validator.isEmail(event.target.value), [type]: event.target.value });
                break;
            case "countryName":
                this.setState({ invalidCountryName: event.target.value === "Select" || validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s]*$/), [type]: event.target.value, StateName: "", cityName: "" });
                break;
            case "stateName":
                this.setState({ invalidStateName: event.target.value === "Select" || validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s]*$/), [type]: event.target.value, cityName: "" });
                break;
            case "cityName":
                this.setState({ invalidCityName: event.target.value === "Select" || validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s]*$/), [type]: event.target.value });
                break;
            case "qualification":
                this.setState({ invalidQualification: event.target.value === "Select", [type]: event.target.value });
                break;
            case "jobStatus":
                this.setState({ invalidJobStatus: event.target.value === "Select", [type]: event.target.value });
                break;
            case "maritalStatus":
                this.setState({ invalidMaritalStatus: event.target.value === "Select", [type]: event.target.value });
                break;
            case "fatherMotherName":
                this.setState({ invalidFatherMotherName: (!validator.isEmpty(event.target.value) && !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/)), [type]: event.target.value });
                break;
            case "phoneRes":
                this.setState({ invalidPhoneRes: (!validator.isEmpty(event.target.value) && !validator.isMobilePhone(event.target.value)), [type]: event.target.value });
                break;
            case "mobile":
                this.setState({ invalidMobile: (!validator.isEmpty(event.target.value) && !validator.isMobilePhone(event.target.value)), [type]: event.target.value });
                break;
            case "caste":
                this.setState({ invalidCaste: (!validator.isEmpty(event.target.value) && !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/)), [type]: event.target.value });
                break;
            case "stdCode":
                this.setState({ invalidStdCode: (!validator.isEmpty(event.target.value) && !validator.isNumeric(event.target.value)), [type]: event.target.value });
                break;
            case "anualIncome":
                this.setState({
                    invalidAnualIncome: (!validator.isEmpty(event.target.value) && !validator.isDecimal(event.target.value)), [type]: event.target.value });
                break;
            default:
                this.setState({ [type]: event.target.value });
                break;
        }

    }

    resetField = () => {
        this.registerForm.current.reset();
        this.setState({ agedob: "" });
    
    }

    createCountryItems = () => {
        const listItems = this.props.address.country
            .map((country) =>
                <option key={country.countryId} value={country.countryName}>{country.countryName}</option>
            );
        return listItems;
    }

    createStateItems = () => {
        var country = this.props.address.country.filter((e) => { return (e.countryName === this.state.countryName); });
        return this.props.address.state
            .filter((e) => { return (country.length === 1 && e.countryId === country[0].countryId); })
            .map((state) =>
                <option key={state.stateId} value={state.stateName}>{state.stateName}</option>
            );
    }
    createCityItems = () => {
        var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.stateName); });
        var citylist = this.props.address.city
            .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) });
        if (citylist.length > 0) {
            return citylist.map((city) =>
                <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
            );
        }
        else
            return <option key="nodata" value="No City Found" disabled > No City Found</option>;

    }


    showTextBoxMethod = () => {

        this.setState({ showTextBox: this.state.showTextBox ? false : true });
    }

    handleAgeDob = (date) => {
        this.setState({ invalidDateOfBirth: date._d === undefined, agedob: format(date._d, 'dd/MM/yyyy') });
    }

    doRegister = () => {
        if (!this.state.invalidAddressRes && !this.state.invalidCityName && !this.state.invalidCountryName && !this.state.invalidDateOfBirth && !this.state.invalidEmail && !this.state.invalidEmail && !this.state.invalidGender
            && !this.state.invalidJobStatus && !this.state.invalidJobStatus && !this.state.invalidMaritalStatus && !this.state.invalidPersonName && !this.state.invalidQualification && !this.state.invalidStateName && !this.state.invalidAnualIncome) {
            let registerDetails = {
                RowID: this.state.rowId,
                PersonName: this.state.personName,
                Gender: this.state.gender,
                Agedob: this.state.agedob,
                FatherMotherName: this.state.fatherMotherName,
                Caste: this.state.caste,
                AddressRes: this.state.addressRes,
                PhoneRes: this.state.phoneRes,
                Mobile: this.state.mobile,
                Stdcode: this.state.stdcode,
                Email: this.state.email,
                Qualification: this.state.qualification,
                HighestDegree: this.state.highestDegree,
                OtherQualication: this.state.otherQualication,
                OtherRelatedDetails: this.state.otherRelatedDetails,
                JobStatus: this.state.jobStatus,
                JobLocation: this.state.jobLocation,
                AnualIncome: this.state.anualIncome,
                OtherJobDetails: this.state.otherJobDetails,
                MaritalStatus: this.state.maritalStatus,
                MotherTongue: this.state.motherTongue,
                Height: this.state.height,
                Complexion: this.state.complexion,
                BodyType: this.state.bodyType,
                SpecialCases: this.state.specialCases,
                Smoke: this.state.smoke,
                Drink: this.state.drink,
                PersonalValues: this.state.personalValues,
                CountryName: this.state.countryName,
                StateName: this.state.stateName,
                CityName: this.state.cityName
            };
            this.props.registerMemberDetails(registerDetails);
        };
    };

    componentDidMount() {
        if (this.props.address.country.length === 0) {
            this.props.addressDetail();
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.isRegisterSuccessful !== prevProps.isRegisterSuccessful) {
            if (this.props.isRegisterSuccessful == 1)
                this.resetField();
        }
    }
    render() {
        return (
            this.props.address.country.length > 0 ?
                <div className="register-section">
                    <Row className="justify-content-between full-height align-items-center h-100">
                        <Col className="m-auto pb-4" xs="12" sm="9" lg="8" md="10" xl="6">
                            <AjaxLoader isOpen={this.props.registering} />
                            <div className="col-sm-12 py-4 px-5 text-center"><Media src={logimg} className="img-fluid d-inline-block" alt="Raman Sujati Samaj" /></div>
                            <Card className="card-bg-register py-4" >
                                <CardHeader className="text-center card-header-register"> NEW MATRIMONIAL REGISTRATION</CardHeader>
                                <CardBody>
                                    <Form innerRef={this.registerForm}  autoComplete="off">
                                        
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row"><Input type="text" name="personName" id="personName" placeholder="--Name--" onChange={(event) => this.handleChange("personName", event)} invalid={this.state.invalidPersonName} autoComplete="off" /><span className="text-danger">*</span>
                                                        <FormFeedback>Please enter valid Name</FormFeedback>
                                                    </div>
                                                        
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup className="select-wrapper">
                                                    <div className="d-flex flex-row"><Input type="select" name="gender" id="gender" onChange={(event) => this.handleChange("gender", event)} invalid={this.state.invalidGender}>
                                                        <option value="Select">--Gender--</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </Input><span className="text-danger">*</span>
                                                   <FormFeedback>Please select gender</FormFeedback>
                                                    </div>
                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row">
                                                    <DateTime id="agedob"
                                                        viewMode="days"
                                                        value={this.state.agedob}
                                                        dateFormat="DD/MM/YYYY"
                                                            onChange={(date) => this.handleAgeDob(date)}
                                                        invalid={this.state.invalidDateOfBirth}
                                                        placeholder="--DOB (DD/MM/YYYY)--"
                                                        className="flex-fill"
                                                        name="agedob"
                                                        /><span className="text-danger">*</span>
                                                        <FormFeedback>Please enter valid Date</FormFeedback>
                                                        </div>
                                                        
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="fatherMotherName" id="fatherMotherName" placeholder="--Father/Mother Name--" onChange={(event) => this.handleChange("fatherMotherName", event)} invalid={this.state.invalidFatherMotherName} />
                                                    <FormFeedback>Please enter a valid Name</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="caste" id="caste" placeholder="--Caste--" onChange={(event) => this.handleChange("caste", event)} invalid={this.state.invalidCaste} />
                                                    <FormFeedback>Please enter a valid Caste Name</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row"><Input type="text" name="addressRes" id="addressRes" placeholder="--Address Residence--" onChange={(event) => this.handleChange("addressRes", event)} invalid={this.state.invalidAddressRes} />
                                                        <span className="text-danger">*</span>
                                                        <FormFeedback>Please enter Address</FormFeedback></div> 
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="stdcode" id="stdcode" placeholder="--Stdcode--" onChange={(event) => this.handleChange("stdCode", event)} invalid={this.state.invalidStdCode} />
                                                    <FormFeedback>Please enter a valid STD Code</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="phoneRes" id="phoneRes" placeholder="--Phone Residence--" onChange={(event) => this.handleChange("phoneRes", event)} invalid={this.state.invalidPhoneRes} />
                                                    <FormFeedback>Please enter valid Phone Number</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="mobile" id="mobile" placeholder="--Mobile--" onChange={(event) => this.handleChange("mobile", event)} invalid={this.state.invalidMobile} />
                                                    <FormFeedback>Please enter valid Mobile Number</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                      <div className="d-flex flex-row select-wrapper"><Input type="select" name="countryName" id="countryName" onChange={(event) => this.handleChange("countryName", event)} invalid={this.state.invalidCountryName}>
                                                        <option value="Select">--Country--</option>
                                                        {this.createCountryItems()}
                                                    </Input><span className="text-danger">*</span>
                                                        <FormFeedback>Please select country</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                   <FormGroup className={`${this.state.countryName !== "INDIA" ? "d-none" : "d-block"}`}>
                                                    <div className="d-flex flex-row select-wrapper"><Input type="select" name="stateName" id="stateName" placeholder="State" onChange={(event) => this.handleChange("stateName", event)} invalid={this.state.invalidStateName}>
                                                        <option value="Select">--State--</option>
                                                        {this.createStateItems()}
                                                    </Input><span className="text-danger">*</span>
                                                        <FormFeedback>Please select state</FormFeedback></div>
                                                </FormGroup>
                                                <FormGroup className={`${this.state.countryName === "INDIA" ? "d-none" : "d-block"}`}>
                                                    <div className="d-flex flex-row"><Input type="text" name="stateName" id="stateName" placeholder="State" onChange={(event) => this.handleChange("stateName", event)} invalid={this.state.invalidStateName} />
                                                        <span className="text-danger">*</span><FormFeedback>Please enter state</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
                                        
                                            <Col sm={12} md={6}>
                                                <FormGroup className={`${this.state.countryName === "INDIA" ? "d-block" : "d-none"}`}>
                                                    <If condition={this.state.showTextBox}>
                                                        <div className="d-flex flex-row"><Input maxLength={50} type="text" name="city" id="city" placeholder="City" onChange={(event) => this.handleChange("cityName", event)} invalid={this.state.invalidCityName} /><span className="text-danger">*</span>
                                                            <FormFeedback>Please enter City</FormFeedback></div>
                                                    </If>
                                                    <If condition={!this.state.showTextBox}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="city" id="city" placeholder="City" onChange={(event) => this.handleChange("cityName", event)}>
                                                            <option key="select" value="Select">--City--</option>
                                                            {this.createCityItems()}
                                                        </Input><span className="text-danger">*</span></div>
                                                    </If>
                                                    <Link className="text-dark float-right" to="#" onClick={this.showTextBoxMethod}><small><u>City other than list</u></small></Link>
                                                    </FormGroup>
                                                <FormGroup className={`${this.state.countryName === "INDIA" ? "d-none" : "d-block"}`}>
                                                    <div className="d-flex flex-row"><Input type="text" name="cityName" id="cityName" placeholder="City" onChange={(event) => this.handleChange("cityName", event)} invalid={this.state.invalidCityName} />
                                                        <span className="text-danger">*</span><FormFeedback>Please enter city</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
 
                                        </Row>
                                        <Row>
       
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row"><Input type="text" name="email" id="email" placeholder="--Email--" onChange={(event) => this.handleChange("email", event)} invalid={this.state.invalidEmail} />
                                                        <span className="text-danger">*</span><FormFeedback>Please enter valid Email</FormFeedback></div>
                                                </FormGroup>
                                            </Col>

                                       
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row select-wrapper"><Input type="select" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)} invalid={this.state.invalidQualification}>
                                                        <option value="Select">--Qualification--</option>
                                                        <option value="Post Graduate">Post Graduate</option>
                                                        <option value="Graduate">Graduate</option>
                                                        <option value="Under Graduate">Under Graduate</option>
                                                        <option value="Professional">Professional</option>
                                                            </Input><span className="text-danger">*</span>
                                                        <FormFeedback>Please select qualification</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="highestDegree" id="highestDegree" placeholder="Highest Degree" onChange={(event) => this.handleChange("highestDegree", event)} />
                                                </FormGroup>
                                            </Col>
                                        
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                  <Input type="text" name="otherQualication" id="otherQualication" placeholder="Other Qualification" onChange={(event) => this.handleChange("otherQualication", event)} />
                                                     </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="otherRelatedDetails" id="otherRelatedDetails" placeholder="Other Related Details" onChange={(event) => this.handleChange("otherRelatedDetails", event)} />
                                                </FormGroup>
                                            </Col>
                                        
                                         
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <div className="d-flex flex-row select-wrapper"><Input type="select" name="jobStatus" id="jobStatus" placeholder="Job Status" onChange={(event) => this.handleChange("jobStatus", event)} invalid={this.state.invalidJobStatus}>
                                                        <option value="Select">--Job Status--</option>
                                                        <option value="None">None</option>
                                                        <option value="Student">Student</option>
                                                        <option value="Govt. Sector">Govt. Sector</option>
                                                        <option value="Private Sector">Private Sector</option>
                                                        <option value="Self Employed/Business">Self Employed/Business</option>
                                                            </Input><span className="text-danger">*</span>
                                                        <FormFeedback>Please select job status</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="jobLocation" id="jobLocation" placeholder="Job Location" disabled={this.state.jobStatus === "None" || this.state.jobStatus === "Student"} onChange={(event) => this.handleChange("jobLocation", event)} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="anualIncome" id="anualIncome" placeholder="Annual Income" disabled={this.state.jobStatus === "None" || this.state.jobStatus === "Student"} onChange={(event) => this.handleChange("anualIncome", event)} invalid={this.state.invalidAnualIncome} />
                                                    <FormFeedback>Please enter valid annual income(Annual income should be in decimals)</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="otherJobDetails" id="otherJobDetails" placeholder="Other Job Details" onChange={(event) => this.handleChange("otherJobDetails", event)} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="maritalStatus" id="maritalStatus" placeholder="Marital Status" onChange={(event) => this.handleChange("maritalStatus", event)} invalid={this.state.invalidMaritalStatus}>
                                                        <option value="Select">--Marital Status--</option>
                                                        <option value="Never Married">Never Married</option>
                                                        <option value="Divorced">Divorced</option>
                                                        <option value="Widow/Widower">Widow/Widower</option>
                                                            </Input><span className="text-danger">*</span>
                                                        <FormFeedback>Please select marital status</FormFeedback></div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="motherTongue" id="motherTongue" placeholder="Mother Tongue" onChange={(event) => this.handleChange("motherTongue", event)} />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="height" id="height" placeholder="Height" onChange={(event) => this.handleChange("height", event)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <FormGroup className="select-wrapper">
                                                    <Input type="select" name="complexion" id="complexion" placeholder="Complexion" onChange={(event) => this.handleChange("complexion", event)}>
                                                        <option value="Select">--Complexion--</option>
                                                        <option value="Fair">Fair</option>
                                                        <option value="Very Fair">Very Fair</option>
                                                        <option value="Wheatish">Wheatish</option>
                                                        <option value="Dark">Dark</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <FormGroup className="select-wrapper">
                                                    <Input type="select" name="bodyType" id="bodyType" placeholder="Body Type" onChange={(event) => this.handleChange("bodyType", event)} >
                                                        <option value="Select">--Body Type--</option>
                                                        <option value="Slim">Slim</option>
                                                        <option value="Athletic">Athletic</option>
                                                        <option value="Average">Average</option>
                                                        <option value="Heavy">Heavy</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={4}>
                                                <FormGroup className="select-wrapper">
                                                    <Input type="select" name="smoke" id="smoke" placeholder="Smoke" onChange={(event) => this.handleChange("smoke", event)} >
                                                        <option value="Select">--Smoke--</option>
                                                        <option value="No">No</option>
                                                        <option value="Occassionaly">Occassionaly</option>
                                                        <option value="Yes">Yes</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <FormGroup className="select-wrapper">
                                                    <Input type="select" name="drink" id="drink" placeholder="Drink" onChange={(event) => this.handleChange("drink", event)} >
                                                        <option value="Select">--Drink--</option>
                                                        <option value="No">No</option>
                                                        <option value="Occassionaly">Occassionaly</option>
                                                        <option value="Yes">Yes</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <FormGroup className="select-wrapper">
                                                    <Input type="select" name="personalValues" id="personalValues" placeholder="Personal Values" onChange={(event) => this.handleChange("personalValues", event)}>
                                                        <option vlaue="Select">--Personal values--</option>
                                                        <option vlaue="Traditional">Traditional</option>
                                                        <option vlaue="Moderate">Moderate</option>
                                                        <option vlaue="Liberal">Liberal</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Button onClick={this.doRegister} className="register-button p-4">Register</Button>
                                        </div>
                                        <Alert color="danger" isOpen={this.props.registerMessage !== null && !this.props.isRegisterSuccessful} className="small"><p className="m-0">{this.props.registerMessage}</p></Alert>
                                        <Alert color="success" isOpen={this.props.registerMessage !== null && this.props.isRegisterSuccessful} className="small"><p className="m-0">{this.props.registerMessage}</p></Alert>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div >
                : ""
        );
    }
}

const mapStateToProps = (state) => {
    return {
        address: state.AddressDetailReducer.address,
        registerMessage: state.MatrimonialReducer.registerMessage,
        registering: state.MatrimonialReducer.registering,
        isRegisterSuccessful: state.MatrimonialReducer.isRegisterSuccessful
    };
}


const mapDispatchToProps = dispatch => ({
    addressDetail: () => {
        dispatch(addressDetail());
    },
    registerMemberDetails: (memeberDetail) => {
        dispatch(registerMemberDetails(memeberDetail));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);