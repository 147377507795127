import React from 'react';
import { connect } from 'react-redux';
import { viewSentEmails, getMailStatus} from '../../../actions/MailToMembersAction';
import { withBreakpoints } from 'react-breakpoints';
import Tabulator from "tabulator-tables";
import DefaultSectionHeader from '../../DefaultSectionHeader';
import { AjaxLoader } from '../../AjaxLoader';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faFileWord, faFile, faFileVideo, faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { renderToString } from 'react-dom/server';
import _ from 'lodash';
class ViewSentMails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: '1',
            modal: false,
            modalMessage: ""
        };
    }

    componentDidMount() {
        this.props.viewSentEmails();
        this.buildTable();
    }
    componentDidUpdate(prevProps) {
        let that = this;
        if (that.props.sentEmailList !== prevProps.sentEmailList) {
            that.tabulator.setData(that.props.sentEmailList);
            this.buildTable();
        }
    }
    onViewLinkClick = (e, cell) => {
        let value = cell.getValue();
        this.setState(prevState => ({
            modal: true,
            modalMessage: value
        }));
    }
    onRefresh = (e, CellComponent) => {
       const id = _.get(CellComponent, '_cell.row.data.id', 0);
        this.props.getMailStatus(id);
     }
    cellFormatter = () => {
        return `<a href="javascript:;" style="color:#a9260c;text-decoration: underline">View</a>`;
    }
    refreshCellFormatter = () => {
        return `<a href="javascript:;" style="color:#a9260c;text-decoration: underline">refresh</a>`;
        //renderToString(<a href="javascript:;" style="color:#a9260c;text-decoration: underline"><FontAwesomeIcon icon={faFileWord} size="lg" className="mr-1 word-icon" /></a>);
    }
    getFormattedDate = (date) => {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }
    dateFormatter = (cell) => {
        let that = this;
        let cellValue = cell.getValue();
        return that.getFormattedDate(new Date(cellValue));
    }

    buildTable = () => {

        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        if (this.props.sentEmailList !== null) {
            this.tabulator = new Tabulator(this.el, {
                data: this.props.sentEmailList,
                pagination: "local",
                paginationSize: 20,
                layout: "fitColumns",
                resizableRows: false,
                autoResize: false,
                responsiveLayout: isMobile ? "collapse" : "",
                columns: [
                    { responsive: 1, field: "id", title: "ID", width: 80 },
                    { responsive: 1, field: "mailDate", title: "MailDate", formatter: this.dateFormatter, width: 130 },
                    { responsive: 1, field: "subject", title: "Subject" },
                    { responsive: 1, field: "status", title: "Status" },
                    { responsive: 1, field: "message", title: "Message", cellClick: this.onViewLinkClick, formatter: this.cellFormatter, width: 150 },
                    { responsive: 1, field: "versionSource", title: "VersionSource" },
                    { responsive: 1, field: "Reload", title: "Reload", cellClick: this.onRefresh, formatter: this.refreshCellFormatter, width: 150 },
                ]
            });
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    createMarkup = (data) => {
        return { __html: data };
    }


    render() {
         return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <DefaultSectionHeader title="View Sent Mails" />
                <AjaxLoader isOpen={this.props.isLoading} />
                <div><div id="tabulator" ref={el => (this.el = el)} className="viewsentmails" /></div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>
                        <div dangerouslySetInnerHTML={this.createMarkup(this.state.modalMessage)} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }

}
const mapStateToProps = (state) => {
    return {
        sentEmailList: state.MailToMembersReducer.sentEmailList,
        isLoading: state.MailToMembersReducer.isLoading
    };
}

const mapDispatchToProps = dispatch => ({
    viewSentEmails: () => {
        dispatch(viewSentEmails());
    },
    getMailStatus: (id) => {
        dispatch(getMailStatus(id));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(ViewSentMails));
