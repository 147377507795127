import React, { Component } from 'react';
import { isAuthenticated } from '../actions/LoginAction';
import SidebarNav from './SidebarNav';
import { Route, Switch } from 'react-router';
import Header from './Header';
import { connect } from 'react-redux';
import Footer from './Footer';
import { sidebarNavClose } from '../actions/SideBarNavAction';
import RequireAuth from '../components/Auth/RequireAuth';
import MaryadiVashnavas from '../components/MaryadiVashnavas/MaryadiVashnavasLife';
import ContactUs from '../components/ContactUs';
import Accommodation from '../components/Accommodation';
import Members_Section from '../components/MemberSection/MembersSection';
import ShriNath from '../components/MemberSection/ShriNath';
import ChaturShlokiGranth from '../components/MemberSection/ChaturShlokiGranth';
import MemberDirectory from '../components/MembersDirectory/MemberDirectory';
import Gallery from '../components/Gallery';
import Documents from '../components/Documents/PdfFiles';
import MemberDirectoryAdmin from '../components/Admin/MembersDirectory/MemberDirectory';
import MatrimonialAdmin from '../components/Admin/Matrimonial/MatrimonialDirectory';
import MailToMembers from '../components/Admin/MailToMembers/MailToMembers';
import ViewSentMails from '../components/Admin/MailToMembers/ViewSentMails';
import MailStatus from '../components/Admin/MailStatus/MailStatus';
import ImageFiles from '../components/ImagesComponent/ImageFiles';
import AudioGallery from '../components/AudioGallery';
import VideoGallery from '../components/VideoGalleryComponent';
import PushtimargHistory from '../components/Pushtimarg/PushtimargCollection';
import PhotoGallery from '../components/PhotoGallery';
import ImageCarousel from '../components/ImagesComponent/ImageCarousel';
import UtsavList from '../components/Pdf/UtsavList';
import UtsavListYearWise from '../components/Pdf/UtsavListYearWise';
import Vanchan from '../components/VanchanComponent/Vanchan';
import AudioSongsList from '../components/Common/AudioSongsList';
import VideoSongs from '../components/VideoComponent/VideoSongs';
import DocumentList from '../components/Documents/DocumentList';
import Rasalay from '../components/RasalayComponent/Rasalay';
import Matrimonial from '../components/Matrimonial/MatrimonialDirectory';
import Welcome from '../components/Welcome';
import Logout from '../components/Logout';
import { withBreakpoints } from 'react-breakpoints';

class Layout extends Component {

    componentDidMount() {
        this.props.isAuthenticated();
    }

    componentWillReceiveProps(nextprops) {
        let isNotAuthenticatedRoute = (nextprops.isAuthentication === null || (this.props.isAuthentication !== nextprops.isAuthentication && !nextprops.isAuthentication));
        if (isNotAuthenticatedRoute) {
            nextprops.props.history.push(process.env.PUBLIC_URL + '/login');
        }

    }

    render() {
        const { currentBreakpoint, breakpoints } = this.props;
        var isMobile = breakpoints[currentBreakpoint] < breakpoints.mobileView;
        return (<React.Fragment>
            {(((this.props.isAuthentication === null || this.props.isAuthentication === false)) ? null : <SidebarNav />)}
            <div className={`h-100 right-page-layout  ${!isMobile ?'paddingLayout':''}`}>
                <div className="contain-background"></div>
                <div className={`mobile-layover ${this.props.isSidebarOpen && isMobile ? 'd-block' : 'd-none'} `} onClick={this.props.sidebarNavClose}></div>
                {((this.props.isAuthentication === null || this.props.isAuthentication === false) ? null : <Header />)}
                <Switch>
                    <Route path={process.env.PUBLIC_URL + '/Welcome'} component={Welcome} />
                    <Route path={process.env.PUBLIC_URL + '/logout'} component={Logout} />
                    <Route path={process.env.PUBLIC_URL + '/AudioGallery'} component={AudioGallery} />
                    <Route path={process.env.PUBLIC_URL + '/VideoGallery'} component={VideoGallery} />
                    <Route path={process.env.PUBLIC_URL + '/PhotoGallery'} component={PhotoGallery} />
                    <Route path={process.env.PUBLIC_URL + '/ImageCarousel'} component={ImageCarousel} />
                    <Route path={process.env.PUBLIC_URL + '/UtsavList'} component={UtsavList} />
                    <Route path={process.env.PUBLIC_URL + '/UtsavListYearWise'} component={UtsavListYearWise} />
                    <Route path={process.env.PUBLIC_URL + '/Vanchan'} component={Vanchan} />
                    <Route path={process.env.PUBLIC_URL + '/AudioSongsList'} component={AudioSongsList} />
                    <Route path={process.env.PUBLIC_URL + '/VideoSongs'} component={VideoSongs} />
                    <Route path={process.env.PUBLIC_URL + '/DocumentList'} component={DocumentList} />
                    <Route path={process.env.PUBLIC_URL + '/Rasalay'} component={Rasalay} />
                    <Route path={process.env.PUBLIC_URL + '/Matrimonial'} component={Matrimonial} />
                    <Route path={process.env.PUBLIC_URL + '/HistoryOfPushtimarg'} component={PushtimargHistory} />
                    <Route path={process.env.PUBLIC_URL + '/MaryadiVashnavas'} component={MaryadiVashnavas} />
                    <Route path={process.env.PUBLIC_URL + '/ContactUs'} component={ContactUs} />
                    <Route path={process.env.PUBLIC_URL + '/Accommodation'} component={Accommodation} />
                    <Route path={process.env.PUBLIC_URL + '/Members_Section'} component={Members_Section} />
                    <Route path={process.env.PUBLIC_URL + '/ShriNath'} component={ShriNath} />
                    <Route path={process.env.PUBLIC_URL + '/ChaturShlokiGranth'} component={ChaturShlokiGranth} />
                    <Route path={process.env.PUBLIC_URL + '/MemberDirectory'} component={MemberDirectory} />
                    <Route path={process.env.PUBLIC_URL + '/Gallery'} component={Gallery} />
                    <Route path={process.env.PUBLIC_URL + '/Documents'} component={Documents} />
                    <Route path={process.env.PUBLIC_URL + '/MemberDirectoryAdmin'} component={MemberDirectoryAdmin} />
                    <Route path={process.env.PUBLIC_URL + '/MatrimonialAdmin'} component={MatrimonialAdmin} />
                    <Route path={process.env.PUBLIC_URL + '/MailToMembers'} component={MailToMembers} />
                    <Route path={process.env.PUBLIC_URL + '/ViewSentMails'} component={ViewSentMails} />
                    <Route path={process.env.PUBLIC_URL + '/MailStatus'} component={MailStatus} />
                    <Route path={process.env.PUBLIC_URL + '/ImageFiles'} component={ImageFiles} />
                </Switch>
                {((this.props.isAuthentication === null || this.props.isAuthentication === false) ? null : <Footer />)}
            </div>
        </React.Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => ({
    isAuthenticated: () => {
        dispatch(isAuthenticated());
    },
    sidebarNavClose: (e) => {
        e.preventDefault();
        dispatch(sidebarNavClose());
    }
});

const mapStateToProps = (state) => {
    return {
        isSidebarOpen: state.SidebarNavReducer.isSidebarOpen,
        isAuthentication: state.LoginReducer.isAuthenticated
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth(withBreakpoints(Layout))); 
