import axios from 'axios';

export const videoGalleryDirPath = () => {

    return function (dispatch) {
       VideoGalleryDirPath('/api/videogallery/FetchVideoGalleryDirPath', dispatch);
    };
}


const VideoGalleryDirPath = (url, dispatch) => {
    
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const VideoGalleryDirPath = response.data;
            dispatch(setPath(VideoGalleryDirPath));

        })
    function get() { return { type: 'GET_VIDEO_GALLERY_DIR_PATH' }; }
};
function setPath(VideoGalleryDirPath) { return { type: 'SET_VIDEO_GALLERY_DIR_PATH', VideoGalleryDirPath }; }

//import axios from 'axios';

//export const videoAlbumDetail = () => {
    
//    return function (dispatch) {
//        albumDetails('/api/videogallery/FetchVideoFolders', dispatch);
//    };
//}
//const albumDetails = (url, dispatch) => {
    
//    axios.get(process.env.PUBLIC_URL + url)
//        .then(function (response) {
//            const VideoalbumName = response.data;
//            dispatch(setAlbumName(VideoalbumName));

//        })
//};

//export const setAlbumName = (VideoalbumName) => {
    
//    return {
//        type: 'SET_VIDEO_ALBUM_NAME',
//        payload: VideoalbumName
//    };
//};