import axios from 'axios';

export const UtsavFileYearWise = () => {
    return function (dispatch) {
        UtsavList('/api/utsav/UtsavListYearWise', dispatch)
    };
}

const UtsavList = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const files = response.data;
            dispatch(setUtsavDetails(files));

        })
    function get() { return { type: 'GET_UTSAV_YEAR_WISE' }; }
}

export const setUtsavDetails = (files) => {
    
    return {
        type: 'SET_UTSAV_YEAR_WISE',
        payload: files
    };
};