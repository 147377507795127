import React from 'react';
import aniljiImage from '../images/anilji.jpg';
import homeImage from '../images/home-icon.png';
import emailImage from '../images/email-icon.png';
import phoneImage from '../images/phone-icon.png';
import Mailto from 'react-protected-mailto';

import DefaultSectionHeader from '../components/DefaultSectionHeader';
export default () =>
    (<div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
        <DefaultSectionHeader title="Contact Us" />
        <div className="row no-gutters">
        <div className="contact-card media flex-column flex-md-row col-12 col-md-auto p-3 p-md-5">
            <div className="contact-card-image align-self-center align-self-md-start mb-3 mb-md-0">
                <img className="img-fluid" src={aniljiImage} alt="AnilJiImage" />
                <p className="p-2 pt-4 text-center">Anil Agrawal</p>
            </div>
            <div className="media-body pl-md-5">
                <div className="media align-items-center pb-3">
                    <img className="img-fluid contact-card-icon" src={homeImage} alt="AnilJiImage" />
                    <div className="media-body pl-3">A-29, Gokul Vatika, Behind Hotel Marriott, Jaipur-302018, India</div>
                </div>
                <div className="media align-items-center pb-3">
                    <img className="img-fluid contact-card-icon" src={phoneImage} alt="AnilJiImage" />
                    <div className="media-body pl-3">
                        <div><b>Home:</b><a href="callto:911414921381">91-141-4921381</a></div>
                        <div><b>Office:</b><a href="callto:911417156800">141-7156800</a></div>
                    </div>
                </div> 
                <div className="media align-items-center">
                    <img className="img-fluid contact-card-icon" src={emailImage} alt="AnilJiImage" />
                    <div className="media-body pl-3"><Mailto email='anil@ramansujatisamaj.com'/></div>
                </div>
            </div>
            </div>
        </div>
    </div>);
