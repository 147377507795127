const initialState = {

    memberSectionDirPath: [],
    SectionName: '',
    isLoading: '',
    ChaturShlokiSection: '',
    ChaturShlokiImagePath:'',
   
}

const MembersSectionReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_MEMBERS_SECTION_DIR_PATH':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_MEMBERS_SECTION_DIR_PATH':
            return Object.assign({}, state, { memberSectionDirPath: action.MemberSectionDirPath.memberSectionDirPath, isLoading: false, SectionName: action.MemberSectionDirPath.section });
        case 'GET_CHATUR_SHLOKI_SECTION':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_CHATUR_SHLOKI_SECTION':
            return Object.assign({}, state, { ChaturShlokiSection: action.chaturShlokiSection.section, isLoading: false, ChaturShlokiImagePath: action.chaturShlokiSection.imageDirectoryPath });
        default:
            return state;
    }

}

export default MembersSectionReducer;
