import { connect } from 'react-redux';
import { AjaxLoader } from '../../AjaxLoader';
import { Form, Input, FormGroup, Button } from 'reactstrap';
import React, { Component } from 'react';
import { searchApprovedMatrimonialDetailsForAdmin, deleteApprovedMatrimonialFromDirectory, setMemberPerPage, setSearchInProgress, setpageNumber, getCityListForAdmin } from '../../../actions/MatrimonialAction';
import MatrimonialDetail from './MatrimonialDetail';
import NoItemFound from '../../NoItemFoundPage';

class MatrimonialApprovedDirectory extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            city: "",
            country: "",
            personName: "",
            email: "",
            sort:"Select"
        };
    }

    componentDidMount() {
        let searchFilters = {
            IsIncreasingOrder: true,
            MemberPerPage: this.props.memberPerPage,
        }
        this.props.searchApprovedMatrimonialDetailsForAdmin(searchFilters);
    }

    handleChange = (type, event) => {

        this.setState({ [type]: event.target.value });
        if (type === "country") {
            this.setState({ city: "" });
            this.props.getCityListForAdmin(event.target.value);
        }
    }

    createCountryName = () => {
        if (this.props.address.country.length > 0) {
            return this.props.address.country
                .map((country) =>
                    <option key={country.countryName} value={country.countryName}>{country.countryName}</option>
                );
        }
    }

    onPerPageChange = (e) => {
        this.props.setMemberPerPage(parseInt(e.target.value));
        this.props.setpageNumber(0)
        let searchFilters = {
            City: this.state.city,
            Country: this.state.country,
            IsIncreasingOrder: this.state.sort === "Select" ? true : this.state.sort,
            MemberPerPage: parseInt(e.target.value),
            PageNumber: 0,
            Email: this.state.email,
            PersonName: this.state.personName
        }
        this.props.searchApprovedMatrimonialDetailsForAdmin(searchFilters);
    }

    searchMatrimonial = () => {
        this.props.setSearchInProgress();
        let searchFilters = {
            City: this.state.city,
            Country: this.state.country,
            IsIncreasingOrder: this.state.sort === "Select" ? true : this.state.sort,
            MemberPerPage: this.props.memberPerPage,
            PageNumber: 0,
            Email: this.state.email,
            PersonName: this.state.personName
        }
        this.props.searchApprovedMatrimonialDetailsForAdmin(searchFilters);
    }

    deleteMatrimonial = (matrimonialToDelete) => {
        this.props.deleteApprovedMatrimonialFromDirectory(matrimonialToDelete);
    }

    handlePreviousPageClick = () => {
        if (this.props.pageNumber !== 0) {
            this.props.setpageNumber(this.props.pageNumber - 1)
            let searchFilters = {
                City: this.state.city,
                Country: this.state.country,
                IsIncreasingOrder: this.state.sort === "Select" ? true : this.state.sort,
                MemberPerPage: this.props.memberPerPage,
                PageNumber: this.props.pageNumber - 1,
                Email: this.state.email,
                PersonName: this.state.personName
            }
            this.props.searchApprovedMatrimonialDetailsForAdmin(searchFilters);
        }
    }

    handleNextPageClick = () => {
        if (this.props.totalPageCount !== this.props.pageNumber) {
            this.props.setpageNumber(this.props.pageNumber + 1)
            let searchFilters = {
                City: this.state.city,
                Country: this.state.country,
                IsIncreasingOrder: this.state.sort === "Select" ? true : this.state.sort,
                MemberPerPage: this.props.memberPerPage,
                PageNumber: this.props.pageNumber + 1,
                Email: this.state.email,
                PersonName: this.state.personName
            }
            this.props.searchApprovedMatrimonialDetailsForAdmin(searchFilters);
        }
    }

    createCityName = () => {
        if (this.props.cityList.length > 0) {
            return this.props.cityList.map((city) =>
                <option key={city} value={city} > {city}</option>
            );
        }
    }

    render() {
        return (
            <div className="">
                <Form className="row mb-3 mb-md-4">
                    <FormGroup className="col-12 col-md-3">
                        <Input className="bg-transparent border-dark" type="text" name="userName" id="userName" onChange={(event) => this.handleChange("personName", event)} placeholder="--Name--" />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-3">
                        <Input className="bg-transparent border-dark" type="email" name="email" id="email" onChange={(event) => this.handleChange("email", event)} placeholder="--Email--" />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                        <Input className="bg-transparent border-dark" type="select" name="country" id="country" placeholder="country" onChange={(event) => this.handleChange("country", event)}>
                            <option value="Select">--Country--</option>
                            {this.createCountryName()}
                        </Input>
                    </FormGroup>
                    <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                        <Input className="bg-transparent border-dark" type="select" name="city" id="city" placeholder="--City--" onChange={(event) => this.handleChange("city", event)} autoComplete="off" >
                            <option value="Select">--City--</option>
                            {this.createCityName()}
                        </Input>
                    </FormGroup>
                    <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                        <Input className="bg-transparent  border-dark" type="select" name="sort" id="sort" placeholder="Sort By" onChange={(event) => this.handleChange("sort", event)}>
                            <option value="Select">--Sort By Name--</option>
                            <option value="True">Ascending</option>
                            <option value="False">Descending</option>
                        </Input>
                    </FormGroup>
                    <div className="col-12 col-md-3">
                        <Button onClick={(event) => this.searchMatrimonial(event)} className="search-button bg-primary border-0 text-white">Search</Button>
                    </div>
                </Form>


                <div className="d-flex justify-content-between align-items-center">
                    <div className="font-weight-bold">List of all Members</div>
                    <div className="d-flex align-items-center">Per page:
                                        <div className="select-wrapper per-page-item">
                            <Input type="select" name="country" id="country" placeholder="country" onChange={(event) => this.onPerPageChange(event)} className="item-per-page d-inline bg-transparent border-dark ml-1">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </Input>
                        </div>

                    </div>
                </div>
                <div className="col-12 py-3 px-0 m-0">
                    <AjaxLoader isOpen={this.props.isLoading} />
                    {this.props.matrimonialDetailsAdmin.length > 0 ? < MatrimonialDetail deleteMatrimonial={this.deleteMatrimonial} /> : <NoItemFound />}
                </div>
                <div className="d-flex justify-content-end">
                    <button onClick={this.handlePreviousPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== 0 ? "d-block" : "d-none"}`}>Previous</button>
                    <button onClick={this.handleNextPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== this.props.totalPageCount - 1 ? "d-block" : "d-none"}`}>Next</button>
                </div>
            </div> 
           );
    }

}
const mapStateToProps = (state) => {
    return {
        matrimonialDetailsAdmin: state.MatrimonialReducer.matrimonialDetailsAdmin,
        memberPerPage: state.MatrimonialReducer.memberPerPage,
        pageNumber: state.MatrimonialReducer.pageNumber,
        isLoading: state.MatrimonialReducer.isLoading,
        totalPageCount: state.MatrimonialReducer.totalPageCount,
        address: state.AddressDetailReducer.address,
        cityList: state.MatrimonialReducer.cityList,
    };
}

const mapDispatchToProps = dispatch => ({
    searchApprovedMatrimonialDetailsForAdmin: (matrimonialSearchFilters) => {
        dispatch(searchApprovedMatrimonialDetailsForAdmin(matrimonialSearchFilters));
    },
    deleteApprovedMatrimonialFromDirectory: (matrimonialToDeleteID) => {
        dispatch(deleteApprovedMatrimonialFromDirectory(matrimonialToDeleteID));
    },
    setMemberPerPage: (memberPerPage) => {
        dispatch(setMemberPerPage(memberPerPage));
    },
    setpageNumber: (pageNumber) => {
        dispatch(setpageNumber(pageNumber));
    },
    setSearchInProgress: () => {
        dispatch(setSearchInProgress());
    },
    getCityListForAdmin: (countryName) => {
        dispatch(getCityListForAdmin(countryName));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialApprovedDirectory);

