const initialState = {
    images: [],
    folderName: [],
    isLoading:'',
}

const ImageCarouselReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_FOLDER_IMAGES':
            return Object.assign({}, state, { isLoading: true });


        case 'SET_FOLDER_IMAGES':
            return Object.assign({}, state, { images: action.images, folderName: action.folderName, isLoading: false });

        default:
            return state;
    }
}

export default ImageCarouselReducer