import React, { Component } from "react";
import { Row, Col, Nav, NavItem, Form, Input } from 'reactstrap';
import 'rmc-tabs/assets/index.css';
import { Link } from 'react-router-dom';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { searchName, ResetSearch } from '../actions/SearchAction';
import { withRouter } from 'react-router';
import { withBreakpoints } from 'react-breakpoints';
import { If } from 'rc-if-else';
import DefaultSectionHeader from '../components/DefaultSectionHeader';

class GalleryHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActiveForImages: false,
            isActiveForAudios: false,
            isActiveForVideos: false,
            searchtextBoxVisibility: false,
            search: '',
            type: '',
            tabChange: false,
         };
    }
    componentDidMount() {
        this.props.ResetSearch();
    }
    ShowSearchTextBox = () => {
        this.setState(prevState => ({
            searchtextBoxVisibility: !prevState.searchtextBoxVisibility
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive) {
            this.props.ResetSearch();
            this.setState({ tabChange: true });
        }
    }

    onSearchChange = (event) => {
        var search = event.target.value;
        this.setState({ search: search });
        this.props.searchName(search);
    }
    keyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
       
    }
    render() {
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        return (
            <React.Fragment>
                <DefaultSectionHeader title="Gallery" />
                {isMobile ?
                    <React.Fragment>
                        <Row>
                            <Col xs={12}>
                            <Nav tabs className="pill-Style border-primary">
                                <NavItem className={this.props.isActive === "1" ? "active link-style mr-1" : "link-style mr-1 "}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Audios' }}>Bhajan</Link>
                                </NavItem>
                                <NavItem className={this.props.isActive === "3" ? "active link-style mr-1" : "link-style mr-1"}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Photos' }}>Photos</Link>
                                </NavItem>
                                <NavItem className={this.props.isActive === "2" ? "active link-style mr-1" : "link-style mr-1"}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Videos' }}>Videos</Link>
                                </NavItem>
                                <NavItem className={this.props.isActive === "4" ? "active link-style mr-1" : "link-style mr-1"}>
                                    <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Documents' }}>Documents</Link>
                                </NavItem>
                                    <NavItem className="d-flex flex-grow-1 justify-content-end">
                                        <a className="mt-2" onClick={() => this.ShowSearchTextBox()}><FontAwesomeIcon icon={faSearch} /></a>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <If condition={this.state.searchtextBoxVisibility}>
                                    <Input className="res-search mt-2 border-0" type="text" name="search" id="search" placeholder="Search" onChange={this.onSearchChange} onKeyDown={this.keyPress}>
                                    </Input>
                                </If>
                            </Col>
                        </Row>
                    </React.Fragment>
                    :
                    <Row>
                        <Col sm="12">
                                    <Nav tabs className="pill-Style border-primary align-items-end">
                                        <NavItem className={this.props.isActive === "1" ? "active link-style mr-1" : "link-style mr-1 "}>
                                            <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Audios' }}>Bhajan</Link>
                                        </NavItem>
                                        <NavItem className={this.props.isActive === "3" ? "active link-style mr-1" : "link-style mr-1"}>
                                            <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Photos' }}>Photos</Link>
                                        </NavItem>
                                        <NavItem className={this.props.isActive === "2" ? "active link-style mr-1" : "link-style mr-1"}>
                                            <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Videos' }}>Videos</Link>
                                        </NavItem>
                                        <NavItem className={this.props.isActive === "4" ? "active link-style mr-1" : "link-style mr-1"}>
                                            <Link className="text-white d-block text-center" to={{ pathname: process.env.PUBLIC_URL + "/Gallery", search: '?Type=Documents' }}>Documents</Link>
                                        </NavItem>
                                        <NavItem className="d-flex flex-grow-1 justify-content-end mb-1">
                                    <Form className="position-relative">
                                        <Input type="text" placeholder="Search your choice" className="search-tab bg-custom position-relative" onChange={this.onSearchChange} onKeyDown={this.keyPress} />
                                                <FontAwesomeIcon icon={faSearch} className="iconSearch" />
                                            </Form>
                                        </NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                }
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    searchName: (search) => {
        dispatch(searchName(search));
    },

    ResetSearch: () => {
        dispatch(ResetSearch());
    },
});
export default connect(null,mapDispatchToProps)(withRouter(withBreakpoints(GalleryHeader)));




