import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { updateMemberDetails } from '../../actions/MatrimonialAction';
import DateTime from '../../components/DatePicker';
import { format } from 'date-fns'
var validator = require('validator');
class EditMatrimonail extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            rowId: props.member.rowId,
            personName: props.member.personName,
            gender: props.member.gender,
            agedob: props.member.agedob,
            fatherMotherName: props.member.fatherMotherName,
            caste: props.member.caste,
            addressRes: props.member.addressRes,
            phoneRes: props.member.phoneRes,
            mobile: props.member.mobile,
            stdcode: props.member.stdcode,
            email: props.member.email,
            qualification: props.member.qualification,
            highestDegree: props.member.highestDegree,
            otherQualication: props.member.otherQualication,
            otherRelatedDetails: props.member.otherRelatedDetails,
            jobStatus: props.member.jobStatus,
            jobLocation: props.member.jobLocation,
            anualIncome: props.member.anualIncome,
            otherJobDetails: props.member.otherJobDetails,
            maritalStatus: props.member.maritalStatus,
            motherTongue: props.member.motherTongue,
            height: props.member.height,
            complexion: props.member.complexion,
            bodyType: props.member.bodyType,
            specialCases: props.member.specialCases,
            smoke: props.member.smoke,
            drink: props.member.drink,
            personalValues: props.member.personalValues,
            countryName: props.member.countryName,
            stateName: props.member.stateName,
            cityName: props.member.cityName,
            editForm: false,
            stateFilled: false,
            invalidPersonName: props.member.personName === "",
            invalidGender: props.member.gender === "Select" || props.member.gender === "",
            invalidDateOfBirth: props.member.agedob === "",
            invalidAddressRes: props.member.addressRes === "",
            invalidEmail: !validator.isEmail(props.member.email),
            invalidCountryName: props.member.country === "Select" || props.member.country === "",
            invalidStateName: props.member.stateName === "Select" || props.member.stateName === "",
            invalidCityName: props.member.cityName === "Select" || props.member.cityName === "",
            invalidQualification: props.member.qualification === "Select" || props.member.qualification === "",
            invalidJobStatus: props.member.jobStatus === "Select" || props.member.jobStatus === "",
            invalidMaritalStatus: props.member.maritalStatus === "Select" || props.member.maritalStatus === "",
            invalidCaste: '',
            showOtherCity: false,
            invalidStdCode: false,
            invalidPhoneRes: false,
            invalidMobile: false
        };
    }

    componentDidMount() {
        this.props.onRef(this);
        var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.stateName) });
        var cityList = this.props.address.city
            .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) });
        var isCityFound = cityList.filter((e) => { return (e.cityName=== this.state.cityName) }).length > 0;
        if (!isCityFound) {
            this.setState({ showOtherCity: true });
        }
        else {
            this.setState({ showOtherCity: false });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.countryName === "INDIA" && prevState.countryName !== "INDIA") {
            this.setState({ showOtherCity: false });
        }
        else if (this.state.countryName !== "INDIA" && prevState.countryName === "INDIA") {
            this.setState({ showOtherCity: true });
        }
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    };

    showOtherCity = () => {
        var toggleCity = !this.state.showOtherCity;
        this.setState({ showOtherCity: toggleCity });
    }

    handleChange = (type, event) => {

        switch (type) {
            case "personName":
                this.setState({ invalidPersonName: validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/), [type]: event.target.value });
                break;
            case "gender":
                this.setState({ invalidGender: event.target.value === "Select", [type]: event.target.value });
                break;
            case "agedob":
                this.setState({ [type]: format(event._d, 'dd/MM/yyyy') });
                break;
            case "addressRes":
                this.setState({ invalidAddressRes: event.target.value === "", [type]: event.target.value });
                break;
            case "email":
                this.setState({ invalidEmail: !validator.isEmail(event.target.value), [type]: event.target.value });
                break;
            case "countryName":
                this.setState({ invalidCountryName: event.target.value === "Select", [type]: event.target.value, stateName: "", cityName: "" });
                break;
            case "stateNameDropdown":
                this.setState({ invalidStateName: event.target.value === "Select" || !validator.matches(event.target.value, /^([^0-9]*)$/), stateName: event.target.value, cityName: "" });
                break;
            case "cityNameDropdown":
                this.setState({ invalidCityName: event.target.value === "Select" || !validator.matches(event.target.value, /^([^0-9]*)$/), cityName: event.target.value });
                break;
            case "qualification":
                this.setState({ invalidQualification: event.target.value === "Select", [type]: event.target.value });
                break;
            case "jobStatus":
                this.setState({ invalidJobStatus: event.target.value === "Select", [type]: event.target.value });
                break;
            case "maritalStatus":
                this.setState({ invalidMaritalStatus: event.target.value === "Select", [type]: event.target.value });
                break;
            case "caste":
                this.setState({ invalidCaste: !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/), [type]: event.target.value });
                break;
            case "fatherMotherName":
                this.setState({ invalidFatherMotherName: !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/), [type]: event.target.value });
                break;
            case "stdcode":
                this.setState({ invalidStdCode: !validator.isNumeric(event.target.value), [type]: event.target.value });
                break;
            case "mobile":
                this.setState({ invalidMobile: !validator.isMobilePhone(event.target.value), [type]: event.target.value });
                break;
            case "phoneRes":
                this.setState({ invalidPhoneRes: !validator.isMobilePhone(event.target.value), [type]: event.target.value });
                break;
            case "anualIncome":
                this.setState({invalidAnualIncome: (!validator.isEmpty(event.target.value) && !validator.isDecimal(event.target.value)), [type]: event.target.value });
                break;
            default:
                this.setState({ [type]: event.target.value });
                break;
        }
    }

    doUpdate = () => {

        if (!this.state.invalidAddressRes && !this.state.invalidCityName && !this.state.invalidCountryName && !this.state.invalidDateOfBirth && !this.state.invalidEmail && !this.state.invalidEmail && !this.state.invalidGender
            && !this.state.invalidJobStatus && !this.state.invalidJobStatus && !this.state.invalidMaritalStatus && !this.state.invalidPersonName && !this.state.invalidQualification && !this.state.invalidStateName && !this.state.invalidAnualIncome) {
            // this registerdetail is an array in which at index 0 olddetails are stored and at index 1 updatedDetails are stored
            let registerDetails = [
                {
                    RowId: this.props.member.rowId,
                    PersonName: this.props.member.personName,
                    Gender: this.props.member.gender,
                    Agedob: this.props.member.agedob,
                    FatherMotherName: this.props.member.fatherMotherName,
                    Caste: this.props.member.caste,
                    AddressRes: this.props.member.addressRes,
                    PhoneRes: this.props.member.phoneRes,
                    Mobile: this.props.member.mobile,
                    Stdcode: this.props.member.stdcode,
                    Email: this.props.member.email,
                    Qualification: this.props.member.qualification,
                    HighestDegree: this.props.member.highestDegree,
                    OtherQualication: this.props.member.otherQualication,
                    OtherRelatedDetails: this.props.member.otherRelatedDetails,
                    JobStatus: this.props.member.jobStatus,
                    JobLocation: this.props.member.jobLocation,
                    AnualIncome: this.props.member.anualIncome,
                    OtherJobDetails: this.props.member.otherJobDetails,
                    MaritalStatus: this.props.member.maritalStatus,
                    MotherTongue: this.props.member.motherTongue,
                    Height: this.props.member.height,
                    Complexion: this.props.member.complexion,
                    BodyType: this.props.member.bodyType,
                    SpecialCases: this.props.member.specialCases,
                    Smoke: this.props.member.smoke,
                    Drink: this.props.member.drink,
                    PersonalValues: this.props.member.personalValues,
                    CountryName: this.props.member.countryName,
                    StateName: this.props.member.stateName,
                    CityName: this.props.member.cityName
                },
                {
                    RowID: this.state.rowId,
                    PersonName: this.state.personName,
                    Gender: this.state.gender,
                    Agedob: this.state.agedob,
                    FatherMotherName: this.state.fatherMotherName,
                    Caste: this.state.caste,
                    AddressRes: this.state.addressRes,
                    PhoneRes: this.state.phoneRes,
                    Mobile: this.state.mobile,
                    Stdcode: this.state.stdcode,
                    Email: this.state.email,
                    Qualification: this.state.qualification,
                    HighestDegree: this.state.highestDegree,
                    OtherQualication: this.state.otherQualication,
                    OtherRelatedDetails: this.state.otherRelatedDetails,
                    JobStatus: this.state.jobStatus,
                    JobLocation: this.state.jobLocation,
                    AnualIncome: this.state.anualIncome,
                    OtherJobDetails: this.state.otherJobDetails,
                    MaritalStatus: this.state.maritalStatus,
                    MotherTongue: this.state.motherTongue,
                    Height: this.state.height,
                    Complexion: this.state.complexion,
                    BodyType: this.state.bodyType,
                    SpecialCases: this.state.specialCases,
                    Smoke: this.state.smoke,
                    Drink: this.state.drink,
                    PersonalValues: this.state.personalValues,
                    CountryName: this.state.countryName,
                    StateName: this.state.stateName,
                    CityName: this.state.cityName
                }];

            this.props.updateMemberDetails(registerDetails);
        }
    }

    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    createCountryItems = () => {
        const listItems = this.props.address.country
            .map((country) =>
                <option key={country.countryId} value={country.countryName}>{country.countryName}</option>
            );
        return listItems;
    }

    createStateItems = () => {
        var country = this.props.address.country.filter((e) => { return (e.countryName === this.state.countryName); });
        return this.props.address.state
            .filter((e) => { return (country.length === 1 && e.countryId === country[0].countryId); })
            .map((state) =>
                <option key={state.stateId} value={state.stateName}>{state.stateName}</option>
            );
    }

    createCityItems = () => {

        if (this.state.countryName === "INDIA") {
            var state = this.props.address.state.filter((e) => { return (e.stateName === this.state.stateName); });
            return this.props.address.city
                .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId); })
                .map((city) =>
                    <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
                );
        }
        else {
            return "";
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Name<span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="personName" id="personName" placeholder="Name" onChange={(event) => this.handleChange("personName", event)} defaultValue={this.props.member.personName} invalid={this.state.invalidPersonName} />
                            <FormFeedback>Please enter Name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Gender<span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="gender" id="gender" placeholder="Name" onChange={(event) => this.handleChange("gender", event)} defaultValue={this.props.member.gender} invalid={this.state.invalidGender}>
                                <option value="Select">--Select--</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </Input>
                            <FormFeedback>Please select gender</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Date Of Birth<span className="text-danger">*</span></div>
                        <FormGroup>
                            <DateTime id="agedob"
                                viewMode="days"
                                defaultValue={this.props.member.agedob}
                                value={this.state.agedob}
                                dateFormat="DD/MM/YYYY"
                                onChange={(event) => this.handleChange("agedob", event)}
                                placeholder="DOB (DD/MM/YYYY)"
                                className="flex-fill"
                                name="agedob" />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Father/Mother Name</div>
                        <FormGroup>
                            <Input type="text" name="fatherMotherName" id="fatherMotherName" placeholder="Father/Mother Name" onChange={(event) => this.handleChange("fatherMotherName", event)} defaultValue={this.props.member.fatherMotherName} invalid={this.state.invalidFatherMotherName} />
                            <FormFeedback>Please enter a valid Name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Caste</div>
                        <FormGroup>
                            <Input type="text" name="caste" id="caste" placeholder="Caste" onChange={(event) => this.handleChange("caste", event)} defaultValue={this.props.member.caste} invalid={this.state.invalidCaste} />
                            <FormFeedback>Please enter a valid Caste Name</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Address Residence <span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="addressRes" id="addressRes" placeholder="Address Residence" onChange={(event) => this.handleChange("addressRes", event)} defaultValue={this.props.member.addressRes} invalid={this.state.invalidAddressRes} />
                            <FormFeedback>Please enter date of Address</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Phone Residence</div>
                        <Input type="text" name="phoneRes" id="phoneRes" placeholder="Phone Residence" onChange={(event) => this.handleChange("phoneRes", event)} defaultValue={this.props.member.phoneRes} invalid={this.state.invalidPhoneRes} />
                        <FormFeedback>Please enter valid Phone Number</FormFeedback>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Country <span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="countryName" id="countryName" placeholder="Country" onChange={(event) => this.handleChange("countryName", event)} defaultValue={this.props.member.countryName} invalid={this.state.invalidCountryName}>
                                <option value="Select">--Select--</option>
                                {this.createCountryItems()}
                            </Input>
                            <FormFeedback>Please select country</FormFeedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">State<span className="text-danger">*</span></div>
                        <FormGroup className={`select-wrapper ${this.state.countryName !== "INDIA" ? "d-none" : "d-block"}`}>
                            <Input type="select" name="stateNameDropdown" id="stateNameDropdown" placeholder="State" onChange={(event) => this.handleChange("stateNameDropdown", event)} value={this.state.stateName} invalid={this.state.invalidStateName}>
                                <option value="Select">--Select--</option>
                                {this.createStateItems()}
                            </Input>
                            <FormFeedback>Please select valid state</FormFeedback>
                        </FormGroup>
                        <FormGroup className={`${this.state.countryName === "INDIA" ? "d-none" : "d-block"}`}>
                            <Input type="text" name="stateNameDropdown" id="stateNameDropdown" placeholder="State" onChange={(event) => this.handleChange("stateNameDropdown", event)} value={this.state.stateName} invalid={this.state.invalidStateName} />
                            <FormFeedback >Please enter valid state</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Mobile</div>
                        <Input type="text" name="mobile" id="mobile" placeholder="Mobile" onChange={(event) => this.handleChange("mobile", event)} defaultValue={this.props.member.mobile} invalid={this.state.invalidMobile} />
                        <FormFeedback>Please enter valid Mobile Number</FormFeedback>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Stdcode</div>
                        <Input type="text" name="stdcode" id="stdcode" placeholder="Stdcode" onChange={(event) => this.handleChange("stdcode", event)} defaultValue={this.props.member.stdcode} invalid={this.state.invalidStdCode} />
                        <FormFeedback>Please enter valid STD Code</FormFeedback>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Email<span className="text-danger">*</span></div>
                        <FormGroup>
                            <Input type="text" name="email" id="email" placeholder="Email" onChange={(event) => this.handleChange("email", event)} defaultValue={this.props.member.email} invalid={this.state.invalidEmail} />
                            <FormFeedback>Invalid email</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Qualification<span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)} defaultValue={this.props.member.qualification} invalid={this.state.invalidQualification}>
                                <option value="Select">--Select--</option>
                                <option value="Post Graduate">Post Graduate</option>
                                <option value="Graduate">Graduate</option>
                                <option value="Under Graduate">Under Graduate</option>
                                <option value="Professional">Professional</option>
                            </Input>
                            <FormFeedback>Please select qualification</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Highest Degree</div>
                        <Input type="text" name="highestDegree" id="highestDegree" placeholder="Highest Degree" onChange={(event) => this.handleChange("highestDegree", event)} defaultValue={this.props.member.highestDegree} />
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Other Qualification</div>
                        <Input type="text" name="otherQualication" id="otherQualication" placeholder="Highest Degree" onChange={(event) => this.handleChange("otherQualication", event)} defaultValue={this.props.member.otherQualication} />
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Other Related Details</div>
                        <Input type="text" name="otherRelatedDetails" id="otherRelatedDetails" placeholder="Other Related Details" onChange={(event) => this.handleChange("otherRelatedDetails", event)} defaultValue={this.props.member.otherRelatedDetails} />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">City<span className="text-danger">*</span></div>
                        <FormGroup className={`select-wrapper ${this.state.countryName === "INDIA" && !this.state.showOtherCity ? "d-block" : "d-none"}`}>
                            <Input type="select" name="cityNameDropdown" id="cityNameDropdown" placeholder="City" onChange={(event) => this.handleChange("cityNameDropdown", event)} value={this.state.cityName} invalid={this.state.invalidCityName}>
                                <option value="Select">--Select--</option>
                                {this.createCityItems()
                                }
                            </Input>
                            <FormFeedback>Please select city</FormFeedback>
                        </FormGroup>
                        <FormGroup className={`${this.state.countryName === "INDIA" && !this.state.showOtherCity ? "d-none" : "d-block"}`}>
                            <Input type="text" name="cityNameDropdown" id="cityNameDropdown" placeholder="City" onChange={(event) => this.handleChange("cityNameDropdown", event)} value={this.state.cityName} invalid={this.state.invalidCityName} />
                            <FormFeedback>Please enter city</FormFeedback>
                        </FormGroup>
                        <a onClick={() => this.showOtherCity()} className={this.state.countryName === "INDIA" ? "d-block" : "d-none"} > <small><u>{this.state.showOtherCity ? "Select from list" : "Other city"}</u></small></a>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Job Status<span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="jobStatus" id="jobStatus" placeholder="Job Status" onChange={(event) => this.handleChange("jobStatus", event)} defaultValue={this.props.member.jobStatus} invalid={this.state.invalidJobStatus}>
                                <option value="Select">--Select--</option>
                                <option value="None">None</option>
                                <option value="Student">Student</option>
                                <option value="Govt. Sector">Govt. Sector</option>
                                <option value="Private Sector">Private Sector</option>
                                <option value="Self Employed/Business">Self Employed/Business</option>
                            </Input>
                            <FormFeedback>Please select job status</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Job Location</div>
                        <FormGroup>
                            <Input type="text" name="jobLocation" id="jobLocation" placeholder="Job Location" onChange={(event) => this.handleChange("jobLocation", event)} defaultValue={this.props.member.jobLocation} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Annual Income</div>
                        <FormGroup>
                            <Input type="text" name="anualIncome" id="anualIncome" placeholder="Annual Income" onChange={(event) => this.handleChange("anualIncome", event)} defaultValue={this.props.member.anualIncome} invalid={this.state.invalidAnualIncome} />
                            <FormFeedback>Please enter valid annual income(Annual income should be in decimals)</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Other Job Details</div>
                        <FormGroup>
                            <Input type="text" name="otherJobDetails" id="otherJobDetails" placeholder="Other Job Details" onChange={(event) => this.handleChange("otherJobDetails", event)} defaultValue={this.props.member.otherJobDetails} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Marital Status<span className="text-danger">*</span></div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="maritalStatus" id="maritalStatus" placeholder="Marital Status" onChange={(event) => this.handleChange("maritalStatus", event)} defaultValue={this.props.member.maritalStatus} invalid={this.state.invalidMaritalStatus}>
                                <option value="Select">--Select--</option>
                                <option value="Never Married">Never Married</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Widow/Widower">Widow/Widower</option>
                            </Input>
                            <FormFeedback>Please select marital status</FormFeedback>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Mother Tongue</div>
                        <FormGroup>
                            <Input type="text" name="motherTongue" id="motherTongue" placeholder="Mother Tongue" onChange={(event) => this.handleChange("motherTongue", event)} defaultValue={this.props.member.motherTongue} />
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Height</div>
                        <FormGroup>
                            <Input type="text" name="height" id="height" placeholder="Height" onChange={(event) => this.handleChange("height", event)} defaultValue={this.props.member.height} />
                        </FormGroup>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Complexion</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="complexion" id="complexion" placeholder="Complexion" onChange={(event) => this.handleChange("complexion", event)} defaultValue={this.props.member.complexion}>
                                <option value="Select">--Select--</option>
                                <option value="Fair">Fair</option>
                                <option value="Very Fair">Very Fair</option>
                                <option value="Wheatish">Wheatish</option>
                                <option value="Dark">Dark</option>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Body Type</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="bodyType" id="bodyType" placeholder="Body Type" onChange={(event) => this.handleChange("bodyType", event)} defaultValue={this.props.member.bodyType}>
                                <option value="Select">--Select--</option>
                                <option value="Slim">Slim</option>
                                <option value="Athletic">Athletic</option>
                                <option value="Average">Average</option>
                                <option value="Heavy">Heavy</option>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Smoke</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="smoke" id="smoke" placeholder="Smoke" onChange={(event) => this.handleChange("smoke", event)} defaultValue={this.props.member.smoke}>
                                <option value="Select">--Select--</option>
                                <option value="No">No</option>
                                <option value="Occassionaly">Occassionaly</option>
                                <option value="Yes">Yes</option>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Drink</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="drink" id="drink" placeholder="Drink" onChange={(event) => this.handleChange("drink", event)} defaultValue={this.props.member.drink}>
                                <option value="Select">--Select--</option>
                                <option value="No">No</option>
                                <option value="Occassionaly">Occassionaly</option>
                                <option value="Yes">Yes</option>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="d-flex flex-column justify-content-between pb-2 pb-md-4">
                        <div className="text-muted">Personal Values</div>
                        <FormGroup className="select-wrapper">
                            <Input type="select" name="personalValues" id="personalValues" placeholder="Personal Values" onChange={(event) => this.handleChange("personalValues", event)} defaultValue={this.props.member.personalValues}>
                                <option value="Select">--Select--</option>
                                <option value="Traditional">Traditional</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Liberal">Liberal</option>
                            </Input>
                        </FormGroup>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        address: state.AddressDetailReducer.address,
    };
};

const mapDispatchToProps = dispatch => ({
    updateMemberDetails: (memeberDetails) => {
        dispatch(updateMemberDetails(memeberDetails));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMatrimonail);

