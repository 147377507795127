import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { withBreakpoints } from 'react-breakpoints';
import Pagination from "react-js-pagination";
import DefaultSectionHeader from '../../components/DefaultSectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
class ShriNath extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activePage: 1,
            totalPages: 37,
        }
    }
    showShriNathSection = (page) => {
        if (page === 1) {
            return (
                <div className="">
                    <h5>SHRI NATHJI'S APPERANCE STORY</h5>
                    <Row>
                    <Col xs={11} >
                        Now will begin the story of Shri Nathji's appearance. These are the episodes that occured on this earth and were told by Shri Gokulnathji, the grandson of Shri Vallabhacharya, for the upliftment of people.
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col xs={11}>
                        Shri Nathji forever resides in a cave on the Govardhan Hill. All these episodes occur within the eternal lila where He is accompanied by His many bhaktas. Shri Mahaprabhuji also resides there, continually engaged in Shri Nathji's seva. Shri Krishna commanded Shri Mahaprabhuji to appear upon the earth to uplift divine souls. When Shri Mahaprabhuji appeared upon the earth, his most beloved, Shri Nathji, also appeared in Braja and not only with His lila partners, but with all the necessary items for lila. This is all revealed in the Giriraja Khanda of the Garga Samhita. There it says:
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            The good people will talk about Shri Nathji, Who<br/>
                            is also called Deva Daman, the One Who conquers<br />
                            the gods. O king, Shri Nathji is always engaged in<br />
                            lila on the Govardhan Hill. Whoever beholds Shri<br />
                            Nathji with their eyes becomes perfected. There<br />
                            are four Naths in the four corners of India. They<br />
                            are Jagannath, Ranganath, Dwarkanath and<br />
                            Badrinath. In the middle of Them all is Shri Nathji.<br />
                            O king, these five Naths are the best of the gods in<br />
                            the pure land of India. They are the adomed pillars<br />
                            of true dharma and They are intent upon carrying<br />
                            one across the sufferings of he world. Those who<br />
                            have Their darshan become like Nara-Narayan.<br />
                            Those who have had the darshan of the four Naths,<br />
                            but not the darshan of Shri Nathji, have not yet<br />
                            had a fruitful pilgrimage. The person who does<br />
                            see Shri Nathji, however, receives the fruits of<br />
                            having made a pilgrimage to the other four Naths.<br />
                    </Col>
                    </Row>
                </div>
                )
        }

        if (page === 2) {
            return (
                <div>
                    <h5 >THE APPEARANCE OF A RAISED ARM</h5>
                <Row>
                        <Col xs={11}>
                            In the year 1409 during the month of Shravana, on the third day of the dark half of the month, the left arm of Shri Govardhanathji, Who is also known as Shri Nathji, appeared. At that time the earth was very blessed.
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11} >
                            One day a cow herder, a resident of Braja, climbed the Govardhan Hill in pursuit of one of his cows. It was during the year 1409, on the fifth day of the bright half of the month of Shravan that the herder came across Shri Nathji's raised left arm manifesting from the top ofthe Govardhan Hill. For the next sixteen days, no one else had the sight of Shri Nathji's raised hand. Then the herder realized that he had never seen such an amazing thing on top of the Govardhan Hill. He decided to tell ten or fifteen of the local people about it. He brought them up on the Govardhan Hill and showed them the raised arm coming out of the mountain. They also were amazed and concluded that it must be the manifestation of some god upon the hill. Among them was a senior Braja Vasi, who said, "That raised arm is the same one that Krishna used to hold up the Govardhan Hill for seven days while He was protecting the people of Braja from Indra's rains."

                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11} >
                            He explained that, after Krishna lowered the Govardhan Hill, the Braja Vasis worshipped Shri Krishna's left arm that had held up the hill and now that same arm had manifested before them. He added, "He is standing in a cave in the Govardhan Hill and has decided to give us the darshan of His raised left arm. Don't pull Him up out of His cave. He will fully appear at His Own will. Until that time, have the darshan of His raised arm.
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11} >
                            After hearing this, the Braja Vasis called for some milk, washed the arm and worshipped it with rice, flowers, sandalpaste and Tulsi. They brought curds and fruits and offered them to Shri Nathji's raised arm. The darshan, the sight of Shri Nathji's arm, occurred on Naga Panchami, the fifth day during the bright half of the month of Shravan. For this reason, every year on every Naga Panchami, ten to twenty thousand Braja Vasis gathered around Shri Nathji's arm on top of the Govardhan Hill. Those who desired something worshipped the raised arm with a milk bath and their wishes would be fulfilled. Shri Nathji's raised arm became famous all over Braja. The arm had the power to grant wishes. Those who had lost a cow, or were childless, or diseased, or even those who were not getting enough milk and curds from their cows, all had their desires fulfilled. There were so many incidents recorded of Shri Nathji's arm fulfilling the wishes of those who worshipped Him, that people continued to worship His arm in this way until 1478.

                        </Col>
                    </Row>
                    <br/>
                    <h5 >THE APPEARANCE OF SHRI KRISHNA'S FACE</h5>
                    <Row>
                        <Col xs={11}>
                            In the year 1478, on he eleventh day of the dark half of the month of Vaishakha, on a Thursday during the middle of the day, Shri Nathji's face appeared from the mountain. At that exact moment, Shrimad Mahaprabhu Vallabhacharya appeared in a ring of fire in Champaran. During this same period, all of Krishna's friends who joined His incarnation were bom in Braja in the various homes of the local residents. They appeared so that Krishna could play with them. 
                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 3) {
            return (
                <div>
                    <h5 >THE STORY OF SHRINATHJI DRINKING MILK</h5>
                    <Row>
                        <Col xs={11}>
                            Manikchand and Saddu Pande resided in the town of Anyor by the Govardhan Hill. They had one thousand cows and one of their cows was a direct descendant from the herd of Nanda Baba, Shri Krishna's father. That cow's name was Dhumar. All day she would graze with the other cows but, at the end of the afternoon, she would fall behind the rest of the herd for an hour and wander on top of the Govardhan Hill. 
                            There she would stand above Shri Nathji and pour her milk over Him. In this fashion Shri Nathji would lovingly partake of her milk. 
                            She would also wander and offer her milk to Shri Nathji at sunrise. Dhumar continued to give Shri Nathji her milk for six months without anyone knowing about it. One day, Manika Chanda, Dhanda and Saddu Pande noticed that Dhumar was not giving very much milk and decided to follow her. When they saw her divine activities with Shri Nathji, they bowed their heads low to the ground.
                        </Col>
                    </Row>
                    <br/>
                    <h5 >SADDU PANDE IS GIVEN A DIRECT COMMAND </h5>
                    <Row>
                        <Col xs={11}>
                            Saddu Pande had the direct darshan of Shri Nathji, Who told him, "I live on top of the Govardhan Hill. My name is Devadaman. During other lilas I have also been called Indradaman, Devadaman and Nagadaman. These are My three names. For seven days I protected the Braja Vasis from Indra's rains and removed Indra's pride. From that incident, 
                            I received the name Indra Daman. My name is also Naga Daman. 
                            Naga refers to the intoxicated elephant Kuvalaya that I defeated in Mathura. I master the inebriated minds of the bhaktas and put them in the palm of the hand that I rest on My fight hip. For these reasons My name is Naga Daman. On My lotus feet there is also an image of the barley grass which refers to the 'Ankush', the prod without which the elephant cannot be controlled.

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11}>
                            "My other name is Deva Daman, meaning that I have conquered all ofthe gods. Shri Krishna has given teachings in the eight realms to all the divinities such as Indra, Kubera, Chandra, Vayu, Mrityu, Yama, Agni, Shiva, and Kama. These are the main deities and I have conquered all of them. For this reason My name is Deva Daman, the 'Conqueror of the gods'. I disciplined Indra when I held up the Govalrdhan Hill and curbed his pride. I also gave teachings to Indra when I stole the celestial tree from his garden. Kuber received teaching from me when I killed Shankhachuda, gave the treasure to Kuber,
                            and told him to protect his wealth. Shiva was conquered in the story of Usha and Brahma was conquered in the lila where I took on as many forms as there were cows and cowlads that he had removed from Braja. Varuna was defeated by Me when I saved Nanda Baba from his clutches. 
                            I defeated Mrityu by returning Devaki's six deceased children and defeated Yama by returning My guru's children. By holding up the Govardhan Hill, I defeated Vayu when he joined with Indra to shower torrents upon Braja,. At that time there was great wind and rain, but I stopped them and provided protection to the Braja Vasis. I defeated the moon when I manifested My mind as the moon during the rasa lila. I defeated Kamadeva, the god of love, in the rasa lila celebration when I danced with the Gopis. In this way, I have defeated all of the devas and that is why My name is Devadaman."
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11}>
                            In this way Shri Nathji appeared and revealed everything to Saddu Pande. He continued, "I daily drink the milk from your cow, Dhumar. As of today, I want milk and curds from that cow twice a day."
                            <br/><br/>
                            Saddu Pande then bowed before Shri Nathji and replied, "It will be done."
                        </Col>
                    </Row>

                </div>
                )
        }
        if (page === 4) {
            return (
                <div>
                    <h5 >SADDU PANDE RETURNS HOME AND TELLS OF SHRINATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            After listening to Shri Nathji, Saddu Pande came down from the hill and retumed to Anyor where he told his wife, Bhavai, and his daughter, Naro, all that Shri Nathji had told him, explaining, "Go and give Shri Nathji some milk to drink twice a day."
                            <br/>
                            From that day on, Naro and Bhavai would daily climb the hill and offer milk to Shri Nathji.
                            <br />
                            Then Shri Nathji told Kumbhandas directly, "O Kumbhandas, come here and play with Me every day. "
                        </Col>
                    </Row>
                    <br/>
                    <h5 >SHRI NATHJI TELLS OF A COW COMING TO THE COWPEN </h5>
                    <Row>
                        <Col xs={11}>
                            Some days later, the milk from the cow that was a descendant from Krishna's herd of cows dried up. When Saddu Pande delivered some milk from another cow to Shri Nathji, Shri Nathji instructed him, "I will only take milk from a cow that descends from Krishna's herd. Tomorrow, a cow from Krishna's herd will wander into your cowpen. Until my other cow starts to give milk again, I will daily enjoy the milk and curds from that new cow."
                        </Col>
                    </Row>
                    <br />
                    <h5 >DHARMADAS RECEIVES THE COMMAND TO PUT HIS COW IN SADDU PANDE'S PEN </h5>
                    <Row>
                        <Col xs={11}>
                            Dharmadas, a Braja Vasi, lived nearby in the town of Yamanavatou and was a good bhakta. He was the uncle of the Astha Chhap poet and singer Kumbhandas and was a disciple of Chaturnaga. He had several cows and one of them descended from Shri Krishna's herd. One day, that cow became separated from the other cows and wandered to the top of the Govardhan Hill and poured her milk over Shri Nathji's lotus face. When she lingered there and did not return home, the cow's owner, Dharmadas, became concemed and went out to look for her with Kumbhandas. Kumbhandas was only ten years old at the time. They went looking for the cow on top of the Govardhan Hill and, after searching for some time, they found her by Shri Nathji. When they tried to take her home, even after much coaxing, she would not budge. Then Shri Nathji appeared to them in living form and said, "Put this cow in Saddu Pande's cowpen. I want to drink her milk. She is from My herd of cows."

                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            Then Shri Nathji told Kumbhandas directly, "O Kumbhandas, come here and play with Me every day."
                            <br/>
                            Hearing Shri Nathji's sweet words, both Kumbhandas and Dharmadas fell unconscious. After they arose, they circled Shri Nathji and bowed before Him. They followed Shri Nathji's wishes and put that cow into Saddu Pande's cowpen and then returned home.
                            <br />
                            From that day on, Kumbhandas went to play with Shri Nathji every day.
                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 5) {
            return (
                <div>
                    <h5 >FOUR POTENCIES APPEAR TO PROTECT SHRINATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            To protect Shri Nathji, four divine potencies appeared. The protective deity Sankarshan appeared from the Sankarshan Lake, while from the Govinda Lake, the deity Govinda appeared. From the Dan Ghati hill, Shri Danirafli appeared and from the Shri Lake, Shri Haridevaji appeared. These four gods are Krishna's four potencies, Sankarshan, Vasudeva, Pradyumna and Aniruddha. They always protect Shri Nathji and are worshipped by bhaktas from other lineages.

                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11}>
                            In the center of these four protective deities resides Purushottam, Who has appeared as Shri Nathji. For Shri Nathji's seva, Krishna appeared as Shri Mahaprabhuji. Only Purushottam can know Purushottam. In the tenth chapter of the Gita it is said, "Neither gods nor demons can comprehend Your manifestation. You Yourself know Yourselfthrough Yourself"
                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI NATHJI APPEARS TO SHRI MAHAPRABHUJI IN JHARAKANDA AND TELLS HIM TO ESTABLISH HIS WORSHIP </h5>
                    <Row>
                        <Col xs={11}>
                            In the year 1493, on the eleventh day during the bright half of the month of Phalgun, on a Thursday, Shri Nathji commanded Shri Mahaprabhuji, "I have appeared as Shri Nathji in a cave on the Govardhan Hill. You know that the Braja Vasis have seen Me there. I am thinking of appearing in My complete form, but I am waiting for you. Quickly come here and perform My seva. There are some souls here in Braja that are left over from My Krishna avatar. Take them in your shelter, then I will agree to play with them. I will meet you on top ofthe Govardhan Hill." 
                        </Col>
                    </Row>
                </div>

                )
        }
        if (page === 6) {
            return (
                <div>
                    <h5 >SHRI MAHAPRABHUJI GOES TO BRAJA AND REMOVES THE SPELL AT VISHRANTI GHAT</h5>
                <Row>
                    <Col xs={11}>
                        After hearing Shri Nathji's command, Shri Mahaprabhuji set out from Jharakanda to Braja. He first stopped at Mathura where he stayed at Ujagar Choube's house. When Shri Mahaprabhuji was going to Vishranti Ghat to take his bath, Ujagar Choube and other people informed him that for the past five days there were serious disturbances at the Vishranti Ghat. When Shri Mahaprabhuji asked what the problem was, they explained to him, "The emperor's minister, Rustamalli, came to Mathura and was ridiculed by the local Coube Brahmans. He became angry and returned to Delhi where he had a charm prepared. He attached the charm to a gate by the Vishranti Ghat where a Muslim guard sits. Any Hindu who passes under the gate would lose the tuft of hair that Hindus keep on top of their heads and immediately grow a Muslim beard. The fear of that charm has stopped all Hindus from bathing there for the past two days."
                        <br />
                        Hearing this, Shri Mahaprabhuji said, "It is terrible that people who go to this holy place are driven away by that charm. I will go to Vishranti Ghat and that charm will not affect me. Anyone who joins me now for a bath in the Yamuna and worship at the Vishranti Ghat will not be affected either."
                        </Col>
                    </Row>
                    <br />
                <Row>
                    <Col xs={11}>
                        After Shri Mahaprabhuji had finished bathing in the Yamuna, the charm began to work again. Ujagar Choube and the others approached Shri Mahaprabhuji saying, "Please do something to remove this charm. It brings us great distress."
                        <br />
                        Shri Mahaprabhuji became filled with compassion and created a yantra spell on a piece of paper and told his disciples Vasudevadas and Krishnadas to go to Delhi. He told them to attach the yantra to the main gate in Delhi and to sit there. He said that the emperor himself would call them and everything would be resolved. Both disciples proceeded to Delhi and after the spell was attached, any Muslim who walked under the arch lost his beard and suddenly grew a tuft of hair, like a Hindu, on his head. The emperor, Sikandar Lodi, hearing of the troublesome spell that two Hindu renunciates had established in Delhi, summoned them to his court.
                        <br />
                       There Vasudevadas and Krishnadas told him, "Both Hindus and Muslims are your citizens. For the past seven days your minister, Rustamalli, has been harassing the Hindus of Mathura with his spell. Seeing the pain of the people there, my guru sent us both to Delhi."
                    </Col>
                    </Row>
                    <br />
                <Row>
                    <Col xs={11}>
                        After hearing their story, the emperor immediately summoned Rustamali. After asking him about everything, the emperor scolded the minister, "You started all of this. Didn't you think about what reaction your spell would create? Now see with your own eyes. Quickly remove your spell from Mathura. Don't be so intimidated by petty taunts."
                           <br />
                            After giving his order to Rustamalli, the emperor told Vasudevadas and Krishnadas, "After the Mathura spell is removed, you remove your spell and quickly leave this place. Convey my respect to your guru."
                           <br />
                            In this way, the Mathura spell was removed. From Mathura, Shri Mahaprabhuji proceeded to the Govardhan Hill. 

                    </Col>
                    </Row>
                </div>
                )
        }
        if (page === 7) {
            return (
                <div>
                    <h5 >SHRI MAHAPRABHUJI GOES TO THE GOVARDHAN HILL AND LOOKS FOR THE PLACE WHERE SHRI NATHJI APPEARED</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Mahaprabhuji then proceeded to the Govardhan Hill with all of his disciples and sat there under a porch in front of Saddu Pande's house. Many Braja Vasis came to see him and thought, "He is a great soul. A human being cannot be so resplendent."
                            <p/>
When Saddu Pande arrived, he offered Shri Mahaprabhuji something to eat.
                            <p />
Then Krishnadas explained, "Shri Mahaprabhuji only accepts things from his disciples."
                            <p />
At that moment, Shri Nathji called from the Govardhan Hill and Shri Mahaprabhuji overheard Him say, "O Naro, bring Me some milk."
                            <p />
Naro replied, "Today we have a guest."
                            <p />
Shri Nathji replied, "Good for your guest, but could you bring Me My milk please?"
                            <p />
Naro replied, "I am bringing it right now."
                            <p />
She then brought Shri Nathji His bowl of milk. Shri Mahaprabhuji asked his disciple Damala, "Did you hear anything?"
                            <p />
Damala replied, "I heard something but I could not fully understand."
                            <p />
Then Shri Mahaprabhuji explained, "The voice I heard in Jharakanda, that told me to come here, is the same voice I just heard. Shri Nathji has appeared here. We will go to look for Him in the morning."
                            <p />
Naro came down from the hill after having given Shri Nathji some milk. Seeing her, Shri Mahaprabhuji asked, "Is there any milk left in that bowl?"
                            <p />
Naro replied, "Maharaj, we have a lot of milk in the house. If you need some, take as much as you like."
                            <p />
Shri Mahaprabhuji then explained, "I am not interested in any other milk, just what is in that bowl."
                            <p />
Saddu Pande then requested to become a disciple. After Mahaprabhuji initiated him with Krishna mantra, he accepted Saddu Pande's offerings. Later that evening, Saddu Pande, Manikchanda and all the local people of Braja came before Shri Mahaprabhuji, bowed to him and sat down. Shri Mahaprabhuji asked them, "How did Shri Nathji appear here?"
                            <p />
Saddu Pande then spoke, "Maharaj, you already know everything. Why are you asking us?"
                            <p />
When Shri Mahaprabhuji asked him again about Shri Nathji, Saddu Pande told him all about Shri Nathji's appearance and Shri Mahaprabhuji's heart became filled with joy.
                        </Col>
                    </Row>
                    <br/>
                    <h5 >SHRI MAHAPRABHUJI GOES TO THE GOVARDHAN HILL AND MEETS SHRINATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            On the following day, Shri Mahaprabhuji was very joyful and climbed up the Govardhan Hill with all of his disciples. Shri Nathji suddenly appeared before them and blissfully greeted Shri Mahaprabuji with a warm embrace. Shri Gopaldas sings of the encounter: "Shri Nathji, the holder of the Govardhan Hill, came blissfully before Shri Mahaprabhuji." 
                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 8) {
            return (
                <div>
                    <h5 >SHRI MAHAPRABHUJI FOLLOWS SHRI NATHJI'S COMMAND AND ESTABLISHES THE TEMPLE; AFTER ARRANGING FOR THE WORSHIP, HE CONTINUES ON HIS PILGRIMAGE</h5>
                    <Row>
                        <Col xs={11}>
                        Shri Mahaprabhuji followed Shri Nathji's command to establish His temple and daily worship. Truly, without seva, there is no entrance into the Path of Grace. So, Shri Mahaprabhuji made a temple and had Shri Nathji established there on the Govardhan Hill. Nearby, next to the Apsara Lake, there is a cave where the accomplished bhakta Ramdas lived. Shri Mahaprabhuji went there and Ramdas became Shri Mahaprabhuji's disciple. The guru told him "Perform Shri Nathji's seva."
                            <p />
                        Ramdas replied, "I don't know anything about seva. I have never done it."
                             <p />
                        Shri Mahaprabhuji knew the wish of Shri Nathji and replied to him "Shri Nathji will teach you."
                             <p />
                        Shri Mahaprabhuji had a peacock-feather crown prepared and performed Shri Nathji's worship and ornamentation. He instructed Ramdas in the arts of seva and explained to him, "Everyday after you bathe in the Govinda Lake, take a pot of water and bathe Shri Nathji. Then put some clothes on Him, just as I have done. Adorn Shri Nathji with a peacock-feather crown and a gunja bead necklace. Whatever you are able to attain through His wish, prepare it and offer it to Shri Nathji. Maintain yourself in this manner. The Braja residents will bring their milk and curds to Shri Nathji."
                             <p />
                        Shri Mahaprabhuji told Saddu Pande and the other Braja residents that, "Shri Nathji is my all and everything. Remain attached to His seva and be ever-ready to react to any complications. Behave in a way that keeps Shri Nathji happy."
                             <p />
                        Then, with his own hands, Shri Mahaprabhuji prepared an offering of cooked grains for Shri Nathji. Until then Shri Nathji had only taken milk and curds. After that day, Shri Nathji began to grab food from the Braja Vasis' lunch boxes.
                        </Col>
                    </Row>
                    <br/>
                    <h5 >PATHO, A DAIRY MAID FROM GANTOLI</h5>
                    <Row>
                        <Col xs={11}>
                            One day, Patho, a dairy woman from Gantoli, was taking her son's lunch to the forest when, suddenly, Shri Nathji appeared and forcibly took two roti breads from her and ate them right there. 
                        </Col>
                    </Row>
                    <br />
                    <h5 >KHEMO,A DAIRYWOMAN FROM GOVARDHAN </h5>
                    <Row>
                        <Col xs={11}>
                            A dairy maid named Khemo was selling her curds at Dan Ghati in Govardhan. Shri Nathji appeared to her and demanded to have her curds. He told her, "Unless you want Me to steal your curds everyday, you better bring Me two roti breads along with some curd rice daily."
                            <p/>
                            From that day forward, whenever she went out to sell her curds, she would bring a lunch for Shri Nathji. If she forgot to bring it, that day Shri Nathji would steal her curds.

                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 9) {
            return (
                <div>
                    <h5 >A BRAJA VAST COWLAD FROM ADING CALLED GOPAL</h5>
                    <Row>
                        <Col xs={11}>
                            There was a Brijavasi from Ading named Gopal. He saw Shri Nathji in a nearby forest and received the command from Him, "Bring Me some milk and roti breads. I will then eat them in the forest with you when you herd your cows. Also bring some barley rotis for us to eat."
                            <p/>
                            So he brought them and Shri Nathji ate them. Shri Nathji then told Gopal, "Come and see Me every day."
                             <p />
                            Gopal was very attached to the form of Shri Nathji and came daily for darshan right after Shri Nathji was ornamented. Once when he came for Shri Nathji's darshan, he was so absorbed in the thought of Shri Nathji that he forgot to remove his sword. At that time the door guard told him, "Remove your weapon before you enter the temple."
                             <p />
                            Inside the temple, Gopal bowed before Shri Nathji. His throat became choked and his eyes suddenly filled with tears. His shirt became drenched with the blissful downpour. Two people had to escort Gopal, the intoxicated bhakta, down from the Govardhan Hill.

                        </Col>
                    </Row>
                    <br />
                    <h5 >A BRAHMAN'S SON FROM AGRA</h5>
                    <Row>
                        <Col xs={11}>
                            There was a son of a Brahman from Agra. In a dream, Shri Nathji appeared to him and told him, "I am the Lord of Braja. Come to the Govardhan Hill and have My darshan." The following morning the Brahman boy pleaded to go to Braja to have the darshan of Shri Nathji. His father finally agreed to take his son to Braja to have darshan of the Krishna temples there. His son was not satisfied with any of the temples until he finally had the darshan of Shri Nathji. Then he said, "This is the One Who appeared to me."

                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            Shri Nathji then grabbed hold of his arm and brought him into His divine realm where he joined the other celestial cow lads. The boy's material body merged into the lila. His father, seeing his son's divine entrance into God's realm, felt honored. They were from the Madhva lineage of Vaishnavas. The father, having realized that his son had retumed to his original realm, went home with a balanced mind. He was not upset by the situation. That Brahman became a great bhakta and his story is found in the Bhakta Mala account of great Indian saints. His name was Prem Nidhi Mishra. Shri Nathji played in many amazing ways with the Braja Vasis.
                        </Col>
                    </Row>
                    <br />
                    <h5 >MANDALIYA PANDE FROM SAKHITARA</h5>
                    <Row>
                        <Col xs={11}>
                            The day that Mandaliya Pande from Sakhitara came to her husband's house for the first time was the day they lost their water buffalo. The people of the household said, "She has brought bad luck with her. Who knows what will happen next?"
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            The new wife was upset by the accusation and prayed to Shri Nathji, "If You can find that buffalo, I will feed You ten kilos of butter. Shri Nathji, You can make everyone happy." Through Shri Nathji's grace, the buffalo was later found and things in her household returned to normal. Then the family gave her the job of churning the curds into butter. Everyday she produced five to seven kilos of butter and each day the new wife would hide one half-kilo of butter. When she had collected ten kilos of butter, she told Shri Nathji, "Come here and take Your butter. I cannot go to You because of what my in-laws might think."

                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            Seeing her great desire, Shri Nathji came to her house, took the butter, and returned to the Govardhan Hill. Shri Nathji ate her butter and distributed some of it to His cowlad friends as well as to the monkeys. Afterwards, Shri Nathji smeared Kumbhandas' face with her butter; He also offered some to the Govardhan Hill. This event happened on Shri Krishna's appearance day and Shri Nathji considered that day to be a divine festival. Kumbhandas sang a special song that day in which he says: "The courtyard has become an ocean of curds."
                        </Col>
                    </Row>
                </div>
                )
        }
       
        if (page === 10) {
            return (
                <div>
                    <h5 >CHATURNAG, A BHAKTA FROM TORAKE GHANE</h5>
                    <Row>
                        <Col xs={11}>
                            A bhakta called Chatumag performed austerities at Torake Ghane near the Govardhan Hill. Because of ills great respect for the hill, he would never walk on the holy mount. Once, in order to allow Chatumag His darshan, Shri Nathji rode on the back of a water buffalo to Torake Ghane accompanied by Ramdas, Saddu Pande, and a few others. When the great bhakta Chaturnag had Shri Nathji's darshan, he considered that day to be the greatest holiday. He gathered "kikora" vegetables from the forest and prepared a "helva" sweet and offered it all to Shri Nathji. Shri Nathji then told Kumbhandas to sing something about the Torake Ghane lila. Kumbhandas sang:
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            I like this thick grove, Torake Ghane.<br/>
                            But we are stuck with thorns and burrs;<br />
                            Our clothes are torn.<br />
                            A lion scared of a jackal?<br />
                            That's something new!<br />
                            Says Kumbhandas,<br />
                            You are the Holder of the Mountain!<br />
                            Who are these Mughals,<br />
                            The brood of a lowborn widow?
                         </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11}>
                            This all happened in the year 1495, on the thirteenth lunar day of the bright half of the month of Shravan. After Chaturnag's Torake Ghane festival, Shri Nathji returned to the Govardhan Hill. Shri Nathji used to play with all of the Braja Vasis.
                        </Col>
                    </Row>
                    <br />
                    <h5 >PURNAMALL HAS A DREAM TO BUILD THE TEMPLE</h5>
                    <Row>
                        <Col xs={11}>
                            In the year 1499, during the second day of the bright half of the month of Chaitra, Shri Nathji gave Purnamall a dream in which He told him to, "Come to Braja and build a large temple for Me. "
                        </Col>
                    </Row>
                    <br />
                    <h5 >PURNAMALL COMES TO BRAJA</h5>
                    <Row>
                        <Col xs={11}>
                            Then Purnamall gathered his wealth and left his town, Ambalya, and travelled to the Govardhan Hill. There he asked, "I have heard that Deva Daman Shri Nathji, lives here. Where does He live?"
                            <p/>
                            Then one Braja Vasi showed him where Shri Nathji lived and Purnamall became elated when he had His darshan. Then he went before Shri Mahaprabhuji and bowed low before him, submitting, "It is Shri Nathji's wish that a large temple be built. He has told me in a dream to construct His temple and I have brought the fiJnds here to accomplish this dream."
                            <p />
                            Shri Mahaprabhuji told him, "Yes, quickly build the temple."
                            <p />
                            Shri Mahaprabhuji asked the Govardhan Hill if it would be proper to build a temple upon its sacred soil. The Govardhan Hill replied, "Shri Nathji resides in My heart. I will not be troubled. with joy, construct the temple!"
                        </Col>
                    </Row>
                    <br />
                    <h5 >HIRAMANI IS ALSO TOLD IN A DREAM TO BUILD SHRI NATHJI'S TEMPLE</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Nathji also told Hiramani from Agra in a dream, "Come here and build My temple."
                            <p/>
                            Hiramani came to the Govardhan Hill and said to Shri Mahaprabhuji, "Shri Nathji has told me to build a temple for Him. If you allow me, I will construct the temple."
                             <p />
                            When the foundation was completed, Shri Mahaprabhuji told him, "Bring a design for the temple."
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={11}>
                         Hiramani brought a large design of Shri Nathji's temple to Shri Mahaprabhuji. When he saw the temple tower on the design, he told Hiranmani to have another one made without any towers. When the second design also included a tower on the temple, Shri Mahaprabhuji told Hiramani to make a third design and to make sure that it would not have any towers. When the third design came with towers, Shri Mahaprabhuji said to Damodardas, "It must be Shri Nathji's wish to have a temple with towers on it. Shri Nathji will stay in that temple for a while, but when conflict with the Mughals arises, Shri Nathji will move to another area. "He will stay there for some time and then will again return to Braja. Then Shri Nathji will remain by Punchari on the south end of the Govardhan Hill. The Govardhan Hill has three peaks : Adi peak, Brahma peak, and Deva peak. When Krishna first appeared here, He used to play by the Adi peak. Then, He sported by the Deva peak and at the end, He played by the Brahma peak. Today the Adi and Deva peaks are hidden; only the Brahma peak 'appears on the Govardhan Hill. Krishna is the Lord of the Govardhan Hill and that is why He makes lila on top of the hill." 
                        </Col>
                    </Row>

                </div>
                )
        }
        if (page === 11) {
            return (
                <div>
                    <h5 >THE BUILDING OF SHRI NATHJI'S NEW TEMPLE BEGINS</h5>
                    <Row>
                        <Col xs={11}>
                            After getting permission, in the year 1499, during the bright half of the month of vaishakha, on a Sunday, Purnamall began the foundation for Shri Nathji's new temple. Purnamall had just over one hundred thousand rupees for the project. When his one hundred thousand rupees were exhausted in construction, Purnamall took a few thousand rupees and went to the south of India. There he sold some precious jewels and made three hundred thousand rupees. He returned with that money twenty years later and completed the temple. Until that time, the temple remained partially built and Shri Nathji resided in His original structure.
                            <p/>
                            Shri Nathji had a desire to play with the Braja Vasis in His temple, but He had to wait twenty years for the building to be completed. During that period, Ramdas Chohan Rajput made His seva. From 1488 to the year 1519 Shri Nathji made many lilas.
                        </Col>
                    </Row>
                    <br />

                    <h5 >SHRI NATHJIIS ESTABLISHED IN HIS NEW TEMPLE</h5>
                    <Row>
                        <Col xs={11}>
                            When the big temple was finally completed, Shri Mahaprabhuji returned to Braja from his pilgrimage throughout India. By that time, the temple was completed and Shri Mahaprabhuji established Shri Nathji there in the year 1519 on the third day of the bright half of the month of Vaishakha, on the festival day of Akshayatritiya. On that day, Purnamall had the darshan of Shri Nathji and was very happy. He considered himself very fortunate that, through Shri Nathji's grace, he was able to behold such a great sight. At that time, Shri Mahaprabhuji was very pleased with Purnamall and blessed him, saying, "Ask of me anything you desire. Today I am very pleased with you."
                              <p />
                            Purnarnall then said, "Maharaj, I would like to offer some very fine sandalwood oil to Shri Nathji with my own hands."
                              <p />
                            Shri Mahaprabhuji replied, "Don't conceal any wish in your heart today. You may happily offer the oil. Whatever you desire, do it with a blissful mind."
                              <p />
                            Then, with great joy, Purnarmall put the sandalwood oil into a bowl and applied the substance to Shri Nathji with great love and parental affection. At that moment he considered himself to be most blessed. Then Shri Mahaprabhuji adorned Shri Nathji with clothes and ornaments. On that day, the joy was ineffable and there was a huge festival. Purnamall was very happy and made generous offerings to Shri Mahaprabhuji. Shri Mahaprabhuji was happy with his disciple and presented him with a cloth shawl he had worn. Purnamall then did a full prostration before Shri Mahaprabhuji and, after requesting permission, returned to his home in Ambalaya.


                        </Col>
                    </Row>
                    <br />

                    <h5 >SHRINATHJI'S SEVA IS ESTABLISHED</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Mahaprabhuji called Saddu Pande and informed him of Shri Nathji's large new temple. "There should be a lot of seva to perform for Shri Nathji in the new temple and you are a Brahmin. According to the scriptures, Brahmins should make temple worship."
                             <p />
                            Saddu Pande then explained,"We don't know anything about the etiquette of temple worship, nor do we understand anything about temple procedure. You should put people who understand these things into the worship. There are some disciples of Shri Krishna Chaitanya who live by Radha Kunda. You should call them and established them into Shri Nathji's seva."
                             <p />
                            Then Shri Mahaprabhuji allowed some Bengali Brahmin bhaktas into Shri Nathji's seva. Shri Mahaprabhuji showed them the way to worship Shri Nathji and put Madhavendrapuri in charge of the worship. Madhavendrapuri and his disciples performed Shri Nathji's seva while Shri Mahaprabhuji's disciple, Krishnadas, was appointed as manager of the temple. Kumbhandas was appointed the temple singer. Shri Mahaprabhuji also established what foods should be offered daily to Shri Nathji and Saddu Pande made sure that the necessary ingredients and amounts were delivered to the temple. He would also gladly deliver any extra offerings. Shri Mahaprabhuji told him, "Maintain yourself on Shri Nathji's prasada and make sure that everything is on time. Whatever you get through the wish of God, offer it to Shri Nathji. Make sure the seva is punctual and that Shri Nathji is worshipped at all the correct times."
                             <p />
                            In this way, Shri Mahaprabhuji instructed Saddu Pande before he continued on his pilgrimage.

                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 12) {
            return (
                <div>
                    <h5 >SHRI MAHAPRABHUJI SELLS A GOLDEN RING AND BUYS A COW FOR SHRI NATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            On the day before Shri Mahaprabhuji was going to leave, Shri Nathji requested, "Give Me a cow."
                            <p/>
                            Shri Mahaprabhuji replied, "I will arrange for it."
                            <p />
                            He then told Saddu Pande, "Shri Nathji desires a cow. Sell this golden ring of mine and purchase a cow for Shri Nathji with the money."
                            <p />
                            Saddu Pande then replied, "I have so many cows and buffaloes. They are all yours. Just tell me how many cows you want from my herd and they are yours."
                            <p />
                            Shri Mahaprabhuji explained, "If you give your cow to Shri Nathji, then I have not truly given it. Shri Nathji did not make the request to you, but to me. So, sell the ring and purchase a cow."
                            <p />
                            So Saddu Pande purchased a cow and brought her before Shri Nathji, Who became very pleased. When all the Braja Vasis heard that Shri Nathji loved cows, one by one the Braja Vasis started to present Shri Nathji with cows. Someone gave Shri Nathji four cows, someone else presented Shri Nathji with two, while another person offered Him one. In this way, many Braja Vasis offered cows to Shri Nathji and His herd grew to be thousands. Then Shri Mahaprabhuji gave Shri Nathji the name Gopal. The bhakta poet Chittasvami has sung of Shri Nathji and His cows:
                            </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            In front of Him there are cows,<br/>
                            In back of Him there are cows.<br />
                            Here there are cows,<br />
                            There there are cows.<br />
                            Govinda loves to live among His cows.<br /><br />
                            He runs with His cows,<br />
                            He is content with His cows.<br />
                            He anoints His body<br />
                            With the dust that is raised by those cows.<br />
                            <br />
                            When the cows cover Braja,<br />
                            One forgets the eternal realm of Vaikuntha.<br />
                            Chittasvami says,<br />
                            Krishna has taken on the form of Shri Vitthalnathji.<br />
                            Now He wears the garb of a cowlad<br />
                            And is wandering with the cows.<br />
                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRINATHJI GOES TO THE GOVINDA LAKE</h5>
                    <Row>
                        <Col xs={11}>
                            One day, Chaturanaga went to the Govinda Lake and prepared roti breads and "bari" vegetables to offer to Shri Nathji. At that time, Madhavendrapuri had also climbed the Govardhan Hill and offered a lunch to Shri Nathji. Meanwhile, Shri Nathji left the temple to accept Chaturanaga's offerings, but because Chaturanaga's offerings were small in quantity, Shri Nathji was still hungry and told Madhavendrapuri, "I am hungry. Make Me another lunch. "
                            <p/>
                            Madhavendrapuri then arranged for the second lunch offering.
                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI NATHJI IS NOT PLEASED WITH THE BENGALI'S SEVA AND REQUESTS THEIR DISMISSAL </h5>
                    <Row>
                        <Col xs={11}>
                            Every day, Madhavendrapuri would adorn Shri Nathji with a crown and kachhani dress; on festival days, he would adorn Shri Nathji with a turban. He would also offer sandal paste to Shri Nathji daily. Shri Nathji was not pleased with this arrangement of worship. Even though Shri Mahaprabhuji had requested Madhavendrapuri to perform Shri Nathji's seva, Madhavendrapuri didn't follow Shri Mahaprabhuji's advice concerning the way Shri Nathji should be served.
                            <p/>
                            The other Bengali devotees continued Shri Nathji's seva for fourteen years. Sometimes they would place the deity of Goddess Vrinda next to Shri Nathji, but Shri Nathji did not like this. Then Shri Nathji told Avadhutdas, a disciple of Shri Mahaprabhuji, to give a message to the temple manager, Krishnadas, for Him: "Tell Krishnadas that the Bengalis in the temple are stealing My money. Send them away!"

                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI MAHAPRABHUJI RETURNS TO HIS OWN REALM </h5>
                    <Row>
                        <Col xs={11}>
                            In the year 1530, on the third day of the bright half of Ashar, during the middle of the day, Shri Mahaprabhuji entered the Ganga River at Hanuman Ghat in Banaras and, while sitting in the full- lotus position, he retumed with his body to his own eternal realm.
                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 13) {
            return (
                <div>
                    <h5 >SHRI MAHAPRABHUJI'S OLDEST SON, GOPINATHJI, SITS ON THE SEAT</h5>
                    <Row>
                        <Col xs={11}>
                            After Shri Mahaprabhuji left this world, his first son, Shri Gopinathji, sat on his father's seat and performed Shri Nathji's seva for three years. At that time the Bengalis were making Shri Natlji's seva. Shri Gopinathji had several hundred thousand rupees worth of pots and jewels made for Shri Nathji.

                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI PURUSHOTTAMJI LEAVES THIS WORLD</h5>
                    <Row>
                        <Col xs={11}>
                            Some days later, Shri Gopinathji's only son, Shri Purushottamji, entered a cave in the Govardhan Hill. There Shri Nathji took hold of him and allowed him entrance into the eternal lila with his body.

                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI GOPINATHJI RETURNS TO KRISHNA'S LILA</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Gopinathji felt very distressed over the loss of his son. He then made a pilgrimage to Shri Jagannathpuri where he merged with the form of Shri Baladeva and became eternally established in his original form.
                        </Col>
                    </Row>
                    <br />
                    <h5 >SHRI GUSAINJI ASCENDS THE THRONE; THE BENGALIS ARE REPLACED BY OTHERS IN SHRI NATHJI'S SEVA </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Mahaprabhuji had two sons. His oldest son was Shri Gopinathji, who had offeered himself to Shri Jagannathji. After Gopinathji left this world, Shri Mahaprabhuji's second son, Shri Vitthaliathji, also known as Shri Gusainji, took over the lineage. Knowing it was Shri Nathji's wish, Shri Gusainji removed the Bengalis from His seva and established his disciples, Gujarati Brahmins, in their place. Ramdas became in charge of Shri Nathji's personal seva.

                        </Col>
                    </Row>
                    <br />
                    <h5 >MADHAVENDRAPURI FOLLOWS SHRI NATHJI'S ORDERS AND GOES TO THE SOUTH TO GET SANDALWOOD </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Madhavendrapuri was told by Shri Nathji, "Get some pure sandalwood and offer it to Me. I love to be anointed with sandalpaste."
                            <p/>
                            Hearing Shri Nathji's words, Madhavendrapuri then proceeded to the south of India.
                        </Col>
                    </Row>
                    <br />
                    <h5 >MADHAVENDRAPURI MEETS SHRI GOPINATHJI IN PENDE</h5>
                    <Row>
                        <Col xs={11}>
                            In Pende, Madhavendrapuri had darshan of the Krishna deity called Shri Gopinathji. When he returned to the pilgrim guest house, he began to think, "Here, Shri Gopinathji is offered a lot of rice pudding. I have never made that offering to Shri Nathji."
                              <p />
                            While he was concerned over this issue, Shri Gopinathji was enjoying His evening offerings that happened to have several bowls of rice pudding. The deity, Shri Gopinathji, took one of the bowls of rice pudding and hid it under the throne. When the servers came to take the offerings away, they began to speak with each other about the where abouts of one of the bowls of rice pudding and wondered who had stolen it. Then Shri Gopinathji told them, "No one stole that bowl. I have taken it and placed it under the throne. I want you to take it and present it to Madhavendrapuri. He serves in Shri Nathji's temple."
                              <p />
                            Then one priest went into town and began to call out, "Is there a Madhavendrapuri, the head pujari of Shri Nathji's temple, here?"
                              <p />
                            Hearing the call, Madhavendrapuri replied, "I am here."
                              <p />
                            The priest gave him the khir (rice pudding) and explained, "Shri Gopinathji has sent you this rice pudding offering."
                              <p />
                            Madhavendrapuri then became very pleased. From that day, Shri Gopinathji became known as "Khir Charo", "the One who steals rice pudding". 

                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 14) {
            return (
                <div>
                    <h5>MADHAVENDRAPURI AND A TAILANGA KING SET OUT WITH A LOAD OF SANDALWOOD FOR SHRI NATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            Madhavendrapuri proceeded to the south where he visited his disciple, a Tailanga king. The king showed him great honor and when he asked him where he was going, Madhavendrapuri told him, "Shri Nathji told me, 'I am hot and need some pure sandalwood', so I am going to the sandalwood forest to procure some sandalwood for Shri Nathji."
                             <p />
                            The king told him, "I have two large pieces of sandalwood in my home. They are so cool that if you boil fifly kilos of oil and put ten ounces of my sandalwood into that boiling oil, it will immediately cool all the oil. Offer this sandalwood to Shri NathJi and allow me to join you so I may also have Shri Nathji's darshan." Madhavendrapuri told him, "Establish your son on the throne and proceed alone with me for Shri Nathji's darshan."
                             <p />
                            The king followed his advice and they set off on their journey for Shri Nathji's darshan, each with a load of sandalwood on his head.
                             <p />
                            Saying that, Shri Nathji withdrew from Madhavendrapuri and returned to the Govardhan Hill. Madhavendrapuri then followed Shri Nathji's orders and always worshipped Shri Himgupal. He later attained the eternal realm.

                        </Col>
                    </Row>
                    <br />
                    <h5>MADHAVENDRAPURI HAS SHRINATHJI'S DARSHAN, WORSHIPS SHRI HIMGUPALJI AND THEN ATTAINS THE ETERNAL REALM </h5>
                    <Row>
                        <Col xs={11}>
                            Madhavendrapuri went to the Balaji temple. He bathed at the Pushkarini River and sat in a small forest there. Afterwards, he meditated upon Shri Nathji. At that time, Shri Nathji knew that Madhavendrapuri was bringing sandalwood for Him and decided to appear before him there in that forest. Shri Nathji manifested, adorned in garments suitable for the hot season. Shri Nathji told Madhavendrapuri, "Anoint Me with some sandal paste, I am feeling hot."
                            <p/>
                            Madhavendrapuri then prepared the substance and offered it to Shri Nathji. He prepared some coconut and bananas for Shri Nathji's enjoyment. At that point, Shri Nathji told Madhavendrapuri, "Braja is near the Himalayas. For this reason, I don't like to have sandalpaste offered to Me twelve months of the year. I only like it during the hot season. But because you desire to offer Me sandalpaste on a daily basis, you should remain here in the south on top of the sandalwood mountain. There you will find Me. Stay there and daily offer Me sandalwood paste. Take your king-disciple as your helper and remain continually absorbed in My seva. Know that I reside there. The people there call Me Himgupal. As Himgupal, I always wear clothes made of sandalwood. There are many sandalwood trees near here and Indra, the lord of heaven, sports there every day. Proceed there now. In Braja Shri Gusaiuji will arrange for My seva. He will vary My ornaments, jewels and food according to the season and will offer Me many types of naturally perfumed oils. He will lovingly worship Me in many ways."

                        </Col>
                    </Row>
                    <br />
                    <h5>MADHAVENDRA ATTAINS THE FINAL ABODE AND SHRI GUSAINJI HEARS ABOUT IT SIX MONTHS LATER AND IS DISTRESSED</h5>
                    <Row>
                        <Col xs={11}>
                            When Shri Gusainji heard about Madhavendrapuri's passing, six months after the fact, he became sad and remarked, "Madhavendrapuri was bringing sandalwood for Shri Nathji and along the way ascended to the eternal realm. Where can we find a bhakta like him? Madhavendrapuri had studied all the scriptures and attained the essence, the path of seva. Shri Nathji showered blessings upon him."
                            <p/>
                            Having said this, Shri Gusainji became filled with melancholy. Then Shri Nathji appeared and comforted him by reporting to him everything that had happened.

                        </Col>
                    </Row>
                   

                </div>
                )
        }

        if (page === 15) {
            return (
                <div>
                    <h5>THE LIFE OF MADHAVENDRAPURI</h5>
                    <Row>
                        <Col xs={11}>
                            Madhavendrapuri was a Tailang south Indian Brahman. He was an Acharya of the Madhva lineage and his disciple was Shri Krishna Chaitanya. Madhavendra had instructed Chaitanya to uplift the Bengal area. So the people from that area of India all became his disciples. Madhavendrapuri had taken sanyasa and lived in Banares. At that time, Shri Lakshman Bhatt, Shri Mahaprabhuji's father, was arranging for Shri Mahaprabhuji's sacred thread ceremony in Banares. It was then that Lakshman Bhatt requested Madhavendrapuri to teach his son. Within four months, Shri Mahaprabhuji had mastered the six scriptures and the four Vedas. As a girl to the guru, Shri Mahaprabhuji told Madhavendrapuri to ask of him whatever he wanted. Madhavendrapuri realized that Shri Mahaprabhuji was Krishna Himself and so he said, "You will bring forth the worship of Shri Nathji. It is through your grace that I now see your divine form with yogic eyes. I would like to receive a bit of Shri Nathji's seva. This is all that I ask for."
                            <p />
                            Then Shri Mahaprabhuji replied, "When I go to Braja and establish Shri Nathji in His temple, you will also be in Braja. At that time I will give you the seva of Shri Nathji. You will serve Shri Nathji as long as He desires your services."
                            <p />
                            Later, Shri Mahaprabhuji went to Braja and established Shri Nathji's seva. At that time, Madhavendrapuri also came to Braja and Shri Mahaprabhuji gave him Shri Nathji's seva. Shri Mahaprabhuji's blessings upon Madhavendrapuri allowed him the fight to serve Shri Nathji for fourteen years. Through him, many Bengalis were able to serve Shri Nathji. Shri Nathji did not see in them the exalted eligibility for seva and so He told them, "Your liberation will occur by remembering and chanting My names. Shri Gusainji will perform My seva."

                        </Col>
                    </Row>
                    <br />
                    <h5>A DESCRIPTION OF THE ASTHA CHHAP POETS</h5>
                    <Row>
                        <Col xs={11}>
                            Then Shri Gusainji began to make the seva of Shri Nathji. When Shri Nathji appeared, eight poets, who were disciples of Shri Mahaprabhuji and Shri Gusainji, also appeared upon the earth. These eight friends of Shri Nathji sang about all the lilas. Their names as Krishna's friends are: Krishna, Tok, Rishabha, Subal, Arjuna, Vishal, Bhoj and Shri Dama. The lineage holder and poet, Shri Dwarkanathji, has sung of these eight friends of Shri Nathji:

                        </Col>
                    </Row>
                    <br />
                   
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            Surdas is Krishna,<br/>
                            Know Paramananda as Tok.<br />
                            Krishnadas is Rishabha,<br />
                            Chittasvami is Subala.<br />
                            Kumbhandas is Arjuna,<br />
                            Chatrabhujadas is Vishala,<br />
                            Nandadas is Bhoj and Govindasvami is Shri Dama.<br />
                            So Shri Dwarkesh has revealed<br />
                            The eight friends of Shri Nathji.<br />
                            Those intimate bhaktas<br />
                            Who sing their songs<br />
                            Become accomplished.<br />
                        </Col>
                    </Row>
                    <br />
                  
                    <Row>
                        <Col xs={11}>
                            When Shri Gusainji appeared on the earth, Shri Nathji made many lilas.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE STORY OF A NAGAR BRAHMIN FROM BANARES </h5>
                    <Row>
                        <Col xs={11}>
                            There was a Nagar Brahmin from Banares. He was from the Smarta lineage. He married a girl from Baranagar. While he was taking his wife to Banares, his wife became the disciple of Shri Gusainji. Later, when they went to Mathura, his wife said, "Here on top of the Govardhan Hill resides Shri Nathji. He is the main deity of my lineage. Let us go and have His darshan."
                            <p />
                            After hearing his wife, and by the wish of God, he decided to go for Shri Nathji's darshan even though he was not initiated. They arrived for the afternoon darshan. At that time, his wife prayed to Shri Nathji, "Through Shri Gusainji, You have taken hold of my hand. Now relieve me from all bad association and keep me close to You."
                            <p />
                            Upon hearing her request, Shri Nathji took hold of her and right there accepted her into His lila with her body. Her husband was so shocked to lose his wife that he started to die. Then Shri Gusainji showed him the eternal liia of Shri Nathji and there he saw his wife in her eternal form as a Gopi. Then all of his doubts were removed and he also became a disciple of Shri Gusainji and later, in his next life, he attained the same lila.
                            <p />
                            In his next birth he was born in the nearby town of Gantoli. He became the well-known pakhavaj drummer, Shyam. He had a daughter called Lalita. She played the vina very well and her father accompanied her very nicely on the drum. One night, Shri Nathji stayed up the whole night in order to hear them play. Shri Nathji returned to His temple after the conch sounded the next morning.
                            <p />
                            When Shri Gusainji went to wake Shri Nathji up and saw that His eyes were red he asked, "Baba, where did You spend Your sleepless night?"
                            <p />
                            Shri Nathji then explained, "I went to Gantoli where I heard Lalita play the vina while her father, Shyam, played the drum. They were very inspired."
                            <p />
                            After hearing Shri Nathji's words, Shri Gusainji called Shyam Pakhavaji and Lalita and initiated them. From then on they were very involved with Shri Nathji's seva. Wherever Shri Nathji played, the Astha Chhap sang while Lalita and Shyam played the ulna and the drum. 

                        </Col>
                    </Row>
                    

                </div>
                )
        }
        if (page === 16) {
            return (
                <div>
                    <h5>ALL THE BRAJA VASIS GATHER AND OFFER SHRI NATHJI COWS</h5>
                    <Row>
                        <Col xs={11}>
                            When the Braja Vasis heard that Shri Nathji liked cows very much, they all got together and decided that whoever had cows would oilier a cow or two to Shri Nathji. The residents of the twenty-four towns that are close to the Govardhan Hill also decided that they would give a cow or two to Shri Nathji. They resolved to give the first-born cows of any twenty pregnant cows to Shri Nathji. In this way, thousands ofcows were offered to Shri Nathji. Then Shri Nathji enjoyed milk, curds, and butter from His own herd.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI GUSAINJI ESTABLISHES SHRI NATHJI'S OPULENT SEVA</h5>
                    <Row>
                        <Col xs={11}>
                            Then Shri Gusainji established that every year, one hundred thousand rupees would be spent on Shri Nathji's seva for sweet balls, sweet breads and other offerings. Shri Gusainji also established the yearly festival calendar. Aside from a pot of curds that Shri Nathji took from one Braja Vasi's house, all of His other milk and curds came from His own cows.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE BRAJA VASI CURDS ARE STOPPED AND BEGUN AGAIN </h5>
                    <Row>
                        <Col xs={11}>
                            One day after the Raj Bhog arati, a bhakta who served in Shri Nathji's temple saw a piece of roti bread in a pot of curds that a Braja Vasi had brought for Shri Nathji's ofering. When that bhakta told Shri Gusainji about the impure bowl of curds, Shri Gusainji prepared another lunch for Shri Nathji and did not allow that Braja Vasi to bring his bowl of curds for Shri Nathji's offering. Instead, Shri Gusainji arranged for a bowl of curds to be brought from the temple kitchen. On the following day, when Shri Nathji was offered His lunch, He mentioned to Ramdas, "I want that bowl of curds the Braja Vasi always brings Me. Make sure it is clean and offer it daily."
                            <p/>
After hearing Shri Nathji's wish from Ramdas, Shri Gusainji then immediately offered that Braja Vasi's curds. Only then did Shri Nathji partake of His lunch.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI GUSAINJI MAKES A COW PEN AND APPOINTS FOUR COW HERDERS </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Gusainji made a large cow pen for Shri Nathji's cows on the road to Gulal Kund and appointed four herders to take care of the cows. Their names are: Krishnadas, Kumbhandas' son, Gopinathdas, Gopal Gwal and Ganga Gwal. During the day, when they went out to herd the cows, Shri Nathji would join the cows and His circle of cow lads.


                        </Col>
                    </Row>
                    <br />

                </div>
                )
        }
       
        if (page === 17) {
            return (
                <div>
                    <h5>SHRI NATHJI TAKES EIGHT SWEETBALLS AND GIVES THEM TO THE COW LADS</h5>
                    <Row>
                        <Col xs={11}>One day, Shri Nathji was playing with the cowlads at Pyauke Dak. At that time, Gopinathdas said, "O ShriNathji, Shri Gusainji gives You lots of sweetballs to eat. From those offerings, why don't You bring us some?"
 <p />
Then Shri Nathji replied, "I will bring some tomorrow."
                             <p />
On the following day, during the Gopivallabh offering in the morning, Shri Nathji took eight sweetballs from the offering and distributed them to the cow lads. He gave each of them one, but awarded Gopinathdas with two. Gopinathdas ate one sweetball and tied the other one into a cloth for later. In the afternoon, when they returned home, they came before Shri Gusainji and bowed. At that time, all the temple cooks were standing before Shri Gusainji talking about the eight sweetballs that were missing from the offerings. Then Gopinathdas showed them Shri Nathji's sweet ball and said, "Would this be one of those sweet balls?"
                             <p />
Shri Gusainji and the other temple cooks replied, "That is one of the eight missing sweetballs."
                             <p />
Then Gopinathdas explained, "Today Shri Nathji brought us eight sweetballs and gave everyone one except me. I got two." From the sweetball that Gopinathdas had received, Shri Gusainji took a small piece for himself and then distributed some of that blessed sweetball to all of the Vaishnavas there. Then Shri Gusainji said, "Make arrangements for Gopinathdas to receive a sweet ball from the temple every day. This is Shri Nathji's snack food. All the people who serve in the temple should be presented with prasada according to the seva they do."

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI GIVES TWO SWEETBALLS TO A RICE FARMER </h5>
                    <Row>
                        <Col xs={11}>By the Govardhan Hill there is a rice field that belongs to Shri Nathji. Two young boys looked after that field. One day, one of the boys went for lunch and was late in returning. The other boy then raised his hand towards Shri Nathji's flag on top of the temple and called out to Shri Nathji, "O Friend, Shri Nathji, I watch over Your rice fields. Send me something to eat. I am hungry."
 <p />
Hearing him, the merciful Shri Nathji came and brought him two sweetballs. Later, Shri Nathji told Shri Gusainji, "I gave two sweetballs to the boy who tends the rice fields. "
 <p />
Shri Gusainji then called the boy, Haraji Gwala, and placed him in Shri Nathji's seva. Now there is a famous lake named after him called Haraji. Shri Nathji's cows would go to that lake every day to drink water.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI DOES NOT GET THE BRAJA VASI'S CURD AT LUNCH SO HE TAKES HIS GOLDEN BOWL AND EATS CURD AT THE GUJARI'S HOUSE</h5>
                    <Row>
                        <Col xs={11}>So the Braja Vasi pot of curd continued to be offered to Shri Nathji every day. One day, that pot of curds was late in arriving. Meanwhile, Shri Nathji's lunch had been offered and taken away and His garland was called for presentation, but the blessed bowl of curd was not offered. After the temple was closed for the afternoon, Shri Nathji thought, "I did not have any curd from the Braja Vasi today."
 <p />
Shri Nathji took a golden bowl from the temple and went to the nearby town of Baroli where Shobha Gujari lived and told her, "Give Me some curd."
 <p />
She gave Him some fresh sweet curd in His golden bowl and Shri Nathji enjoyed it to His complete satisfaction. Shri Nathji left the bowl there and proceeded to Shyam Dak. There, He drank some water at the Jalghar and saw Gopinathdas, Kumbhandas, Govindasvami and other bhaktas herding cows. Shri Nathji decided to join all the boys for a game of hide and seek. When the afternoon conch sounded, marking the beginning of the aflemoon seva, Shri Nathji returned to His temple while the cows returned to their cow pen. Meanwhile, when the bhaktas in the temple had noticed that a golden bowl was missing, they started to question each other. At that time, Shobha Gujari arrived from Baroli with the golden bowl in her hand and gave it to Shri Gusainji saying, "Maharaj, today Shri Nathji came to my home to eat some curd. He left this bowl in my home so I am returning it to you. " After hearing that, Shri Gusainji became very concerned and remembered that the Braja Vasi bowl of curd was not offered today and he then knew that Shri Nathji could not remain without the Braja Vasi's curd. He understood Shri Nathji went to Shobha's house to eat curd. From that day on, that curd was called for early so that it would always be part of the lunch offering. 

                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        
        if (page === 18) {
            return (
                <div>
                <h5>SHRI NATHJI EATS RUPA'S RICE AND CURDS </h5>
                <Row>
                    <Col xs={11}>
                        One day, while Shri Nathji was standing next to the Govinda Lake, a Braja Vasi woman passed by bringing some rice and curds to her son. Seeing this and being hungry, Shri Nathji asked her for some of her rice and curds in a bowl. Rupa then offered Shri Nathji some of the rice and curds intended for her son in a bowl and Shri Nathji joyfully took it back to temple on the Govardhan Hill. After He finished eating it, Shri Nathji left the bowl in the temple. Later, when the temple doors were opened in the afternoon, the temple cook saw the bowl lying in the inner chamber. When he asked the person who cleans the pots about it, he said that he had washed all of the pots and bowls and knew nothing about that bowl. Later, Shri Nathji told Shri Gusainji, "Rupa Gujari gave Me some of her curds and rice. I washed her bowl and left it here in the temple." Then Shri Gusainji thought, 'We are now in the hot season. Rice and curds is a good refreshing offering for this time of year. As of today, we will begin to offer rice mixed with curds every day to Shri Nathji." In this way, special offerings were put into Shri Nathji's seva according to the season and time of day. It all occurred according to Shri Nathji's wishes. 

                    </Col>
                </Row>
                <br />
                <h5>SHRI NATHJI HAS HIS LUNCH AT SHYAM DAK</h5>
                <Row>
                    <Col xs={11}>
                        Once Shri Nathji told Gopaldas, "Tell Shri Gusainji that I am at the Apsara Lake. Request him to quickly bring Me some rice and curds in the forest at Shyam Dak. Tell him I am hungry. " Then Gopaldas told Shri Gusainji, who was in the temple, to immediately bring a lunch for Shri Nathji and go to Shyam Dak. There, Shri Nathji and His brother, Balaram, along with all 'of Their friends partook of the lunch. After experiencing that lila, Shri Gusainji returned to his room in Jatipura just below the Govardhan Hill. 

                    </Col>
                </Row>
                <br />
                <h5>SHRI NATHJI GOES TO SHRI GUSAINJI'S HOUSE IN MATHURA, WHERE EVERYTHING IS OFFERED TO HIM, AND PLAYS HOLI THERE BEFORE RETURNING TO THE GOVARDHAN HILL </h5>
                <Row>
                    <Col xs={11}>
                        Once when Shri Gusainji went to Gujarat, Shri Nathji told Shri Gusainji's oldest son, Shri Giridhariji, "I would like to see your house in Mathura."
                        <p/>
Knowing Shri Nathji's wish, Shri Giridharji prepared a horse- drawn chariot for Shri Nathji and had it ready for Him by the side of the Govardhan Hill at Dandavati Shila. Then Shri Nathji jumped on Giridhariji's back and was carried to the Dandavati Shila where He got into the chariot. Shri Giridhariji then urged the chariot on towards his home in Mathura. They arrived at Shri Gusainji's Sat Ghara house in Mathura in the year 1567, on the seventh day of the dark half of the month of Phalgun. Even today, the day that Shri Nathji arrived in Mathura is celebrated in all the seven houses of the Pushti Marga. On the day that Shri Nathji arrived in Mathura, Shri Giridhariji and his entire family offered everything they owned to Shri Nathji. Shri Giridhariji came out wearing just a single cloth. All the other wives and sisters of the family also offered everything they owned and each wore nothing but a sari. They offered to Shri Nathji priceless jewels, cloth, vessels, chariots, horses and absolutely everything they owned. Shri Giridhariji's sister, Kamala, secretly kept one nose ring for herself and offered everything else to Shri Nathji. Knowing this, Shri Nathji told her, "Give Me My nose ring." The way that Shri Nathji reacted to the situation clearly indicated His total acceptance of her.

                    </Col>
                </Row>
                <br />
                <h5>SHRINATHJI PLAYS HOLI</h5>
                <Row>
                    <Col xs={11}>
                        Then Shri Nathji played Holi with Shri Giridhariji and the rest of his family. Every member of the family played Holi with Shri Nathji. Afterwards, Shri Nathji told all the female members of the family to play Holi with Him. While they played Holi, they wore blouses adorned with choba, a black sandalwood extract; they were all adorned in a very enchanting fashion. The joy that arose there was ineffable. They then stole Shri Nathji's flute and would only return it to Him when Shri Nathji agreed to give them some snack food.

                    </Col>
                    </Row>
                </div>
                )
        }
        
           
        
        if (page === 19) {
            return (
                <div>
                    <h5>SHRINATHJI RETURNS TO THE GOVARDHAN HILL AND MEETS SHRI GUSAINJI</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Gusainji, hearing about Shri Nathji's visit to Mathura, started his return journey to Braja from Gujarat. Knowing that Shri Gusainji was returning, Shri Nathji told Shri Giridhariji, "If Shri Gusainji does not see Me on top of the Govardhan Hill, he will be very upset. Bring Me back to the Govardhan Hill today."
                            <p/>
After taking His mid-morning Gopivallabh food offering, Shri Nathji sat in His chariot and told Shri Giridhariji, "Quickly drive the chariot to the Govardhan Hill and offer Me lunch and dinner together when we arrive there." Shri Nathji arrived at the Govardhan Hill at Dandavat Shila an how before sunset. Shri Nathji then climbed on Shri Giridhariji's back and was brought up to the temple where He sat on His throne. This entire lila is divine beyond any logic. The day Shri Nathji returned was Narasimha Chaturdashi, and the festival was celebrated on the Govardhan Hill where Shri Nathji's lunch and dinner were offered together. Every year on the day of Narasimha Chaturdashi, Shri Nathji is still offered His lunch and dinner together.
                             <p />
On the following day, Shri Gusainji returned from Gujarat to the Govardhan Hill, where he heard all about Shri Nathji's lila in Mathura. Shri Gusainji then said, "Shri Mahaprabhuji established Shri Nathji on top of the Govardhan Hill. Shri Nathji has blessed me and has fulfilled my wishes. He has returned to give me His darshan on top of the Govardhan Hill."
                             <p />
Then Shri Gusainji affectionately pinched Shri Nathji's cheeks and asked Him, "Why did you go to Mathura?"
                             <p />
Shri Nathji replied, "I went to see your daughters and daughter-in-laws."
                             <p />
In this way they spoke to each other and were both very pleased.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI'S SHAWL GETS STUCK ON A BRANCH </h5>
                    <Row>
                        <Col xs={11}>
                            One day, Shri Nathji went with Govinda Swami to the Shyam Dak forest to play. Later in the afternoon, the conch from the temple sounded marking the beginning of the afternoon worship.
                             <p />
While Shri Nathji was trying to return to the temple quickly, His shawl got caught on a branch and ripped, leaving a portion of the shawl hanging on the tree. When Shri Gusainji arrived in the temple during the afternoon darshan and saw that Shri Nathji's cloth was torn, he was distressed. He could not understand how it happened. Then Govinda Swami arrived and returned the missing piece of cloth to Shri Gusainji and told him, "Your son is very unsteady."
                             <p />
Shri Gusainji took the piece of cloth and attached it to Shri Nathji's shawl and told Ramdas, "From today onwards, after sounding the conch, wait a few moments before you enter the temple to open Shri Nathji's curtain."

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI BECOMES SMALL TO ACCEPT A SMALL GARMENT 
</h5>
                    <Row>
                        <Col xs={11}>
                            Once, Shri Gusainji made a black outfit for Shri Nathji, but it was too small. When Shri Gusainji tried to put it on Shri Nathji, Shri Nathji, knowing the garment was too small, took on a smaller form so the cloth would fit Him. Seeing this lila, Shri Gusainji became very pleased. He then recited the following sloka in front of his sons, Shri Giridhari and Gokulnathji:

                        </Col>
                    </Row>
                   <br/>
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            Krishna, the life of Gokul, has appeared through<br/>
                            His grace in manifested form and appears in my<br />
                            heart in a very small form as well as in a huge form.

                        </Col>
                    </Row>
                    <br />

                </div>
                )
        }
        if (page === 20) {
            return (
                <div>
                    <h5>SHRI NATHJI PLAYS A BOARD GAME WITH RUPA MANJARI</h5>
                    <Row>
                        <Col xs={11}>
                            One day, Shri Nathji played "chopad", a board game, with Rupa Manjari, the daughter of a cow herder. He played that game with her all night long. He also listened to her play the vina. She was a good player. Nandadas was also there and sang beautifully. Nandadas wrote a work for her called the Rupamanjari in which he says:
                        </Col>
                    </Row>
                    <br />
                   
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            The lady Rupa Madjari made a dwelling<br/>
                            For Shri Nathji within her heart.<br />
                            
                        </Col>
                    </Row>
                    <br />
                    
                    <Row>
                        <Col xs={11}>
                            On the following morning, when Shri Nathji returned to His temple, Shri Gusainji noticed that ShriNathji's eyes were red during the morning Mangala darshan and inquired, "Where did You spend the night?"
                            <p/>
Shri Nathji then told him everything,"I went to play 'chopad' with Rupa Manjari."
                             <p />
Shri Gusainji then considered, "It is troublesome for Shri Nathji to go so far to play. He can play here in the temple with His Braja Bhaktas."
                             <p />
From that day, the board game 'chopad' was established into Shri Nathji's seva.

                        </Col>
                    </Row>
                    <br />
                    <h5>EMPEROR AKBAR'S LADY BIBI TAJ </h5>
                    <Row>
                        <Col xs={11}>
                            Alikan Patan's daughter was named Bibi Taj. She wrote the Holi Dhamar poem:
                        </Col>
                    </Row>
                    <br />
                   
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            I see Him coming,<br/>
Krishna, the Lord of Taj,<br/>
Singing the spring Holi song.<br/>
                        </Col>
                    </Row>
                    <br />
               
                    <Row>
                        <Col xs={11}>
                            Tij was the disciple of Shri Gusainji. When Shri Gusainji came to know that Shri Nathji used to go to Agra in order to play chess with Taj, he arranged for a chess set to be placed before Him in the temple. One day, the Emperor came to the Govardhan Hill. Taj had also come with him to have Shri Nathji's darshan.
                             <p />
She entered the temple and saw Shri Nathji in His full divine glory. When Shri Nathji called out to her, this increased her divine distress. She tried to run to meet and merge with Shri Nathji saying, "I will join You, Shri Nathji!"
                             <p />
At that time, Vrindavandas"s daughter was with Taj. She was a friend who used to play chess with her. When Taj tried to run to Shri Nathji, Vrindavandas's daughter struggled to stop her and, holding her by the arm, brought her down the Govardhan Hill.
                             <p />
There, below Shri Nathji's temple, Taj left her body. At that moment, she attained a divine body and entered into the lila.
                             <p />
Everyone there became very concerned about what Emperor Akbar would do. But through Shri Nathji's power, when the emperor heard about what had happened, he said, "She has returned to her own place," and then proceeded to Delhi.
                             <p />
A similar event happened with the temple manager, Krishnadas, and a prostitute that he brought to dance before Shri Nathji. She also left her body before Shri Nathji. The rest of Shri Nathji's lilas even the serpent-god Shesha, with his thousand mouths, cannot fully relate.

                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        if (page === 21) {
            return (
                <div>
                    <h5>SHRI NATHJI HAS THE ROOF TERRACE REMOVED</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Mahaprabhuji had a window made in the temple in front of the Vichhau Lake through which he could see his cowlads. One day, while Shri Gokulnathji, Shri Mahaprabhuji's grandson, was making Shri Nathji's ornamentation during the hot season, some sunlight came into the room through that window and made him feel very hot. Shri Gokulnathji then had a room constructed on the adjacent roof which sheltered that window from the hot sun, and returned to Gokul. Shri Nathji told the sweeper, Mohan Bhangi, "Go and tell Gokulnathji to take down the room he made on the roof I can no longer see the Vichhau Lake. "
                            <p/>
Hearing Shri Nathji's words, Mohan Bhangi ran to Ading where he met Shri Gokulnathji and told him, "Maharaj, one little thing has become very important. Shri Nathji told me to tell you to remove that room you made on the roof. Shri Nathji says that now He cannot see the Vichhau Lake."
                             <p />
Then Shri Gokulnathji asked, "Shri Nathji knows my name?"
                             <p />
Gokulnathji then became choked with emotion and asked four more times, "Does Shri Nathji really know my name?'What a command He has given me today! "
                             <p />
Then Gokulnathji returned to the Govardhan Hill and made a special offering to Shri Nathji. After he apologized to Shri Nathji, he had the roof room removed. This all pleased Shri Nathji.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE STORY OF KALYAN JOTISHI AND HOW SHRI GIRIDHARIJI MERGES INTO THE FORM OF SHRI MATHURESHJI </h5>
                    <Row>
                        <Col xs={11}>
                            Kalyan Jotishi was a follower of Shri Nathji who used to sing before Him. One day, while Shri Giridhariji was giving a betel leaf to Shri Nathji, Kalyan sang:
                        </Col>
                    </Row>
                    <br />
                   
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            O friend!<br/>
                            Krishna is my life;<br />
                            I have no other meditation.<br />
                            He removes my pain<br />
                            And is the source of my pleasure.
                        </Col>
                    </Row>
                    <br />
                    
                    <Row>
                        <Col xs={11}>
                            Shri Giridhariji then considered, "When Shri Gusainji's disciples, the Astha Chhap poets, sang before Shri Nathji, He used to laugh and talk with them. Now He does not laugh nor speak with the bhaktas."
                             <p />
Shri Nathji, the inner witness, knew of Shri Giridhariji's concern. While Giridhariji was giving Shri Nathji a betel pan, He laughed and told Shri Giridhariji, "That bhakta sings very nicely."
                             <p />
As Shri Nathji was saying that, at that moment Kalyan Jotishi had the darshan of the liberated abode. Then Giridhariji mentioned, "You can only tell where Shri Nathji will shower grace after the fact."
                             <p />
His brother Gokulnathji added, "Shri Nathji always remains as one nectar essence. It is there in the beginning, middle and end. When our father, Shri Gusainji, was alive, the pure Pushti Marga lived. At that time, Shri Nathji spoke and played with all the bhaktas. Now the following of the Pushti Marga is a mixed group. Shri Nathji accepts all of their seva, but only speaks with those who are purely grace-filled."
                             <p />
Some days later, after hearing his brother Gokulnathji's words, Shri Giridhariji merged into Mathureshji's (Shri Krishna's) face. This all happened when Shri Gokulnathji went to Kashmir to defend the right of Vaishnavas to wear tulsi necklaces. One day during his absence, Shri Giridhariji made Shri Mathureshji's ornamentation while his son, Shri Damodarji, was serving Shri Nathji. During the seva, Shri Mathureshji suddenly yawned and Shri Giridhariji merged into the Lord's mouth with his body and entered into Shri Krishna's lila. Seeing this event, his two brothers became very concerned. Then Shri Nathji handed them a shawl and said, "Don't worry. Perform Shri Giridhariji's last rites with this shawl." 
                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 22) {
            return (
                <div>
                    <h5>SHRI DAMODARJI ASCENDS THE SEAT</h5>
                    <Row>
                        <Col xs={11}>
                            After Giridhariji left this world, his oldest son, Damodarji, ascended his father's seat. At that time there were three hundred thousand rupees in Shri Nathji's offering box. The storeroom manager took that money and hid it. Shri Nathji then told Shri Damodarji, "Under the Jan Ajan tree you will find the three hundred thousand rupees that the storeroom manager stole. Call for it."
                            <p/>
Shri Damodarji then retrieved the money. In this way Shri Nathji looked after His divine wealth.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI IS ADORNED WITH A DAGGER </h5>
                    <Row>
                        <Col xs={11}>Once Shri Nathji had the desire to hold a dagger. Then Shri Gusainji said, "I will adorn Shri Nathji with a dagger on the Vijay Dashami festival."
                             <p />
At that time Shri Nathji was adorned with the knife.

                        </Col>
                    </Row>
                    <br />
                    <h5>DURING A QUARREL,SHRI VITTHALRAYA GOES TO AGRA,SHRINATHJI GIVES HIS COMMAND AND THE EMPEROR RESOLVES THE DISAGREEMENT 
</h5>
                    <Row>
                        <Col xs={11}>One day, Shri Damodarji's son, Shri Vitthalraya, went to Agra. There was a quarrel concerning the right to Shri Nathji's seva among the lineage holders. Shri Vitthalraya was very distressed over the daily conflict. He prayed to Shri Nathji, "I have no one else."
                             <p />
He later told the emperor what happened when Shri Nathji appeared to him. He explained to the emperor that Shri Nathji came before him with a red cane in His hand and placed His hand upon his head and appeased him, "When Shri Gusainji was at the Govardhan Hill, his seven sons stood before Me and at that time Shri Gusainji said to Me, 'Whoever You are pleased with, have them make Your seva.' At that time I took hold of Shri Giridhariji's hand. Of all of the seven sons, Giridhariji is the one who has the capacity. He brought Me to his home in Mathura and offered everything to Me. He brought Me from the Dandavat Shila all the way up the Govardhan Hill on his back. And when Shri Navanita Priyaji (Child Krishna) was to be brought to Braja, that responsibility, although given to the other six brothers, was handled by Shri Giridhariji. For that reason, Shri Giridhariji has the right to the main seva. The year has three hundred and sixty days. Shri Giridhariji will make the seva during the sixty days of festivals and during the other three hundred days, My ornamentation will be made by the other lineage holders."
                             <p />
Saying that, Shri Nathji returned to His temple on the Govardhan Hill and on the following day, just after Shri Nathji had given instructions for the division of the seva, the emperor issued a decree outlining the exact same agreement. Then the quarrel came to an end and Shri Vitthalraya returned home.


                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI VITTHALRAYA PUTS A TIPARA CROWN ON SHRI NATHJI 
</h5>
                    <Row>
                        <Col xs={11}>Shri Vitthalraya adorned Shri Nathji very nicely with a Tipara crown. Shri Nathji enjoyed this Tipara So much that two or four times a month He would ask to wear it. Shri Nathji was always very pleased when He saw Himself in the mirror wearing that crown. Once, when Shri Vitthalraya went to Agra, another lineage holder wanted to adorn Shri Nathji with the Tipara crown, but Shri Nathji would not allow it and said, "When Shri Vitthalraya returns, then I will wear the Tipara."
                             <p />
In this way, Shri Nathji favored His Own.
                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI GIRIDHARIJI PLAYS HOLI AND SWINGS SHRI NATHJI </h5>
                    <Row>
                        <Col xs={11}>Once, Shri Vitthalraya's son, Shri Giridhariji, arrived in Lahore sixteen days before the Dol festival. Shri Nathji told him, "I will only play Holi when you come and play with Me. One bhakta is going to offer you one hundred thousand rupees. Take that offering and quickly return to Braja."
                             <p />
The following day, after Giridhariji received the offerinng, he set out for Braja and reached the Govardhan Hill twelve days later. There he played Holi with Shri Nathji and swung Him during the Dol festival. Shri Nathji became very happy. In this way Shri Nathji favored Shri Giridhariji. Through Shri Gusainji's intercession, all the lineage holders could perform Krishna's seva, but the main seva of Shri Nathji is reserved for the descendants of Shri Giridhariji, the oldest son of Shri Gusainji.


                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        if (page === 23) {
            return (
                <div>
                    <h5>SHRI GOKULNATHJI PLAYS HOLI WITH SHRINATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            There is a similar story concerning Shri Gokulnathji. Once, when Shri Gokulnathji went to Kashmir in order to defend the right of Vaishnavas to wear their tulsi necklaces, the entire spring season passed before he could return to Braja. He did not have an opportunity to play Holi with Shri Nathji. Then Shri Nathji told one cowlad who helped with the milk preparations in the temple, "Tell Gokulnathji that I want him to play Holi with Me."
                            <p/>
                                Then Gokulnathji played Holi with Shri Nathji for fifteen days after the Holi festival was over. Shri Gokulnathji made a house of roses for Shri Nathji to sit in as well as a bower of mango tree branches. On that day Shri Nathji was adorned with a crown and the following song was sung : 
                              <p />
                            <Col xs={11} className="d-flex justify-content-center">
                                Spring remains forever in Vrindavan where<br />
                                     Krishna wanders through the vines and trees.
                                </Col>
                            <p />
                            This is how Shri Nathji has concem for Shri Vallabhacharya's lineage.
                            One day, Shri Lakshman Maharaja, who was a very good singer and a descendant of Shri Raghunathji, was singing a song before Shri Nathji. After the ornamentation had been taken off in the early evening, he sang:
                            
                            <p />
                            <Col xs={11} className="d-flex justify-content-center"> 
                                She forgot to milk, or even to arrange for,<br />
                            the cow to be milked.
                                </Col>
                            <p />
                            On another day during the spring season, a full hour after the doors to the temple had been shut, Shri Lakshman Maharaj was still singing to Shri Nathji. Shri Gokulnathji then asked, "Who is singing at this late hour after the temple has been closed?"
                            <p />
                            When someone told him it was Shri Lakshman, he ordered that the singing be stopped. That evening Shri Gokulnathji had a dream in which Shri Nathji told him, "Let Shri Lakshman sing when he wants. It is his seva."
                            
                        </Col>
                    </Row>
                    <br />
                    <h5>ON THE WAY TO DWARKA, SHRI GUSAINJI VISITS SINHAD IN MEVAR, TELLS OF SHRI NATHJI'S FUTURE ARRIVAL THERE, AND MAKES THE KING AND QUEEN HIS DISCIPLES </h5>
                    <Row>
                        <Col xs={11}>
                            Once, while Shri Gusainji was on his way to Dwarka, he saw a very lovely spot in Mevar, Rajasthan, called Sinhad and mentioned to Harivamshaji, "In the future, Shri Nathji will reside here. As long as I am here, Shri Nathji will not leave the Govardhan Hill."
                             <p />
Shri Gusainji then stayed there for two days. The king of the region, Shri Udaya Singh, came for his darshan and presented him with one hundred gold coins. Shri Gusainji blessed him with one of his shawls. After the king accepted it, he bowed to Shri Gusainji and returned to his palace. Later his queen came. She was the daughter of the princess Mira Bai. The queen's daughter- in-law, Ajaba Kunvari, also came and took initiation from Shri Gusainji. She was very attached to Shri Gusainji's form. When Shri Gusainji decided to head on to Dwarka, Ajaba fainted at the thought of separation from him. Then Shri Gusainji told her, "I cannot live here, but Shri Nathji will daily come here and allow you His darshan."
                             <p />
Saying that, Shri Gusainji proceeded to Dwarka.

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI COMES TO MEVAR DAILY PLAYS CHOPAR WITH AJABA, AND TELLS OF HIS PLANS TO LIVE IN MEVAR </h5>
                    <Row>
                        <Col xs={11}>
                            Afterwards, Shri Nathji daily came from the Govardhan Hill to Mevar to appear before Ajaba. After playing the board game Chopar with her, Shri Nathji would return to the Govardhan Hill. One day Ajaba requested, "In Your comings and goings to Mevar from the Govardhan Hill, You are very troubled. Live here in Mevar, then I can always see You."
                             <p />
Shri Nathji replied, "As long as Shri Gusainji remains on the earth, I will not leave the Govardhan Hill. Afterwards, I will definitely come to Mevar and will stay here for many years. Then, when Shri Gusanji again appears in his lineage, I will return to Braja and will play at the Govardhan Hill for many years."
                             <p />
Saying that, Shri Nathji returned to the Govardhan Hill. 

                        </Col>
                    </Row>
                  
                </div>
                )
        }
        if (page === 24) {
            return (
                <div>
                    <h5>SHRI NATHJI REMEMBERS MEVAR AND INSPIRES A MUGHAL TO MAKE HIM LEAVE THE GOVARDHAN HILL</h5>
                    <Row>
                        <Col xs={11}>
                            Many years later, Shri Nathji remembered that He was supposed to move to Mevar and thought, "I must go to Mevar. But Shri Mahaprabhuji has established My temple on the Govardhan Hill, so the lineage holders will only agree to the move if they are forced. So I will inspire a demon to force My move to Mevar."
                            <p/>
                            Then, one night Shri Vallabhji Maharaj had a dream in which he saw that Shri Nathji was leaving the Govardhan Hill for another place. The following day, after the evening arati, when all the temple bhaktas returned home, one Mughal came to the temple and started to sweep the floor with his beard. No one knew about this because he arrived in the temple through his yogic powers and then would leave the same way. One day, Shri Nathji became pleased with him and gave him two betel leaves from His snack box and told him, "I will give you a kingdom for fifty-two years. Make Me leave the Govardhan Hill and never come to My temple here again. My temple on the Govardhan Hill will become secret. Make your Mosque, bow your head there and don't ever enter into this temple again! "
                             <p />
                            Upon-hearing Shri Nathji's words, the Mughal returned to Agra and commanded a powerfill kingdom.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE EMPEROR SENDS A MESSENGER TO SHRI NATHJI'S TEMPLE </h5>
                    <Row>
                        <Col xs={11}>
                            One day, that emperor sent a messenger to the Govardhan Hill. He went before Shri Vitthalraya's younger son, Shri Govindaji, who was the temple manager at the time, as well as before Shri Dauji, the son of Shri Giridhariji, who was only fifteen years old. The minister informed them, "The emperor has said to tell you hermits that you must show him a miracle. If you don't, then you must leave this place."
                             <p />
                            Later, Shri Govindiji asked Shri Nathji, "The emperor has asked to see a miracle, but in this path miracles only happen through Your grace. If it is Your wish, I will show him a miracle."
                             <p />
                            When Shri Nathji did not answer him, Shri Govindiji became very concerned and thought, "Without Shri Nathji's order I cannot show a miracle; but if I do not show a miracle, then we cannot remain here. What is the solution?"

                        </Col>
                    </Row>
                    <br />
                    <h5>A SHORT ACCOUNT OF SHRI GIRIDHARIJI'S ENTRANCE INTO LILA </h5>
                    <Row>
                        <Col xs={11}>
                            By that time, Shri Govindaji's older brother, Shri Giridhariji, had entered into the lila. Shri Nathji showered much grace over him. Shri Giridhariji did not pay heed to the emperor's order and said, "As long as I remain on this earth, nothing will happen to our position on the Govardhan Hill."
                            <p/>
                            After saying that, he went to Shri Nathji's temple. Giridhariji was in disagreement with the Brahmins and warriors in Govardhan. Because of the unrest, he no longer used the Dan Ghati route through Govardhan and started to make another route over the Govardhan hill by the Govinda Lake. To overcome the sin of making a new route over the Govardhan Hill, Giridhariji was stabbed by someone holding a knife and entered into the lila where he is eternally engaged in Shri Nathji's seva.
                        </Col>
                    </Row>
                    <br />
                    <h5>AFTER GIRIDHARIJI ENTERS THE LILA, HE TELLS GOVINDAJI THAT IT IS SHRINATHJI'S WISH TO GO TO MEVAR </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Nathji told Shri Giridhariji that Shri Govindaji is very concerned so, "Give him your darshan and tell him to take Me to Mevar."
                            <p/>
In the middle of the night, Shri Giridhariji appeared to Shri Govindaji. Shri Govindiji gave him a seat and Shri Giridhariji repeated to him Shri Mahaprabhuji's teachings from his Navaratna:
 <p />
Those who have completely surrendered their
                           <Col xs={11} className="d-flex justify-content-center">   selves should never have any anxiety because the<br/>
                            Lord is established in grace and will not place them<br />
                                in the way of the world.
                            </Col>    
 <p />
He explained to him, "It is Shri Nathji's wish to play in a hidden fashion on the Govardhan Hill. When Shri Mahaprabhuji made Shri Nathji's birth chart, he gave Him the name Gopal which means 'the protector of the cows'. To protect the cows, Shri Nathji will leave the Govardhan Hill. The Mughal invaders are only an excuse for Shri Nathji to move to Mevar. If He does not go, the wishes of the bhaktas will remain unfulfilled. Prepare Shri Nathji's chariot. Tomorrow is the perfect thirteenth lunar day. Just after sunset, start your joumey with Shri Nathji. Do not show any miracles. Act according to Shri Nathji's wishes and go wherever Shri Nathji desires to go. Shiva will go ahead of the chariot and lead the way with a torch. The first night you will stay in Agra. From there, you should always prepare the next day's camp in advance so that everything is ready when Shri Nathji arrives. Shri Nathji will convey His desires to the bhakta Gangabai. Ask her what to do. Only when the Braja Vasis touch Shri Nathji and insult Him will the chariot be able to move. Shri Nathji will only get up and go when the Brija Vasis jab him."
 <p />
Having said that, Shri Giridhariji went to Shri Nathji's sleeping quarters.
                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 25) {
            return (
                <div>
                    <h5>ON HIS WAY TO MEVAR, SHRI NATHJI FIRST GOES TO AGRA</h5>
                    <Row>
                        <Col xs={11}>
                            The following morning, Shri Nathji's lunch was offered early and after the chariot was adorned and prepared for Him, a horse was yoked to the chariot and stood ready by the Dandavati Shila at the base of the Govardhan Hill. The leaders were called and prepared everything. The three brothers, Govindaji, Shri Bal Krishnaji and Shri Vallabhji, gathered together and after bowing before Shri Nathji, requested Him in the presence of all the bhaktas to proceed, but Shri Nathji would not move. Then they called some Braja Vasis who came and teased Shri Nathji. "So You don't want to go, do You! Do You want all of us to get our heads chopped off?"
                            <p/>
                            Hearing them, Shri Nathji had a big laugh and blossomed like a lotus. He then quickly got up and sat in the chariot. This occurred on a Friday, on the fifteenth lunar day during the bright half of the month ofAsoja in the year 1669, during the last three hours of the night. Shri Vallabhji prepared a sweet fruit salad for Shri Nathji. After Shri Nathji partook of it, they tried to make the chariot move, but it would not budge. Then all the lineage holders requested Shri Nathji to move and Shri Nathji replied, "I will only move when you put Gangabai in a wagon behind My chariot."
                            <p/>
                            They then brought a wagon for Gangabai that always followed Shri Nathji's chariot. Whenever Shri Nathji's chariot stopped, everyone would ask Gangabai what to do and she would convey Shri Nathji's desire to them. In this way, they reached Agra in one night. Shivaji provided light along the way with his torch. Shri Nathji stayed at His temple in Agra. 
                          </Col>
                    </Row>
                    <br />
                    <h5>TWO WATER BOYS' SERVICE AND DIVINE VICTORY </h5>
                    <Row>
                        <Col xs={11}>
                            There were two boys who used to carry water for Shri Nathji. After Shri Nathji had left the Govardhan Hill, the emperor's chef came to destroy the temple with two hundred men in his army. The two water boys stood by the door of the temple. They prevented their entry and in the ensuing baffle killed all of the Mughal invaders. They spared their leader to inform his superiors that if he brought more forces, they would also die. Those two boys were filled with so much power that for one and a half months they continually stood by the temple doors with their swords without experiencing hunger or thirst. For forty-five days they did not allow the temple to be harmed. Other Mughal generals came seventeen times with five to six hundred men, but those two boys killed all of them. The emperor then told his minister to take a very large force and to attack the temple. At that time Shri Nathji thought, "Those two boys are filled with such power that they can kill all of the Mughal invaders. I will appear before them."
                             <p />
Then Shri Nathji came from Agra and appeared by the temple doors and said, "Shri Giridhariji has given you such power that you were able to kill all those barbarians. Now it is not My wish to stay here. Wherever I have given a promise to My bhaktas, to that place I go. After fulfilling My bhaktas' desires in other places, I will return to Braja and everything will be accomplished. Stop fighting and return to My lila."
                             <p />
Saying that, Shri Nathji returned to Agra. Then through the desire of Shri Nathji, those two boys attained divine vision. They beheld the Govardhan Hill as being comprised of precious jewels and saw many temples on the jewelled hill. Among them there was one jewelled temple. A kettle drum sounded outside of its gate. There they saw a mosque and a Muslim cleaning the temple floor with his beard. Then they understood Shri Nathji's wish completely. They put down their swords, left their material bodies and attained Shri Nathji's eternal lila. Their names were Seva and Sabha.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE EMPEROR ATTACKS FOR THE EIGHTEENTH TIME AND BUILDS A MOSQUE </h5>
                    <Row>
                        <Col xs={11}>
                            Then the emperor attacked for the eighteenth time with a large army and workmen who would destroy the temple. When they came to the Govardhan Hill, Shri Nathji made it so that they could not even see the temple. They left the area after constructing a mosque. 
                        </Col>
                    </Row>
                    <br />
                    <h5>THE COMPLETE STORY OF SHRI NATHJI'S TRIP TO AGRA </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Nathji reached Agra an hour and a half before sunrise and found all the doors to His temple open and the guards fast asleep. No one stopped Shri Nathji's entrance. Shri Nathji got down from His chariot and went straight into the temple. After establishing Himself He said, "After I celebrate Annakuta, I will move on from here."
                            <p/>
                            At the time Shri Nathji came to Agra, the emperor was sleeping on his royal bed in the palace. Shri Nathji appeared there and hit him with a stick and told him in his dream, "I have come to Agra today. What can you do to Me? I will leave here when I am ready."
                            <p />
                            Then the Mughal ruler got up but he did not see Shri Nathji. He was left with the mark of Shri Nathji's lotus feet on his back exactly where Shri Nathji had hit him. That mark stayed with him for as long as he lived, but he never told anyone about it. He kept it all within his heart. He always worshipped Shri Nathji secretly and lived on barley roti breads and green vegetables. He always slept on the floor. He did all of these austerities in the hope of having Shri Nathji's darshan. 

                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        if (page === 26) {
            return (
                <div>
                    <h5>THE COMPLETE STORY OF SHRI NAVANITA PRIYAJI'S TRIP TO AGRA</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Govindaji and his two brothers brought Shri Nathji to Agra. Shri Navanita Priya resided in Gokul at that time. They sent some men to bring Shri Navanita Priyaji, the image of Child Krishna, to Agra and told them, "Also bring Shri Dauji and all the women of the family to Agra. Tell the priest, Govinda Dubeji, to bring Navanita Priyaji to Agra."
                            <p />
                            Having received the message, Govinda Dubeji bathed and tried to awaken Navanita Priyaji by sounding the conch at about 9 p.m. He remained fast asleep. Then Govinda Dubeji made great efforts to awaken Navanita Priyaji, but Navanita Priyaji would not get up. Then he took hold of Navanita Priyaji's hand, but even then Navanita Priyaji would not get up from His bed, Then Dubeji knew that it was not Navanita Priyaji's wish to wake up at that time.
                            <p />
                            He thought, "We will have to wake Him up in the morning." After considering the situation, he took rest in Navanita Priyaji's courtyard. One hour before sunrise, Dube took another bath and, after making an offering, he awoke Navanita Priyaji. After offering Navanita Priyaji His breakfast, he made His ornamentation and then placed Him in a palanquin. Wth the help of several temple cooks and water carriers, they reached Go Ghat some time later. There, Shri Gusainji's third son, Shri Bal Krishna, and his grandson, Shri Brijarayaji, were waiting for them. Shri Brijarayaji had received the boon from Navanita Priyaji that, "One day, I will have lunch from your hand. " The story of that boon is given below.
                            <p />
                            Shri Gusainji and all of the lineage holders, as well as the temple servants were in the practice of leaving the temple after Navanita Priyaji was put to sleep. Then all the wives and daughters of Shri Gusainji's seven sons would go to touch Navanita Priyaji's feet. The wife of Shri Pitambarji, Shri Bal Krishna's son, would always be the last to touch Navanita Priyaji's feet.
                            <p />
                            Once Navanita Priyaji told her,"I will come to your house."
                            <p />
                            She put Navanita Priyaji in her blouse and brought Him to her house. Navanita Priyaji stayed in her house almost the entire night. Just before sunrise, Navanita Priyaji told her, "Bring Me back to Shri Gusainji's house. If Shri Giridhariji does not find Me in the temple, he will be distressed."
                            <p />
                            Meanwhile, Shri Giridhariji and his brother, Shri Gokulnathji, had gone to awaken Navanita Priyaji. When they entered Navanita Priyaji's sleeping quarters and did not find Him there, both of them wondered, "What has happened?"
                            <p />
                            Shri Giridhariji then said, "There must be some good reason for this. Shri Gusainji has given me Navanita Priyaji so Navanita Priyaji cannot leave this place."
                            <p />
                            After saying that, both brothers sat outside the temple under the triple arches and meditated upon Shri Gusainji in their hearts.
                            <p />
                            By that time Navanita Priyaji again told Pitarnbarji's wife, "Return Me quickly to the temple."
                            <p />
                            She asked Navanita Priyaji,"Stay here for lunch, then leave."
                            <p />
                            Navanita Priyaji declined, but added, "In the future, while I am running away from here, I will take lunch from your son, Shri Brajarayaji. Now bring Me back to My bed. Go right now so no one will see you."
                            <p />
                            She followed Navanita Priyaji's orders and put Navanita Priyaji back into His bed in the temple and returned home. Then Giridhariji entered the temple, awoke Navanita Priyaji and offered Him His breakfast.
                            <p />
                            So, years later, Shri Brajarayaji remembered the boon he received from Navanita Priyaji. He knew that Navanita Priyaji was coming to Go Ghat later that morning. Before Navanita Priyaji's arrival, he prepared His lunch and went to wait in the middle of the road. When he saw Navanita Priyaji's palanquin arrive, he told Vitthal Dube, "Navanita Priyaji is hungry, so I have prepared His lunch. Navanita Priyaji will go on to Agra after He has had His meal."
                            <p />
                            At that place, Navanita Priyaji partook of His lunch. When Navanita Priyaji had finished His lunch and the offering was taken away, then Shri Brajarayaji said, "I will perform my prayers by the Yamuna river. Dube, you stay with great attention by Navanita Priyaji."
                            <p />
                            Shri Brajarayaji then went to the Yamuna with his attendants. Then Dube gave Navanita Priyaji a betel leaf and washed His hands and mouth. He placed Navanita Priyaji in the palanquin and quickly proceeded to Agra. They reached Shri Nathji's temple in Agra three hours after sunset. There, Shri Govinda, Shri Bal Krishna, Shri Vallabhji, Shri Dauji and all their wives and sisters were very worried, but when they finally saw Navanita Priyaji, they became very pleased. They offered Navanita Priyaji His afternoon and supper offerings and then put Him to rest.
                            <p />
                            Afterwards, Shri Govindaji called Dubeji and said, "You have brought my all and everything, my Navanita Priyaji. Ask of me whatever you want. Then Dubeji said, "Maharaja, I wish that my lineage will never experience a break in the worship of Shri Nathji and Navanita Priyaji."
                            <p />
                            Shri Govindaji replied, "So it will be. As long as my lineage continues, Shri Nathji will never turn His back on your lineage."

                        </Col>
                    </Row>
                </div>
                )
        }

        if (page === 27) {
            return (
                <div>
                    <h5>SHRI GOVINDAJI'S WISH IS FOLLOWED BY THE EMPEROR'S MESSENGER AND, AFTER THE SECRET ANNAKUTA IS CELEBRATED, THEY MOVE ONWARDS</h5>
                    <Row>
                        <Col xs={11}>
                            Then Shri Govindaji told the emperor's messenger, "Until I celebrate the Annakuta festival here in Agra, don't inform the emperor of our presence."
                              <p />
                            That messenger, being Shri Govindaji's disciple, did not inform the emperor until after the Annakuta festival was celebrated. They celebrated Annakuta secretly. They offered rice and all other foods according to the festival calendar and very quietly worshipped the Govardhan Hill.


                        </Col>
                    </Row>
                    <br />
                    <h5>SHRINATHJI GOES TO DANDOTI GHAT </h5>
                    <Row>
                        <Col xs={11}>
                            After the Annakuta festival, Shri Nathji said, "Now I will go to Dandoti Ghat. Gangabai, make the preparations."
                              <p />
                            Gangabai said to Shri Govindaji, "Put Shri Nathji in the chariot."
                              <p />
                            Then Shri Govindaji put Shri Nathji in the chariot and proceeded towards Dandoti Ghat. They left after waving the Raja Bhoga arati. As they left, a Mughal was sitting by the door, but Shri Nathji made it so he could not see anything; he became blind. They arrived at their camp two hours before sunset and there Shri Nathji took His blissful rest.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE MESSENGER TELLS OF SHRI NATHJI'S COMING TO AGRA </h5>
                    <Row>
                        <Col xs={11}>
                            On the following day, the messenger gave the message to the emperor. He said, "Emperor, last night Shri Nathji, Who left the Govardhan Hill, came to Agra and stayed in a house here. This morning, Shri Nathji left and we don't know to where."
                             <p />
                            Hearing those words the emperor said, "How did you know that Shri Nathji came here?"
                             <p />
                            The messenger then explained, "By the house where Shri Nathji stayed, we found many used leaf plates and there was also a lot of water flowing in the gutters. From those signs we were able to tell that Shri Nathji had been there. You see, only the people from Gokul use so much water and leaf plates."
                             <p />
                            Hearing that, the emperor laughed and told the messenger, "Shri Nathji has been in Agra for many days. They left here three days ago. I knew from the day they came to Agra. I am not His enemy. I gave an order and now wherever He wants to play, let Him. Don't tell anyone what I said. If my religious heads hear about this, they will go after Shri Nathji." 
                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        if (page === 28) {
            return (
                <div>
                    <h5>MANY MUGHALS CHASE AFTER SHRINATHJI</h5>
                    <Row>
                        <Col xs={11}>
                            When the emperor asked to see a miracle and did not see one, his religious leader, the head "mulla", decided that they would destroy Shri Nathji's temple. He had a force of five hundred men and when he heard that Shri Nathji, Who lived on the Govardhan Hill, had gone to Dandoti Ghat, he took his forces with him and pursued Shri Nathji. At that time the emperor advised him, "Don't chase after Shri Nathji. He is very powerful. Shri Nathji left the Govardhan Hill because of His own wish. I did not make Him leave."
                            <p/>
                            But the religious leader did not listen to the emperor's advice and went out after Shri Nathji. During that night, Shri Nathji's chariot was on the other side of the Chambal River.
                            <p />
                            When only three hours of night remained, Shri Nathji's chariot suddenly stopped. Shri Govindaji inspired Ganga Bai to ask Shri Nathji, "Now, what is Your wish?"
                            <p />
                            Shri Nathji said, "Make My worship here. Today I will stay by the banks of the Chambal River."
                            <p />
                            A short while later, the Mughal army arrived on the other side of the river while Shri Govindaji was preparing for the afternoon Utthapan offering. Seeing the Mughals, he became concerned and said to Ganga Bai, "Tell Nathji that the Mughals are positioned on the other side of the river. What should I do about the Utthapan offering?"
                            <p />
                            When Ganga Bai asked Shri Nathji what to do, He replied, "Quickly make Utthapan. Why are you worried about those Mughals? If they come here, I will take care of them."
                            <p />
                            Then they blew the conch and without any concern started to make Shri Nathji's seva while the Mughals stood on the other side ofthe river. When they saw Shri Nathji's chariot, it appeared as a large mountain. Meanwhile, all the men with Shri Nathji appeared as large lions to them. They did not see a single man there. When the Mughals saw all those lions, they said to each other, "It looks like there are only lions over there. We don't see a single man."
                            <p />
                            When the Braja Vasis were speaking with each other, their voices seemed like the roar of many lions to the Mughals. Hearing lions, they said to each other,"We should quickly leave this place before those roaring lions come and devour us!"
                            <p />
                            When the Mughals saw the water boys and the pot cleaners come to the river to get water, they said to each other, "Those lions are coming to devour us. We must flee."
                            <p />
                            They all fled in such fear that some of them lost their clothes, while others stumbled over each other. Somehow they managed to get to Agra in one night. Later the "mulla" mentioned to the emperor, "That Shri Nathji is very powerful. We fled for our lives with great difficulty. From today onwards, I will not even speak His name."
                            <p />   
                            The emperor replied, "I told you before not to chase Shri Nathji. That God is full of miracles. Why did you attack Him?"

                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI TELLS GANGABAI THAT HE WANTS TO GO TO KRISHNAPUR </h5>
                    <Row>
                        <Col xs={11}>
                            On the following day, Shri Nathji told Gangabai, "Tell Shri Govindaji that I want to cross the Chambal River and go towards Dandoti Ghat."
                             <p />   
                            Beyond Dandoti Ghat is the town of Krishnapur. There Shri Nathji decided to stay.
                        </Col>
                    </Row>
                    <br />
                </div>
                )
        }
        if (page === 29) {
            return (
                <div>
                    <h5>SHRI GUSAINJI GIVES SAL KRISHNA A BOON</h5>
                    <Row>
                        <Col xs={11}>
                            While Shri Gusainji was alive, during Shri Krishna's appearance day festivities, Shri Gusainji's third son, Shri Bal Krishna, got dressed up as mother Yashoda before the deity of Child Krishna, Shri Navanita Priyaji, in Gokul. During the festivities he became immersed in bhava while he swung Navanita Priyaji in the cradle, and sang the following song:
                            <p/>
                            <Col xs={11} className="d-flex justify-content-center"> 
                                Mother Yashoda takes Krishna into her lap
                                And milk flows from her breasts.
                                You are blessed, mother of Krishna,
                                O queen of Braja.
                            </Col>
                            <p />
                            At that time, milk flowed from Shri Bal Krishna's breast. He then took Navanita Priyaji from the cradle into his lap. Seeing this, Shri Gusainji took hold of Navanita Priyaji and put Him back into the cradle. Shri Gusainji knew that Bal Krishna was filled with a great bhava and that mother Yashoda had entered into his son. He became very pleased and said to his son, "Ask of me whatever you desire. "
                              <p />
                            Bal Krishna requested, "I would like to have this bhava during every Janmashthami celebration. Also I would like to have Shri Nathji's seva for some time."
                              <p />
                            Shri Gusainji replied, "Granted. Every Janmashthami you will be filled with Yashoda, but your elder brother, Shri Giridhariji, has Shri Nathji's seva because Shri Nathji grabbed his hand. And in the future, when Shri Nathji goes to another land, your great grandson, Shri Brajarayaji, will make Shri Nathji's seva for twenty- seven days. On the twenty-eighth day, Shri Govindaji, from your older brother Shri Giridhariji's lineage, will take Shri Nathji's seva back from him." This was the boon that Shri Gusainji gave to Shri Bal Krishna.

                        </Col>
                    </Row>
                    <br />
                    <h5>FROM THE BOON, BRAJARAYAJI MAKES SEVA FOR TWENTY- SEVEN DAYS</h5>
                    <Row>
                        <Col xs={11}>
                            Bal Krishna's son was Pitamber, and his son was Shri Shyamlal, and his son was Brajarayaji. Brajarayiji often stayed with the emperor. One day the emperor became pleased with him and said, "Brajarayaji, ask of me whatever you desire. You have attended me a great deal and have been with me for four years." Brajarayaji requested, "Shri Nathji has left the Govardhan Hill and I desire to make His seva." The emperor replied, "No, Shri Nathji's seva should continue the way it has been. But you have pleased me very much and for that reason your wish will not go unanswered. Take my forces to where Shri Nathji is and you may stay there for one month in the service of Shri Nathji. But know that you will not be able to make Shri Nathji's seva for more than one month."
                            <p/>
                            Then Brajarayaji took some of the emperor's forces and came to Dandoti Ghat, where Shri Nathji was staying at Krishnapur.
                        </Col>
                    </Row>
                    <br />
                    <h5>SHRINATHJI TELLS GANGABAI ABOUT BRAJARAYAJI'S ARRIVAL AND BOON </h5>
                    <Row>
                        <Col xs={11}>
                            Knowing of Shri Brajarayaji's arrival, Shri Nathji told Gangabai, "Tell Shri Govindaji that he should leave and go to a town twenty miles from here with his entire family and followers. He will find a large house there. He should stay for one month. Brijarayaji is coming here with many men and has secured the emperor's order to make seva for twenty-seven days. This is all due to an old boon that Shri Gusainji gave to his forefather. On the twenty-eighth day, he will come and take the seva from Brijarayaji. Then Shri Govindaji will again perform My seva."

                        </Col>
                    </Row>
                    <br />
                    <h5>GANGABAI TELLS GOVINDAJI SHRINATHJI'S COMMAND</h5>
                    <Row>
                        <Col xs={11}>
                            Gangabai told Govindaji of Shri Nathji's wish, explaining that "Shri Nathji is able to do, not do, or do otherwise. Know that everything that happens is due to His wish. As Shri Gusainji experienced Shri Nathji's separation in the month of Ashar, so you have been awarded twenty-seven days of separation. You must act according to Shri Nathji's will."

                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 30) {
            return (
                <div>
                    <h5>SHRI GOVINDA'S TWENTY-SEVEN DAYS OF SEPARATION</h5>
                    <Row>
                        <Col xs={11}>
                            Upon hearing that, Shri Govindaji contemplated Shri Mahaprabhuji's teachings :
                            <p/>
                            <Col xs={11} className="d-flex justify-content-center">
                           Wisdom is knowing that everything happens<br/>
                                according to the wish of Hari. There is no use for<br /> 
                                prayer. There should never be any doubt regarding<br />
                            Krishna's intentions.</Col>
                            <p />
                            Shri Govindaji then considered, "So I should not pray to Shri Nathji. What power does Brajarayaji have that allows him to take Shri Nathji's seva from me? But it is Shri Gusainji's old boon that for twenty-seven days he will make the seva and on the twenty- eighth day I will come and take his place. Then once again I will serve Shri Nathji."
                             <p />
                            Then Shri Govindaji and his entire family and following stayed in a nearby town. Shri Nathji would go there daily and give Ganga Bai His darshan. The separation they felt was like the separation the Braja Bhaktas felt when Shri Krishna disappeared from them. They experienced great separation and searched for Krishna everywhere. They even asked the trees and creepers in the forest where their Beloved went. In a similar fashion, Shri Govindaji went from town to town trying to find his Shri Nathji.
                             <p />
                            Only a few water boys and pot cleaners stayed with Shri Nathji. Everyone else went with Shri Govindaji. During the time Brajarayaji made Shri Nathji's seva, Shri Govindaji abstained from all grains and lived on fruits. In the morning, he would dress up as a yogi and wear deer and tiger skins. He appeared like he was a forest recluse. He covered his body with ash. There was a tailor called Rorda who made Shri Nathji's clothes. He was a very good sarangi player and became Shri Govindaji's yogi disciple. He also dressed up like a renounced yogi. While the tailor played the wand they would wander about singing the following song in the raga Ashavari:
                             <p />
                            <Col xs={11} className="d-flex justify-content-center">
                                O friend, where can we find Krishna?<br />
                                He is the One Who lives in the forest.<br />
                                Now my heart feels like<br />
                                Wandering like a yogi.
                             </Col>
                            <p />
                            Both of them kept singing this song to the accompaniment of the sarangi. They did not know where Shri Nathji went and so they wandered from house to house saying, "We have lost a young Boy. Have you seen Him? If you have, please tell us."
                             <p />
                            So, in this extreme state of separation, Shri Govinndaji went searching for Shri Nathji for twenty-seven days, but no one was able to tell him where his Lord was. 

                        </Col>
                    </Row>
                </div>
                )
        }

        if (page === 31) {
            return (
                <div>
                    <h5>ON THE TWENTY-EIGHTH DAY GOVINDAJI REMOVES BRAJARAYAJI FROM SHRI NATHJI'S SEVA</h5>
                    <Row>
                        <Col xs={11}>
                            On the twenty-eighth day, Govindaji and Rorda, the tailor, both came to a lake by Krishnapur and sat down. At that time, Shri Nathji's lunch had been offered. Two water boys then came to that lake to wash some of Shri Nathji's rice pots and saw Shri Govindaji. They did not recognize him because he was dressed like a yogi. While they were washing the pots, they mentioned to each other, "In Shri Brajarayaji's entire family, there is no one strong enough to remove Shri Govinndaji from Shri Nathji's seva. Shri Vitthalraya has four sons and among them Shri Govindaji is very powerful. But at this time I don't know where he has gone. Brajarayaji had an army with him when he took over Shri Nathji's seva. If he did not have those forces, Shri Govindaji would have just grabbed hold of him and removed him."
                            <p/>
                            Overhearing their conversation, Shri Govindaji went to the water boys and asked them, "Tell me, do you know where Shri Nathji is? My name is Govindaji."
                            <p />
                            Having said that, he removed his yogi dress, bathed and put on a dhoti and a shawl. He put a dagger in his belt, covered it with his shawl and followed the water boys back to the temple. At that time the garland was called for in Shri Nathji's seva. Brajarayaji had filled Shri Nathji's water pitcher and washed His hands. The morning seva was about to be completed. Brajarayaji stood ready to wave Shri Nathji's arati lights when, suddenly, Shri Govindaji appeared. With one hand he took the knife from his belt and showed it to Brajaraya and said, "We have both arrived upon the great battle field of death. This will all occur in front of Shri Nathji. When it is over, someone else will have to wave Shri Nathji's arati. You have served Shri Nathji for many days. Now save yourself and leave. If you do not, I will stab you in the stomach and then I will stab myself I will not allow you to make any more seva. My son, Shri Dauji will make Shri Nathji's seva."
                            <p />
                            Shri Govindaji was a very powerful man and his threat frightened Shri Brajarayaji so much that he began to tremble. With eyes filled with tears and folded hands, he submitted, "Don't kill me. I will leave immediately. You should care for Shri Nathji."
                            <p />
                            Having said that, he quickly left and returned to Agra where he met the emperor. After he told him everything, the emperor said, "From this day forward do not return there."
                            <p />
                            Meanwhile, Shri Govindaji called his entire family back to Shri Nathji. His son Dauji and all the wives, daughters, and the rest of his family came to Shri Nathji and everyone touched His feet. Everyone present was very pleased. Shri Nathji, seeing his family, was also blissful. For all the days that Shri Brajarayaji made seva, Shri Nathji was not pleased. But on that day, Shri Govindaji, Shri Bal Krishnaji, Shri Vallabhji and Shri Dauji all gathered together and made Shri Nathji's ornamentation. On that day, Shri Nathji gave them all a divine darshan.
                        </Col>
                    </Row>
                    <br />
                    <h5>A DESCRIPTION OF HOW SHRI NATHJI WENT TO MEVAR </h5>
                    <Row>
                        <Col xs={11}>
                            While travelling to Mevar, Shri Nathji spent His first rainy season at Dandoti Ghat. Seeing the large houses there, Shri Nathji was very happy and thought, "Although this place is very nice, I will leave here."
                            <p/>
                            Shri Nathji then told Gangabai what He wanted to do and then He sat in the chariot and proceeded onwards. Shri Govindaji and his brothers were with Him. In the morning, one brother went ahead to set up the camp for the evening. He took the cook and the water boy with him and made everything ready for the Utthapan afternoon offering. Meanwhile, after Shri Govindaji made Shri Nathji's arati, they would all leave the camp and head out towards the next one. Shri Nathji arrived at the next camp about two hours before sunset and found everything ready there. Then they quickly made the afternoon and evening worship before putting Shri Nathji to rest. The next morning, after making Shri Nathji's breakfast, ornamentation and lunch offerings, everyone took their prasada before proceeding toward the next camp.
                             <p />
                            Meanwhile, Shri Vallabhji went to the next camp and made everything ready. The other two brothers went with Shri Nathji. Shri Govindaji rode on a horse in front of Shri Nathji's chariot while Shri Bal Krishnaji rode on a horse behind Shri Nathji. He would wear five weapons on his body as well as armor and appeared very valiant and exhilarated. If any king or noble person along the way asked about Shri Nathji and the chariot, Shri Govindaji said, "Shri Nathji lives forever in a cave on the Govardhan Hill. Our own personal items are stored in this chariot."
                             <p />
                            Govindaji did not allow anyone darshan of Shri Nathji. In 1669, in the month of Ashvin, on a Friday during full moon day, Shri Nathji left the Govardhan Hill He arrived in Sinhand in 1672 during the month of Phalgun, on the seventh day of the dark half of the month on a Saturday. There in Sinhand, Shri Nathji was established in His temple. It took Him two and a half years to make the trip in His chariot from Braja to Mevar. During that period of travel, Shri Vallabh prepared the vegetables and did the cooking with his own hands. He also ground the flour by hand. His brother, Bal Krishna, made the fried foods as well as the foods that were milk preparations with the help of the daughters and the other women of the family. Many cows travelled with Shri Nathji and provided Him with ample milk and curds.

                        </Col>
                    </Row>
                    
                </div>
                )
        }
        if (page === 32) {
            return (
                <div>
                    <h5>FROM DANDOTI GHAT SHRI NATHJI PROCEEDS TO KOTA-BUNDI</h5>
                    <Row>
                        <Col xs={11}>
                            From Dandoti Ghat, Shri Nathji proceeded to Kota-Bundi. Aniruddha Singh was the king there. He came for darshan and, knowing him to be a Vaishnava, Shri Govindiji allowed him the darshan of Shri Nathji. Then the king requested, "Ask Shri Nathji to remain in my kingdom. This is a very nice area and I have a force of five thousand warriors. If the Mughals attack, I will fight them."
                              <p />
                            Then Shri Govinda said, "You are a Vaishanva, so we will find a nice place and will stay here for some time. But when it is Shri Nathji's wish, we will leave. We will not always reside here because you do not have a very large kingdom."
                              <p />
                            In the kingdom of Kota at Krishnavilas, near a rock marked with a lotus, Shri Nathji stayed for the four months of the rainy season.


                        </Col>
                    </Row>
                    <br />
                    <h5>SHRI NATHJI LEAVES KOTA AND, ON THE WAY TO JODHPUR, PASSES PUSHKAR</h5>
                    <Row>
                        <Col xs={11}>
                            Shri Nathji stayed the rainy season at Kota and then proceeded to Jodhpur. They passed through Pushkar along the way. When they were close to the Pushkar Lake, Shri Nathji's chariot suddenly stopped. Then Govindaji told Ganga Bai, "Ask Shri Nathji why the chariot stopped. Discover His wish."
                              <p />
                            Then she went inside the chariot and after delighting over Shri Nathji's face, she asked Him, "Why did You stop the chariot?''
                              <p />
                            Shri Nathji replied, "There is a lake nearby that has blooming lotuses. I can smell them. Quickly bring some of those lotuses. Once they are brought here and I smell them, I will move on to wherever I desire."
                              <p />
                            Then a few Braja Vasis proceeded to the Pushkar Lake where they saw many red and white lotuses blooming. They put them into lotus leaves and quickly brought them to Shri Nathji and stood by the chariot. Then Shri Govindaji arranged for them to be offered to Shri Nathji. He explained, "Shri Nathji wanted these lotuses. He loves lotuses."
                              <p />
                            First Shri Bal Krishna and Shri Vallabhji offered some lotuses to Shri Nathji and then Shri Dauji was called for the offering. He was a young boy at that time. Then the women of the family came and offered them to Shri Nathji.

                        </Col>
                    </Row>
                    <br />
                    <h5>ON THE WAY TO JODHPUR FROM PUSHKAR SHRI NATHJI GOES TO KRISHNAGARH </h5>
                    <Row>
                        <Col xs={11}>
                            The king of Krishnagarh was Rup Singh. He was a disciple of Shri Vittheleshwarji. The king was killed in a battle he fought against the emperor. Before he left his body, he told his attendant to bring a diamond to Shri Nathji Who resided on the Govardhan Hill. After the king died, that attendant went to Shri Nathji's temple and offered the king's diamond. On that day, after the midday darshan, he came down from the Govardhan Hill. By the Dandavati rock he saw his deceased king wearing a yellow "pitambar" cloth around his neck and a saffron shawl. He was adorned with tilak marks. He seemed as bright as God. His worldly body was left on the battlefield while his divine body went to Shri Nathji's temple. Everyone saw him enter the temple, but no one saw him leave. Then everyone said, "King Rup Singh has truly entered Shri Nathji's lila."
                              <p />
                            When Shri Nathji arrived there, Rup Singh's son, Man Singh, was the ruling king of Krishnagarh. When he heard that Shri Nathji had arrived in his kingdom, he considered, "Shri Nathji is my family deity. I must not partake of any food or water until I have had Shri Nathji's darshan."
                              <p />
                            When he came for Shri Nathj i's darshan, Shri Nathji's chariot was in the nearby town of Ujad. A dense forest of Dhak trees stood close by. There was also a beautifull lake and a river flowed from a spring on a nearby mountain. In this beautiful place, Shri Nathji's chariot came to a halt. When Raja Man Singh came to see Shri Nathji, Shri Govindaji, knowing him to be a Vaishnava, allowed him Shri Nathji's darshan. The king then said, "If you display Shri Nathji's presence here, the Mughals will come to know. But if you stay here in secrecy, then I am ever ready to be in your service."
                              <p />
                            Then Shri Govindaji had Gangabai ask Shri Nathji what He felt about the situation and Shri Nathji replied, "This place is very beautiful there are many Dhak trees and Kesu flowers. I will spend the spring season here and will then move onwards. I will not live here."
                              <p />
                            Shri Nathji celebrated the Dol festival and stayed there for the entire spring season as well as for some of the hot season before proceeding on towards Mevar.

                        </Col>
                    </Row>
                   
                </div>
                )
        }
        if (page === 33) {
            return (
                <div>
                    <h5>WHILE GOING TO MEVAR, SHRI NATHJI GIVES A RENUNCIATE DARSHAN AT VISALPURA</h5>
                    <Row>
                        <Col xs={11}>
                            On the way from Braja to Jodhpur there is a town called Visalpura where a renunciate and his disciple lived. Once, when Shri Nathji resided on the Govardhan Hill, a guru and his disciple came to bathe in the Ganga. After bathing in the Ganga, they went to the Govardhan Hill. The guru climbed the Govardhan Hill while the disciple, who had read the Shrimad Bhagavata, remembered the following sloka:
                            <p />
                            <Col xs={11} className="d-flex justify-content-center">
                                Krishna then took on a huge form, amazed the cow
                                lads and said, "I am the Govardhan Hill." He then
                                ate the offerings.
                            </Col>
                            So the disciple thought, "The Govardhan Hill is spoken of in the Bhagavata in such an exalted manner. How can I place my foot on top of the sacred hill?"
                            <p />
                            While he was considering this, his guru returned from Shri Nathji's temple on top of the Govardhan Hill and praised the darshan he had of Shri Nathji saying, "Shri Nathji is very beautiful."
                            <p />
                            When the disciple heard about Shri Nathji's darshan from his guru, he went to the Govardhan Hill, but felt very uncomfortable about placing his foot on top of the hill. Yet, the desire to have Shri Nathji's darshan was lodged in his heart. He was very distressed and for three days he was undecided about going up to see Shri Nathji while he stayed by the Govardhan Hill with his guru. The disciple encircled the Govardhan Hill, but never did have the darshan of Shri Nathji. He was very upset about the situation. After some time, the guru passed away and the disciple became a renunciate guru and lived in Visalpura. Once, Shri Nathji appeared in his dream and told him, "I am the Deity you wanted to have the darshan of but did not because you did not want to climb the Govardhan Hill. Tomorrow I am coming around your town with My chariot. Come before My chariot and tell Shri Govindaji, 'Allow me to have darshan.' When he refuses you My darshan, then describe to him what I will be wearing. Tell him that I am adorned with a white turban and a white Pichhora dress and that Shri Nathji is definitely in the chariot and then add, 'Please, you must allow me darshan.' Then he will allow you My darshan. You should also make a table for Me. I will eat My meals on it. Bring it with you and place it before My chariot. I will daily eat My lunch on that table."
                            <p />
                            The following morning, the renunciate arose and called a carpenter and told him,"I have twenty-five buffaloes. You may take the best ofthe group but I need you to make a table right now."
                            <p />
                            Then the carpenter made a table in three hours and gave it to the renunciate. The renunciate then took the table with him and sat in the middle of the road. Towards the end of the afternoon, he saw Shri Nathji's chariot in the distance. He then went before the chariot and stood in the middle of the road and said, "When you allow me Shri Nathji's darshan I will get out of your way."
                            <p />
                            Everyone thought he must be some messenger sent from the emperor and that he was trying to trick them. Then Shri Govindaji replied, "Shri Nathji always resides within a cave on the Govardhan Hill. We are only carrying our personal items in this chariot."
                            <p />
                            Then the renunciate replied, "Last night, Shri Nathji appeared in my dream and told me to make a table for His lunch. So I have prepared it and brought it for Shri Nathji. Please take it and also allow me Shri Nathji"s darshan. He is now adorned with a white turban as well as a white Pichhora dress."
                            <p />
                            Hearing the renunciate's words, Shri Govindaji knew that he was an experienced bhakta and decided to allow him Shri Nathji's darshan. Then he told everyone, "Today we will make the afternoon Utthapan offering here."
                            <p />
                            The camp was made there. During the afternoon Utthapan darshan, the renunciate had the darshan of Shri Nathji. Shri Nathji stayed in that town that night and the next day. After the Raj Bhog arati was waved, Shri Nathji headed off on His journey towards Jodhpur. However, the table that the renunciate had made was left behind. They reasoned, "What use is that table now? We should not be held up by that renunciate's table. It was his desire that it be made for Shri Nathji and Shri Nathji has eaten off of it once. Let us just leave it here by the road and head onwards. The renunciate will take it."
                            <p />
                            Having said that, they left the table there and quickly headed onwards. Then the same renunciate came to the place where Shri Nathji had been and saw that Shri Nathji had gone and that His table was left behind. He became very sad and thought, "Shri Nathji told me in the dream to make Him a table and now He has not accepted it. What is the reason?"
                            <p />
                            He began to think about it as he picked up the table and brought it to his house and established it in a prominent place there. He sat down and became sullen. Meanwhile, Shri Nathji's chariot proceeded six miles from Visalpur and suddenly got stuck.
                            <p />
                            They tried many remedies to free the chariot, but Shri Nathji's chariot would not budge. Then Govindaji said to Gangabai, "Ask Shri Nathji why He stopped the chariot in this desolate place where there are no villages for miles and where there is not even any water or shade?"
                            <p />
                            Then Gangabai spoke to Shri Nathji, "Baliharilal, why did You stop the chariot? Why don't You move?"
                            <p />
                            Then Shri Nathji replied, "I am distressed about the table that I told that renunciate to make for Me. You left it where I had My lunch yesterday. I will only move forward when that table is brought here. I will daily take My lunch on that table."
                            <p />

                            After hearing what Shri Nathji told Gangabai, Shri Govindaji became very concerned and immediately sent two Braja Vasis on lovingiy. He himself adorned Shri Nathji and celebrated all the holidays and festivals with Shri Nathji.

                            </Col>
                    </Row>
                </div>
                )
        }

        if (page === 34) {
            return (
                <div>
                    <h5>THE EMPEROR HEARS THAT SHRI NATHJI IS IN MEVAR AND ATTACKS RANA SINGHJI</h5>
                    <Row>
                        <Col xs={11}>
                            Four years later, the emperor asked his messenger, "Where does that deity, Shri Nathji, Who moved from the Govardhan Hill, live now? Is Shri Nathji residing within my kingdom or somewhere else?"
                            <p/>
                            The messenger started to search for Shri Nathji in Marvad and Mevar. Wherever Shri Nathji had resided, the messenger visited. After discovering Shri Nathji's whereabouts, he returned to the emperor and said, "Shri Nathji lives in the kingdom of Ranaji in Udaipura. Ranaji lives standing with folded hands before Shri Nathji. He is under Shri Nathji's control."
                            <p />
                            Hearing that, the emperor said, "Shri Nathji is supposed to live in my kingdom. So, wherever He goes, that is also my kingdom all the way to the ocean. He has left my kingdom and has gone to Ranaji's kingdom. So, I will go to Rana's kingdom just to see."
                            <p />
                            Having said that, the emperor prepared himself and arrived in Mevar a few days later. Knowing of this, Rana Singhji sent his entire family away while the king took forty thousand troops and camped at Nahar. On that day the emperor made his camp at the Rayasagar.

                        </Col>
                    </Row>
                    <br />
                    <h5>THE EMPEROR'S FORCES CAMP AT RAYASAGAR WHILE RANAJI'S FORCES CAMP AT NAHAR AND SHRI NATHJI GOES TO BATARA </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Nathji told Gangabai, "Tell Shri Dauji about the town of Batara. It is by the Batara River and is a very beautiful place. Many types of flowering vines and trees grow there, like Kevar, Ketaki, Dambeli and Rayabela. I certainly want to see that place. In the nearby mountain there is a cave and in that cave lives a sage who has been doing austerities for thousands of years. He has a desire in his heart and prays, 'I hope Shri Krishna will appear before me in this cave. Then I will leave this place and will force my life breath to leave from the top of my head. Then I will ascend to the place where time has no reach.' So, for thousands of years, that sage has been sitting there waiting for My darshan, I want to meet him, so let us move towards that place, I will stay there for three days. Then I will return to My temple. The emperor will stay at Rayasagar until My return. Then he will move away."
                            <p />
                            Gangabai told all of this to Dauji. Dauji Maharaj was a powerful man. He immediately made the chariot ready and, after Shri Nathji sat down in the chariot, he headed out for Batara. Whenever the road became rough, due to Shri Nathji's wish, the path would suddenly become straight and easy. When the road became very rough, Dauji Maharaja would place quilts filled with cotton down before the chariot to ensure that Shri Nathji would not be rocked. In this way, Shri Nathji came to rest on that hill and, upon seeing the mountain, He was very pleased. Shri Nathji stayed there for three days. On the first day, the afternoon and evening offerings were completed there. On the following day, while Shri Nathji was staying there, the doors to the temple were left open during one of the offerings. It was at that time that the renounced sage came out from his cave in order to have Shri Nathji's darshan. Upon seeing Shri Nathji, he bowed and offered a garland of blue lotuses that are not found anywhere upon the earth, but only in the divine realm, a place to which the sage had access. He presented them to Shri Nathji. After he had prepared the garland, the sage considered, "When Shri Nathji comes here, I will adorn Him with this garland. I will also offer Shri Nathji a three pound piece of pure sandal wood."
                            <p />
                            The sage's sandal wood was very pure and so strong that if you were to pour a tiny amount of it into fifty kilos of hot water, the sandalwood would cool all of that boiling water. He offered that sandalwood to Shri Nathji and, after bowing down to Him, he left the mountain. The grace of God was upon him and the servants of Vishnu arrived for him. They put him in a celestial car and took him to Vaikuntha. Then Shri Nathji told Shri Dauji, "During the hot season, of§er Me a bowl of sandalpaste and always use the renunciate's piece of sandalwood."
                            <p />
                            It was presented to Shri Nathji for as long as it lasted. 

                        </Col>
                    </Row>
                </div>
                )
        }
        if (page === 35) {
            return (
                <div>
                    <h5>THE STORY OF THE EMPEROR'S TRIP IN MEVAR</h5>
                    <Row>
                        <Col xs={11}>
                            The emperor stayed at Rayasagar for one night and on the next day he made his camp by the Banas River at Khamnor. There he decided, "I will stay here for one month. A garden will be built here. After I see the garden completed, I will leave."
                            <p/>
                            After hearing of the emperor's plans, king Ranaji became afraid and told Shri Nathji, "Maharaj, the Mughals have come to my kingdom so I will appease them by offering them some towns."
                            <p />
                            That evening in Batara, Shri Nathji told Gangabai to tell Shri Dauji, "Tomorrow I will return to My Sinhad temple and have the afternoon offering of Utthapan there. That emperor will leave Khamnor tonight and will go to Udaipura."
                            <p />
                            At about nine o' clock that night, from Shri Nathji's temple in Sinhad, millions of very large black bees appeared and headed directly for Khamnor where the emperor's forces were. They stung all the men, horses and elephants. Hundreds of thousands of people were stung and suddenly everyone started to flee. The emperor had over one million forces with him at that time. Because they were afraid of getting stung, they fled and some forces became separated. The emperor had his queens with him and one of them was called Rangi Dhangi. There were always ten thousand soldiers with her and that evening she got separated from the emperor's other forces. As she wandered lost in Rajasthan, she ran into Ranaji's army on the Nahar road. At that time, Shri Ranaji Singhji knew that the emperor's queen was lost. He went to the queen and said, "You are my sister. Tell me where you want to go. I will personally escort you to the emperor."
                            <p />
                            Then the queen replied, "You are my brother in the dharma; take me directly to the emperor. Then the emperor will agree to quickly leave your kingdom."
                            <p />
                            Then Ranaji commanded his ten thousand warriors, "Bring her to the emperor."
                            <p />
                            At that time, Ranaji presented her with ten towns. Meanwhile, the emperor had gone to Udaipura and had made his camp by the lake there. When the people in the town saw his forces, they fled. By noon the next day, the emperor had not eaten any food and remarked, "I will only eat when my Rangi Dhangi returns."
                            <p />
                            At that moment, Rangi Dhangi came before him and told him all the news about Ranaji. "He has very graciously brought me here. I have made him my brother in the dhanna. You should no longer stay in his kingdom."
                            <p />
                            Then the emperor replied, "After I make a mosque in Udaipura, I will leave."
                            <p />
                            The queen then said, "No, you should leave tomorrow. I will tell my brother, King Rana, to make a mosque in your name."
                            <p />
                            Then the queen called Ranaji and had him meet the emperor. The emperor said to Ranaji, "You have taken good care of my queen and now you are her brother in the dhanna. Ask of me whatever you want. I am very pleased with you."
                            <p />
                            Then Shri Rana Singhji said,"If you are happy with me,then quickly withdraw your forces from my kingdom. My people have fled the city."
                            <p />
                            Then the emperor requested, "Make a mosque here in my name. I tried to make Shri Nathji, Who has left the Govardhan Hill and now resides here, live in my kingdom, but it was Shri Nathji's wish to stay here. Live by Shri Nathji's orders. As long as Shri Nathji lives in your kingdom in Mevar, I will not come here."
                            <p />
                            The next day, the forces were withdrawn and they proceeded towards Dwarka. Mevar became peaceful once again. Ranaji's entire family then returned to Udaipura. All the people who had fled from the villages and Udaipura also came back to their homes.
                            <p />
                            Meanwhile, after Shri Nathji enjoyed His Raj Bhog arati, He left Batara and returned to His temple in Sinhad where He was reestablished.

                        </Col>
                    </Row>
                    <br />
                    
                    <Row>
                        <Col xs={11} className="d-flex justify-content-center">
                            <h5>Chir Haran lila</h5>
                            Bhagavan<br/>
                            Knew very well <br />
                            Of the Gopi's intentions,<br />
                            That they are holders of the vow<br />
                            And longed to touch His feet.<br />
                            Shri Krishna, who is also Damodar <br />
                            Then addressed them<br />
                            The Abalas.
                         </Col>
                    </Row>
                </div>
                )
        }
        if (page === 36) {
            return (
                <div>
                    <h5>SUBODHINI</h5>
                    <h5>This is the lila order: </h5>
                    <Row>
                        <Col xs={11}>
                            Shri Krishna sees what is appropriate and then acts. The Gopi's true intention are now clearly before Him. Shri Krishna is called All Capable. He understands what is lila-appropiate and whatever He does becomes perfect. He undertands both the source and appearance of things and this allows Him to penetrate the situation.
                            <p/>
                            He knows that the Gopis did not come to Him for a worldly marriage or sensuous enjoyment, nor do they contradict the ways of the world or desire wisdom. Those blessed girls contemplated the situation from every angle and arrived there only for love. They confirm this by longing to touch His feet.
                            <p />
                            They desired, through bhakti yoga, only Krishna. They crave the devotion of touch and now have no interested in even hearing about Him. There is no longer any appeal to practice anything else, not even the devotion of being His friend. All they want is His personal touch, His contact.
                            <p />
                            It is not merely a longing, they also desire to employ their bodies and senses in the sacred act. They worshipped Katyayani Devi to attain Shri Krishna as their husbands and that vow is still in place. If they had completed their pledge, they would no longer be Holders of the Vow.
                            <p />
                            Their Krishna oath encompasses a constant engagement of their minds and hearts. All of their capacites are infused into exalted bhakti. When Shri Krishna sees this, He addresses them is a way that will remedy and perfect their adoration.
                            <p />
                            If it be asked, "Why is Krishna addressing the Gopis?" It is because He is Damodar. As Damodar He falls under the Gopis control as He fell under His mother's hold when she tied Him up after He feed curds to the monkeys." The Gopis know, "Now He is under our thumb."
                            <p />
                            Krishna address the Gopis as Abala, women who have no strength to resist Him. By calling them Abalas, He assures them that this is only about ras. Now they will fall under each others control As Damodar, Krishna is under their domain.
                            <p />
                            As Abalas, they are too weak to resist Him.
                            <Col xs={11} className="d-flex justify-content-center">
                                
                                <h5>BHAGAVATA 25 ½</h5><br/>
                                Virtuous Gopis<br/>
                                I know of your<br />
                                Desire to adore Me<br />
                                Through your worship.<br />
                                
                                It is worthy of<br />
                                Emanation
                                
                                
                            </Col>
                        </Col>
                    </Row>
                    <br/>
                    <h5>SUBODHINI </h5>
                    <Row>
                        <Col xs={11}>
                            The Gopis heart's desired to reveal their inner intentions. Shri Krishna understood this and before they could say anything, to accomplish all ends mentions, "I already know."
                            <p/>
                            There is a good reason for the Gopis not to speak directly of their desires. If they did, the loving ras would no longer be nurtured.
                             <p />
                            If you question the purity of their intentions and that is why Shri Krishna does not address them directly, look again. Shri Krsihna calls them "Sadhavis", extremely virtuous. The Gopis are pure-hearted and free of impurities. They are loyal to their husband, the amazing Shri Krishna.
                             <p />
                            Their motive to adore Him qualified them. Their divine desires will be fulfilled from the stamina of their devotional oath. The Gopis now have no use for anything anyone could offer them.
                             <p />
                            Krishna explains, "You have come to know Me. Even if you first worshipped Devi Katyayani, it was really just to attain Me. In your second round of worship, you adored Me directly.
                             <p />
                            I could question that real worship is offered to the awaken deva, the deva who responds and you Gopis did not even touch Me. Therefore, even though your worship was earnest it is make-believe. Also it could be questioned that worship done without the deva's permission bears no fruit. But none of this applies to you blessed Gopis. I am delighted with you, with your beings as well as with the reward you have choosen. I totally approve. Understanding your lila aspirations exhilarates Me; I am ecstatic."
                             <p />
                            Someone else continues to doubt the Gopis, "It is well know that the puja you offered to Shri Krishna with loving glances, flowers and other items of worship was useless because He never appeared to accept them." This doubt is erased with Shri Krishna's own words, "I approve of your puja and now perfect it by appearing before you and fulfilling your inner-most desires. Your loving glances and bhava are the complete and final offerings. Everything you have done up to this point is now perfectly poised. Your sequence of pujas have brought you to this devotional position."
                             <p />
                            <Col xs={11} className="d-flex justify-content-center">
                                When the form of desire,<br /> 
                                The inner intention<br />
                                As well as the puja is pure,<br />
                                Then the fruit is always<br />
                                True and worthy.
                            </Col>
                            <p />
                            It is proper that from truth the authentic reward arrives and now there is no doubt, the Gopis will receive it. This is the bhava. The Gopis pure puja actions are under their command and this insures that the reward is also under their control. Shri Krishna's addresses the Gopis to remove their concerns. 


                        </Col>
                    </Row>
                </div>
                )
        }

        if (page === 37) {
            return (
                <div>
                    <h5>TIPPANI</h5>
                    <h5>There are two types of wisdom.</h5>
                    <Row>
                        <Col xs={11}>
                            One is to know something's virtues, The other is to know the inner form.

                            When Shri Krishna refers to the "Gopis desires" in those two words He shows that they understand the virtues of their oath but have not yet grasped its inner form. That procedure is comprised of pure ras and can only be known by direct experience. It will come to them in a later lila.

                            When Shri Krsihna explains to them "Now I understand you." He is really speaking about their relationship of initmate love and said in a way to increase the intensity of the play. The Brahma Sutra says, "His lila only appears to be worldly". In fact everything Shri Krishna's does is perfectly divine. His words keep the exchange within the confines of the rules of ras. He also indicates that they will experience the complete form of their desires when they are blessed by direct contact with Him.
                            <Row>
                                <Col xs={11} className="d-flex justify-content-center">
                                    <b>"HE TOOK MY CLOTHS" BHAGAVAT 27 ½</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={11} className="d-flex  align-self-center">
                                    Gopis<br />
                                    Return to Braja.<br />
                                    Your vows<br />
                                    Are completed.<br />
                                    Virtuous ones<br />
                                    The purpose of your Katyayani worship,<br />
                                    The pledges you undertook have ripened.<br />
                                    Now you will dance with Me<br />
                                    During those autum nights<br />
                                    That I am showing you<br />
                                    Right now.     
                                </Col>
                            </Row>

                                                    
                               
                            </Col>
                       
                    </Row>
                    <br />
                    <h5>SUBODHINI </h5>
                    <Row>
                        <Col xs={11}>
                            "From now on dear Gopis wherever you are, you will never be touched by anything mundane."
                            <p/>
                                When Shri Krishna commands them to return home, His words reveal that they are now His own as well as His loving affection for them.
                            <p />
                              <Col xs={11} className="d-flex justify-content-center">
                               To wander about is <br/>
                                An activity of strength and  <br />
                                You are Abalas, now devoid of power. <br />
                                It is time to return. <br />
                            </Col>
                            <p />
                            Krishna begins, "O Gopis, the earth does not move. Only grass and other small particles upon it do. Now there is no other place you can go besides me."
                            <p />
                            If someone questions, "As the earth is prepared by plowing it, in a similar way, doesn't Shri Krishna now have to prepare the Gopis?"
                            <p />
                            To this Shri Krishna replied, "Of course not, you are perfected, you have attained, there is nothing left for you to do."
                            <p />
                            Because they are spiritually accomplished Shri Krishna tells them go home. Just before that, He allowed them the darshan of the Braja lila they would later enter as He said, "You will play with Me during those nights."
                            <p />
                            The moment He says "those nights" was when they received lila darshan of themselves by the banks of the Yamuna river dancing with the immortal Hari. The actual event did not occur until much later that year.
                            <p />
                            That divine night Always resides with Him. When Krishna says "During those nights you will make lila with Me" He reveals to those blessed Gopis a condition of extreme union. Now there can be no further doubts, they have had the darshan.
                            <p />
                            If you ask, why didn't Shri Krishna give them the reward of His eternal connection instead of a few nights of union? The answer is that they undertook the vow specifically for nights of dalliance. It was why they worshipped Katyayani Devi. Their adoration of Shri Krishna was pure and provided them an ultimate reward.
                            <p />
                            Krishna lila was the true gift of their month long Devi worship. Because their pujas concluded at the end of night, that event decided that their lila with Shri Krishna would also take place at night.

                        </Col>
                    </Row>
                    <br />
                    <h5>"Virtuous women"</h5>
                    <Row>
                        <Col xs={11}>
                            They don't not make dalliance during the day or whenever they desire. Their dalliance arises according to the decrees of marriage. If the Gopis came when He first called them, that reward would have been indestructible. The fruit of all other karmas is perishable.
                        </Col>
                    </Row>
                    <br />
                    <h5>TIPPANI </h5>
                    <Row>
                        <Col xs={11}>
                            Although he Gopis had the desire to go everywhere with Shri Krishna, that would not have nourished the ras and so Shri Krishna told them to return to Braja.
                            <p />
                            Like a plow goes to the earth, the earth does not go to the plow, so when necessary, Shri Krishna in the evening comes to Braja for a night of dalliance.
                            <p />
                            <h5>Jai Jai Sri Gokulesh</h5> 

                        </Col>
                    </Row>
                    
                </div>
                )
        }
    }
    handlePageClick = data => {
        this.setState({ activePage: data })
    }

    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="breadcrumb-link"><Link className="pr-1" to={process.env.PUBLIC_URL + "/Members_Section"}>Member Section</Link>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <span className="pl-2">SHRI NATHJI'S APPEARANCE STORY</span>
                </div> 
                <DefaultSectionHeader title="SHRI NATHJI'S APPEARANCE STORY" />
            <Row className="mx-0 ml-md-3">
                {this.showShriNathSection(this.state.activePage)}
                <Col className="pageStyle pagination-block">
                    <div className="pushtimarg-pagination pt-10">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={1}
                            totalItemsCount={37}
                            onChange={this.handlePageClick}
                            prevPageText="Previous"
                            nextPageText="Next"
                            itemClassPrev="pagination-previous"
                            itemClassNext="pagination-next"
                            itemClassFirst="pagination-firstPageText"
                            itemClassLast="pagination-lastPageText"
                            activeClass="pagination-active"
                        />
                    </div>
                 </Col>
                </Row>
            </div>
        )
    }
}
export default (withBreakpoints(ShriNath));