import React from 'react';
import { connect } from 'react-redux';
import Delete from '../../../images/delete.png';
import Approve from '../../../images/approve.png';
import Inactive from '../../../images/wait-approved.png';
import { Media } from 'reactstrap';
import { deleteMemberFromDirectory, approveMemberFromDirectory, activateDeactivateMember, setShowLayover } from '../../../actions/MemberDirectoryAction';
import { AjaxLoader } from '../../AjaxLoader';
import ReactTooltip from 'react-tooltip';
import Active from '../../../images/approved.png';
var dateFormat = require('dateformat');

class MemberDetailElement extends React.Component {
    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    delete = (item) => {
        let searchFilters = {
            City: this.props.city,
            Country: this.props.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            PageNumber: 0,
            EndDate: this.props.endDate,
            StartDate: this.props.startDate,
            IsNew: this.props.isNew === "Select" ? null : this.props.isNew,
            Approved: this.props.approve === "Select" ? null : this.props.approve,
            ID: item.id
        }
        this.props.deleteMemberFromDirectory(searchFilters);
    }

    ActivateDeactivateMember = (value, rowID) => {
        this.props.activateDeactivateMember(value, rowID);
    }

    approve = (item) => {
        let searchFilters = {
            City: this.props.city,
            Country: this.props.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            PageNumber: 0,
            EndDate: this.props.endDate,
            StartDate: this.props.startDate,
            Approved: this.props.approve === "Select" ? null : this.props.approve,
            ID: item.id,
            Email: item.email,
            PersonName: item.personName,
            IsNew: item.isNew
        };
        this.props.approveMemberFromDirectory(searchFilters);
    }

    setShowLayover = (showLayover) => {
        this.props.setShowLayover(showLayover);
    }

    render() {
        return (
            <div className="row single-member-detail position-relative no-gutters">
                <AjaxLoader isOpen={this.props.singleMemberLoader} />
                <div className={`edit-form-layover justify-content-center align-center ${this.props.showLayover ? 'd-flex' : 'd-none'} `} onClick={() => this.setShowLayover(false)}><span className="align-middle">{this.props.updateMessage}</span></div>
                <div className={`member-detail-edit`}>
                    <Media className={`member-detail-icon ${this.props.approve ? "d-none" : "d-inline-block"}`} src={Approve} alt="save" onClick={() => this.approve(this.props.member)} data-tip="Approve" />
                    <Media className={`member-detail-icon ${this.props.approve ? "d-none" : "d-inline-block"}`} src={Delete} onClick={() => this.delete(this.props.member)} alt="delete" data-tip="Delete" />
                    <Media className={`member-detail-icon ${!this.props.approve ? "d-none" : "d-inline-block"}`} src={this.props.member.isActive ? Inactive : Active} alt="ActivateDeactivateMember" onClick={() => this.ActivateDeactivateMember(this.props.member.isActive == 1 ? 0 : 1, this.props.member.rowId)} data-tip={this.props.member.isActive == 1 ? "Deactivate" : "Activate"} />
                    <ReactTooltip effect="solid" place="bottom" type="error" border={true} />
                </div>
                <div className="py-5 px-md-5 px-md-2 d-flex flex-wrap w-100">
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personName) ? "--" : this.props.member.personName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Father/Mother Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.fatherMotherName) ? "--" : this.props.member.fatherMotherName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Blood Group</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.bloodGroup) ? "--" : this.props.member.bloodGroup}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Profession</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.profession) ? "--" : this.props.member.profession}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">About Profession</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.description) ? "--" : this.props.member.description}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Experience</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.experience) ? "--" : this.props.member.experience}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Request Date</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.requestDate) ? "--" : dateFormat(this.props.member.requestDate, "isoDate")}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Approve Date</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.approveDate) ? "--" : dateFormat(this.props.member.approveDate, "isoDate") }</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current Country</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.countryName) ? "--" : this.props.member.countryName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native Country</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perCountryName) ? "--" : this.props.member.perCountryName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Name of Spouse</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.spouseName) ? "--" : this.props.member.spouseName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Caste</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.caste) ? "--" : this.props.member.caste}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Date Of Birth</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.ageDob) ? "--" : this.props.member.ageDob}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mobile</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.mobile) ? "--" : this.props.member.mobile}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Phone</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneRes) ? "--" : this.props.member.phoneRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Last Modified Date</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.approveDate) ? "--" : dateFormat(this.props.member.approveDate, "isoDate")}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current State</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stateName) ? "--" : this.props.member.stateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native State</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perStateName) ? "--" : this.props.member.perStateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">STD Code</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stdcode) ? "--" : this.props.member.stdcode}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Email</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.email) ? "--" : this.props.member.email}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4"> 
                            <div className="text-muted">Phone(Shop/Office)</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneShop) ? "--" : this.props.member.phoneShop}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Type Of Sewa</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.service) ? "--" : this.props.member.service}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Website</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.website) ? "--" : this.props.member.website}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Qualification</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.qualification) ? "--" : this.props.member.qualification}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current City</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.cityName) ? "--" : this.props.member.cityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native City</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perCityName) ? "--" : this.props.member.perCityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Address Shop/Office</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressShop) ? "--" : this.props.member.addressShop}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Native Address</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.perAddressRes) ? "--" : this.props.member.perAddressRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Current Address</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressRes) ? "--" : this.props.member.addressRes}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sortIncreasingOrder: state.MemberDirectoryReducer.sortIncreasingOrder,
        memberPerPage: state.MemberDirectoryReducer.memberPerPage,
        pageNumber: state.MemberDirectoryReducer.pageNumber,
        totalPageCount: state.MemberDirectoryReducer.totalPageCount,
        country: state.MemberDirectoryReducer.country,
        city: state.MemberDirectoryReducer.city,
        approve: state.MemberDirectoryReducer.approve,
        isNew: state.MemberDirectoryReducer.isNew,
        startDate: state.MemberDirectoryReducer.startDate,
        endDate: state.MemberDirectoryReducer.endDate,
        showLayover: state.MemberDirectoryReducer.showLayover,
        updateMessage: state.MemberDirectoryReducer.updateMessage,
        singleMemberLoader: state.MemberDirectoryReducer.singleMemberLoader
    };
};

const mapDispatchToProps = dispatch => ({
    deleteMemberFromDirectory: (searchFilters) => {
        dispatch(deleteMemberFromDirectory(searchFilters));
    },
    approveMemberFromDirectory: (searchFilters) => {
        dispatch(approveMemberFromDirectory(searchFilters));
    },
    setShowLayover: (showlayover) => {
        dispatch(setShowLayover(showlayover));
    },
    activateDeactivateMember: (value, rowID) => {
        dispatch(activateDeactivateMember(value, rowID));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetailElement);

