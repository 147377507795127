import React from 'react';
import { connect } from 'react-redux';
import { deletePendingMatrimonialFromDirectory, approveMemberFromDirectory, setShowLayover } from '../../../actions/MatrimonialAction';
import Delete from '../../../images/delete.png';
import Approve from '../../../images/approve.png';
import { Media } from 'reactstrap';
import { AjaxLoader } from '../../AjaxLoader';
import ReactTooltip from 'react-tooltip';
var dateFormat = require('dateformat');

class MatrimonialDetailElement extends React.Component {

    delete = (item) => {
        this.props.deleteMember(item);
    }

    approve = (item) => {
       
        let searchFilters = {
            City: this.props.city,
            Country: this.props.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            PageNumber: 0,
            EndDate: this.props.endDate,
            StartDate: this.props.startDate,
            IsNew: item.isNew,
            Approved: this.props.approve === "Select" ? null : this.props.approve,
            ID: item.id,
            PersonName: item.personName,
            Email: item.email
        };
        this.props.approveMemberFromDirectory(searchFilters);
    }

    setShowLayover = (showLayover) => {
        this.props.setShowLayover(showLayover);
    }

    isInvalidPropsValue = (vlaue) => {
        return (vlaue === undefined || vlaue === "" || vlaue === null);
    }

    render() {
        return (
            <div className="row single-member-detail position-relative no-gutters">
                <AjaxLoader isOpen={this.props.singleMemberLoader} />
                <div className={`edit-form-layover justify-content-center align-center ${this.props.showLayover ? 'd-flex' : 'd-none'} `} onClick={() => this.setShowLayover(false)}><span className="align-middle">{this.props.updateMessage}</span></div>
                <div className={`member-detail-edit`}>
                    <span data-tip="Approve" className={`member-detail-icon ${this.props.member.approve === undefined ? "d-none" : this.props.member.approve ? "d-none": "d-inline-block"}`}><Media src={Approve} alt="save" onClick={() => this.approve(this.props.member)} /></span>
                    <span data-tip="Delete" className={`member-detail-icon ${this.props.member.approve === true ? "d-none" : "d-inline-block"}`}><Media src={Delete} onClick={() => this.delete(this.props.member)} alt="delete" /></span>
                    <ReactTooltip effect="solid" place="bottom" type="error" border={true} />
                </div>
                <div className="py-5 px-md-2 px-xl-5 d-flex flex-wrap w-100">
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personName) ? "--" : this.props.member.personName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Gender</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.gender) ? "--" : this.props.member.gender}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Date Of Birth</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.agedob) ? "--" : this.props.member.agedob}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Father/Mother Name</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.fatherMotherName) ? "--" : this.props.member.fatherMotherName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Caste</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.caste) ? "--" : this.props.member.caste}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Address Residence</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.addressRes) ? "--" : this.props.member.addressRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Phone Residence</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.phoneRes) ? "--" : this.props.member.phoneRes}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Country</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.countryName) ? "--" : this.props.member.countryName}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">State</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stateName) ? "--" : this.props.member.stateName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mobile</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.mobile) ? "--" : this.props.member.mobile}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Stdcode</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.stdcode) ? "--" : this.props.member.stdcode}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Email</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.email) ? "--" : this.props.member.email}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Qualification</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.qualification) ? "--" : this.props.member.qualification}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Highest Degree</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.highestDegree) ? "--" : this.props.member.highestDegree}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Qualification</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherQualication) ? "--" : this.props.member.otherQualication}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Related Details</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherRelatedDetails) ? "--" : this.props.member.otherRelatedDetails}</div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">City</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.cityName) ? "--" : this.props.member.cityName}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Job Status</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.jobStatus) ? "--" : this.props.member.jobStatus}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Job Location</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.jobLocation) ? "--" : this.props.member.jobLocation}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Annual Income</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.anualIncome) ? "--" : this.props.member.anualIncome}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Other Job Details</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.otherJobDetails) ? "--" : this.props.member.otherJobDetails}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Marital Status</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.maritalStatus) ? "--" : this.props.member.maritalStatus}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Mother Tongue</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.motherTongue) ? "--" : this.props.member.motherTongue}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Height</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.height) ? "--" : this.props.member.height}</div>
                        </div>

                    </div>
                    <div className="col-12 col-md-3">
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Complexion</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.complexion) ? "--" : this.props.member.complexion}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Body Type</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.bodyType) ? "--" : this.props.member.bodyType}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Smoke</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.smoke) ? "--" : this.props.member.smoke}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Drink</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.drink) ? "--" : this.props.member.drink}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Personal values</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.personalValues) ? "--" : this.props.member.personalValues}</div>
                        </div>
                        <div className="d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Request Date</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.requestDate) ? "--" : dateFormat(this.props.member.requestDate, "isoDate")}</div>
                        </div>
                        <div className= "d-flex flex-row flex-md-column justify-content-between pb-4">
                            <div className="text-muted">Approve Date</div>
                            <div className="text-right text-md-left">{this.isInvalidPropsValue(this.props.member.approveDate) ? "--" : dateFormat(this.props.member.approveDate, "isoDate")}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sortIncreasingOrder: state.MatrimonialReducer.sortIncreasingOrder,
        memberPerPage: state.MatrimonialReducer.memberPerPage,
        pageNumber: state.MatrimonialReducer.pageNumber,
        totalPageCount: state.MatrimonialReducer.totalPageCount,
        address: state.AddressDetailReducer.address,
        country: state.MatrimonialReducer.country,
        city: state.MatrimonialReducer.city,
        approve: state.MatrimonialReducer.approve,
        isNew: state.MatrimonialReducer.isNew,
        startDate: state.MatrimonialReducer.startDate,
        endDate: state.MatrimonialReducer.endDate,
        showLayover: state.MatrimonialReducer.showLayover,
        updateMessage: state.MatrimonialReducer.updateMessage,
        singleMemberLoader: state.MatrimonialReducer.singleMemberLoader
    };
}

const mapDispatchToProps = dispatch => ({
    deleteMatrimonialFromDirectory: (searchFilters) => {
        dispatch(deletePendingMatrimonialFromDirectory(searchFilters));
    },
    approveMemberFromDirectory: (searchFilters) => {
        dispatch(approveMemberFromDirectory(searchFilters));
    },
    setShowLayover: (showlayover) => {
        dispatch(setShowLayover(showlayover));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialDetailElement);

