import axios from 'axios';

export const memberSectionPath = () => {
    return function (dispatch) {
        MemberSectionPath('/api/membersection/MemberSectionDirPath', dispatch);
    }
}

const MemberSectionPath = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const MemberSectionDirPath = response.data;
            dispatch(setPath(MemberSectionDirPath));

        })
    function get() { return { type: 'GET_MEMBERS_SECTION_DIR_PATH' }; }
};
function setPath(MemberSectionDirPath) { return { type: 'SET_MEMBERS_SECTION_DIR_PATH', MemberSectionDirPath }; }


export const chaturShlokiGranth = () => {
    return function (dispatch) {
        ChaturShlokiGranth('/api/membersection/ChaturShlokiSection', dispatch)
    }
}
const ChaturShlokiGranth = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const chaturShlokiSection = response.data;
            dispatch(setShlokiPath(chaturShlokiSection));

        })
    function get() { return { type: 'GET_CHATUR_SHLOKI_SECTION' }; }
};
function setShlokiPath(chaturShlokiSection) { return { type: 'SET_CHATUR_SHLOKI_SECTION', chaturShlokiSection }; }

