const initialState = {
    VanchanDirectoryName:[],
    BhajanDirectoryName: [],
    BhajanSubDirectoryName: [],
    VanchanSubDirectoryName:[],
    BhajanFilesList: [],
    VanchanAudioFilesList:[],
    isLoading: '',
    isPresent:''
}

const AudioDirectoryReducer = (state = initialState, action) => {
    
    switch (action.type) {
      
        case 'GET_AUDIO_DIRECTORY_NAME':
            return Object.assign({}, state, { isLoading: true });

        case 'SET_AUDIO_DIRECTORY_NAME':
            return Object.assign({}, state, { BhajanDirectoryName: action.directoryName, isLoading: false });

        case 'SET_AUDIO_SUB_DIRECTORY_NAME':
            return Object.assign({}, state, { BhajanSubDirectoryName: action.directoryName, isLoading: false });

        case 'SET_VANCHAN_AUDIO_DIRECTORY_NAME':
            return Object.assign({}, state, { VanchanDirectoryName: action.directoryName, isLoading: false });

        case 'SET_VANCHAN_SUB_AUDIO_DIRECTORY_NAME':
            return Object.assign({}, state, { VanchanSubDirectoryName: action.directoryName, isLoading: false });

        case 'GET_AUDIO_FILES_LIST':
            return Object.assign({}, state, { isLoading: true });

        case 'SET_AUDIO_FILES_LIST':
            return Object.assign({}, state, { BhajanFilesList: action.AudioFilesList.audioFiles, isLoading: false });

        case 'SET_VANCHAN_AUDIO_FILES_LIST':
            return Object.assign({}, state, { VanchanAudioFilesList: action.AudioFilesList.audioFiles, isLoading: false });

        case 'DIRECTORY_PRESENT':
            return Object.assign({}, state, { isPresent: action.isPresent, isLoading: false });

        default:
            return state;
            
    }

}

export default AudioDirectoryReducer;
