const initialState = {
    isAuthenticated: null,
    errorMessage: null,
    loading: false,
    loginType: "",
    filePathURL:""
}
const LoginReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return Object.assign({}, state, { errorMessage: null,loading: true });
        case 'SET_LOGIN_SUCCESS':
            return Object.assign({}, state, { isAuthenticated: true, errorMessage: null, loading: false, loginType: action.loginSuccess.loginType, filePathURL: action.loginSuccess.filePathURL});
        case 'SET_LOGIN_FAILURE':
            return Object.assign({}, state, { isAuthenticated: false, errorMessage: action.loginError.errorMessage, loading: false });
        case 'LOGOUT_REQUEST':
            return Object.assign({}, state, action.logout);
        case 'LOGOUT_SUCCESS':
            return Object.assign({}, state, { isAuthenticated: false, errorMessage: null });
        default:
            return state;
    }
}
export default LoginReducer;