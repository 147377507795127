
import React from 'react';
import { connect } from 'react-redux';
import { photoGalleryDirPath } from '../actions/PhotoGalleryAction';
import ImageDirectories from './ImagesComponent/ImageDirectories';
class PhotoGallery extends React.Component {

    componentDidMount() {
       this.props.photoGalleryDirPath();
    }

    showImageComponent = () => {
        if (this.props.PhotoGalleryDirPath != null && this.props.PhotoGalleryDirPath != '') {
            return (
                <ImageDirectories imageDirectoryPath={this.props.PhotoGalleryDirPath} SectionName={this.props.SectionName} />
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.showImageComponent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        PhotoGalleryDirPath: state.PhotoGalleryReducer.imagesDirPath,
        isLoading: state.PhotoGalleryReducer.isLoading,
        SectionName: state.PhotoGalleryReducer.section,
       
    };
}

const mapDispatchToProps = dispatch => ({
    photoGalleryDirPath: () => {
        dispatch(photoGalleryDirPath());
    },
 });

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery)