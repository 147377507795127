import ReactAudioPlayer from 'react-audio-player';
import React from 'react';

export default class AudioPlayer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            audioSongPath:'#'
        }
    }
    componentDidMount() {
        this.setFilePath();
    }
    setFilePath = () => {
        if (this.props.relativePath) {
            this.setState({ songPath: process.env.PUBLIC_URL + '/Media/' + this.props.relativePath })
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.relativePath !== prevProps.relativePath) {
            this.setState({ songPath: process.env.PUBLIC_URL + '/Media/' + this.props.relativePath })
        }
    }
   render() {
        return (
            <div className="mt-4 w-100">
              <ReactAudioPlayer className="w-100" src={this.state.songPath} autoPlay controls></ReactAudioPlayer>
            </div>
        )
    };
}




