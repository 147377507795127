import { sidebarActionType } from '../actions/ActionTypes';

const initialState = {
    isSidebarOpen: false   
};

const SidebarNavReducer = (state = initialState, action) => {
    switch (action.type) {       
        case sidebarActionType.OPEN_SIDEBARNAV:
            return Object.assign({}, state, { isSidebarOpen: action.isSidebarOpen });
        case sidebarActionType.CLOSE_SIDEBARNAV:
            return Object.assign({}, state, { isSidebarOpen: action.isSidebarOpen });       
        default:
            return state;
    }
};
export default SidebarNavReducer;