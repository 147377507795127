import React from 'react';
import NoItemFoundImage from '../images/image-blank.png';
export default class NoItemFound extends React.Component {
    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="no-item-image text-center py-5"><img src={NoItemFoundImage} alt="item not found" />
                    <p>No items found</p>
                </div>
            </div>
            )
    }
}

    