const initialState = {
    FileDetail: {
        name:'',
        path:'',
    },
    isLoading:'',
}

const UtsavListReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_UTSAV_FILE_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_UTSAV_FILE_NAME':
            return Object.assign({}, state, { FileDetail: action.payload, isLoading: false });
           
        default:
        return state;
    }

}

export default UtsavListReducer;