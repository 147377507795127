const initialState = {

    imageDetails: [],
    isLoading: '',
    imagesDirPath: '',
   section:''
    
}

const PhotoGalleryReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_IMAGE_GALLERY_DIR_PATH':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_IMAGE_GALLERY_DIR_PATH':
            return Object.assign({}, state, { imagesDirPath: action.ImageDirectoryPath.imageDirectoryPath, isLoading: false, section: action.ImageDirectoryPath.section });
        default:
            return state;
    }

}

export default PhotoGalleryReducer;