import React from 'react';
import ReactPlayer from 'react-player'

export default class VideoPlayer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            videoSongPath: '#',           
            errored: false
        }
    }
    componentDidMount() {
        this.setFilePath();
    }
    setFilePath = () => {
        if (this.props.relativePath) {
            this.setState({
                videoSongPath: process.env.PUBLIC_URL + '/Media/' + this.props.relativePath
            });
        }
    }
    componentDidUpdate(prevProps) {
       if (this.props.relativePath !== prevProps.relativePath) {
            this.setState({
                videoSongPath: process.env.PUBLIC_URL + '/Media/' + this.props.relativePath               
            });
        }
    }

   render() {
        
        return (
           <React.Fragment>
                <div className="videoplayer">
                    <ReactPlayer url={this.state.videoSongPath} 
                        playing
                     controls></ReactPlayer>
                </div>
                </React.Fragment>
            )      
    };
}






