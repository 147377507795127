import React from 'react';
import { Container } from 'reactstrap';
import Mailto from 'react-protected-mailto';

export default () =>
    <div className="footer">
        <Container fluid className="d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row py-2 content-section">
            <span className="text-primary pb-2 pb-md-0">Phone: <a href="callto:911417156800">91-141-7156800</a>, <a href="callto:911414921381">141-4921381</a></span>
            <span className="text-primary">E-mail: <Mailto email='anil@ramansujatisamaj.com' /></span>
        </Container>
    </div>
