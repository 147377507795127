const initialState = {

    PdfFilesList: [],
    isLoading:'',

}

const DocumentListReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_PDF_FILES_LIST':
            return Object.assign({}, state, { PdfFilesList: [], isLoading: true });

        case 'SET_PDF_FILES_LIST':
            return Object.assign({}, state, { PdfFilesList: action.DocumentFilesList, isLoading: false });

        case 'RESET_PDF_FILES_LIST':
            return Object.assign({}, state, { PdfFilesList: [], isLoading: false });

        default:
            return state;
            
    }

}

export default DocumentListReducer;
