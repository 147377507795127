import axios from 'axios';
import { ProcessStatus } from '../common/CommonEnums';
import _ from 'lodash';
const mailMemberUrl= "/api/mailmember/";
export const mailContent = (requestData) => {

    return function (dispatch) {
        dispatch(setContent(requestData));
    }


}

function setContent(mailDetails) { return { type: 'SET_MAIL_DETAILS', mailDetails }; }

export const getAllEmail = (requestData) => {

    return function (dispatch) {
        GetAllEmail('/api/mailmember/fetchemaillist', requestData, dispatch);
    };
}


const GetAllEmail = (url, requestData, dispatch) => {

    dispatch(get());
    axios.post(process.env.PUBLIC_URL + url, requestData)
        .then(function (response) {
            const emailList = response.data;
            dispatch(setPath(emailList));

        })
    function get() { return { type: 'GET_EMAIL_LIST' }; }
};
function setPath(emailList) { return { type: 'SET_EMAIL_LIST', emailList }; }

export const sendEmail = (requestData) => {
    return function (dispatch) {
        SendMail('/api/mailmember/sendmail', requestData, dispatch);
    };
}
const SendMail = (url, requestData, dispatch) => {

    dispatch(get());
    axios.post(process.env.PUBLIC_URL + url, requestData)
        .then(function (response) {
            const status = response.data;
            dispatch(setEmailStatus(status));

        })
    function get() { return { type: 'GET_EMAIL_STATUS' }; }
};
function setEmailStatus(status) { return { type: 'SET_EMAIL_STATUS', status }; }

export const uploadFile = (requestData) => {
    return function (dispatch) {
        UploadFile('/api/mailmember/uploadfile', requestData, dispatch);
    };
}
const UploadFile = (url, requestData) => {
    axios.post(process.env.PUBLIC_URL + url, requestData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
};
export const getMailStatus = (id) => {
    return function (dispatch,getState) {
        dispatch(get());
        axios.get(process.env.PUBLIC_URL + mailMemberUrl + 'FetchSentMailDetails', { params: { id } })
            .then(function (response) {
                const selectedMailDetail = response.data;
                const mailInfo = { ...getState().MailToMembersReducer }

                switch (selectedMailDetail.status) {
                    case ProcessStatus.Pending:
                        selectedMailDetail.status = "Pending";
                        break;
                    case ProcessStatus.InProgress:
                        selectedMailDetail.status = "In-progress";
                        break;
                    case ProcessStatus.Done:
                        selectedMailDetail.status = "Success";
                        break;
                    default:
                        selectedMailDetail.status = "";
                }
                let index = _.findIndex(mailInfo.sentEmailList, (e) => {
                    return e.id == selectedMailDetail.id;
                }, 0); 
                 const mailList = _.map(mailInfo.sentEmailList, (item, i) => {
                    return i === index ? selectedMailDetail : item;
                })
                dispatch(details(mailList));
            })
        function get() { return { type: 'GET_SENT_EMAIL_DETAILS' }; }
    }
};


export const viewSentEmails = () => {

    return function (dispatch) {
        ViewSentEmails('/api/mailmember/fetchsentemaildetails', dispatch);
    };
}
const ViewSentEmails = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const sentEmaildetails = response.data;
            if (sentEmaildetails.length > 0) {
                sentEmaildetails.forEach(function (item) {
                    switch (item.status) {
                        case ProcessStatus.Pending:
                            item.status = "Pending";
                            break;
                        case ProcessStatus.InProgress:
                            item.status = "In-progress";
                            break;
                        case ProcessStatus.Done:
                            item.status = "Success";
                            break;
                        default:
                            item.error = "";
                    }

                });
            }
            dispatch(details(sentEmaildetails));
        })
    function get() { return { type: 'GET_SENT_EMAIL_DETAILS' }; }
};
function details(sentEmaildetails) { return { type: 'SET_SENT_EMAIL_DETAILS', sentEmaildetails }; }









