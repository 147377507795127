import React from "react";
import { Media } from 'reactstrap';
import { SetCurrentPageNumber } from '../../actions/PushtimargDetailAction';
import { connect } from 'react-redux';

class PushtimargElement extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="d-none d-md-block col-md-4 col-lg-3 p-0 m-0" ><Media src={require("../../images/pushtimarg/collectionImages/" + this.props.img)} className="img-fluid" alt={this.props.alt} /> </div>
                <div className="col-md-8 col-lg-9 p-0 m-0 pl-md-4">
                    <h3 className="pb-2 text-dark">{this.props.header}</h3>
                    <div className="pushtimarg-content">
                        <Media className="d-block d-md-none pushtimarg-image img-fluid float-left" src={require("../../images/pushtimarg/collectionImages/" + this.props.img)} alt={this.props.header} />
                        <div className={` ${this.props.Id % 2 !== 0 ? 'pr-xl-3' : 'pr-0'} `}>
                            {this.props.content}
                        </div>
                    </div>
                    <div className="pt-3 pb-2"><button className="btn btn-primary btn-sm" onClick={() => this.props.SetCurrentPageNumber(this.props.id, true)}>Show more</button></div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    SetCurrentPageNumber: (id, showDetail) => {
        dispatch(SetCurrentPageNumber(id, showDetail));
    }
});

const mapStateToProps = (state) => {
    return {
        currentPageNumber: state.PushtimargDetailReducer.currentpageNumber
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushtimargElement)
