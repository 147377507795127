import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { AjaxLoader } from '../AjaxLoader';
import { MaryadiVashnavasFiles } from '../../actions/MaryadiVashanavAction';
import _reverse from 'lodash/reverse';
import CommonFunction from '../../common/CommonFunction';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';
import DefaultSectionHeader from '../../components/DefaultSectionHeader'

class MaryadiVashnavasLife extends Component {
    componentDidMount() {
        this.props.MaryadiVashnavasFiles();
   
    }

    showMaryadiVashnavasFiles = () => {
        if (this.props.maryadiVashnavasList.length > 0) {
            let  maryadiVashnavasFiles;
            var style = {
                display:'inline-block'
            };
           
            maryadiVashnavasFiles = this.props.maryadiVashnavasList.map((folder, index) => (
                <li className=" maryadiVashnavas-list-items" key={index}>
                    <div>{folder.directoryName}</div>
                    {
                        _reverse(folder.files).map((file, fileIndex) => (
                         
                            <React.Fragment key={fileIndex}>
                                <div className=" maryadiVashnavas-items-icons">

                                    <a href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/MaryadiVashnavasLife/' + folder.directoryName + "/" + file + ".pdf"} target='_blank' type="application/pdf" title="Preview-file">
                                        <span className="preview-icon"><img src={previewFileIcon} alt="preview" style={style} /></span>
                                    </a>
                                    <a onClick={() => { CommonFunction.downloadFile("MaryadiVashnavasLife", folder.directoryName, file) }} title="Download-icon" className="file-icon">
                                        <span className="download-icon"><img src={downloadFileIcon} alt="download" /></span>
                                    </a>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </li>
            ));

            return maryadiVashnavasFiles;
        }
    }

    render() {
                
            return (
                <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                    <DefaultSectionHeader title="Life of Maryadi Vashnavas" />  
                    <AjaxLoader isOpen={this.props.isLoading} />
                    
                    {
                        this.props.maryadiVashnavasList != null ? <div><React.Fragment>
                            <div className="maryadiVashnavas-text">"We are starting this section to collect valuable information on life of our respected Maryadi Vaishnavas. Every member is
    requested to send details of the life of any maryadi Vaishnava, if they have, to upload here with picture, if available."</div>
                            <Row>
                                <Col md="4" className="">
                                    <div className="maryadiVashnavas-list">
                                        <ul>
                                            <li className="maryadiVashnavas-title">
                                                <div><strong>Maryadi Vashnavas</strong></div>
                                            </li>
                                            {this.showMaryadiVashnavasFiles()}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment> </div> : null
                    }
                    
                </div>
            );
        
       
    }
}


const mapStateToProps = (state) => {
    return {
        
            maryadiVashnavasList: state.MaryadiVashnavasReducer.FileDetail,
        isLoading: state.MaryadiVashnavasReducer.isLoading,
        filePathURL: state.LoginReducer.filePathURL
    };

}

const mapDispatchToProps = dispatch => ({
    MaryadiVashnavasFiles: () => {
        dispatch(MaryadiVashnavasFiles());
    }
  
});
export default connect(mapStateToProps, mapDispatchToProps)(MaryadiVashnavasLife);


