const initialState = {

    ImageGalleryDirectoryName: [],
    MemberSectionImageDirectoryName: [],
    MemberSectionImageFilesName: [],
    ImageGalleryFilesName:[],
    images: [],
    selectedFolderPath: '',
    isLoading:'',
}

const ImageReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_IMAGE_DIRECTORY_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_IMAGE_DIRECTORY_NAME':
            return Object.assign({}, state, { ImageGalleryDirectoryName: action.directoryName, isLoading: false });
        case 'SET_MEMBER_SECTION_IMAGE_DIRECTORY_NAME':
            return Object.assign({}, state, { MemberSectionImageDirectoryName: action.directoryName, isLoading: false });
        case 'GET_IMAGE_FILES':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_IMAGE_FILES':
            return Object.assign({}, state, { ImageGalleryFilesName: action.images, selectedFolderPath: action.selectedFolderPath, isLoading: false });
        case 'SET_MEMBER_SECTION_IMAGE_FILES':
            return Object.assign({}, state, { MemberSectionImageFilesName: action.images, selectedFolderPath: action.selectedFolderPath, isLoading: false });
        case 'RESET_FOLDER_IMAGES':
            return Object.assign({}, state, { images: [], selectedFolderPath: [], isLoading: false, directoryName:[] });
        default:
            return state;

    }

}

export default ImageReducer;
