import React, { Component } from "react";
import { connect } from 'react-redux';
import { chaturShlokiGranth } from '../../actions/MembersSectionAction';
import ImageDirectories from "../ImagesComponent/ImageDirectories";
import { resetImageFiles } from '../../actions/ImageAction';
import DefaultSectionHeader from '../../components/DefaultSectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
class ChaturShlokiGranth extends Component {
    componentWillMount() {
        this.props.resetImageFiles();
    }

    componentDidMount() {
        this.props.chaturShlokiGranth();
    }

    showDocumentComponent = () => {
        if (this.props.SectionName !== null && this.props.SectionName !== '') {
            this.props.history.push({
                pathname: process.env.PUBLIC_URL + '/DocumentList', search: `${this.props.SectionName}`
            });
        }
    }

    showImageComponent = () => {
        
        if (this.props.ChaturShlokiImagePath !== null && this.props.ChaturShlokiImagePath !== '') {
            return (
                <ImageDirectories  imageDirectoryPath={this.props.ChaturShlokiImagePath} SectionName="ChaturShlokiGranth" />
            )
        }
    }

    render() {
        return (
            <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div>
                    <div>
                        <Link className="pr-1" to={process.env.PUBLIC_URL + "/Members_Section"}>Members Section</Link>
                        <FontAwesomeIcon icon={faChevronRight} />
                        <span className="pl-1">Chatur Shloki Granth</span>
                    </div> 
                    <DefaultSectionHeader title="Chatur Shloki Granth" />
                    {this.showImageComponent()}
                   
                </div>

            </div>
            )
    }
}

const mapStateToProps = (state) => {
    
    return {
        SectionName: state.MembersSectionReducer.ChaturShlokiSection,
        ChaturShlokiImagePath: state.MembersSectionReducer.ChaturShlokiImagePath,
    };
}

const mapDispatchToProps = dispatch => ({
    chaturShlokiGranth: () => {
        dispatch(chaturShlokiGranth());
    },
    resetImageFiles: () => {
        dispatch(resetImageFiles());
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ChaturShlokiGranth);