import axios from 'axios';

export const SetMembersNationalityType = (isIndian, isUS, isUK, isOther) => {
    return function (dispatch) {
        dispatch(SetNationalityType(isIndian, isUS, isUK, isOther));
    }
};

export const SetNationalityType = (isIndian, isUS, isUK, isOther) => {
    return {
        type: "SET_MEMBER_NATIONALITY_TYPE", isIndian, isUS, isUK, isOther
    };
};

export const setSortProperty = (isIncreasingOrder) => {
    return function (dispatch) {
        dispatch(setSortPropertyOfMemberDetails(isIncreasingOrder));
    }
};

export const setSortPropertyOfMemberDetails = (isIncreasingOrder) => {
    return {
        type: "SET_SORT_PROPERTY", isIncreasingOrder
    };
};

export const setMemberPerPage = (memberPerPage) => {
    return function (dispatch) {
        dispatch(setMemberPerPageReducer(memberPerPage));
    }
};

export const setMemberPerPageReducer = (memberPerPage) => {
    return {
        type: "SET_PER_PAGE_COUNT", memberPerPage
    };
};


export const setApproveStatus = (status) => {
    return function (dispatch) {
        dispatch(setApproveStatusReducer(status));
    }
};

export const setApproveStatusReducer = (status) => {
    return {
        type: "SET_APPROVE_STATUS", status
    };
};

export const searchMemberDetails = (memberSearchFilters) => {
    return function (dispatch) {
        searchMemberDetailsWithFilters('/api/memberdirectory/searchMemberDetails', memberSearchFilters, dispatch)
    };
}

const searchMemberDetailsWithFilters = (url, memberSearchFilters, dispatch) => {
    dispatch(setSearchInProgress());
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            const message = response.data;
            dispatch(setmemberDetails(message));

        })
}

const setmemberDetails = (message) => {
    return {
        type: 'SET_MEMBER_DETAILS',
        payload: message
    };
};

export const searchMemberDetailsForAdmin = (memberSearchFilters) => {
    return function (dispatch) {
        searchMemberDetailsWithFiltersForAdmin('/api/memberdirectory/SearchMemberDetailsForAdmin', memberSearchFilters, dispatch)
    };
}

export const searchApprovedMemberDetailsForAdmin = (memberSearchFilters) => {
    return function (dispatch) {
        searchApprovedMemberDetailsWithFiltersForAdmin('/api/memberdirectory/SearchApprovedMemberDetailsForAdmin', memberSearchFilters, dispatch)
    };
}

const searchMemberDetailsWithFiltersForAdmin = (url, memberSearchFilters, dispatch) => {
    dispatch(setSearchInProgress());
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            const message = response.data;
            dispatch(setmemberDetailsForAdmin(message));
        })
}

const searchApprovedMemberDetailsWithFiltersForAdmin = (url, memberSearchFilters, dispatch) => {
    dispatch(setSearchInProgress());
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            const message = response.data;
            dispatch(setApprovedMemberDetailsForAdmin(message));
        })
}

const setmemberDetailsForAdmin = (message) => {
    return {
        type: 'SET_MEMBER_DETAILS_ADMIN',
        payload: message
    };
};

const setApprovedMemberDetailsForAdmin = (message) => {
    return {
        type: 'SET_APPROVED_MEMBER_DETAILS_ADMIN',
        payload: message
    };
};

export const deleteMemberFromDirectory = (memberSearchFilters) => {
    return function (dispatch) {
        deleteMember('/api/memberdirectory/deleteMember', memberSearchFilters, dispatch)
    };
}

export const deleteMemberForAdmin = (id) => {
    return {
        type: 'DELETE_MEMBER', id
    };
};
export const approveMemberForAdmin = (id) => {
    return {
        type: 'APPROVE_MEMBER', id
    };
};

export const updateActivatedDeactivatedMember = (status, rowID) => {
    return {
        type: 'ACTIVE_INACTIVE_MEMBER', status, rowID
    };
};

const deleteMember = (url, memberSearchFilters, dispatch) => {
    dispatch(setSingleMemberLoader(true));
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            if (response.data) {
                dispatch(deleteMemberForAdmin(memberSearchFilters.ID));
            }
            else {
                dispatch(setSingleMemberLoader(false));
                dispatch(setShowLayover(true));
                dispatch(setUpdateMessage("Some error occured"));
            }
        })
}


export const approveMemberFromDirectory = (memberSearchFilters) => {
    return function (dispatch) {
        approveMember('/api/memberdirectory/approveMember', memberSearchFilters, dispatch)
    };
}

const approveMember = (url, memberSearchFilters, dispatch) => {
    dispatch(setSingleMemberLoader(true));
    axios.post(process.env.PUBLIC_URL + url, memberSearchFilters)
        .then(function (response) {
            if (response.data) {
                dispatch(approveMemberForAdmin(memberSearchFilters.ID));
            }
            else {
                dispatch(setSingleMemberLoader(false));
                dispatch(setShowLayover(true));
                dispatch(setUpdateMessage("Some error occured"));
            }
        });
}


export const setpageNumber = (pageNumber) => {
    return function (dispatch) {
        dispatch(setpageNumbrReducer(pageNumber));
    }
};

export const setpageNumbrReducer = (pageNumber) => {
    return {
        type: "SET_PAGE_NUMBER", pageNumber
    };
};

export const updateMemberDetails = (registerDetails) => {
    
    return function (dispatch) {
        dispatch(setSingleMemberLoader(true));
        updateMemberDetailsReducer('/api/memberdirectory/UpdateDetail', registerDetails, dispatch)
    };
}

const updateMemberDetailsReducer = (url, registerDetails, dispatch) => {
    axios.post(process.env.PUBLIC_URL + url, registerDetails)
        .then(function (response) {
            dispatch(setSingleMemberLoader(false));
            dispatch(setShowLayover(true));
            dispatch(setUpdateMessage(response.data.message));
        })

}

export const activateDeactivateMember = (status,rowID) => {

    return function (dispatch) {
        dispatch(setSingleMemberLoader(true));
        activateDeactivateMemberReducer('/api/memberdirectory/ActivateDeactivateMember', status, rowID, dispatch)
    };
}

const activateDeactivateMemberReducer = (url, status, rowID, dispatch) => {
    axios.get(process.env.PUBLIC_URL + url, {
        params: {
            "status": status,
            "rowID": rowID
        }
    })
        .then(function (response) {
            if (response.data) {
                dispatch(updateActivatedDeactivatedMember(status, rowID));
            }
            else {
                dispatch(setSingleMemberLoader(false));
                dispatch(setShowLayover(true));
                dispatch(setUpdateMessage("Some error occured"));
            }
        })

}


export const getEditedMember = (rowId) => {
    return function (dispatch) {
        setEditedmemberInReducer('/api/memberdirectory/getEditedMember', rowId, dispatch)
    };
}

const setEditedmemberInReducer = (url, rowId, dispatch) => {
    dispatch(setSingleMemberLoader(true));
    axios.get(process.env.PUBLIC_URL + url, {
        params: {
            "rowId": rowId
        }
    })
        .then(function (response) {
            dispatch(setEditedMember(response.data));
        })
}

const setEditedMember = (message) => {
    return {
        type: 'SET_EDITED_MEMBER',
        payload: message
    };
};

export const setSearchInProgress = () => {
    return {
        type: "SET_SEARCH_PROGRESS"
    };
}

export const setSingleMemberLoader = (isLoading) => {
    return {
        type: "SET_SINGLE_MEMBER_LOADER", isLoading
    };
}

export const setShowLayover = (showLayover) => {
    return {
        type: "SET_SHOW_LAYOVER", showLayover
    };
}


export const setCountryStatesLoader = (isLoading) => {
    return {
        type: "SET_COUNTRY_CITY_LOADER", isLoading
    };
}

export const setUpdateMessage = (message) => {
    return {
        type: "SET_UPDATE_MESSAGE", message
    };
}

export const setAdminFilters = (country, city, isNew, startDate, endDate, sortIncreasingOrder) => {

    return {
        type: "SET_ADMIN_FILTERS", country, city, isNew, startDate, endDate, sortIncreasingOrder
    };
}

export const setAdminFiltersApproveMembers = (country, city, sortByApprovalDate, status) => {

    return {
        type: "SET_ADMIN_FILTERS_APPROVED_MEMBER", country, city, sortByApprovalDate, status
    };
}

export const getCityList = (nationality) => {
    return function (dispatch) {
        axios.post(process.env.PUBLIC_URL + '/api/memberdirectory/getCityList', nationality)
            .then(function (response) {
                const message = response.data;
                dispatch(setCityList(message));
            });
    };
}

export const getCityListForAdmin = (countryName) => {
    
    return function (dispatch) {
        axios.get(process.env.PUBLIC_URL + '/api/memberdirectory/getCityListForAdmin', { params: { countryName } })
            .then(function (response) {
                const message = response.data;
                dispatch(setCityList(message));
            });
    };
}

const setCityList = (message) => {
    return {
        type: 'SET_CITY_LIST',
        payload: message
    };
};

export const resetFilters = () => {
    return {
        type: 'RESET_FILTERS',
    };
};
