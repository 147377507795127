import React from 'react';
import { connect } from 'react-redux';
import { videoGalleryDirPath } from '../actions/VideoGalleryAction';
import VideoDirectory from './VideoComponent/VideoDirectory';

class VideoGalleryComponent extends React.Component {

    componentDidMount() {
        this.props.videoGalleryDirPath();
    }

    showVideoComponent = () => {
        if (this.props.VideoGalleryDirPath != null && this.props.VideoGalleryDirPath != '') {
            return (
                <VideoDirectory VideoDirectoryPath={this.props.VideoGalleryDirPath} SectionName={this.props.SectionName} />
            )
        }
    }

    render() {

        return (
            <React.Fragment>
                {this.showVideoComponent()}
                </React.Fragment>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        VideoGalleryDirPath: state.VideoGalleryReducer.VideoGalleryDirPath,
        isLoading: state.VideoGalleryReducer.isLoading,
        SectionName: state.VideoGalleryReducer.SectionName,
    };

}

const mapDispatchToProps = dispatch => ({
    videoGalleryDirPath: () => {
        dispatch(videoGalleryDirPath());
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(VideoGalleryComponent);



