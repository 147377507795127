const initialState = {
    UtsavFiles: {
        fileName: '',
        path: '',
        directoryName:''
    }
}

const UtsavListYearWiseReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_UTSAV_YEAR_WISE':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_UTSAV_YEAR_WISE':
            return Object.assign({}, state, { UtsavFiles: action.payload, isLoading: false });

        default:
            return state;
    }

}

export default UtsavListYearWiseReducer;