import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { documentDirectoryName } from '../../actions/DocumentDirectoryAction';
import { AjaxLoader } from '../AjaxLoader';
import Pagination from "react-js-pagination";
import { withBreakpoints } from 'react-breakpoints';
import CommonFunction from '../../common/CommonFunction';
import NoItemFound from '../NoItemFoundPage';
class DocumentDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            filteredList: [],
            activePage: 1,
            folderPerPage: 12,
        };
    }
    componentDidMount() {
        if (this.props.DocumentDirectoryPath !== null) {
            this.props.documentDirectoryName(this.props.DocumentDirectoryPath);
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.DocumentDirectoryName !== prevProps.DocumentDirectoryName || this.props.search !== prevProps.search) {
            if (this.props.search === "") {
                this.setState({ filteredList: this.props.DocumentDirectoryName });
            }
            else {
                this.setState({ filteredList: this.props.DocumentDirectoryName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
            }
        }
    }


    render() {
      
        const { filteredList, activePage, folderPerPage } = this.state;
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        let ListofFolders;
        if (isMobile)
            ListofFolders = filteredList;
            else
         ListofFolders = CommonFunction.getFolderList(filteredList, activePage, folderPerPage);
        let pageNumber = CommonFunction.getPageCount(filteredList, folderPerPage);
        if (ListofFolders !== undefined && ListofFolders !== null) {
            return (
                <React.Fragment>
                    <AjaxLoader isOpen={this.props.isLoading} />
                    <Row>
                        {
                            ListofFolders.map(name => {
                                return (
                                    <Col xl="2" md="4" sm="3" xs="6" className="mt-4 mt-md-5 directorylist" key={name}>
                                        <Link to={{ pathname: process.env.PUBLIC_URL + "/Documents", search: `${this.props.SectionName + '/' + name}` }}>
                                            <div className="directory-type pdf-directory" />
                                            <div className="directory-title text-black border border-top-0 border-primary rounded-bottom bg-white d-flex align-items-center justify-content-center px-2">{name}</div>
                                        </Link>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                    {isMobile || pageNumber === 1 ? "" :

                        <div className="pushtimarg-pagination pt-10">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={1}
                                totalItemsCount={pageNumber}
                                onChange={this.handlePageClick}
                                prevPageText="Previous"
                                nextPageText="Next"
                                itemClassPrev="pagination-previous"
                                itemClassNext="pagination-next"
                                itemClassFirst="pagination-firstPageText"
                                itemClassLast="pagination-lastPageText"
                                activeClass="pagination-active"
                            />
                        </div>

                    }
                </React.Fragment>
            )
        }
        else {
            if (!this.props.isLoading) {
                return (<NoItemFound />)
            }
            else {
                return (<AjaxLoader isOpen={this.props.isLoading} />);
            }
        }
    };
}

const mapStateToProps = (State) => {
    return {
        DocumentDirectoryName: State.DocumentDirectoryReducer.documentDirectoryName,
        isLoading: State.DocumentDirectoryReducer.isLoading,
        search: State.SearchReducer.search
    };
};

const mapDispatchToProps = dispatch => ({
    documentDirectoryName: (DocumentFolderPath) => {
        dispatch(documentDirectoryName(DocumentFolderPath));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(DocumentDirectory));
