import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormFeedback, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { addressDetail } from '../../../actions/AddressDetailsAction';
import { withRouter } from 'react-router';
import { mailContent, getAllEmail, sendEmail, uploadFile } from '../../../actions/MailToMembersAction';
import { If } from 'rc-if-else';
import { Link } from 'react-router-dom';
import { CountryType } from '../../../common/CommonEnums'
import _ from 'lodash';

class MailToMembers extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            cityName: '',
            countryName: '--All--',
            invalidCityName: '',
            invalidCountryName: '',
            subject: '',
            message: '',
            invalidSubject: '',
            invalidMessage: '',
            from: 'anil@ramansujatisamaj.com',
            showMailPreview: false,
            to: '',
            modal: false,
            showEmailList: '',
            file: null,
            attachImage : [],
            attachFile: []
         
        }
    }
    componentDidMount() {
        this.props.addressDetail();
        let requestData = {
            CountryName: this.state.countryName,
            CityName: this.state.cityName
        };
        this.props.getAllEmail(requestData);
    }
    handleChange = (type, event) => {
        switch (type) {
            case "subject":
                this.setState({ invalidSubject: event.target.value === "", [type]: event.target.value });
                break;
            case "message":
                this.setState({ invalidMessage: event.target.value === "", [type]: event.target.value });
                break;
            default:
                this.setState({ [type]: event.target.value });
                break;
        }
    }
    handleCountryName = (event) => {
       
        this.setState({ invalidCountryName: event.target.value === "" });
        this.setState({ countryName: event.target.value, cityName: "" });

        let requestData = {
            CountryName: event.target.value,
            CityName: ""
        };
        this.props.getAllEmail(requestData);
    }
    handleCityName = (event) => {
       
        this.setState({ invalidCityName: event.target.value === "", cityName: event.target.value });
        let requestData = {
            CountryName: this.state.countryName,
            CityName: event.target.value
        };
        this.props.getAllEmail(requestData);
    }
    handleClick = () => {
        this.setState({ showMailPreview: true });
    }
    createCountryList = () => {
        return this.props.address.country.sort((a, b) => a.countryName.toString().toLowerCase() - b.countryName.toString().toLowerCase())
            .map((country) =>
                <option key={country.countryId} value={country.countryName}>{country.countryName}</option>
            );
    }
    createCityList = () => {
        return this.props.address.city.sort((a, b) => a.cityName - b.cityName)
            .map((city) =>
                <option key={city.CityId} value={city.cityName}>{city.cityName} </option>
            );
    }

    createCityItems = () => {
        return this.props.address.city
            .filter((e) => { return (e.stateId < 36) })
            .map((city) =>
                <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
            );
    }
    handleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    getCountryID = () => {      
        var that = this;
        let countryID;
        switch (that.state.countryName) {
            case CountryType.All:
            case "-1":
                countryID = -1;
                break;
            case CountryType.AllOverseasUser:
            case "-2":
                countryID = -2;
                break;
            default:
                var countryList = _.get(that.props.address, 'country', []);
                const selectedCountry = _.find(countryList, function (o) { return o.countryName.toLowerCase() === that.state.countryName.toLowerCase(); });
                countryID = selectedCountry.countryId;
        }
        return countryID;
    }
    getCityID = () => {       
        var that = this;
        let cityID;
        if (that.state.cityName === "")
            cityID = -1;
        else {
            var cityList = _.get(that.props.address, 'city', []);
            const selectedCity = _.find(cityList, function (o) { return o.cityName.toLowerCase() === that.state.cityName.toLowerCase(); });
            cityID = selectedCity.cityId;
        }
        return cityID;
    }
    onSendMailButtonClick = () => {
        const { attachImage, attachFile, subject, message } = this.state;
            let requestData = {
                EmailRecipients: this.props.emailList,
                EmailSubject: subject,
                EmailBody: message,
                AttachImageName: attachImage.length > 0 ? attachImage.join(';') : "",
                AttachFileName: attachFile.length > 0 ? attachFile.join(';') : "",
                Country: this.getCountryID(),
                City: this.getCityID()
            }
        
        this.props.sendEmail(requestData);
    }
    onBackButtonClick = () => {

        this.setState(prevState => ({
            showMailPreview: !prevState.showMailPreview
        }));

    }
    onUploadImage = (event) => {

        let fileName = event.target.files[0].name;
        var Extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (Extension === "gif" || Extension === "png" || Extension === "bmp"
            || Extension === "jpeg" || Extension === "jpg") {
            if (event.target.files.length > 0) {
                //We are only allowing file size less than 4 MB for Upload.
                if (event.target.files[0].size < 4000000)
                {
                    this.setState({
                        attachImage: [...this.state.attachImage, event.target.files[0].name]
                    });

                    var formData = new FormData();
                    formData.append('File', event.target.files[0]);
                    this.props.uploadFile(formData);
                   
                }
                else {
                    return alert("File size can not be greater than 4 MB")
                }
        }
        else {
            return alert("Photo only allows file types of GIF, PNG, JPG, JPEG and BMP.");
            }
        }
    }

    onUploadFile = (event) => {
        if (event.target.files.length > 0) {
            //We are only allowing file size less than 4 MB for Upload.
            if (event.target.files[0].size < 4000000)
            {
                this.setState({
                    attachFile: [...this.state.attachFile, event.target.files[0].name]

                });

                var formData = new FormData();
                formData.append('File', event.target.files[0]);
                this.props.uploadFile(formData);
               
            }
            else {
                return alert("File size can not be greater than 4 MB")
            }
        }
       
    }
    sendMailMessage = () => {
        
        if (this.props.isEmailSend !== "") {
            if (this.props.isEmailSend) {
                return (
                    <Alert color="success" className="small">
                        <p className="m-0">Mail has been successfully send</p>
                    </Alert>
                );
            }
            else {
                return (
                    <Alert color="danger" className="small">
                        <p className="m-0">Mail has been not sent Please try again</p>
                    </Alert>
                );
            }

        }
    }
    render() {
       
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center pb-2 mb-4 page-title-wrapper">
                    <div className="w-100 my-md-2 d-flex justify-content-between flex-column flex-md-row">
                        <h1 className="text-primary">Mail to Members</h1>
                        <div className="d-flex flex-row align-items-center align-self-end mb-2 mb-md-0">
                            <span><Link className="btn btn-outline-primary btn-sm" to={process.env.PUBLIC_URL + "/ViewSentMails"}>View Sent Mails</Link></span>
                        </div>
                    </div>
                </div>
                
                <Form className={`${this.state.showMailPreview === true || this.state.modal === true ? "d-none" : "d-block"}`} >
                    <Row>
                        <Col xl={10}>
                            <FormGroup >
                                <Row>
                                    <Col>
                                        <FormGroup >
                                            <Row>
                                                <Label className="text-sm-right" for="from" sm={2}>From</Label>
                                                <Col sm={5} xl={3}>
                                                    <Input className="bg-transparent border-dark" disabled value="anil@ramansujatisamaj.com" id="from" />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label className="text-sm-right" for="selectCountry" sm={2}>Select Country</Label>
                                    <Col sm={5} xl={3} className="select-wrapper matrimonials-select">
                                        <Input className="bg-transparent border-dark" type="select" id="selectCountry" onChange={this.handleCountryName} invalid={this.state.invalidCountryName}>
                                            <option value={-1}>--All--</option>
                                            <option value={-2}>--All Overseas Users--</option>
                                            {this.createCountryList()}
                                        </Input>
                                        <FormFeedback>Please select country</FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={`${this.state.countryName === "INDIA" ? "d-block" : "d-none"}`} >
                                <Row>
                                    <Label className="text-sm-right" for="selectCity" sm={2}>Select City</Label>
                                    <Col sm={5} xl={3} className="select-wrapper matrimonials-select">
                                        <Input className="bg-transparent border-dark" type="select" id="selectCity" onChange={this.handleCityName} invalid={this.state.invalidCityName}>
                                            <option value={-1}>--All--</option>
                                            {this.createCityItems()}
                                        </Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={`${this.state.countryName === "--All--" ? "d-block" : "d-none"}`} >
                                <Row>
                                    <Label className="text-sm-right" for="selectCity" sm={2}>Select City</Label>
                                    <Col sm={5} xl={3} className="select-wrapper matrimonials-select">
                                        <Input className="bg-transparent border-dark" type="select" id="selectCity" onChange={this.handleCityName} invalid={this.state.invalidCityName}>
                                            <option>--All--</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={`${this.state.countryName !== "INDIA" && this.state.countryName !== "--All--" ? "d-block" : "d-none"}`} >
                                <Row>
                                    <Label className="text-sm-right" for="selectCity" sm={2}>Select City</Label>
                                    <Col sm={5} xl={3} className="select-wrapper matrimonials-select">
                                        <Input className="bg-transparent border-dark" type="select" id="selectCity" onChange={this.handleCityName} invalid={this.state.invalidCityName}>
                                            <option>--All--</option>
                                        </Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup >
                                        <Row>
                                            <Label className="text-sm-right" for="from" sm={2}>Recipient</Label>
                                            <Col xs="auto">
                                                <Button className="btn-outline-primary" onClick={this.handleModal}>{this.state.countryName == "-1" ? "--All--" : (this.state.countryName == "-2" ? "--All Overseas Users--" : this.state.countryName)}</Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup >
                                        <Row>
                                            <Label className="text-sm-right" for="subject" sm={2}>Subject</Label>
                                            <Col sm={6} className="text-input">
                                                <Input className="bg-transparent border-dark" type="text" placeholder=" Enter Subject For Mail" id="subject" onChange={(event) => this.handleChange("subject", event)} invalid={this.state.invalidSubject} />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup >
                                        <Row>
                                            <Label className="text-sm-right" for="message" sm={2}>Message</Label>
                                            <Col sm={6} className="text-input">
                                                <Input className="bg-transparent border-dark" type="textarea" placeholder=" Enter Your Message" id="message" rows="8" onChange={(event) => this.handleChange("message", event)} invalid={this.state.invalidMessage} />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup >
                                        <Row>
                                            <Label className="text-sm-right" for="attachdocument" sm={2}>Attach Document</Label>
                                            <Col sm={6}>
                                                <Input type="file" id="attachdocument" onChange={this.onUploadFile} />
                                            </Col>
                                        </Row> 
                                        {
                                            this.state.attachFile.length > 0 &&

                                            <FormGroup >
                                                <Row>
                                                    <Label className="text-sm-right" for="attachdocument" sm={2} >Your Attached Files</Label>
                                                    <Col sm={6}>
                                                        <ul>
                                                            {this.state.attachFile.map((fileName) => (<li>{fileName}</li>))}
                                                           
                                                        </ul>
                                                      
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        } </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Label className="text-sm-right" for="uploadphoto" sm={2}>Upload Photo</Label>
                                            <Col sm={6}>
                                                <Input type="file" id="uploadphoto" onChange={this.onUploadImage} accept="image/*"  />
                                            </Col>
                                                </Row> 
                                                {
                                                    this.state.attachImage.length > 0 &&

                                                    <FormGroup >
                                                        <Row>
                                                            <Label className="text-sm-right" for="attachdocument" sm={2}>Your Attached Images</Label>
                                                            <Col sm={6}>
                                                        <ul>

                                                            {this.state.attachImage.map((fileName) => (<li>{fileName}</li>))}

                                                        </ul>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                }
                                       
                                            </FormGroup>
                                    <FormGroup>
                                        <Row>
                                            <Col xs={4} sm={{ size: 2, offset: 2 }}>
                                                <Button className="button-regular bg-primary text-white" onClick={this.handleClick} disabled={!this.state.subject || !this.state.message}>Preview</Button>
                                            </Col>
                                            <Col xs={4} sm={{ size: 2 }}>
                                                <Button className="button-regular bg-primary text-white" onClick={this.onSendMailButtonClick} disabled={!this.state.subject || !this.state.message}>Send</Button>
                                            </Col>
                                        </Row>
                                        {this.sendMailMessage()}
                                        </FormGroup>
                                       
                                    <Row>
                                        <Label className="text-sm-right" for="uploadphoto" sm={2}>Note:</Label>
                                        <Col sm={10} xl={6}>
                                            <small className="text-break">
                                                1. In Order to attach image in above text editor please upload image from "Upload Image" button.<br />
                                                2. Click on Add image Add Image in above text editor, than copy and paste the name of image uploaded.<br />
                                                Ex: (http://www.ramansujatisamaj.com//NewUpload//Imagename.png).
                                            </small>
                                        </Col>
                                            </Row>
                                        
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <Form className={`${this.state.showMailPreview !== true || this.state.modal === true ? "d-none" : "d-block"}`}>
                    <FormGroup>
                        <Row>
                            <Label className="text-sm-right" for="from" sm={2}>From</Label>
                            <Col sm={6}>
                                <Input plaintext value="anil@ramansujatisamaj.com" id="from" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Label className="text-sm-right" for="to" sm={2}>To</Label>
                            <Col sm={6}>
                                <Button color="link" onClick={this.handleModal}>{this.state.countryName}</Button>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Label className="text-sm-right" for="subject" sm={2}>Subject</Label>
                            <Col sm={6}>
                                <Input plaintext value={this.state.subject} id="from" />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Label className="text-sm-right" for="message" sm={2}>Message</Label>
                            <Col sm={6}>
                                <Input type="textarea" rows="10" value={this.state.message} id="from" />
                            </Col>
                        </Row>
                    </FormGroup>
                            {
                                this.state.attachFile.length > 0 &&

                                <FormGroup >
                                    <Row>
                                <Label className="text-sm-right" for="attacheddocument" sm={2}>Your Attached Files</Label>
                                <Col sm={6}>
                                    <ul>

                                        {this.state.attachFile.map((fileName) => (<li>{fileName}</li>))}

                                    </ul>
                                </Col>
                                    </Row>
                                </FormGroup>
                            }
                            {
                                this.state.attachImage.length > 0 &&

                                <FormGroup >
                                    <Row>
                                <Label className="text-sm-right" for="attacheddocument" sm={2}>Your Attached Images</Label>
                                <Col sm={6}>

                                    <ul>

                                        {this.state.attachImage.map((fileName) => (<li>{fileName}</li>))}

                                    </ul>
                                </Col>
                                    </Row>
                                </FormGroup>
                            }
                    <FormGroup>
                        <Row>
                            <Col>
                                <Button size="lg" color="primary" onClick={this.onSendMailButtonClick}>Send</Button>
                            </Col>
                            <Col>
                                <Button size="lg" color="primary" onClick={this.onBackButtonClick}>Back</Button>
                            </Col>
                        </Row>
                        {this.sendMailMessage()}
                    </FormGroup>
                </Form>
                <If condition={this.state.modal}>
                    <React.Fragment className={`${this.state.modal !== true ? "d-none" : "d-block"}`} key={this.state.modal}>
                        <Button color="primary" onClick={this.toggle}>Back</Button>{' '}
                        <ul className="mail-id-list">
                            {this.props.emailList !== undefined && this.props.emailList.length > 0 ?
                                this.props.emailList.map(email => {
                                    return (<li>{email}<span>,</span> </li>)
                                })
                                : <ul />}
                        </ul>
                    </React.Fragment>
                        </If>  
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        address: state.AddressDetailReducer.address,
        emailList: state.MailToMembersReducer.emailList,
        isEmailSend: state.MailToMembersReducer.isEmailSend,
    };
};

const mapDispatchToProps = dispatch => ({
    addressDetail: () => {
        dispatch(addressDetail());
    },
    mailContent: (requestData) => {
        dispatch(mailContent(requestData));
    },
    getAllEmail: (requestData) => {
        dispatch(getAllEmail(requestData));
    },
    sendEmail: (requestData) => {
        dispatch(sendEmail(requestData));
    },
    uploadFile: (requestData) => {
        dispatch(uploadFile(requestData));
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MailToMembers));



