import React from 'react';
import { Media, Form, Input, FormGroup, Button } from 'reactstrap';
import { SetMembersNationalityType, searchMemberDetails, setSortProperty, setMemberPerPage, setSearchInProgress, setpageNumber, getCityList } from '../../actions/MatrimonialAction';
import { addressDetail } from '../../actions/AddressDetailsAction'
import { connect } from 'react-redux';
import MemberDetail from './MatrimonialDetail';
import WebsiteIcon from '../../images/web-icon.png';
import SortingICon from '../../images/sorting-icon.png';
import { AjaxLoader } from '../AjaxLoader';
import NoItemFound from '../NoItemFoundPage'

class MatrimonialDirectory extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isIndian: true,
            city: "",
            country: "",
            ageFrom: "",
            ageTo: "",
            gender: "",
            qualification: "",
            jobStatus: "",
            annualIncome: ""
        }
        this.matrimonialFilters = React.createRef();
    }

    componentDidMount() {
        this.props.addressDetail();
        let natioanlityFilters = {
            IsIndian: true,
        }
        this.props.getCityList(natioanlityFilters);
        let searchFilters = {
            IsIncreasingOrder: true,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
        }
        this.props.searchMemberDetails(searchFilters);
    }

    SetSearchNationality = (isIndian) => {
        this.props.setSearchNationality(isIndian);
        this.setState({
            isIndian: isIndian,
            city: "",
            country: "",
            ageFrom: "",
            ageTo: "",
            gender: "",
            qualification: "",
            jobStatus: "",
            annualIncome: ""
        });
        if (isIndian) {
            let natioanlityFilters = {
                IsIndian: isIndian,
            }
            this.props.getCityList(natioanlityFilters);
        }
        this.resetField();
        let searchFilters = {
            City: "",
            Country: "",
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: isIndian,
            PageNumber: 0,
            AgeFrom: "",
            AgeTo: "",
            Gender: "",
            Qualification: "",
            JobStatus: "",
            AnnualIncome: ""
        }
        this.props.searchMemberDetails(searchFilters);
    }

    openRegistrtationPage = () => {
        const win = window.open(process.env.PUBLIC_URL + "/RegisterMatrimonial", "_blank");
        win.focus();
    }

    handleChange = (type, event) => {
        this.setState({ [type]: event.target.value });
    }

    createCountryName = () => {
        if (this.props.address.country.length > 0 && !this.props.isIndian) {
            return this.props.address.country
                .filter((e) => { return (e.countryId !== 0) })
                .map((country) =>
                    <option key={country.countryName} value={country.countryName}>{country.countryName}</option>
                );
        }
    }

    createCityName = () => {
        if (this.props.cityList.length > 0) {
            return this.props.cityList.map((city) =>
                <option key={city} value={city} > {city}</option>
            );
        }
    }

    setSortingOrder = () => {
        this.props.setSortProperty(!this.props.sortIncreasingOrder);
        let searchFilters = {
            City: this.state.city,
            Country: this.state.country,
            IsIncreasingOrder: !this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
            PageNumber: this.props.pageNumber,
            AgeFrom: this.state.ageFrom,
            AgeTo: this.state.ageTo,
            Gender: this.state.gender,
            Qualification: this.state.qualification,
            JobStatus: this.state.jobStatus,
            AnnualIncome: this.state.annualIncome
        }
        this.props.searchMemberDetails(searchFilters);

    }

    onPerPageChange = (e) => {

        this.props.setMemberPerPage(parseInt(e.target.value));
        this.props.setpageNumber(0)
        let searchFilters = {
            City: this.state.city,
            Country: this.state.country,
            BloodGroup: this.state.bloodGroup,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: parseInt(e.target.value),
            IsIndian: this.props.isIndian,
            PageNumber: 0,
            AgeFrom: this.state.ageFrom,
            AgeTo: this.state.ageTo,
            Gender: this.state.gender,
            Qualification: this.state.qualification,
            JobStatus: this.state.jobStatus,
            AnnualIncome: this.state.annualIncome
        }
        this.props.searchMemberDetails(searchFilters);
    }

    searchMember = (event) => {
        event.preventDefault();
        this.props.setSearchInProgress();
        this.props.setpageNumber(0)
        let searchFilters = {
            City: this.state.city,
            Country: this.state.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
            PageNumber: 0,
            AgeFrom: this.state.ageFrom,
            AgeTo: this.state.ageTo,
            Gender: this.state.gender,
            Qualification: this.state.qualification,
            JobStatus: this.state.jobStatus,
            AnnualIncome: this.state.annualIncome
        }
        this.props.searchMemberDetails(searchFilters);
    }

    handlePreviousPageClick = () => {
        if (this.props.pageNumber !== 0) {
            this.props.setpageNumber(this.props.pageNumber - 1)
            let searchFilters = {
                City: this.state.city,
                Country: this.state.country,
                IsIncreasingOrder: this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                IsIndian: this.props.isIndian,
                PageNumber: this.props.pageNumber - 1,
                AgeFrom: this.state.ageFrom,
                AgeTo: this.state.ageTo,
                Gender: this.state.gender,
                Qualification: this.state.qualification,
                JobStatus: this.state.jobStatus,
                AnnualIncome: this.state.annualIncome
            }
            this.props.searchMemberDetails(searchFilters);
        }
    }

    handleNextPageClick = () => {
        if (this.props.totalPageCount !== this.props.pageNumber) {
            this.props.setpageNumber(this.props.pageNumber + 1)
            let searchFilters = {
                City: this.state.city,
                Country: this.state.country,
                IsIncreasingOrder: this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                IsIndian: this.props.isIndian,
                PageNumber: this.props.pageNumber + 1,
                AgeFrom: this.state.ageFrom,
                AgeTo: this.state.ageTo,
                Gender: this.state.gender,
                Qualification: this.state.qualification,
                JobStatus: this.state.jobStatus,
                AnnualIncome: this.state.annualIncome
            }
            this.props.searchMemberDetails(searchFilters);
        }
    }

    handleClick = () => {
        this.inputElement.click();
    }

    resetField = () => {
        this.matrimonialFilters.current.reset();
    }

    render() {
        let ageOptions = [];
        for (var i = 18; i <= 80; i++) {
            ageOptions.push(<option value={i}>{i}</option>);
        }

        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center pb-2 page-title-wrapper">
                    <div className="w-100 my-md-2 d-flex justify-content-between flex-column flex-md-row">
                        <h1 className="text-primary mb-3 mb-md-2">Matrimonials</h1>
                        <div className="d-flex flex-row align-items-center align-self-end mb-2 mb-md-0">
                            <span className="pr-2">New matrimonial registraion</span>
                            <span className="member-directory-icon rounded border border-dark p-1"><Media className="img-fluid" src={WebsiteIcon} alt="register" onClick={() => this.openRegistrtationPage()} /></span>
                        </div>
                    </div>
                </div>

                {
                    this.props.address.country.length > 0 ? <div>
                        <div className="d-flex flex-column w-100 mt-3">
                            <div className="pb-4">
                                Disclaimer:Website is displaying information submitted by members & doesn't authenticate the details in any manner & not responsible for any outcome of using this data.
                            </div>
                            <div className="d-inline py-2"><div className="float-left nationality-button"><button onClick={() => this.SetSearchNationality(true)} className={`${this.state.isIndian ? "selected-nationality" : ""}`}>India</button><button onClick={() => this.SetSearchNationality(false)} className={`${!this.state.isIndian ? "selected-nationality" : ""}`}>Other Countries</button></div></div>
                            <Form className="py-4 py-md-4 row matrimonial-search-group" innerRef={this.matrimonialFilters}>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="gender" id="gender" placeholder="Gender" onChange={(event) => this.handleChange("gender", event)}>
                                        <option value="Select">--Gender--</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className={`col-xl-auto text-input select-wrapper mob-select-icon matrimonials-select ${!this.props.isIndian ? "d-none" : "d-block"} col-12 col-md-3`}>
                                    <Input className="bg-transparent border-dark" type="select" name="city" id="city" placeholder="--City--" onChange={(event) => this.handleChange("city", event)} autoComplete="off">
                                        <option value="Select">--City--</option>
                                        {this.createCityName()}
                                    </Input>
                                </FormGroup>
                                <FormGroup className={`col-xl-auto select-wrapper mob-select-icon matrimonials-select ${this.props.isIndian ? "d-none" : "d-block"} col-12 col-md-3`}>
                                    <Input className="bg-transparent border-dark" type="select" name="country" id="country" placeholder="country" onChange={(event) => this.handleChange("country", event)}>
                                        <option value="Select">--Country--</option>
                                        {this.createCountryName()}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="ageFrom" id="ageFrom" placeholder="Age From" onChange={(event) => this.handleChange("ageFrom", event)}>
                                        <option value="Select">--Age From--</option>
                                        {ageOptions}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="ageTo" id="ageTo" placeholder="Age To" onChange={(event) => this.handleChange("ageTo", event)}>
                                        <option value="Select">--Age To--</option>
                                        {ageOptions}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)}>
                                        <option value="Select">--Qualification--</option>
                                        <option value="Post Graduate">Post Graduate</option>
                                        <option value="Graduate">Graduate</option>
                                        <option value="Under Graduate">Under Graduate</option>
                                        <option value="Professional">Professional</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="jobStatus" id="jobStatus" placeholder="Job Status" onChange={(event) => this.handleChange("jobStatus", event)}>
                                        <option value="Select">--Job Status--</option>
                                        <option value="None">None</option>
                                        <option value="Student">Student</option>
                                        <option value="Govt. Sector">Govt. Sector</option>
                                        <option value="Private Sector">Private Sector</option>
                                        <option value="Self Employed/Business">Self Employed/Business</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3 select-wrapper mob-select-icon matrimonials-select">
                                    <Input className="bg-transparent border-dark" type="select" name="annualIncome" id="annualIncome" placeholder="Annual Income" onChange={(event) => this.handleChange("annualIncome", event)}>
                                        <option value="Select">--Annual Income--</option>
                                        <option value="0">Less than 1 Lac</option>
                                        <option value="1">Between 1 to 5 Lac</option>
                                        <option value="2">Between 5 to 10 Lac</option>
                                        <option value="3">Greater than 10 Lac</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup className="col-12 col-md-3">
                                    <Button onClick={(event) => this.searchMember(event)} className="search-button bg-primary border-0 text-white">Search</Button>
                                </FormGroup>
                            </Form>
                            <div className="d-flex justify-content-between align-items-center border-bottom border-dark pb-2">
                                <div className="font-weight-bold">List of all Members</div>
                                <div className="d-flex align-items-center">Per page:
                                        <div className="select-wrapper per-page-item">
                                        <Input type="select" name="country" id="country" placeholder="country" onChange={(event) => this.onPerPageChange(event)} className="item-per-page d-inline bg-transparent border-dark ml-1">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                        </Input>
                                    </div>
                                    <span className="member-directory-icon rounded border border-dark p-1 ml-1"><Media className="img-fluid" src={SortingICon} alt="download form" onClick={() => this.setSortingOrder()} /></span>
                                </div>
                            </div>
                            <div className="col-12 py-3 px-0 m-0 ">
                                <AjaxLoader isOpen={this.props.isLoading} />
                                {this.props.matrimonialDetails.length > 0 ? < MemberDetail /> : <NoItemFound />}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button onClick={this.handlePreviousPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== 0 ? "d-block" : "d-none"}`}>Previous</button>
                                <button onClick={this.handleNextPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== this.props.totalPageCount - 1 ? "d-block" : "d-none"}`}>Next</button>
                            </div>
                        </div> </div> : null
                }


            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        isIndian: state.MatrimonialReducer.isIndian,
        matrimonialDetails: state.MatrimonialReducer.matrimonialDetails,
        cityList: state.MatrimonialReducer.cityList,
        countryList: state.MatrimonialReducer.countryList,
        sortIncreasingOrder: state.MatrimonialReducer.sortIncreasingOrder,
        memberPerPage: state.MatrimonialReducer.memberPerPage,
        pageNumber: state.MatrimonialReducer.pageNumber,
        isLoading: state.MatrimonialReducer.isLoading,
        totalPageCount: state.MatrimonialReducer.totalPageCount,
        address: state.AddressDetailReducer.address,
        cityList: state.MatrimonialReducer.cityList
    };
}

const mapDispatchToProps = dispatch => ({
    searchMemberDetails: (memberSearchFilters) => {
        dispatch(searchMemberDetails(memberSearchFilters));
    },
    setSearchNationality: (isIndian) => {
        dispatch(SetMembersNationalityType(isIndian));
    },
    setSortProperty: (isIncreasingOrder) => {
        dispatch(setSortProperty(isIncreasingOrder));
    },
    setMemberPerPage: (memberPerPage) => {
        dispatch(setMemberPerPage(memberPerPage));
    },
    setpageNumber: (pageNumber) => {
        dispatch(setpageNumber(pageNumber));
    },
    setSearchInProgress: () => {
        dispatch(setSearchInProgress());
    },
    addressDetail: () => {
        dispatch(addressDetail());
    },
    getCityList: (nationality) => {
        dispatch(getCityList(nationality));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatrimonialDirectory);

