const initialState = {

    VideoGalleryDirPath: [],
    SectionName: '',
}

const VideoGalleryReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_VIDEO_GALLERY_DIR_PATH':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_VIDEO_GALLERY_DIR_PATH':
            return Object.assign({}, state, { VideoGalleryDirPath: action.VideoGalleryDirPath.videoDirectoryPath, isLoading: false, SectionName: action.VideoGalleryDirPath.section });

        default:
            return state;
    }

}

export default VideoGalleryReducer;

//const initialState = {

//   VideoalbumName: [],
//}

//const VideoGalleryReducer = (state = initialState, action) => {
    
//    switch (action.type) {
//        case 'SET_VIDEO_ALBUM_NAME':
//            return {
//                ...state, VideoalbumName: action.payload
//            };

//        default:
//            return state;
//    }

//}

//export default VideoGalleryReducer;
