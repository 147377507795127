import React from "react";
import Tabulator from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { withBreakpoints } from 'react-breakpoints';
import VanchanamrutData from './Vanchanamrut.json';

class Vanchanamrut extends React.Component {
    el = React.createRef();
    componentDidMount() {
        this.buildTable();
    }
    buildTable = () => {
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        this.tabulator = new Tabulator(this.el, {
            data: VanchanamrutData,
            tooltips: true,
            autoResize: false,
            layout: "fitColumns",
            resizableRows: false,
            resizableColumns: true,
            responsiveLayout: isMobile ? "collapse" : "",
            columns: [
                { responsive: 1, field: "f1", title: "Posh", headerSort: false },
                { responsive: 2, field: "f2", title: "Maha", headerSort: false},
                { responsive: 2, field: "f3", title: "Fagan", headerSort: false},
                { responsive: 2, field: "f4", title: "Chaitra", headerSort: false},
                { responsive: 2, field: "f5", title: "Vaishakh", headerSort: false},
                { responsive: 2, field: "f6", title: "Jeth", headerSort: false },
                { responsive: 2, field: "f7", title: "Ashadh", headerSort: false},
                { responsive: 2, field: "f8", title: "Shravan", headerSort: false},
                { responsive: 2, field: "f9", title: "Bhadarvo", headerSort: false},
                { responsive: 2, field: "f10", title: "Asho", headerSort: false},
                { responsive: 2, field: "f11", title: "Kartak", headerSort: false},
                { responsive: 2, field: "f12", title: "Magsar", headerSort: false},
                { responsive: 2, field: "f13", formatter: "textarea", headerSort: false}
            ],
        });
    }

    render() {
        return (
            <div className="pb-2 pt-2"><div id="tabulator" className="p-0 vanchanmrut" ref={el => (this.el = el)} /></div>
        )
    }
}
export default (withBreakpoints(Vanchanamrut));