const initialState = {
    FileDetail : null,    
    isLoading: '',
}

    const MaryadiVashnavasListReducer = (state = initialState, action) => { 
    switch (action.type) {
        case 'GET_MARYADI_VASHNAVAS_FILE_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_MARYADI_VASHNAVAS_FILE_NAME':
            return Object.assign({}, state, { FileDetail: action.payload, isLoading: false });
           
        default:
        return state;
    }

}

export default MaryadiVashnavasListReducer;