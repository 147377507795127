import axios from 'axios';
import { history } from '../store/ConfigureStore';
import { getSiteUrl } from '../common/Global';


export const doLogin = (requestData) => {
    return function (dispatch) {
        login('/api/account/login', requestData, dispatch);
    };
}

const login = (url, requestData, dispatch) => {
    dispatch(get());
    axios.post(process.env.PUBLIC_URL + url, requestData)
        .then(function (response) {
            
            const { isAuthenticated, errorMessage, loginType, filePathURL } = response.data;
            if (isAuthenticated) {
                
                dispatch(success({ isAuthenticated, errorMessage, loginType, filePathURL }));
                if (loginType === "U") {
                    history.push(getSiteUrl('/Welcome'));
                }
                else if (loginType === "A") {
                    history.push(getSiteUrl('/MemberDirectoryAdmin'));
                }
            }
            else
                dispatch(failure({ isAuthenticated, errorMessage, loginType }));
        })
        .catch(function () {
            
            dispatch(failure({ errorMessage: 'An error occured. Please try again.' }));
        });
    function get() { return { type: 'LOGIN_REQUEST' }; }
    function success(loginSuccess) {
        return { type: 'SET_LOGIN_SUCCESS', loginSuccess };
    }
    function failure(loginError) { return { type: 'SET_LOGIN_FAILURE', loginError }; }
}

export const isAuthenticated = () => {
    return function (dispatch) {
        axios.post(process.env.PUBLIC_URL + '/api/account/authentication')
            .then(function (response) {
                const { isAuthenticated, errorMessage, loginType, filePathURL } = response.data;
                if (isAuthenticated)
                    dispatch(success({ isAuthenticated, errorMessage, loginType, filePathURL }));
                else {
                    dispatch(failure({ isAuthenticated, errorMessage, loginType }));
                }
            })
            .catch(function (error) {
                dispatch(failure(error));
            });
    };
    function success(loginSuccess) {
        return { type: 'SET_LOGIN_SUCCESS', loginSuccess };
    }
    function failure(loginError) { return { type: 'SET_LOGIN_FAILURE', loginError }; }
}

export const isCookieExist = () => {
    return function (dispatch) {
        axios.post(process.env.PUBLIC_URL + '/api/account/verifycookie')
            .then(function (response) {
                const { isAuthenticated, errorMessage, loginType, filePathURL } = response.data;
                if (isAuthenticated) {
                    dispatch(success({ isAuthenticated, errorMessage, loginType, filePathURL }));
                    if (loginType === "U") {
                        history.push(getSiteUrl('/Welcome'));
                    }
                    else if (loginType === "A") {
                        history.push(getSiteUrl('/MemberDirectoryAdmin'));
                    }
                }
                   
                else {
                    dispatch(failure({ isAuthenticated, errorMessage, loginType }));
                    history.push(getSiteUrl('/Login'));
                }
            })
            .catch(function (error) {
                dispatch(failure(error));
            });
    };
    function success(loginSuccess) {
        return { type: 'SET_LOGIN_SUCCESS', loginSuccess };
    }
    function failure(loginError) { return { type: 'SET_LOGIN_FAILURE', loginError }; }
}

export const logout = () => {
    
    return function (dispatch) {
        axios.get(process.env.PUBLIC_URL + '/api/account/Logout')
            .then(function () {
                dispatch(success());
            })
            .catch(function () {
            });
    };
    
    function request() { return { type: 'LOGOUT_REQUEST', logout: { isAuthenticated: false, isLoggedOut: true } }; }
    function success() { return { type: 'LOGOUT_SUCCESS'} }

};

