import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Navbar } from 'reactstrap';
import HamburgerMenuControl from './HamburgerMenuControl'

class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="d-none d-lg-block">
                    <Navbar className="shadow header-bg-color p-0">
                        <div className="navbar-brand p-0"></div>
                    </Navbar>
                </div>
                <div className="d-block d-lg-none">
                    <Navbar className="shadow header-bg-color-mobile">
                        <div className="navbar-brand d-flex flex-row">
                            <div className="header-content-mobile text-center">
                                {(this.props.isSidebarOpen ? "" : "Raman Sujati")}
                            </div>
                            <div className="hamburger">
                                {((this.props.isAuthenticated === null || this.props.isAuthenticated === false) ? null : <HamburgerMenuControl />)}
                            </div>
                        </div>
                    </Navbar>
                </div>
            </React.Fragment>);
    }
}
const mapStateToProps = (state) => {
    return {
        isSidebarOpen: state.SidebarNavReducer.isSidebarOpen,
        isAuthenticated: state.LoginReducer.isAuthenticated
    };
};

export default connect(mapStateToProps)(Header);
