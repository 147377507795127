import axios from 'axios';

export const doRegister = (registerDetails) => {
    return function (dispatch) {
        
        register('/api/member/register', registerDetails, dispatch);
    };
}
const register = (url, registerDetails, dispatch) => {
    dispatch(get());
    axios.post(process.env.PUBLIC_URL + url, registerDetails)
        .then(function (response) {
            const { isSuccessfull, message } = response.data
            if (isSuccessfull) {

                dispatch(success({ isSuccessfull, message }));
            }
            else {
                dispatch(failure({ isSuccessfull, message }));
            }
        }
        )
    function get(registering) { return { type: 'REGISTER_REQUEST', registering }; }
    function success(message) { return { type: 'SET_REGISTER_SUCCESS', message }; }
    function failure(message) { return { type: 'SET_REGISTER_FALIURE', message }; }

};


