import React from 'react';
import { connect } from 'react-redux';
import MemberDetailElement from './MemeberDetailElement';
import { Media } from 'reactstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import nextId from "react-id-generator";
import Approved from '../../../images/approved.png';
import NotApproved from '../../../images/not-approved.png';
import profileImg from '../../../images/proimg.png';
import Inactive from '../../../images/wait-approved.png';

class MemberDetail extends React.Component {
    render() {
        var isApprovedTab = this.props.approve;
        return (
            <Accordion allowZeroExpanded={true}>
                {this.props.memberDetailsAdmin.map((item) => {
                    let imagePreviewUrl = item.profilePicture;
                    let $imagePreview = null;
                    if (imagePreviewUrl) {
                        $imagePreview = (<Media src={imagePreviewUrl} alt="profile-Pic" className="profile-photo  clip-circle" />);
                    } else {
                        $imagePreview = (<div className="profile-upload"><img src={profileImg} alt="profile image" /></div>);
                    }
                    return (
                        <AccordionItem className="mb-3" key={nextId()}>
                            <AccordionItemHeading>
                                <AccordionItemButton className="focus-out">
                                    <div className="row w-100 p-2 m-0 align-items-center">
                                        <div className="col-3 col-md-2 col-xl-1 profile-image">
                                            <div className="mask">
                                                {$imagePreview}
                                            </div>
                                        </div>
                                        <div className="col-7 col-md-3">
                                            <div className="text-muted">Name</div>
                                            <div>{item.personName}</div>
                                        </div>
                                        <div className="col-md-3 d-none d-md-block">
                                            <div className="text-muted">City</div>
                                            <div>{item.cityName}</div>
                                        </div>
                                        <div className={`col-md-3  ${isApprovedTab ? "d-none" : "d-none d-md-block"}`}>
                                            <Media className="member-status-icon p-1 border-0" src={NotApproved} alt="approve status" />
                                        </div>
                                        <div className={`col-md-3  ${!isApprovedTab ? "d-none" : "d-none d-md-block"}`}>
                                            <Media className="member-status-icon p-1 border-0" src={item.isActive == 1 ? Approved : Inactive} alt="approve status" />
                                        </div>
                                        <div className="col-2 col-md-1 col-xl-2 focus-out text-right">
                                            <span><FontAwesomeIcon className="fa-w-14" icon={faChevronDown} /></span>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <MemberDetailElement member={item} approve={this.props.approve} ></MemberDetailElement>
                            </AccordionItemPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberDetailsAdmin: state.MemberDirectoryReducer.memberDetailsAdmin,
    };
}
export default connect(mapStateToProps)(MemberDetail);

