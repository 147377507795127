import axios from 'axios';
export const doForgotPassword = (requestData) => {
    return function (dispatch) {
        forgotPassword('/api/ForgotPassword/ForgotPassword', requestData, dispatch);
    };
}

const forgotPassword = (url, requestData, dispatch) => {
    axios.post(process.env.PUBLIC_URL + url, requestData)        .then(function (response) {            const { message, isSuccessful } = response.data;
            if (isSuccessful === 1)
                dispatch(success({ message, isSuccessful }));
            else
                dispatch(failure({ message, isSuccessful }));        })        .catch(function () {            dispatch(failure({ message: 'An error occured. Please try again.' }));        });
    function success(successMessage) { return { type: 'SET_FORGOTPASSWORD_SUCCESS', successMessage }; }
    function failure(failureMessage) { return { type: 'SET_FORGOTPASSWORD_FAILURE', failureMessage }; }
}