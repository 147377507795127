import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Col, Row, Card, CardBody, CardHeader, Alert, FormFeedback, Media } from 'reactstrap';
import { If } from 'rc-if-else';
import { Link } from 'react-router-dom';
import { doRegister } from '../actions/RegisterAction';
import { connect } from 'react-redux';
import { AjaxLoader } from './AjaxLoader';
import { addressDetail } from '../actions/AddressDetailsAction';
import logimg from '../images/raman-sujati-samaj-logo.png';
import profileImg from '../images/proimg.png';
import DateTime from '../components/DatePicker';
import { format } from 'date-fns';
var validator = require('validator');

class Register extends Component {
    constructor() {
        super();
        this.state = {
            fields: {
                showTextBoxNative: false,
                showTextBoxCurrent: false,
                showOtherQualification: false,
                personName:"",
                fatherMotherName:"",
                bloodGroup:"" ,
                spouseName:"",
                caste:"",
                ageDob:"",
                mobile:"",
                phoneRes:"" ,
                stdcode:"",
                email:"",
                phoneShop:"",
                service:"",
                website:"",
                addressShop:"",
                countryName:"",
                addressRes:"",
                qualification:"" ,
                cityName:"" ,
                stateName:"",
                perAddressRes:"",
                perCountryName:"",
                perStateName:"",
                perCityName:"",
                profession: "",
                description:"",
                experience: "",
                profilePicture: "",
            }
        };
        this.registerForm = React.createRef();
    }

    handleChange = (type, event) => {
        
        let fields = this.state.fields;
        switch (type) {
            case "personName":
                fields["invalidPersonName"] = validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/);
                break;
            case "spouseName":
                fields["invalidSpouseName"] = !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/);
                break;
            case "fatherMotherName":
                fields["invalidFatherMotherName"] = !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/);
                break;
            case "caste":
                fields["invalidCaste"] = !validator.matches(event.target.value, /^[a-zA-Z\s|\.]*$/);
                break;
            case "phoneRes":
                fields["invalidPhoneRes"] = !validator.isMobilePhone(event.target.value);
                break;
            case "phoneShop":
                fields["invalidPhoneShop"] = !validator.isMobilePhone(event.target.value);
                break;
            case "mobile":
                fields["invalidMobile"] = !validator.isMobilePhone(event.target.value);
                break;
            case "stdcode":
                fields["invalidStdCode"] = !validator.isNumeric(event.target.value);
                break;
            case "addressRes":
                fields["invalidAddressRes"] = validator.isEmpty(event.target.value);
                break;
            case "email":
                fields["invalidEmail"] = validator.isEmpty(event.target.value) || !validator.isEmail(event.target.value);
                break;
            case "countryName":              
                fields["invalidCountryName"] = validator.isEmpty(event.target.value);
                fields["stateName"] = "";
                fields["cityName"] = "";                
                break;
            case "stateName":
                fields["invalidStateName"] = event.target.value == "Select" || validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^([^0-9]*)$/);
                fields["cityName"] = "";
                break;
            case "cityName":
                fields["invalidCityName"] = validator.isEmpty(event.target.value) || !validator.matches(event.target.value, /^([^0-9]*)$/);
                break;
           case "perCountryName":
                fields["perStateName"] = "";
                fields["perCityName"] = "";
                break;
            case "perStateName":
                fields["invalidPerStateName"] = !validator.matches(event.target.value, /^([^0-9]*)$/);
                fields["perCityName"] = "";
                break;
            case "perCityName":
                fields["invalidPerCityName"] = !validator.matches(event.target.value, /^([^0-9]*)$/);
                break;
            default:
                break;
        }
        fields[type] = event.target.value;
        this.setState({ fields });
    }

    handleDobChange = (date) => {
        
        let fields = this.state.fields;
        fields["ageDob"] = format(date._d, 'dd/MM/yyyy');
        this.setState({ fields });
    }
    showOtherQualification = () => {
        let fields = this.state.fields;
        fields["showOtherQualification"] = fields["showOtherQualification"] ? false : true;
        this.setState({ fields });
    }

    showOtherProfession = () => {
        let fields = this.state.fields;
        fields["showOtherProfession"] = fields["showOtherProfession"] ? false : true;
        this.setState({ fields });
    }

    showTextBoxMethodNative = () => {
        let fields = this.state.fields;
        fields["showTextBoxNative"] = fields["showTextBoxNative"] ? false : true;
        this.setState({ fields });
    }

    showTextBoxMethodCurrent = () => {
        let fields = this.state.fields;
        fields["showTextBoxCurrent"] = fields["showTextBoxCurrent"] ? false : true;
        this.setState({ fields });
    }

    createCountryItems() {
        const listItems = this.props.address.country.map((country) =>
            <option key={country.countryId} value={country.countryName}>{country.countryName}</option>
        );
        return listItems;
    }

    createBloodGroupItems() {
        const listItems = this.props.address.bloodGroups.map((bloodGroup) =>
            <option key={bloodGroup.SystemId} value={bloodGroup.code}>{bloodGroup.code}</option>
        );
        return listItems;
    }

    createQualificationItems() {
        const listItems = this.props.address.qualifications.map((qualification) =>
            <option key={qualification.SystemID} value={qualification.code}>{qualification.code}</option>
        );
        return listItems;
    }

    createProfessionItems() {
        const listItems = this.props.address.professions.map((profession) =>
            <option key={profession.SystemID} value={profession.code}>{profession.code}</option>
        );
        return listItems;
    }

    createStateItems() {
        let fields = this.state.fields;
        var country = this.props.address.country.filter((e) => { return (e.countryName === fields["countryName"]) });
        var statelist = this.props.address.state
            .filter((e) => { return (country.length === 1 && e.countryId === country[0].countryId) });
        if (statelist.length > 0) {
            return statelist.map((state) =>
                <option key={state.stateId} value={state.stateName}>{state.stateName}</option>
            );
        }
        else
            return <option key="nodata" value="No State Found" disabled> No State Found</option>;
    }

    createCityItems() {
        let fields = this.state.fields;
        var state = this.props.address.state.filter((e) => { return (e.stateName === fields["stateName"]) });
        var citylist = this.props.address.city
            .filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) });
        if (citylist.length > 0) {
            return citylist.map((city) =>
                <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
            );
        }
        else
            return <option key="nodata" value="No City Found" disabled > No City Found</option>;
    }

    createStateItemsNative() {
        let fields = this.state.fields;
        var country = this.props.address.country.filter((e) => { return (e.countryName === fields["perCountryName"]) });
        var statelist = this.props.address.state.filter((e) => { return (country.length === 1 && e.countryId === country[0].countryId) });
        if (statelist.length > 0) {
            return statelist.map((state) =>
                <option key={state.stateId} value={state.stateName} >{state.stateName}</option>
            );
        }
        else
            return <option key="nodata" value="No State Found" disabled > No State Found</option>;
    }
    createCityItemsNative() {
        let fields = this.state.fields;
        var state = this.props.address.state.filter((e) => { return (e.stateName === fields["perStateName"]) });
        var citylist = this.props.address.city.filter((e) => { return (state.length === 1 && e.stateId === state[0].stateId) });
        if (citylist.length > 0) {
            return citylist.map((city) =>
                <option key={city.CityId} value={city.cityName}>{city.cityName}</option>
            );
        }
        else
            return <option key="nodata" value="No City Found" disabled > No City Found</option>;
    }

    doRegister = () => {
        
        let fields = this.state.fields;
        var isValidForm = !fields["invalidPersonName"] && !fields["invalidAddressRes"] && !fields["invalidEmail"] && !fields["invalidCountryName"]
            && !fields["invalidStateName"] && !fields["invalidCityName"] && !fields["invalidSpouseName"] && !fields["invalidFatherMotherName"] && !fields["invalidPhoneRes"] && !fields["invalidPhoneShop"] && !fields["invalidMobile"] && !fields["invalidStdCode"] && !fields["invalidEmail"] && !fields["invalidperStateName"]
            && !fields["invalidPerCityName"] ;
        if (isValidForm) {
            let registerDetails = {
                PersonName: fields["personName"],
                FatherMotherName: fields["fatherMotherName"],
                BloodGroup: fields["bloodGroup"],
                SpouseName: fields["spouseName"],
                Caste: fields["caste"],
                AgeDob: fields["ageDob"],
                Mobile: fields["mobile"],
                PhoneRes: fields["phoneRes"],
                Stdcode: fields["stdcode"],
                Email: fields["email"],
                PhoneShop: fields["phoneShop"],
                Service: fields["service"],
                Website: fields["website"],
                AddressShop: fields["addressShop"],
                CountryName: fields["countryName"],
                AddressRes: fields["addressRes"],
                Qualification: fields["qualification"],
                CityName: fields["cityName"],
                StateName: fields["stateName"],
                PerAddressRes: fields["perAddressRes"],
                PerCountryName: fields["perCountryName"],
                PerStateName: fields["perStateName"],
                PerCityName: fields["perCityName"],
                Profession: fields["profession"],
                Description: fields["description"],
                Experience: fields["experience"],
                ProfilePicture: fields["file"]
            };
            this.props.doRegister(registerDetails);
        }
    }
    componentDidMount() {
        this.props.addressDetail();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isSuccessful !== prevProps.isSuccessful) {
            if (this.props.isSuccessful == 1)
                this.resetField();
        }
    }

    resetField = () => {
        
        this.registerForm.current.reset();
        this.inputElement.value = "";
        let fields = {
            showTextBoxNative: false,
            showTextBoxCurrent: false,
            showOtherQualification: false,
            ageDob:""
        };
        this.setState({ fields: fields });

    }

    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let fileName = file.name;
        var Extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        if (file.size > 8 * 1000 * 1000) {
            alert("File size limit exceeds 8 MB");
            this.inputElement.value = "";
            return;
        }
        if (Extension == "gif" || Extension == "png" || Extension == "bmp"
            || Extension == "jpeg" || Extension == "jpg") {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                let fields = this.state.fields;
                fields["file"] = reader.result;
                this.setState({
                    fields
                });
            }
        }
        else {
            return alert("Photo only allows file types of GIF, PNG, JPG, JPEG and BMP.");
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        this.inputElement.click();
    }

    render() {
      
        let $imagePreview = null;
        if (this.state.fields["file"] !== undefined && this.state.fields["file"] !== "") {
            $imagePreview = (<div className="profile-upload"><img src={this.state.fields["file"]} /></div>);
        }
        else {
            $imagePreview = (<div className="profile-upload"><img src={profileImg} /></div>);
        }
        return (
           
            this.props.address.country.length > 0 ?
                <React.Fragment>
                    <div className="register-section">
                        <Row className="justify-content-between full-height align-items-center h-100">
                            <Col className="m-auto pb-4" xs="12" sm="9" lg="8" md="10" xl="6">
                                <AjaxLoader isOpen={this.props.registering} />
                                <div className="col-sm-12 py-4 px-5 text-center"><Media src={logimg} className="img-fluid d-inline-block" alt="Raman Sujati Samaj" /></div>
                                <Card className="card-bg-register py-4" >
                                    <CardHeader className="text-center card-header-register"> NEW MEMBER REGISTRATION</CardHeader>
                                    <CardBody className="p-4">
                                        <Form innerRef={this.registerForm} autoComplete="off">
                                            <input className="fileInput d-none" type="file" onChange={(e) => this._handleImageChange(e)} ref={input => this.inputElement = input} />
                                            <Row>
                                                <Col sm={12} md={6} >
                                                    <FormGroup className="d-flex align-items-center">
                                                        <div className="mask">
                                                            {$imagePreview}
                                                        </div>
                                                        <button onClick={(e) => this.handleClick(e)} className="btn ml-3 bg-primary text-white">Upload photo</button>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <div className="d-flex flex-row"><Input maxLength={50} type="text" name="name" id="name" placeholder="Name" onChange={(event) => this.handleChange("personName", event)} invalid={this.state.fields["invalidPersonName"]} /><span className="text-danger">*</span>
                                                                    <FormFeedback>Please enter  Name</FormFeedback></div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <div className="d-flex flex-row"><Input maxLength={50} type="text" name="spouseName" id="spouseName" placeholder="Spouse Name" onChange={(event) => this.handleChange("spouseName", event)} invalid={this.state.fields["invalidSpouseName"]} />
                                                                    <FormFeedback>Please enter valid Spouse Name</FormFeedback></div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={50} type="text" name="fatherMotherName" id="fatherMotherName" placeholder="Father/Mother Name" onChange={(event) => this.handleChange("fatherMotherName", event)} invalid={this.state.fields["invalidFatherMotherName"]} />
                                                            <FormFeedback>Please enter valid Father/Mother Name</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <DateTime id="agedob"
                                                            viewMode="days"
                                                            value={this.state.fields['ageDob']}
                                                            dateFormat="DD/MM/YYYY"
                                                            onChange={(date) => this.handleDobChange(date)}
                                                            invalid={this.state.invalidDateOfBirth}
                                                            placeholder="--DOB (DD/MM/YYYY)--"
                                                            className="flex-fill"
                                                            name="agedob"

                                                        />

                                                        <FormFeedback>Please enter valid Date</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={50} type="text" name="caste" id="caste" placeholder="Caste" onChange={(event) => this.handleChange("caste", event)} invalid={this.state.fields["invalidCaste"]} />
                                                            <FormFeedback>Please enter valid Caste Name</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={100} type="text" name="sewaType" id="sewaType" placeholder="Sewa Type" onChange={(event) => this.handleChange("service", event)} /></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={600} type="textarea" name="currentAddress" id="currentAddress" placeholder="Current Address" onChange={(event) => this.handleChange("addressRes", event)} invalid={this.state.fields["invalidAddressRes"]} /><span className="text-danger">*</span>
                                                            <FormFeedback>Please enter Address</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className="select-wrapper">
                                                        <div className="d-flex flex-row"><Input type="select" name="country" id="country" placeholder="Country" onChange={(event) => this.handleChange("countryName", event)} invalid={this.state.fields["invalidCountryName"]}>
                                                            <option key="select" value="Select">--Select Country--</option>
                                                            {this.createCountryItems()}
                                                        </Input><span className="text-danger ">*</span>
                                                            <FormFeedback>Please enter Country</FormFeedback></div>
                                                    </FormGroup >
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className={`${this.state.fields["countryName"] !== "INDIA" ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="state" id="state" placeholder="State" onChange={(event) => this.handleChange("stateName", event)} invalid={this.state.fields["invalidStateName"]}>
                                                            <option value="Select">--State--</option>
                                                            {this.createStateItems()}
                                                        </Input><span className="text-danger">*</span>
                                                            <FormFeedback>Please select state</FormFeedback></div>
                                                    </FormGroup>
                                                    <FormGroup className={`${this.state.fields["countryName"] === "INDIA" ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row"><Input type="text" name="state" id="state" defaultValue="" placeholder="State" onChange={(event) => this.handleChange("stateName", event)} invalid={this.state.fields["invalidStateName"]} />
                                                            <span className="text-danger">*</span><FormFeedback>Please enter valid state</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className={`${this.state.fields["showTextBoxCurrent"] ? "d-block" : "d-none"}`}>
                                                        <div className="d-flex flex-row"><Input maxLength={50} type="text" name="city" id="city" placeholder="City" onChange={(event) => this.handleChange("cityName", event)} invalid={this.state.fields["invalidCityName"]} /><span className="text-danger">*</span>
                                                            <FormFeedback>Please enter valid City</FormFeedback></div>
                                                        <div><Link className="d-block float-right text-dark" to="#" onClick={this.showTextBoxMethodCurrent}><small><u>City In list</u></small></Link></div>
                                                    </FormGroup>
                                                    <FormGroup className={`${!this.state.fields["showTextBoxCurrent"] ? "d-block" : "d-none"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="city" id="city" placeholder="City" onChange={(event) => this.handleChange("cityName", event)}>
                                                                <option key="select" value="Select">--City--</option>
                                                                {this.createCityItems()}
                                                        </Input><span className="text-danger">*</span><FormFeedback>Please select City</FormFeedback></div>
                                                        <div><Link className="d-block float-right text-dark" to="#" onClick={this.showTextBoxMethodCurrent}><small><u>City other than list</u></small></Link></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={600} type="textarea" name="nativeAddress" id="nativeAddress" placeholder="Native Address" onChange={(event) => this.handleChange("perAddressRes", event)} /></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="country" id="country" placeholder="Country" onChange={(event) => this.handleChange("perCountryName", event)}>
                                                            <option key="select" value="Select">--Select Country--</option>
                                                            {this.createCountryItems()}
                                                        </Input></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className={`${this.state.fields["perCountryName"] !== "INDIA" ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="state" id="state" placeholder="State" onChange={(event) => this.handleChange("perStateName", event)} invalid={this.state.fields["invalidPerStateName"]}>
                                                            <option value="Select">--State--</option>
                                                            {this.createStateItemsNative()}
                                                        </Input><FormFeedback>Please enter valid State Name</FormFeedback>
                                                            </div>
                                                </FormGroup>
                                                    <FormGroup className={`${this.state.fields["perCountryName"] === "INDIA" ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row"><Input type="text" name="state" id="state" placeholder="State" onChange={(event) => this.handleChange("perStateName", event)} invalid={this.state.fields["invalidPerStateName"]} />
                                                            <FormFeedback>Please enter valid State</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup className={`${this.state.fields["showTextBoxNative"] ? "d-block" : "d-none"}`}>
                                                       <div className="d-flex flex-row"><Input maxLength={50} type="text" name="city" id="city" placeholder="City" onChange={this.onNativeCityChange} onChange={(event) => this.handleChange("perCityName", event)} invalid={this.state.fields["invalidPerCityName"]} />
                                                            <FormFeedback>Please enter valid City</FormFeedback></div>
                                                        <Link className="text-dark float-right" to="#" onClick={this.showTextBoxMethodNative}><small><u>City in list</u></small></Link>
                                                    </FormGroup>
                                                    <FormGroup className={`${!this.state.fields["showTextBoxNative"] ? "d-block" : "d-none"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="city" id="city" placeholder="City" onChange={(event) => this.handleChange("perCityName", event)} invalid={this.state.fields["invalidPerCityName"]}>
                                                                <option key="select" value={-1}>--City--</option>
                                                                {this.createCityItemsNative()}
                                                            </Input></div>
                                                        <Link className="text-dark float-right" to="#" onClick={this.showTextBoxMethodNative}><small><u>City other than list</u></small></Link>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={20} type="text" name="stdCode" id="stdCode" placeholder="STD Code" onChange={(event) => this.handleChange("stdcode", event)} invalid={this.state.fields["invalidStdCode"]} />
                                                            <FormFeedback>Please enter valid STD Code</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={20} type="text" name="phone" id="phone" placeholder="Phone(Res.)" onChange={(event) => this.handleChange("phoneRes", event)} invalid={this.state.fields["invalidPhoneRes"]} />
                                                            <FormFeedback>Please enter valid Phone Number</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={200} type="text" name="addressWork" id="addressWork" placeholder="Address Shop/Office" onChange={(event) => this.handleChange("addressShop", event)} /></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={20} type="text" name="phoneWork" id="phoneWork" placeholder="Phone Shop/Office" onChange={(event) => this.handleChange("phoneShop", event)} invalid={this.state.fields["invalidPhoneShop"]} />
                                                            <FormFeedback>Please enter valid Phone Number</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={20} type="text" name="mobile" id="mobile" placeholder="Mobile" onChange={(event) => this.handleChange("mobile", event)} invalid={this.state.fields["invalidMobile"]} />
                                                            <FormFeedback>Please enter valid Mobile Number</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input type="email" name="email" id="email" placeholder="Email" onChange={(event) => this.handleChange("email", event)} invalid={this.state.fields["invalidEmail"]} /><span className="text-danger">*</span>
                                                            <FormFeedback>Please enter valid email</FormFeedback></div>
                                                    </FormGroup>
                                                </Col>


                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={50} type="text" name="website" id="website" placeholder="Website" onChange={(event) => this.handleChange("website", event)} /></div>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12} md={6} className="text-right">
                                                    <FormGroup className={`m-0 ${!this.state.fields["showOtherQualification"] ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row"><Input maxLength={150} type="text" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)} /></div>
                                                    </FormGroup>
                                                    <FormGroup className={`m-0 ${this.state.fields["showOtherQualification"] ? "d-none" : "d-block"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="qualification" id="qualification" placeholder="Qualification" onChange={(event) => this.handleChange("qualification", event)}>
                                                            <option key="Select" value="Select">--Qualification--</option>
                                                            {this.createQualificationItems()}
                                                        </Input></div>
                                                    </FormGroup>
                                                    <a className="d-block mb-3" onClick={() => this.showOtherQualification()}><small className="cursor-pointer"><u>{this.state.fields["showOtherQualification"] ? "Select from list" : "Other qualification"}</u></small></a>
                                                </Col>

                                                <Col sm={12} md={6} className="text-right">
                                                    <FormGroup className={`m-0 ${this.state.fields["showOtherProfession"] ? "d-block" : "d-none"}`}>
                                                        <div className="d-flex flex-row"><Input maxLength={100} type="text" name="profession" id="profession" placeholder="Profession" onChange={(event) => this.handleChange("profession", event)} /></div>
                                                    </FormGroup>
                                                    <FormGroup className={`m-0 ${!this.state.fields["showOtherProfession"] ? "d-block" : "d-none"}`}>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="profession" id="profession" placeholder="Profession" onChange={(event) => this.handleChange("profession", event)}>
                                                            <option key="Select" value="Select">--Profession--</option>
                                                            {this.createProfessionItems()}
                                                        </Input></div>
                                                    </FormGroup>
                                                    <a className="d-block mb-3" onClick={() => this.showOtherProfession()}><small className="cursor-pointer"><u>{!this.state.fields["showOtherProfession"] ? "Other Profession" : "Select from list"}</u></small></a>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"><Input maxLength={1000} type="textarea" name="description" id="description" placeholder="Description" onChange={(event) => this.handleChange("description", event)} /></div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row"> <Input maxLength={200} type="text" name="experience" id="experience" placeholder="Experience" onChange={(event) => this.handleChange("experience", event)} /></div>
                                                    </FormGroup>
                                                </Col>

                                                <Col sm={12} md={6}>
                                                    <FormGroup>
                                                        <div className="d-flex flex-row select-wrapper"><Input type="select" name="bloodGroup" id="bloodGroup" onChange={(event) => this.handleChange("bloodGroup", event)} >
                                                            <option key="Select" value="Select">--Blood Group--</option>
                                                            {this.createBloodGroupItems()}
                                                        </Input></div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <div className="d-flex justify-content-center align-items-center">
                                                <Button onClick={this.doRegister} className="register-button p-3 m-2 text-white">Register</Button>
                                                <Button onClick={this.resetField} className="register-button p-3 m-2 text-white">Reset</Button><br />

                                            </div>

                                            <div className="text-center p-2">
                                                <small >**Incomplete forms will not be accepted & forms with incorrect Email ID will be rejected</small>
                                            </div>
                                            <Alert color="danger" isOpen={this.props.message !== null && this.props.isSuccessful == 0} className="small"><p className="m-0">{this.props.message}</p></Alert>
                                            <Alert color="success" isOpen={this.props.message !== null && this.props.isSuccessful == 1} className={`small ${this.resetField}`}><p className="m-0">{this.props.message}</p></Alert>
                                            <div className="text-center">
                                                <Link className="buttonLink" to={process.env.PUBLIC_URL + "/Login"}><small>Back to login</small></Link> <br />
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
                : <AjaxLoader isOpen={this.props.isLoading} />

        );
    }
}

const mapStateToProps = (state) => {

    return {
        isSuccessful: state.RegistrationReducer.isSuccessful,
        message: state.RegistrationReducer.message,
        registering: state.RegistrationReducer.registering,
        address: state.AddressDetailReducer.address,
        isLoading: state.AddressDetailReducer.isLoading
    };
}


const mapDispatchToProps = dispatch => ({
    doRegister: (registerDetails) => {
        dispatch(doRegister(registerDetails));
    },
    addressDetail: () => {
        dispatch(addressDetail());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);