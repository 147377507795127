import React from 'react';
import { Col, Row,Media } from 'reactstrap';
import { connect } from 'react-redux';
import ReactTable from 'react-table'
import { videoFiles } from '../../actions/VideoSongListAction';
import "react-table/react-table.css";
import VideoPlayer from '../VideoPlayer';
import { AjaxLoader } from '../AjaxLoader';
import GalleryHeader from '../GalleryHeader';
import { If } from 'rc-if-else';
import NoItemFound from '../NoItemFoundPage';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from '../AudioPlayer';

class VideoSongs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            relativePath: '',
            relativeWebMPath: '',
            path: decodeURIComponent(this.props.location.search.substring(1)),
            directoryName: '', 
            sectionName:'',
            selected: '',
            foundThumbnailPath: false,
            filteredList:[]
        }
    }
    
    componentWillMount() {
        
        if (this.state.path !== null && this.state.path !== "") {
            this.state.directoryName = this.state.path.split("/");
            this.state.foundThumbnailPath = true;
            this.props.videoFiles(this.state.path);
        }
    }
   
    componentDidUpdate(prevProps) {
        
       if (this.props.VideoFiles !== prevProps.VideoFiles || this.props.search !== prevProps.search) {
            if (this.props.search === "") {
                this.setState({ filteredList: this.props.VideoFiles })
            } else {
                this.setState({ filteredList: this.props.VideoFiles.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
              
            }

        }
    }
    onSongChange(name) {
        
        if (this.state.path !== undefined && this.state.path !== '') {
            this.setState({
                relativePath: this.state.path + "/" + name,
                relativeWebMPath: this.state.path + "/WebM/" + name.substr(0, name.lastIndexOf(".")) + ".webm",
                selected: name
            });
        }

    }

    showVideoFiles = (filteredList) => {
        let fileType;
        if (filteredList !== null && filteredList.length > 0 && filteredList !== undefined) {
            const data = filteredList.map((name) => (
                fileType = this.getFileType(name),
                {
                    title:
                        <a href="#" className={this.state.selected === name ? 'font-weight-bold' : ''} onClick={() => this.onSongChange(name)}>
                        <div className={this.state.selected === name ? 'songNameDivBackground' : 'songNameBackground'}>
                        <div className="Track">
                                    <div className={fileType == "mp3" || fileType == "m4a" ? 'Track-image' : 'Track-imageVideo'} />
                            <div className="ml-2 mt-3 songNameTitle">{name}</div>
                        </div>
                            </div>
                        </a>,
                        artists: 'RamanSujati'
                }
            ));
            const columns = [
                {
                    Header: 'TITLE',
                    accessor: 'title',
                }
            ]
            return <ReactTable className="columnFlex mb-2"
                data={data}
                columns={columns}
                defaultPageSize={filteredList.length}
                PageSizeOptios={[3, 6]}
                showPagination={false}
            />
        }
    }
    getFileType = (filePath) => {
        const fileType = filePath.split('.').pop();
        return fileType.toLowerCase();
    }
    switchAudioVideo = () => {
        let fileType;
        if (this.state.relativePath !== "") {
            fileType = this.getFileType(this.state.relativePath);
        }
        else {
            fileType = this.getFileType(this.state.filteredList[0]);
        }
        switch (fileType) {
            case "mp4":
            case 'mov':
                return (<VideoPlayer relativePath={this.state.relativePath} relativeWebMPath={this.state.relativeWebMPath} />);
                break;
            case "mp3":
            case 'm4a':
                return (<AudioPlayer relativePath={this.state.relativePath} />);
                break;
            default:
                return "";
                break;
        }

    }
    render() {
        const fileType = this.getFileType(this.state.relativePath);
        if (this.state.filteredList != null)
        {
            return (
                <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                    <AjaxLoader isOpen={this.props.isLoading} />
                    <div className="breadcrumb-link"><Link className="pr-2" to={this.state.path.search('Vanchan') === -1 ? process.env.PUBLIC_URL + "/Gallery?Type=Videos" : process.env.PUBLIC_URL + "/Vanchan?Type=Videos"}>Videos</Link> <FontAwesomeIcon icon={faChevronRight} /> <span className="pl-2">{this.state.directoryName[1]}</span></div> 
                    <If condition={this.state.path.search('Vanchan') === -1}><GalleryHeader isActive='2' /></If>
                    {this.state.filteredList.length > 0 ?
                        <React.Fragment>
                            <div className="audioDirectoryName mb-4 mt-4">
                                <div className="d-flex align-items-center">
                                    <If condition={this.state.foundThumbnailPath}>
                                        <Media className="mr-3 directoryStyle" src={process.env.PUBLIC_URL +`/Media/` + this.state.directoryName[0] + `/Thumbnails/${this.state.directoryName[1]}.jpg`} />
                                        <b className="ml-3"> {this.state.directoryName[1]}</b>
                                    </If>
                                </div>

                            </div>
                            <Row>
                                <Col xl={6} lg={fileType === "mp4" || fileType === "mov" ? 6 : 12} className={fileType === "mp3" && "mt-3 d-flex flex-column align-items-center justify-content-center"}>
                                    {this.switchAudioVideo()}
                                </Col>
                                <Col xl={6} className="mb-2">
                                    {this.showVideoFiles(this.state.filteredList)}
                                </Col>
                            </Row>
                        </React.Fragment>
                        :
                        null
                    }
                </div>
            );
        }
        else
        {
            if (!this.props.isLoading) {
                return (<NoItemFound/>);
            }
            else
            {
                return (<AjaxLoader isOpen={this.props.isLoading} />);
            }
            
        }
       
    }
}

const mapStateToProps = (state) => {
   return {
       VideoFiles: state.VideoReducer.VideoFilesList,
       isLoading: state.VideoReducer.isLoading,
       search: state.SearchReducer.search
    }
};

const mapDispatchToProps = dispatch => ({
    videoFiles: (selectedFolderPath) => {
        dispatch(videoFiles(selectedFolderPath));

    }
}
);
  
export default connect(mapStateToProps, mapDispatchToProps)(VideoSongs);

