import './scss/default/default.scss';
import './scss/default/common.scss';
import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router';
import Layout from './components/Layout';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import RegisterMatrimonial from './components/Matrimonial/RegisterNewMatrimonial';

class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={process.env.PUBLIC_URL + '/'} component={Login} />
                <Route path={process.env.PUBLIC_URL + '/login'} component={Login} />)} />
                <Route path={process.env.PUBLIC_URL + '/Register'} component={Register} />
                <Route path={process.env.PUBLIC_URL + '/ForgotPassword'} component={ForgotPassword} />
                <Route path={process.env.PUBLIC_URL + '/RegisterMatrimonial'} component={RegisterMatrimonial} />
                <Layout props={{ ...this.props }}  />
            </Switch>
        );
    }
}

export default withRouter(App);