export default class CommonFunction {

    //Download file from the respective locations
    static downloadFile = (sectionName, subSectionName, fileName) => {

        if (sectionName !== "" || subSectionName !== "" || fileName !== "") {
            let url = process.env.PUBLIC_URL + "/api/utsav/downloadfile?sectionName=" + sectionName + "&subSectionName=" + subSectionName + "&fileName=" + fileName;
            return window.open(url, '_blank');
        }

    }
    static downloadPdfFile = (sectionName, fileName) => {

        let url = process.env.PUBLIC_URL + "/api/utsav/Downloadpdffile?sectionName=" + sectionName + "&fileName=" + fileName;
        return window.open(url, '_blank');

    }

    static getFolderList = (filteredList, activePage, folderPerPage) => {
        const indexOfLastTodo = activePage * folderPerPage;
        const indexOfFirstTodo = indexOfLastTodo - folderPerPage;
        return filteredList.slice(indexOfFirstTodo, indexOfLastTodo);
    }

    static getPageCount = (filteredList, folderPerPage) => {
        let pageNumber = null;
        for (let i = 1; i <= Math.ceil(filteredList.length / folderPerPage); i++) {
            pageNumber = i;
        }
        return pageNumber;
    }

}
