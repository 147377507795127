import "react-datetime/css/react-datetime.css";
import React from 'react';
import { connect } from 'react-redux';
import * as Datetime from 'react-datetime';

class DateTimePicker extends React.Component {
    render() {
        var valid = function (current) {
            return current.isBefore(new Date());
        };

        return (
            <React.Fragment>
                <Datetime
                    value={this.props.value}
                    viewMode={this.props.viewMode}
                    dateFormat={this.props.dateFormat}
                    renderInput={this.props.renderInput}
                    onChange={this.props.onChange}
                    isValidDate={valid}
                    inputProps={{
                        placeholder: this.props.placeholder,
                        id: this.props.id,
                        readOnly: true
                    }}
                    closeOnSelect={true}
                    className={this.props.className}
                    closeOnTab={true}
                    strictParsing={true}
                    timeFormat={false}
                />
            </React.Fragment>
        );
    }
}
export default connect(state => state)(DateTimePicker);