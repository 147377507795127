import React from 'react';
import { connect } from 'react-redux';
import { folderImage } from '../../actions/ImageAction';
import { Col, Media, Row } from 'reactstrap';
import { AjaxLoader } from '../AjaxLoader';
import { Link } from 'react-router-dom';
import NoItemFound from '../NoItemFoundPage';
import { withBreakpoints } from 'react-breakpoints';
import GalleryHeader from '../GalleryHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
class ImageFiles extends React.Component {
   
    constructor(props) {
        
        super(props);
        this.state = {
            path: '',
            isOpen: true,
            photoIndex: 0,
            prevPath: '',
            filteredList: [],
            imageRootPath:'',
        };
    }

    componentWillMount() {
        
        if (this.props.location.search !== null) {
            this.setState({ path: decodeURIComponent(this.props.location.search.substring(1)) });
        }
    }

    componentDidMount() {
        this.setState({imageRootPath:this.state.path.split("/")})
        
        if (this.props.location.search !== null) {
            this.props.folderImage(this.state.path);

        }
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.imageGalleryFilesName !== prevProps.imageGalleryFilesName || this.props.memberSectionImageFilesName !== prevProps.memberSectionImageFilesName || this.props.search !== prevProps.search) {
            if (this.props.search === "") {
                if (this.props.location.search.search('ChaturShlokiGranth') === -1) {
                    this.setState({ filteredList: this.props.imageGalleryFilesName });
                }
                else {
                    this.setState({ filteredList: this.props.memberSectionImageFilesName });
                }
            }
            else {
                if (this.props.location.search.search('ChaturShlokiGranth') === -1) {
                    this.setState({ filteredList: this.props.imageGalleryFilesName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
           
                }
                else {
                    this.setState({ filteredList: this.props.memberSectionImageFilesName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
                }
            }
          
        }
        if (!this.state.isOpen && this.props.location.search.search('ChaturShlokiGranth') === -1) {
            this.props.history.push(process.env.PUBLIC_URL + '/Gallery?Type=Photos');
        }
        else if (!this.state.isOpen && this.props.location.search.search('ChaturShlokiGranth') !== -1) {
            this.props.history.push(process.env.PUBLIC_URL + '/ChaturShlokiGranth');
        }
    }
   
    render() {
        if (this.state.filteredList !== null) {
           
                return (

                    <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                        <AjaxLoader isOpen={this.props.isLoading} />
                        <div>
                                {this.props.location.search.search("ChaturShlokiGranth") !== -1 ?
                                    <div>
                                        <Link className="pr-1" to={process.env.PUBLIC_URL + "/Members_Section"}>Members Section</Link>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    <Link className="pr-1" to={process.env.PUBLIC_URL + "/ChaturShlokiGranth"}><span className="pl-1">Chatur Shloki Granth</span></Link>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                    <span className="pl-1">{this.state.imageRootPath[1]}</span>
                                    </div> 
                                    : <div>
                                    <Link className="pr-1" to={process.env.PUBLIC_URL + "/Gallery?Type=Photos"}>Photos</Link>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                   <span className="pl-1">{this.state.imageRootPath[1]}</span>
                                     </div>}
                        </div> 
                        {this.props.location.search.search("ChaturShlokiGranth") !== -1 ? ""  :
                           <GalleryHeader isActive='3'/>}
                         <Row>
                            {this.state.filteredList.map(name => {
                                return (
                                    <Col xl="2" md="4" sm="3" xs="6" className="mt-4 mt-md-5 directorylist" key={name}>
                                        <Link to={{ pathname: process.env.PUBLIC_URL + "/ImageCarousel", search: `${this.state.path + '/big/' + name}`}}>
                                            <div className="photo-directory">
                                                <Media className="imageStyle" src={process.env.PUBLIC_URL + `/Media/${this.state.path}/small/${name}`}></Media>
                                            </div>
                                            <div className="directory-title text-black border border-top-0 border-primary rounded-bottom bg-white d-flex align-items-center justify-content-center px-2">
                                                {name}
                                            </div>
                                        </Link>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                );
          }
        else {
            if (!this.props.isLoading) {
                return (<NoItemFound />)
            }
            else {
                return (<AjaxLoader isOpen={this.props.isLoading} />);
            }
        }
    }
}

const mapStateToProps = (state) => {
     return {
        imageGalleryFilesName: state.ImageReducer.ImageGalleryFilesName,
        memberSectionImageFilesName: state.ImageReducer.MemberSectionImageFilesName,
        folderName: state.ImageReducer.selectedFolderPath,
         isLoading: state.ImageReducer.isLoading,
         search: state.SearchReducer.search
    };
};

const mapDispatchToProps = dispatch => ({
    folderImage: (selectedFolderPath) => {
        dispatch(folderImage(selectedFolderPath));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(ImageFiles));



