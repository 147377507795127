import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { audioFiles } from '../../actions/AudioSongsListAction';
import ReactTable from 'react-table'
import "react-table/react-table.css";
import AudioPlayer from '../AudioPlayer';
import { AjaxLoader } from '../AjaxLoader';
import GalleryHeader from '../GalleryHeader';
import { withBreakpoints } from 'react-breakpoints';
import VanchanHeader from '../VanchanComponent/VanchanHeader';
import NoItemFound from '../NoItemFoundPage';
import { checkDirectories } from '../../actions/AudioDirectoryAction';
import audioIcon from '../../images/music-folder.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import VideoPlayer from '../VideoPlayer';
    
class AudioSongsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativePath: '',
            path: decodeURIComponent(this.props.location.search),
            selected: '',
            directoryName: '',
            filteredList: [],
        };
    }

    componentDidMount() {

        if (this.state.path !== null) {
            const directoryName = this.state.path.split("/");
            this.setState({ directoryName: directoryName.pop() });
            this.props.audioFiles(this.state.path.substring(1));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.BhajanFilesList !== prevProps.BhajanFilesList || this.props.VanchanAudioFilesList !== prevProps.VanchanAudioFilesList || this.props.search !== prevProps.search) {
            this.state.path.search('Vanchan') === -1?
               ( this.props.search === ""?
                    this.setState({ filteredList: this.props.BhajanFilesList }):
                    this.setState({ filteredList: this.props.BhajanFilesList.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) }))
                :this.setState({ filteredList: this.props.VanchanAudioFilesList });
        }
    }
    onSongChange(name) {
        if (this.state.path !== undefined && this.state.path !== '') {
            this.setState({
                relativePath: this.state.path + "/" + name,
                selected: name
            });
        }
    }

    showAudioFiles = (filteredList) => {
        let fileType;
        if (filteredList !== null) {
            const data = filteredList.map((name) => (
                fileType = this.getFileType(name),
                {

                    title:
                        <a to="#" className={this.state.selected === name ? 'font-weight-bold' : ''} onClick={() => this.onSongChange(name)}>
                            <div className={this.state.selected === name ? ' songNameDivBackground' : 'songNameBackground'}>

                                <div className="Track">
                                    <div className={fileType == "mp3" || fileType == "mov" ? 'Track-image' : 'Track-imageVideo'} />
                                    <div className="ml-2 mt-2 songNameTitle">{name}
                                    </div>
                                </div>
                            </div>
                        </a>
                    ,
                    artists: 'RamanSujati'
                }

            ));

            const columns = [
                { accessor: 'title' }
            ];
            return < ReactTable className="columnFlex" showPagination={false}
                data={data}
                columns={columns}
                defaultPageSize={filteredList.length}
                PageSizeOptios={[3, 6]}
            />
        };
    }
    getFileType = (filePath) => {
        const fileType = filePath.split('.').pop();
        return fileType.toLowerCase();
    }
    switchAudioVideo = () => {
        let fileType;
        if (this.state.relativePath !== "") {
             fileType = this.getFileType(this.state.relativePath.substring(1));
        }
        else {
            fileType = this.getFileType(this.state.filteredList[0]);
        }
        switch (fileType) {
            case "mp4":
            case 'mov':
                return (<VideoPlayer relativePath={this.state.relativePath.substring(1)} relativeWebMPath={this.state.relativeWebMPath} />);
                break;
            case "mp3":
            case 'm4a':
                return (<AudioPlayer relativePath={this.state.relativePath.substring(1)} />);
                break;
            default:
                return "";
                break;
        }
        
    }
    render() {
        const fileType = this.getFileType(this.state.relativePath.substring(1));
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
       
        if (this.state.filteredList != null) {
            return (
                <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">

                    <AjaxLoader isOpen={this.props.isLoading} />
                    <div className="breadcrumb-link"><Link className="pr-2" to={this.state.path.search('Vanchan') === -1 ? process.env.PUBLIC_URL + "/Gallery?Type=Audios" : process.env.PUBLIC_URL + "/Vanchan?Type=Audios"}>Bhajan</Link><FontAwesomeIcon icon={faChevronRight} /><span className="pl-2">{this.state.directoryName}</span></div> 
                    {this.state.path.search('Vanchan') === -1 ? <GalleryHeader isActive='1' /> : <VanchanHeader isActive='1' />}
                    {this.state.filteredList.length > 0 ?
                        <React.Fragment>
                            <div className="audioDirectoryName mb-4 mt-4">
                                <div className="d-flex align-items-center">
                                    <img src={audioIcon} alt="audio icon" />
                                    <b className="ml-3"> {this.state.directoryName} </b>
                                </div>
                            </div>
                            <Row className="align-items-start">
                                <Col xl={6} lg={fileType === "mp3" || fileType === "m4a" ? 6 : 12} className={fileType === "mp3" || fileType === "m4a" && "mt-3 d-flex flex-column align-items-center justify-content-center"}>
                                    {isMobile || fileType === "mp4" || fileType === "mov"   ? "" : <div className=" h4 text-center pb-5">{this.state.selected}</div>}
                                    {this.switchAudioVideo()}
                                   
                                  </Col>
                                <Col lg={6} className="">
                                    {this.showAudioFiles(this.state.filteredList)}
                                </Col>
                            </Row>
                        </React.Fragment>
                        :
                        null}

                </div>
            );
        }
        else {
            return !this.props.isLoading ? <NoItemFound /> : null;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        BhajanFilesList: state.AudioReducer.BhajanFilesList,
        VanchanAudioFilesList: state.AudioReducer.VanchanAudioFilesList,
        isLoading: state.AudioReducer.isLoading,
        search: state.SearchReducer.search,
        isPresent: state.AudioReducer.isPresent,
    };
};

const mapDispatchToProps = dispatch => ({
    audioFiles: (selectedFolderPath) => {
        dispatch(audioFiles(selectedFolderPath));

    },
    checkDirectories: (selectedFolderPath) => {
        dispatch(checkDirectories(selectedFolderPath));

    }

}
);

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(AudioSongsList));