const initialState = {

    documentDirectoryName: [],
    documentGalleryDirPath: [],
    sectionName:''
}

const DocumentDirectoryReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_DOCUMENT_DIRECTORY_NAME':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_DOCUMENT_DIRECTORY_NAME':
            return Object.assign({}, state, { documentDirectoryName: action.directoryName, isLoading: false });
        case 'GET_DOCUMENT_GALLERY_DIR_PATH':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_DOCUMENT_GALLERY_DIR_PATH':
            return Object.assign({}, state, { documentGalleryDirPath: action.documentGalleryDirPath.docDirectoryPath, sectionName: action.documentGalleryDirPath.section, isLoading: false });

        default:
            return state;

    }

}

export default DocumentDirectoryReducer;
