import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { documentList } from '../../actions/DocumentListAction';
import { AjaxLoader } from '../AjaxLoader';
import CommonFunction from '../../common/CommonFunction';
import downloadFileIcon from '../../images/download-file-icon.png';
import previewFileIcon from '../../images/preview-icon.png';
import GalleryHeader from '../GalleryHeader'
import VanchanHeader from '../VanchanComponent/VanchanHeader';
import DefaultSectionHeader from '../../components/DefaultSectionHeader';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons';
class PdfFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relativePath: decodeURIComponent(this.props.location.search.substring(1)),
            directoryName: '',
            filteredList:[]
        };
    }

    componentDidMount() {
        if (this.state.relativePath !== null && this.props.relativePath !== '') {
            const directoryName = this.state.relativePath.split("/");
            this.setState({ directoryName: directoryName.pop() });
            this.props.documentList(this.state.relativePath);
        }
    }

    componentDidUpdate(prevProps) {
       
        if (this.props.pdfFiles !== prevProps.pdfFiles || this.props.search !== prevProps.search) {
            if (this.props.search !== undefined) {
                if (this.props.search === "") {
                    this.setState({ filteredList: this.props.pdfFiles })
                } else {
                    this.setState({ filteredList: this.props.pdfFiles.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });

                }
            }
            else {
                this.setState({ filteredList: this.props.pdfFiles })
            }
          
        }
    }

    showPdfFiles = () => {
        if (this.state.filteredList.length > 0) {
            let pdfFiles;
            pdfFiles = this.state.filteredList.map((file) => (
                <li className="utsav-list-items document-list-items" key={file}>
                    <div>{file}</div>
                    <React.Fragment key={file}>
                        <div className="utsav-items-icons">
                            <a href={"http://docs.google.com/gview?embedded=true&url=" + this.props.filePathURL + '/Media/' + this.state.relativePath + "/" + file + '.pdf'} target='_blank' type="application/pdf" title="Preview-file" key={file}>
                                    <span className="preview-icon"><img src={previewFileIcon} alt="preview" /></span>
                                    </a>
                            <a onClick={() => { CommonFunction.downloadPdfFile(this.state.relativePath, file) }} title="Download-file" className="file-icon">
                                <span className="download-icon"><img src={downloadFileIcon} alt="download" /></span>
                            </a>
                        </div>
                    </React.Fragment>
                </li>
            ));
            return pdfFiles;
        }
    }

    render() {
        return (
            <div className="h-100 content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <AjaxLoader isOpen={this.props.isLoading} />
                <div className="breadcrumb-link">
                    <Link className="pr-1" to={this.props.location.search.search("Vanchan") !== -1 ? process.env.PUBLIC_URL + "/Vanchan?Type=Documents" :
                        this.props.location.search.search("MembersSection") !== -1 ? process.env.PUBLIC_URL + "/Members_Section" : process.env.PUBLIC_URL + "/Gallery?Type=Documents"}>
                        {this.props.location.search.search("MembersSection") !== -1 ? "Members Section" : "Documents"}</Link><FontAwesomeIcon icon={faChevronRight} />
                    <span className="pl-1">{this.state.directoryName}</span>
                </div> 
               {this.props.location.search.search("Vanchan") !== -1 ? <VanchanHeader isActive='2' /> :
                    this.props.location.search.search("MembersSection") !== -1 ? <DefaultSectionHeader title="84 Vaishnavas" /> : <GalleryHeader isActive='4' />}
                <React.Fragment>
                    <Row>
                        <Col md="10" xl="6">
                            <div className="utsav-list">
                                <ul>
                                    <li className="utsav-title">
                                        <div><strong>{this.state.directoryName}</strong></div>
                                    </li>
                                    {this.showPdfFiles()}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        pdfFiles: state.DocumentListReducer.PdfFilesList,
        isLoading: state.DocumentListReducer.isLoading,
        search: state.SearchReducer.search,
        filePathURL: state.LoginReducer.filePathURL
    };
};

const mapDispatchToProps = dispatch => ({
    documentList: (selectedFolderPath) => {
        dispatch(documentList(selectedFolderPath));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfFiles);