import axios from 'axios';

export const documentGalleryDirPath = () => {

    return function (dispatch) {
        DocumentGalleryDirPath('/api/utsav/FetchDocumentDirectoryPath', dispatch);
    };
}

const DocumentGalleryDirPath = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const documentGalleryDirPath = response.data;
            dispatch(setPath(documentGalleryDirPath));

        })
    function get() { return { type: 'GET_DOCUMENT_GALLERY_DIR_PATH' }; }
};
function setPath(documentGalleryDirPath) { return { type: 'SET_DOCUMENT_GALLERY_DIR_PATH', documentGalleryDirPath }; }

export const documentDirectoryName = (DocumentFolderPath) => {
    
    return function (dispatch) {
        docDirectoryNames('/api/utsav/FetchDocumentDirectory', DocumentFolderPath, dispatch);
    };
}
const docDirectoryNames = (url, DocumentFolderPath, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url, { params: { DocumentFolderPath } })
        .then(function (response) {
            const directoryName = response.data;
            dispatch(setDirectories(directoryName));

        })
    function get() { return { type: 'GET_DOCUMENT_DIRECTORY_NAME' }; }
};
function setDirectories(directoryName) { return { type: 'SET_DOCUMENT_DIRECTORY_NAME', directoryName }; }
