import React from 'react';
import { connect } from 'react-redux';
import { rasalayPath } from '../../actions/RasalayAction';
import { resetDocumentFiles } from '../../actions/DocumentListAction';
class Rasalay extends React.Component {

    componentDidMount() {
        this.props.resetDocumentFiles();
        this.props.rasalayPath();
    }

    showDocumentComponent = () => {
        
        if (this.props.rasalaySection != null && this.props.rasalaySection != '') {
            this.props.history.push({
                pathname: process.env.PUBLIC_URL + '/DocumentList', search: `${this.props.rasalaySection}`});
        }
    }

    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">{this.showDocumentComponent()}</div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        rasalaySection: state.RasalayReducer.rasalaySection,
        isLoading: state.RasalayReducer.isLoading,
    };
}

const mapDispatchToProps = (dispatch) => ({    
    rasalayPath: () => {
        dispatch(rasalayPath());
    },
    resetDocumentFiles: () => {
        dispatch(resetDocumentFiles());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Rasalay);
