export const SetCurrentPageNumber = (currentpageNumber, showDetail) => {
    return function (dispatch) {
        dispatch(CurrentPageNumber(currentpageNumber, showDetail));
    }
};

export const CurrentPageNumber = (currentpageNumber, showDetail) => {
    return {
        type: "SET_CURRENT_PAGE_NUMBER", currentpageNumber, showDetail
    };
};