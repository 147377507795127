import axios from 'axios';

export const MaryadiVashnavasFiles = () => {
    
    return function (dispatch) {
        MaryadiVashnavasDetails('/api/maryadiVashnavas/FetchMaryadiVashnavasFiles', dispatch)
    };
}

const MaryadiVashnavasDetails = (url, dispatch) => {

    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const files = response.data;
            dispatch(setMaryadiVashnavasDetails(files));

        })
    function get() {
        return {
            type: 'GET_MARYADI_VASHNAVAS_FILE_NAME'
        };
    }
}

export const setMaryadiVashnavasDetails = (files) => {

    return {
        type: 'SET_MARYADI_VASHNAVAS_FILE_NAME',
        payload: files
    };
};