import React from "react";
import Tabulator from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import { withBreakpoints } from 'react-breakpoints';
import ChittabhedData from './Chittabhed.json'
class Chittabhed extends React.Component {
    el = React.createRef();
    componentDidMount() {
        this.buildTable();
    }
    buildTable = () => {
        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        this.tabulator = new Tabulator(this.el, {
            data: ChittabhedData,
            tooltips: true,
            autoResize: false,
            layout: "fitColumns",
            resizableRows: false,
            resizableColumns: true,
            responsiveLayout: isMobile ? "collapse" : "",
            columns: [
                { field: "serialno", formatter: "rownum", responsive: 0, width: 50 },
                { responsive: 1, field: "f1", width: 150},
                { responsive: 2, field: "f2", formatter: "textarea"}
            ],
        });
    }

    render() {
        return (
            <div className="pb-2 pt-2"><div id="tabulator" className="p-0 chittabhed" ref={el => (this.el = el)} /></div>
        )
    }
}
export default (withBreakpoints(Chittabhed));