const initialState = {
    rasalaySection:'',
}

const RasalayReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'GET_RASALAY_SECTION_PATH':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_RASALAY_SECTION_PATH':
            return Object.assign({}, state, { isLoading: false, rasalaySection: action.rasalaySectionPath });

        default:
            return state;


    }
}
export default RasalayReducer;