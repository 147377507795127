const initialState = {

    search:'',
}

const SearchReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case 'SET_SEARCH':
            return Object.assign({}, state, { search: action.searchDetail });
        case 'RESET_SEARCH':
            return Object.assign({}, state, { search: '' });
        default:
            return state;

    }

}

export default SearchReducer;
