var dateFormat = require('dateformat');
const initialState = {
    matrimonialDetails: [],
    matrimonialDetailsAdmin: [],
    isLoading: false,
    singleMemberLoader: false,
    isIndian: true,
    sortIncreasingOrder: true,
    memberPerPage: 10,
    isSearchMode: false,
    totalPageCount: 0,
    pageNumber: 0,
    showLayover: false,
    registerMessage: null,
    registering: false,
    isRegisterSuccessful: '',
    updateMessage: '',
    editedMember: '',
    country: "",
    city: "",
    approve: null,
    isNew: null,
    startDate: null,
    endDate: null,
    cityList: []
}

const MemberDirectoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MATRIMONIAL_NATIONALITY_TYPE':
            return Object.assign({}, state, { isIndian: action.isIndian });
        case 'SET_MATRIMONIAL_SORT_PROPERTY':
            return Object.assign({}, state, { sortIncreasingOrder: action.isIncreasingOrder });
        case 'SET_MATRIMONIAL_PER_PAGE_COUNT':
            return Object.assign({}, state, { memberPerPage: action.memberPerPage });
        case 'SET_MATRIMONIAL_PAGE_NUMBER':
            return Object.assign({}, state, { pageNumber: action.pageNumber });
        case 'SET_MATRIMONIAL_DETAILS':
            return Object.assign({}, state, { matrimonialDetails: action.payload.matrimonialInfo, totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_APPROVED_MATRIMONIAL_DETAILS_ADMIN':
            return Object.assign({}, state, { matrimonialDetailsAdmin: action.payload.matrimonialInfo, totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_PENDING_MATRIMONIAL_DETAILS_ADMIN':
            return Object.assign({}, state, { matrimonialDetailsAdmin: action.payload.matrimonialNewInfo, totalPageCount: action.payload.personInfoCount, isLoading: false });
        case 'SET_MATRIMONIAL_SEARCH_PROGRESS':
            return Object.assign({}, state, { isLoading: true });
        case 'SET_MATRIMONIAL_SINGLE_MEMBER_LOADER':
            return Object.assign({}, state, { singleMemberLoader: action.isLoading });
        case 'SET_MATRIMONIAL_SHOW_LAYOVER':
            return Object.assign({}, state, { showLayover: action.showLayover });
        case 'SET_MATRIMONIAL_UPDATE_MESSAGE':
            return Object.assign({}, state, { updateMessage: action.message });
        case 'SET_REGISTER_IN_PROGRESS':
            return Object.assign({}, state, { registering: action.isLoading }); 
        case 'SET_REGISTER_MESSAGE':
            return Object.assign({}, state, { registerMessage: action.message, isRegisterSuccessful: action.isSuccessfull, registering: false });
        case 'SET_EDITED_MEMBER':
            return Object.assign({}, state, { editedMember: action.payload, singleMemberLoader: false });
        case 'SET_ADMIN_FILTERS_MATRIMONIAL':
            return Object.assign({}, state, {
                country: action.country, city: action.city, approve: action.approve,
                isNew: action.isNew, startDate: action.startDate, endDate: action.endDate,
            });
        case 'DELETE_MEMBER_PENDING_MATRIMONIAL':
            var memberDetails = state.matrimonialDetailsAdmin.filter(item => { return item.id !== action.id });
            return Object.assign({}, state, { matrimonialDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'DELETE_MEMBER_APPROVED_MATRIMONIAL':
            var memberDetails = state.matrimonialDetailsAdmin.filter(item => { return item.rowId !== action.rowId });
            return Object.assign({}, state, { matrimonialDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'APPROVE_MEMBER_MATRIMONIAL':
            var memberDetails = [];
            for (var i = 0; i < state.matrimonialDetailsAdmin.length; i++) {
                var member = state.matrimonialDetailsAdmin[i];
                if (member.id === action.id) {
                    member.approve = true;
                    member.approveDate = dateFormat(new Date(), "isoDate");
                }
                memberDetails.push(member);
            }
            return Object.assign({}, state, { matrimonialDetailsAdmin: memberDetails, singleMemberLoader: false });
        case 'SET_CITY_LIST_MATRIMONIAL':
            return Object.assign({}, state, { cityList: action.payload });
        default:
            return state;
    }

}

export default MemberDirectoryReducer;