import axios from 'axios';

export const rasalayPath = () => {
    return function (dispatch) {
        RasalayPath('/api/rasalay/FetchRasalayPath', dispatch);
    };
}

const RasalayPath = (url, dispatch) => {
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const rasalaySectionPath = response.data;
            dispatch(setPath(rasalaySectionPath));
        })
};

function setPath(rasalaySectionPath) { return { type: 'SET_RASALAY_SECTION_PATH', rasalaySectionPath }; }
