const initialState = {
    message: null,
    registering: '',
    isSuccessful:'',
}

const RegistrationReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case 'REGISTER_REQUEST':
            return Object.assign({}, state, {registering: true });  
        case 'SET_REGISTER_SUCCESS':
            return Object.assign({}, state, { message: action.message.message, registering: false, isSuccessful: action.message.isSuccessfull }); 
        case 'SET_REGISTER_FALIURE':
            return Object.assign({}, state, { message: action.message.message, registering: false, isSuccessful: action.message.isSuccessfull}); 
        default:
            return state;
    }
}
export default RegistrationReducer ;