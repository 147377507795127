import React from 'react';
import { Media, Form, Input, FormGroup, Button } from 'reactstrap';
import { searchMemberDetailsForAdmin, setSortProperty, setMemberPerPage, setSearchInProgress, setpageNumber, setAdminFilters, getCityListForAdmin } from '../../../actions/MemberDirectoryAction';
import { addressDetail } from '../../../actions/AddressDetailsAction'
import { connect } from 'react-redux';
import MemberDetail from './MemberDetail';
import { AjaxLoader } from '../../AjaxLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NoItemFound from '../../NoItemFoundPage';

class PendingMemberDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            personName: '',
            email:''
        }
    }
    componentDidMount() {
        this.props.addressDetail(); 
        let searchFilters = {
            IsIncreasingOrder: true,
            MemberPerPage: this.props.memberPerPage,
        }
        this.props.searchMemberDetailsForAdmin(searchFilters);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.country !== prevState.country) {
            this.props.getCityListForAdmin(this.state.country);
        }
    }

    handleChange = (type, event) => {
        switch (type) {
            case "country":
                this.props.setAdminFilters(event.target.value, "", this.props.isNew, this.props.startDate, this.props.endDate, this.props.sortIncreasingOrder, this.props.active);
                this.setState({ country: event.target.value })
               break;
            case "city":
                this.props.setAdminFilters(this.props.country, event.target.value, this.props.isNew, this.props.startDate, this.props.endDate, this.props.sortIncreasingOrder, this.props.active);
                break;
            case "type":
                this.props.setAdminFilters(this.props.country, this.props.city,  event.target.value, this.props.startDate, this.props.endDate, this.props.sortIncreasingOrder, this.props.active);
                break;
            case "sort":
                this.props.setAdminFilters(this.props.country, this.props.city, this.props.isNew, this.props.startDate, this.props.endDate, event.target.value, this.props.active);
                break;
            case "personName":
                this.setState({ personName: event.target.value})
                break;
            case "email":
                this.setState({ email: event.target.value })
                break;
        }
    }

    createCountryName = () => {
        if (this.props.address.country.length > 0) {
            return this.props.address.country.map((country) =>
                <option key={country.countryName} value={country.countryName}>{country.countryName}</option>
            );
        }
    }
    onPerPageChange = (e) => {
        this.props.setMemberPerPage(parseInt(e.target.value));
        this.props.setpageNumber(0)
        let searchFilters = {
            City: this.props.city,
            Country: this.props.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder === "Select" ? true : this.props.sortIncreasingOrder,
            MemberPerPage: parseInt(e.target.value),
            PageNumber: 0,
            EndDate: this.props.endDate,
            StartDate: this.props.startDate,
            IsNew: this.props.isNew === "Select" ? null : this.props.isNew,
            MemberName: this.state.personName,
            Email: this.state.email,
        }
        this.props.searchMemberDetailsForAdmin(searchFilters);
    }

    searchMember = () => {
        
        this.props.setSearchInProgress();
        let searchFilters = {
            City: this.props.city,
            Country: this.props.country,
            IsIncreasingOrder: this.props.sortIncreasingOrder === "Select" ? true : this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            PageNumber: 0,
            EndDate: this.props.endDate,
            StartDate: this.props.startDate,
            IsNew: this.props.isNew === "Select" ? null : this.props.isNew,
            MemberName: this.state.personName,
            Email: this.state.email,
        }
        this.props.searchMemberDetailsForAdmin(searchFilters);
    }
    handlePreviousPageClick = () => {
        if (this.props.pageNumber !== 0) {
            this.props.setpageNumber(this.props.pageNumber - 1)
            let searchFilters = {
                City: this.props.city,
                Country: this.props.country,
                IsIncreasingOrder: this.props.sortIncreasingOrder === "Select" ? true : this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                PageNumber: this.props.pageNumber - 1,
                EndDate: this.props.endDate,
                StartDate: this.props.startDate,
                IsNew: this.props.isNew === "Select" ? null : this.props.isNew,
                MemberName: this.state.personName,
                Email: this.state.email
            }
            this.props.searchMemberDetailsForAdmin(searchFilters);
        }
    }

    handleNextPageClick = () => {
        if (this.props.totalPageCount !== this.props.pageNumber) {
            this.props.setpageNumber(this.props.pageNumber + 1)
            let searchFilters = {
                City: this.props.city,
                Country: this.props.country,
                IsIncreasingOrder: this.props.sortIncreasingOrder === "Select" ? true : this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                PageNumber: this.props.pageNumber + 1,
                EndDate: this.props.endDate,
                StartDate: this.props.startDate,
                IsNew: this.props.isNew === "Select" ? null : this.props.isNew,
                MemberName: this.state.personName,
                Email: this.state.email,
            }
            this.props.searchMemberDetailsForAdmin(searchFilters);
        }
    }

    setEndDate = (date) => {
        this.props.setAdminFilters(this.props.country, this.props.city, this.props.isNew, this.props.startDate, date,this.props.sortIncreasingOrder);
    }

    setStartDate = (date) => {
        this.props.setAdminFilters(this.props.country, this.props.city, this.props.isNew, date, this.props.endDate, this.props.sortIncreasingOrder);
    }

    

    createCityName = () => {
        if (this.props.cityList.length > 0) {
            return this.props.cityList.map((city) =>
                <option key={city} value={city} > {city}</option>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                            <Form className="row mb-3 mb-md-4">
                        <FormGroup className={`col-12 col-md-3 select-wrapper matrimonials-select ${this.props.approve ? 'd-none' : 'd-block'}`}>
                                    <Input className="bg-transparent border-dark" type="select" name="type" id="type" placeholder="type" onChange={(event) => this.handleChange("type", event)}>
                                        <option value="Select">--Approval Request Type--</option>
                                        <option value="True">New</option>
                                        <option value="False">Modified</option>
                                    </Input>
                        </FormGroup>
                        <FormGroup className="col-12 col-md-3">
                            <Input className="bg-transparent border-dark" type="text" name="personName " id="personName" onChange={(event) => this.handleChange("personName", event)} placeholder="--PersonName--" />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-3">
                            <Input className="bg-transparent border-dark" type="text" name="email " id="email" onChange={(event) => this.handleChange("email", event)} placeholder="--Email--" />
                        </FormGroup>
                        

                        <FormGroup className="col-12 col-md-3">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(date) => this.setStartDate(date)}
                                        selected={this.props.startDate}
                                        placeholderText="--Start Date--"
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        highlightDates
                                        peekNextMonth
                                    />
                                </FormGroup>
                        <FormGroup className="col-12 col-md-3">
                                    <DatePicker
                                        dateFormat="yyyy-MM-dd"
                                        onChange={(date) => this.setEndDate(date)}
                                        selected={this.props.endDate}
                                        placeholderText="--End Date--"
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                    />
                        </FormGroup>
                        <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                            <Input className="bg-transparent  border-dark" type="select" name="country" id="country" placeholder="country" onChange={(event) => this.handleChange("country", event)}>
                                <option value="Select">--Country--</option>
                                {this.createCountryName()}
                            </Input>
                        </FormGroup>
                        <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                            <Input className="bg-transparent border-dark" type="select" name="city" id="city" onChange={(event) => this.handleChange("city", event)} >
                                <option value="Select">--City--</option>
                                {this.createCityName()}
                            </Input>
                        </FormGroup>
                        <FormGroup className="col-12 col-md-3 select-wrapper matrimonials-select">
                            <Input className="bg-transparent  border-dark" type="select" name="sort" id="sort" placeholder="Sort By" onChange={(event) => this.handleChange("sort", event)}>
                                <option value="Select">--Sort By Request Date--</option>
                                <option value="False">Ascending</option>
                                <option value="True">Descending</option>
                            </Input>
                        </FormGroup>
                               
                                <div className="col-12 col-md-3">
                                    <Button onClick={(event) => this.searchMember(event)} className="search-button bg-primary border-0 text-white">Search</Button>
                                </div>
                            </Form>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="font-weight-bold">List of all Members</div>
                                <div className="d-flex align-items-center">Per page:
                                    <div className="select-wrapper per-page-item">
                                        <Input type="select" name="country" id="country" placeholder="country" onChange={(event) => this.onPerPageChange(event)} className="item-per-page d-inline bg-transparent border-dark ml-1">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                        </Input>
                                    </div>
                                </div>
                    </div>
                    {
                        this.props.address.country.length > 0 ?
                            <div>
                            <div className="col-md-12 py-3 px-0 m-0">
                                    <AjaxLoader isOpen={this.props.isLoading} />
                                {this.props.memberDetailsAdmin.length > 0 ? < MemberDetail approve={false}/> : <NoItemFound />}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button onClick={this.handlePreviousPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== 0 ? "d-block" : "d-none"}`}>Previous</button>
                                <button onClick={this.handleNextPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== this.props.totalPageCount - 1 ? "d-block" : "d-none"}`}>Next</button>
                                </div> </div>: null
                            }
                        
            </React.Fragment>
                
        );
    }
}

const mapStateToProps = (state) => {
    return {
        memberDetailsAdmin: state.MemberDirectoryReducer.memberDetailsAdmin,
        sortIncreasingOrder: state.MemberDirectoryReducer.sortIncreasingOrder,
        memberPerPage: state.MemberDirectoryReducer.memberPerPage,
        pageNumber: state.MemberDirectoryReducer.pageNumber,
        isLoading: state.MemberDirectoryReducer.isLoading,
        totalPageCount: state.MemberDirectoryReducer.totalPageCount,
        address: state.AddressDetailReducer.address,
        country: state.MemberDirectoryReducer.country,
        city: state.MemberDirectoryReducer.city,
        isNew: state.MemberDirectoryReducer.isNew,
        startDate: state.MemberDirectoryReducer.startDate,
        endDate: state.MemberDirectoryReducer.endDate,
        cityList: state.MemberDirectoryReducer.cityList,
    };
}

const mapDispatchToProps = dispatch => ({
    searchMemberDetailsForAdmin: (memberSearchFilters) => {
        dispatch(searchMemberDetailsForAdmin(memberSearchFilters));
    },
    setSortProperty: (isIncreasingOrder) => {
        dispatch(setSortProperty(isIncreasingOrder));
    },
    setMemberPerPage: (memberPerPage) => {
        dispatch(setMemberPerPage(memberPerPage));
    },
    setpageNumber: (pageNumber) => {
        dispatch(setpageNumber(pageNumber));
    },
    setSearchInProgress: () => {
        dispatch(setSearchInProgress());
    },
    addressDetail: () => {
        dispatch(addressDetail());
    },
    setAdminFilters: (country, city, isNew, startDate, endDate, sortIncreasingOrder) => {
        dispatch(setAdminFilters(country, city, isNew, startDate, endDate, sortIncreasingOrder));
    },
    getCityListForAdmin: (countryName) => {
        dispatch(getCityListForAdmin(countryName));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingMemberDirectory);

