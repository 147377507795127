const initialState = {
    subject: '',
    message: '',
    to: '',
    emailList: '',
    isEmailSend: '',
    sentEmailList: [],
    isLoading: ''
}

const MailToMembersReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case 'SET_MAIL_DETAILS':
            return Object.assign({}, state, { to: action.mailDetails.to, message: action.mailDetails.message, subject: action.mailDetails.subject });
        case 'SET_EMAIL_LIST':
            return Object.assign({}, state, { emailList: action.emailList });
        case 'SET_EMAIL_STATUS':
            return Object.assign({}, state, { emailList: action.status, isEmailSend: action.status });
        case 'SET_SENT_EMAIL_DETAILS':
            return Object.assign({}, state, { sentEmailList: action.sentEmaildetails, isLoading: false });
        case 'GET_SENT_EMAIL_DETAILS':
            return Object.assign({}, state, { isLoading: true });
        default:
            return state;

    }

}

export default MailToMembersReducer;