import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

// Reducers
import LoginReducer from './LoginReducer';
import SidebarNavReducer from './SidebarNavReducer';
import RegistrationReducer from './RegistrationReducer';
import ForgotPasswordReducer from './ForgotPasswordReducer';
import AddressDetailReducer from './AddressDetailReducer';
import AudioGalleryReducer from './AudioGalleryReducer';
import RasalayReducer from './RasalayReducer';
import VideoGalleryReducer from './VideoGalleryReducer';
import PhotoGalleryReducer from './PhotoGalleryReducer';
import ImageCarouselReducer from './ImageCarouselReducer';
import UtsavListReducer from './UtsavListReducer';
import UtsavListYearWiseReducer from './UtsavListYearWiseReducer';
import VanchanReducer from './VanchanReducer';
import AudioReducer from './AudioReducer';
import VideoReducer from './VideoReducer';
import DocumentDirectoryReducer from './DocumentDirectoryReducer';
import DocumentListReducer from './DocumentListReducer';
import MatrimonialReducer from './MatrimonialReducer';
import MaryadiVashnavasReducer from './MaryadiVashnavasReducer';
import MembersSectionReducer from './MembersSectionReducer';
import ImageReducer from './ImageReducer';
import MemberDirectoryReducer from './MemberDirectoryReducer';
import SearchReducer from './SearchReducer';
import PushtimargDetailReducer from './PushtimargDetailReducer';
import MailToMembersReducer from './MailToMembersReducer';
import MailStatusReducer from './MailStatusReducer';
export const history = createBrowserHistory();

const reducers = {
    LoginReducer: LoginReducer,
    SidebarNavReducer: SidebarNavReducer,
    RegistrationReducer: RegistrationReducer,
    ForgotPasswordReducer: ForgotPasswordReducer,
    AddressDetailReducer: AddressDetailReducer,
    AudioGalleryReducer: AudioGalleryReducer,
    RasalayReducer: RasalayReducer,
    VideoGalleryReducer: VideoGalleryReducer,
    PhotoGalleryReducer: PhotoGalleryReducer,
    ImageCarouselReducer: ImageCarouselReducer,
    UtsavListReducer: UtsavListReducer,
    UtsavListYearWiseReducer: UtsavListYearWiseReducer,
    VanchanReducer: VanchanReducer,
    AudioReducer: AudioReducer,
    VideoReducer: VideoReducer,
    DocumentDirectoryReducer: DocumentDirectoryReducer,
    DocumentListReducer: DocumentListReducer,
    MatrimonialReducer: MatrimonialReducer,
    MaryadiVashnavasReducer: MaryadiVashnavasReducer,
    MembersSectionReducer: MembersSectionReducer,
    ImageReducer: ImageReducer,
    MemberDirectoryReducer: MemberDirectoryReducer,
    SearchReducer: SearchReducer,
    PushtimargDetailReducer: PushtimargDetailReducer,
    MailStatusReducer: MailStatusReducer,
    MailToMembersReducer: MailToMembersReducer
};

const appReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;