import React from 'react';
import { withBreakpoints } from 'react-breakpoints';
import 'react-image-lightbox/style.css';
import Pagination from "react-js-pagination";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Media, Row } from 'reactstrap';
import { folderImage, imagesDirectoryName, resetImageFiles } from '../../actions/ImageAction';
import { AjaxLoader } from '../AjaxLoader';
import NoItemFound from '../NoItemFoundPage';
import CommonFunction from '../../common/CommonFunction';

class ImageDirectories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            selectedFolderName: '',
            filteredList: [],
            activePage: 1,
            folderPerPage: 12,
        };
    }

    componentDidMount() {
        this.props.resetImageFiles();
        if (this.props.imageDirectoryPath != null) {
            this.props.imagesDirectoryName(this.props.imageDirectoryPath);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.ImageGalleryDirectoryName !== prevProps.ImageGalleryDirectoryName || this.props.MemberSectionImageDirectoryName !== prevProps.MemberSectionImageDirectoryName || this.props.search !== prevProps.search) {

            if (this.props.imageDirectoryPath.search('ChaturShlokiGranth') === -1) {
                if (this.props.search === "") {
                    this.setState({ filteredList: this.props.ImageGalleryDirectoryName });

                } else {

                    this.setState({ filteredList: this.props.ImageGalleryDirectoryName.filter((name) => name.toLowerCase().includes(this.props.search.toLowerCase())) });
                }
            }
            else if (this.props.imageDirectoryPath.search('ChaturShlokiGranth') !== 1) {
                this.setState({ filteredList: this.props.MemberSectionImageDirectoryName });
            }
        }
    }
    handlePageClick = (pageNumber) => {
        this.setState({ activePage: pageNumber });

    }
    GetImages = (selectedFolderName) => {

        const path = this.props.SectionName + '/' + selectedFolderName;
        this.props.folderImage(path);
        this.state.isOpen = true;
    }
    render() {

        const { breakpoints, currentBreakpoint } = this.props;
        let isMobile = breakpoints[currentBreakpoint] < breakpoints.desktop;
        const { filteredList, activePage, folderPerPage } = this.state;
        let ListofFolders;
        if (isMobile) 
            ListofFolders = filteredList;
        else 
            ListofFolders = CommonFunction.getFolderList(filteredList, activePage, folderPerPage);
        
        
        let pageNumber = CommonFunction.getPageCount(filteredList, folderPerPage);

        if (ListofFolders !== null) {
            if (ListofFolders.length > 0) {
                return (
                    <React.Fragment>
                        <AjaxLoader isOpen={this.props.isLoading} />
                        <Row>
                            {ListofFolders.map(name => {
                                return (

                                    <Col xl="2" md="4" sm="3" xs="6" className="mt-4 mt-md-5 directorylist" key={name}>
                                        <Link to={{ pathname: process.env.PUBLIC_URL + "/ImageFiles", search: `${this.props.SectionName + "/" + name}` }}>
                                            <div className="photo-directory">
                                                <Media className="imageStyle" src={process.env.PUBLIC_URL + `/Media/${this.props.SectionName}/Thumbnails/${name}.jpg`}></Media>
                                            </div>
                                            <div className="directory-title text-black border border-top-0 border-primary rounded-bottom bg-white d-flex align-items-center justify-content-center px-2">
                                                {name}
                                            </div>
                                        </Link>
                                    </Col>
                                );
                            })}

                        </Row>
                        {isMobile || pageNumber === 1 ? "" :
                            <div className="pushtimarg-pagination pt-10">
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={1}
                                    totalItemsCount={pageNumber}
                                    onChange={this.handlePageClick}
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                    itemClassPrev="pagination-previous"
                                    itemClassNext="pagination-next"
                                    itemClassFirst="pagination-firstPageText"
                                    itemClassLast="pagination-lastPageText"
                                    activeClass="pagination-active"
                                />
                            </div>}

                    </React.Fragment>
                );
            }
            else {
                if (!this.props.isLoading) {
                    return (<NoItemFound />)
                }
                else {
                    return (<AjaxLoader isOpen={this.props.isLoading} />);
                }
            }
        }
    };
}

const mapStateToProps = (State) => {

    return {
        ImageGalleryDirectoryName: State.ImageReducer.ImageGalleryDirectoryName,
        MemberSectionImageDirectoryName: State.ImageReducer.MemberSectionImageDirectoryName,
        isLoading: State.ImageReducer.isLoading,
        images: State.ImageReducer.images,
        folderName: State.ImageReducer.selectedFolderPath,
        search: State.SearchReducer.search,
    };

};

const mapDispatchToProps = dispatch => ({
    imagesDirectoryName: (folderPath) => {
        dispatch(imagesDirectoryName(folderPath));
    },
    resetImageFiles: () => {
        dispatch(resetImageFiles());
    },
    folderImage: (folderPath) => {
        dispatch(folderImage(folderPath));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(ImageDirectories));


