import React from 'react';
import { Media, Form, Input, FormGroup, Button } from 'reactstrap';
import { SetMembersNationalityType, searchMemberDetails, setSortProperty, setMemberPerPage, setSearchInProgress, setpageNumber, getCityList } from '../../actions/MemberDirectoryAction';
import { addressDetail } from '../../actions/AddressDetailsAction'
import { connect } from 'react-redux';
import MemberDetail from './MemberDetail';
import DownloadIcon from '../../images/download-icon.png';
import WebsiteIcon from '../../images/web-icon.png';
import SortingICon from '../../images/sorting-icon.png';
import ExcelExport from '../../images/excel-export.png';
import { AjaxLoader } from '../AjaxLoader';
import { CSVLink } from "react-csv";
import NoItemFound from '../NoItemFoundPage'
import ReactTooltip from 'react-tooltip';

class MemberDirectory extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            city: "",
            country: "",
            memberName: "",
            boodGroup: "",
            profession: "",
        }
        this.memberDirectoryFilters = React.createRef();
    }

    componentDidMount() {
        this.props.addressDetail();
        let natioanlityFilters = {
            IsIndian: true,
            isUS: false,
            isUK: false,
            isOther: false
        }
        this.props.getCityList(natioanlityFilters);
        let searchFilters = {
            IsIncreasingOrder: true,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
            isUS: this.props.isUS,
            isUK: this.props.isUK,
            isOther: this.props.isOther
        }
        this.props.searchMemberDetails(searchFilters);
    }

    SetSearchNationality = (isIndian, isUS, isUK, isOther) => {
        this.props.setSearchNationality(isIndian, isUS, isUK, isOther);
        if (!isOther) {
            let natioanlityFilters = {
                IsIndian: isIndian,
                isUS: isUS,
                isUK: isUK,
                isOther: isOther
            }
            this.props.getCityList(natioanlityFilters);
        }
        this.setState({
            city: "",
            country: "",
            memberName: "",
            boodGroup: "",
            profession: "",
        });
        this.resetField();
        let searchFilters = {
            MemberName: this.state.memberName,
            City: "",
            Country: "",
            BloodGroup: "",
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: isIndian,
            PageNumber: 0,
            isUS: isUS,
            isUK: isUK,
            isOther: isOther,
            Profession: "",
            memberName: "",
        }
        this.props.searchMemberDetails(searchFilters);
    }


    createBloodGroupItems() {
        const listItems = this.props.address.bloodGroups.map((bloodGroup) =>
            <option key={bloodGroup.SystemId} value={bloodGroup.code}>{bloodGroup.code}</option>
        );
        return listItems;
    }

    openRegistrtationPage = () => {
        const win = window.open(process.env.PUBLIC_URL + "/Register", "_blank");
        win.focus();
    }
    handleChange = (type, event) => {
        this.setState({ [type]: event.target.value });
    }
    createCountryName = () => {
        if (this.props.address.country.length > 0 && !this.props.isIndian) {
            return this.props.address.country.map((country) =>
                (country.countryName != "UNITED KINGDOM" && country.countryName != "UNITED STATES" && country.countryName != "INDIA") ? < option key={country.countryName} value={country.countryName} > {country.countryName}</option > : null
            );
        }
    }

    createCityName = () => {
        if (this.props.cityList.length > 0) {
            return this.props.cityList.map((city) =>
                <option key={city} value={city} > {city}</option>
            );
        }
    }

    setSortingOrder = () => {
        this.props.setSortProperty(!this.props.sortIncreasingOrder);
        let searchFilters = {
            MemberName: this.state.memberName,
            City: this.state.city,
            Country: this.state.country,
            BloodGroup: this.state.bloodGroup,
            IsIncreasingOrder: !this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
            PageNumber: this.props.pageNumber,
            Profession: this.state.profession,
            isUS: this.props.isUS,
            isUK: this.props.isUK,
            isOther: this.props.isOther
        }
        this.props.searchMemberDetails(searchFilters);

    }
    onPerPageChange = (e) => {
        this.props.setMemberPerPage(parseInt(e.target.value));
        this.props.setpageNumber(0)
        let searchFilters = {
            MemberName: this.state.memberName,
            City: this.state.city,
            Country: this.state.country,
            BloodGroup: this.state.bloodGroup,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: parseInt(e.target.value),
            IsIndian: this.props.isIndian,
            PageNumber: 0,
            Profession: this.state.profession,
            isUS: this.props.isUS,
            isUK: this.props.isUK,
            isOther: this.props.isOther
        }
        this.props.searchMemberDetails(searchFilters);
    }
    searchMember = (event) => {
        event.preventDefault();
        this.props.setSearchInProgress();
        this.props.setpageNumber(0)
        let searchFilters = {
            MemberName: this.state.memberName,
            City: this.state.city,
            Country: this.state.country,
            BloodGroup: this.state.bloodGroup,
            IsIncreasingOrder: this.props.sortIncreasingOrder,
            MemberPerPage: this.props.memberPerPage,
            IsIndian: this.props.isIndian,
            PageNumber: 0,
            Profession: this.state.profession,
            isUS: this.props.isUS,
            isUK: this.props.isUK,
            isOther: this.props.isOther
        }
        this.props.searchMemberDetails(searchFilters);
    }
    handlePreviousPageClick = () => {
        if (this.props.pageNumber !== 0) {
            this.props.setpageNumber(this.props.pageNumber - 1)
            let searchFilters = {
                MemberName: this.state.memberName,
                City: this.state.city,
                Country: this.state.country,
                BloodGroup: this.state.bloodGroup,
                IsIncreasingOrder: this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                IsIndian: this.props.isIndian,
                PageNumber: this.props.pageNumber - 1,
                Profession: this.state.profession,
                isUS: this.props.isUS,
                isUK: this.props.isUK,
                isOther: this.props.isOther
            }
            this.props.searchMemberDetails(searchFilters);
        }
    }
    handleNextPageClick = () => {
        if (this.props.totalPageCount !== this.props.pageNumber) {
            this.props.setpageNumber(this.props.pageNumber + 1)
            let searchFilters = {
                MemberName: this.state.memberName,
                City: this.state.city,
                Country: this.state.country,
                BloodGroup: this.state.bloodGroup,
                IsIncreasingOrder: this.props.sortIncreasingOrder,
                MemberPerPage: this.props.memberPerPage,
                IsIndian: this.props.isIndian,
                PageNumber: this.props.pageNumber + 1,
                Profession: this.state.profession,
                isUS: this.props.isUS,
                isUK: this.props.isUK,
                isOther: this.props.isOther
            }
            this.props.searchMemberDetails(searchFilters);
        }
    }

    headers = [
        { label: "Person Name", key: "personName" },
        { label: "FatherMother Name", key: "fatherMotherName" },
        { label: "Address Residence", key: "addressRes" },
        { label: "Address Shop", key: "addressShop" },
        { label: "DOB", key: "ageDob" },
        { label: "Approve Date", key: "approveDate" },
        { label: "Blood Group", key: "bloodGroup" },
        { label: "Caste", key: "caste" },
        { label: "City Name", key: "cityName" },
        { label: "Country Name", key: "countryName" },
        { label: "Description", key: "description" },
        { label: "Email", key: "email" },
        { label: "Experience", key: "experience" },
        { label: "IsActive", key: "isActive" },
        { label: "Mobile", key: "mobile" },
        { label: "Permanent Address", key: "perAddressRes" },
        { label: "Permanent City Name", key: "perCityName" },
        { label: "Permanent Country Name", key: "perCountryName" },
        { label: "Permanent State Name", key: "perStateName" },
        { label: "Phone Residence", key: "phoneRes" },
        { label: "Phone Shop", key: "phoneShop" },
        { label: "Profession", key: "profession" },
        { label: "Qualification", key: "qualification" },
        { label: "Service", key: "service" },
        { label: "Spouse Name", key: "spouseName" },
        { label: "State Name", key: "stateName" },
        { label: "Std Code", key: "stdcode" },
        { label: "Website", key: "website" }
    ];

    handleClick = () => {
        this.inputElement.click();
    }

    resetField = () => {
        this.memberDirectoryFilters.current.reset();
    }



    render() {
        return (
            <div className="content-section pt-3 pt-sm-5 pb-3 pb-sm-5">
                <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center pb-2 mb-4 page-title-wrapper">
                    <a target="_blank" href={require('../../files/RamanSujatiNewMemberRegistrationForm.pdf')} className="text-link" ref={input => this.inputElement = input}></a>
                    <div className="w-100 my-md-2 d-flex justify-content-between flex-column flex-md-row">
                        <h1 className="text-primary mb-3 mb-md-2">Members Directory</h1>
                        <div className="d-flex flex-row align-items-center align-self-end mb-2 mb-md-0">
                            <span className="pr-2">New Member Form</span>
                            <span className="member-directory-icon rounded border border-dark p-1" data-tip=' Register Online' ><Media id="registerOnline" className="img-fluid" src={WebsiteIcon} alt="register" onClick={() => this.openRegistrtationPage()} />
                            </span>
                            <span id="downloadForm" className="member-directory-icon rounded border border-dark p-1 mx-1" data-tip='Download Form'><Media className="img-fluid" src={DownloadIcon} alt="download form" onClick={() => this.handleClick()} /></span>
                            <CSVLink id="exportToExcel" className="member-directory-icon rounded border border-dark p-1" data={this.props.memberDetails} headers={this.headers} data-tip='Export to  Excel'>
                                <Media className="img-fluid" src={ExcelExport} alt="Export excel" />
                            </CSVLink>

                            <ReactTooltip effect="solid" place="bottom" type="error" border="true" />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column w-100">
                    <div className="pb-4 member-detail-text">Modify Details (Existing Members): To send request for change in details, please click on “Modify” button against your name in directory & update details & submit. An email will go to administrator & he will update the same.</div>
                    <div className="">
                        <div className="nationality-button">
                            <button onClick={() => this.SetSearchNationality(true, false, false, false)} className={this.props.isIndian ? "selected-nationality" : ""}>India</button>
                            <button onClick={() => this.SetSearchNationality(false, true, false, false)} className={this.props.isUS ? "selected-nationality" : ""}>US</button>
                            <button onClick={() => this.SetSearchNationality(false, false, true, false)} className={this.props.isUK ? "selected-nationality" : ""}>UK</button>
                            <button onClick={() => this.SetSearchNationality(false, false, false, true)} className={this.props.isOther ? "selected-nationality" : ""}>Other Countries</button>

                        </div>
                        <Form className="py-4 py-md-4 search-container" innerRef={this.memberDirectoryFilters}>
                            <FormGroup className="row">
                                <div className="col-md-2 mb-2 mb-md-0 pr-md-2">
                                    <Input className="bg-transparent border-dark" type="text" name="userName " autoFocus id="userName" onChange={(event) => this.handleChange("memberName", event)} placeholder="Username" />
                                </div>
                                <div className={`col-md-2 mb-2 mb-md-0 px-md-2 select-wrapper mob-select-icon ${this.props.isOther ? "d-none" : "d-block"}`}>
                                    <Input className="bg-transparent border-dark" type="select" name="city" id="city"  onChange={(event) => this.handleChange("city", event)}>
                                        <option value="Select">--City--</option>
                                        {this.createCityName()}
                                    </Input>
                                </div>
                                <div className={`col-md-2 mb-2 mb-md-0 px-md-2 select-wrapper mob-select-icon ${this.props.isOther ? "d-block" : "d-none"}`}>
                                    <Input className="bg-transparent border-dark" type="select" name="country" id="country"  onChange={(event) => this.handleChange("country", event)}>
                                        <option value="Select">--Country--</option>
                                        {this.createCountryName()}
                                    </Input>
                                </div>
                                <div className="col-md-2 mb-2 mb-md-0 px-md-2 select-wrapper mob-select-icon">
                                    <Input className="bg-transparent border-dark" type="select" name="bloodGroup" id="bloodGroup"  onChange={(event) => this.handleChange("bloodGroup", event)}>
                                        <option value="Select">--Blood Group--</option>
                                        {this.createBloodGroupItems()}
                                    </Input>
                                </div>
                                <div className="col-md-2 mb-2 mb-md-0 px-md-2">
                                    <Input className="bg-transparent border-dark" type="text" name="profession" id="profession" placeholder="--Profession--" onChange={(event) => this.handleChange("profession", event)} />
                                </div>
                                <div className="login-form col-5 col-md-2 pl-md-2">
                                    <Button onClick={(event) => this.searchMember(event)} className="search-button bg-primary border-0 text-white">Search</Button>
                                </div>
                            </FormGroup>
                        </Form>
                        <div className="d-flex justify-content-between align-items-center border-bottom border-dark pb-2">
                            <div className="font-weight-bold">List of all Members</div>
                            <div className="d-flex align-items-center">Per page:
                                        <div className="select-wrapper per-page-item">
                                    <Input type="select" name="country" id="country" placeholder="country" onChange={(event) => this.onPerPageChange(event)} className="item-per-page d-inline bg-transparent border-dark ml-1">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                    </Input>
                                </div>
                                <span className="member-directory-icon rounded border border-dark p-1 ml-1"><Media className="img-fluid" src={SortingICon} alt="download form" onClick={() => this.setSortingOrder()} /></span>
                            </div>
                        </div>
                        {
                            this.props.address.country.length > 0 ?
                                <div>
                                    <div className="row py-3 m-0 ">
                                        <div className="col-md-12 p-0 m-0 member-detail-container">
                                            <AjaxLoader isOpen={this.props.isLoading} />
                                            {this.props.memberDetails.length > 0 ? < MemberDetail /> : <NoItemFound />}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button onClick={this.handlePreviousPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== 0 ? "d-block" : "d-none"}`}>Previous</button>
                                        <button onClick={this.handleNextPageClick} className={`detail-button ${this.props.totalPageCount > 1 && this.props.pageNumber !== this.props.totalPageCount - 1 ? "d-block" : "d-none"}`}>Next</button>
                                    </div>
                                </div> : null


                        }
                    </div> </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isIndian: state.MemberDirectoryReducer.isIndian,
        isUK: state.MemberDirectoryReducer.isUK,
        isUS: state.MemberDirectoryReducer.isUS,
        isOther: state.MemberDirectoryReducer.isOther,
        memberDetails: state.MemberDirectoryReducer.memberDetails,
        cityList: state.MemberDirectoryReducer.cityList,
        countryList: state.MemberDirectoryReducer.countryList,
        sortIncreasingOrder: state.MemberDirectoryReducer.sortIncreasingOrder,
        memberPerPage: state.MemberDirectoryReducer.memberPerPage,
        pageNumber: state.MemberDirectoryReducer.pageNumber,
        isLoading: state.MemberDirectoryReducer.isLoading,
        totalPageCount: state.MemberDirectoryReducer.totalPageCount,
        countryAndStatesLoading: state.MemberDirectoryReducer.countryAndStatesLoading,
        address: state.AddressDetailReducer.address,
        cityList: state.MemberDirectoryReducer.cityList
    };
}

const mapDispatchToProps = dispatch => ({
    searchMemberDetails: (memberSearchFilters) => {
        dispatch(searchMemberDetails(memberSearchFilters));
    },
    setSearchNationality: (isIndian, isUS, isUK, isOther) => {
        dispatch(SetMembersNationalityType(isIndian, isUS, isUK, isOther));
    },
    setSortProperty: (isIncreasingOrder) => {
        dispatch(setSortProperty(isIncreasingOrder));
    },
    setMemberPerPage: (memberPerPage) => {
        dispatch(setMemberPerPage(memberPerPage));
    },
    setpageNumber: (pageNumber) => {
        dispatch(setpageNumber(pageNumber));
    },
    setSearchInProgress: () => {
        dispatch(setSearchInProgress());
    },
    addressDetail: () => {
        dispatch(addressDetail());
    },
    getCityList: (nationality) => {
        dispatch(getCityList(nationality));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDirectory);

