
import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/LoginAction';
import { Redirect } from 'react-router';
class Logout extends React.Component {

    componentDidMount() {
        
       this.props.dispatch(logout());
    }
    render() {        
        return null;
    }
}

export default connect(state => state)(Logout);




