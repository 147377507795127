import React from 'react';
import { connect } from 'react-redux';
import MemberDetailElement from './MatrimonialDetailElement';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import nextId from "react-id-generator";
import Approved from '../../../images/approved.png';
import NotApproved from '../../../images/not-approved.png';
import { Media } from 'reactstrap';

class MatrimonialDetail extends React.Component {
    render() {
        return (
            <Accordion allowZeroExpanded={true}>
                {this.props.matrimonialDetailsAdmin.map((item) => {
                    return (
                        <AccordionItem className="mb-3" key={nextId()}>
                            <AccordionItemHeading>
                                <AccordionItemButton className="focus-out">
                                    <div className="row w-100 p-2 m-0 align-items-center">
                                        <div className="col-7 col-md-3">
                                            <div className="text-muted">Name</div>
                                            <div>{item.personName}</div>
                                        </div>
                                        <div className="col-md-3 d-none d-md-block">
                                            <div className="text-muted">City</div>
                                            <div>{item.cityName}</div>
                                        </div>
                                        <div className="col-md-3 d-none d-md-block">
                                            <Media className="member-status-icon p-1 border-0" src={item.approve === undefined ? Approved : item.approve ? Approved: NotApproved} alt="approve status" />
                                        </div>
                                        <div className="col-5 col-md-3 focus-out text-right">
                                            <span><FontAwesomeIcon className="fa-w-14" icon={faChevronDown} /></span>
                                        </div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <MemberDetailElement member={item} deleteMember={this.props.deleteMatrimonial} ></MemberDetailElement>
                            </AccordionItemPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        matrimonialDetailsAdmin: state.MatrimonialReducer.matrimonialDetailsAdmin,
    };
}
export default connect(mapStateToProps)(MatrimonialDetail);

