import axios from 'axios';

export const bhajanGalleryDirPath = () => {
    
    return function (dispatch) {
        BhajanGalleryDirPath('/api/audiogallery/FetchBhajanGalleryDirPath', dispatch);
    };
}


const BhajanGalleryDirPath = (url, dispatch) => {
    dispatch(get());
    axios.get(process.env.PUBLIC_URL + url)
        .then(function (response) {
            const BhajanGalleryDirPath = response.data;
            dispatch(setPath(BhajanGalleryDirPath));

        })
    function get() { return { type: 'GET_BHAJAN_GALLERY_DIR_NAME' }; }
};
function setPath(BhajanGalleryDirPath) { return { type: 'SET_BHAJAN_GALLERY_DIR_NAME', BhajanGalleryDirPath }; }
